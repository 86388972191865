import Select from 'react-select'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../../elements/BackButton'
import { getTeacherSchoolData } from "../../../../redux/Teacher/user/action"
import { addOnlineClass } from "../../../../redux/Teacher/student/action"
import Modal from "react-modal";
import { CgCheckO } from 'react-icons/cg'
export class CreateMeeting extends Component {
    state = {
        loading: false,
        online_class: [],
        sections: [],
        subjects: [],
        open: false,
    };
    componentDidMount() {
        const { teacher } = this.props
        this.props.getTeacherSchoolData(teacher.data.auth_token)
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { teacher, schooldata } = this.props;
        const { online_class } = this.state;
        let formData = new FormData();
        formData.append("title", online_class.title)
        formData.append("school_id", schooldata.data.id)
        formData.append("class_id", online_class.class_id)
        formData.append("section_id", online_class.section_id)
        formData.append("subject_id", online_class.subject_id)
        formData.append("link", online_class.link)
        formData.append("date", online_class.date)
        formData.append("from", online_class.from)
        formData.append("to", online_class.to)
        formData.append("token", teacher.data.auth_token)
        this.props.addOnlineClass(formData).then((response) => {
            if (response?.data?.success) {
                this.modalOpen()
                document.getElementById('liveclassform').reset();
            }
        })
    }
    getSectionsSubjects = (val) => {
        const { schooldata } = this.props;
        const sections = schooldata?.data?.student_classes?.find((data) => data.id == val)?.sections?.map((section) => ({
            value: section.id,
            label: section.name
        }))
        this.setState({ sections: sections })
        const subjects = schooldata?.data?.student_classes?.find((data) => data.id == val)?.subjects?.map((sub) => ({
            value: sub.id,
            label: sub.name
        }))
        this.setState({ subjects: subjects })
    }
    handleInput(e, name) {
        let data = this.state.online_class;
        data[name] = e;
        this.setState({ online_class: data });
    }
    modalOpen = () => {
        this.setState({ open: true });
    };
    modalClose = () => {
        this.setState({ open: false });
    };
    render() {
        const { schooldata } = this.props;
        const { sections, subjects } = this.state;
        const classes = schooldata?.data?.student_classes?.map((data) => ({
            value: data.id,
            label: data.name
        }))
        return (
            <React.Fragment>
                <BackButton title={"Create Meeting"} />
                <Form onSubmit={this.onSubmit} id="liveclassform">
                    <div className='px-3' style={{ paddingBottom: '25vw' }}>
                        <div className="mt-3">
                            <InputGroup hasValidation className="mt-2 attendence-input" style={{ boxSizing: 'border-box' }} >
                                <FormControl
                                    type="text"
                                    placeholder="Title"
                                    className="attendence-input"
                                    onChange={e => {
                                        let value = e.target.value;
                                        this.handleInput(value, "title");
                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                        <div className="mt-3">
                            <InputGroup hasValidation className="mt-2 attendence-input" style={{ boxSizing: 'border-box' }} >
                                <FormControl
                                    type="text"
                                    placeholder="Meeting URL"
                                    className="attendence-input"
                                    onChange={e => {
                                        let value = e.target.value;
                                        this.handleInput(value, "link");
                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Class' styles={customStyles} options={classes}
                                onChange={e => {
                                    let value = e.value;
                                    this.handleInput(value, "class_id");
                                    this.getSectionsSubjects(value);
                                }} />
                        </div>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Section' styles={customStyles} options={sections}
                                onChange={e => {
                                    let value = e.value;
                                    this.handleInput(value, "section_id");
                                }} />
                        </div>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Subject' styles={customStyles} options={subjects}
                                onChange={e => {
                                    let value = e.value;
                                    this.handleInput(value, "subject_id");
                                }} />
                        </div>
                        <div className="mt-3">
                            <label className='text-muted'>Date</label>
                            <InputGroup hasValidation className="mt-2" style={{ boxSizing: 'border-box' }} >
                                <FormControl
                                    type="date"
                                    placeholder="Date"
                                    className="attendence-input"
                                    onChange={e => {
                                        let value = e.target.value;
                                        this.handleInput(value, "date");
                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                        <div className="mt-3">
                            <label className='text-muted'>From Time</label>
                            <InputGroup hasValidation className="mt-2" style={{ boxSizing: 'border-box' }} >
                                <FormControl
                                    type="time"
                                    placeholder="From"
                                    className="attendence-input"
                                    onChange={e => {
                                        let value = e.target.value;
                                        this.handleInput(value, "from");
                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                        <div className="mt-3">
                            <label className='text-muted'>To Time</label>
                            <InputGroup hasValidation className="mt-2" style={{ boxSizing: 'border-box' }} >
                                <FormControl
                                    type="time"
                                    placeholder="To"
                                    className="attendence-input"
                                    onChange={e => {
                                        let value = e.target.value;
                                        this.handleInput(value, "to");
                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' type="submit">
                            Submit
                        </button>
                    </div>
                </Form>
                <Modal
                    isOpen={this.state.open}
                    ariaHideApp={false}
                    onRequestClose={() => this.modalClose()}
                    style={modalStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "70vw",
                            width: '70vw',
                            zIndex: "9999",
                        }}
                    >
                        <React.Fragment>
                            <div className="d-flex flex-column justify-content-center align-items-between">
                                <div className='d-flex justify-content-center mt-5'
                                    style={{ fontSize: '18px', fontWeight: '600', color: '#2B2C2F' }}>
                                    <CgCheckO color={"#7ECB40"} size={"4em"} />
                                </div>
                                <div className='d-flex justify-content-center mt-4'
                                    style={{ fontSize: '15px', fontWeight: '400', color: '#2B2C2F' }}>
                                    Live class Added Successfully</div>
                                <div className='d-flex justify-content-between w-100 position-fixed' style={{ bottom: '20%', left: '0%' }}>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    schooldata: state.teacher.schooldata,
})

export default connect(mapStateToProps, {
    getTeacherSchoolData,
    addOnlineClass
})(CreateMeeting)

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        border: 'none', // tried border: 'none'
        boxShadow: 'none', // tried border: 'none'
        outline: 'none',

    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "88vw",
        border: "none",
        boarderRadius: "10px",
        fontSize: '13px',
        borderStyle: 'none',
        background: 'rgba(190, 175, 179, 0.05)',
        outline: 'none',
        fontWeight: '400',
        lineHeight: '14px',
        letterSpacing: '1px',
        color: '#86878E',

    })
}
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #C24A6B",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
