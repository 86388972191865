import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import BackButton from "../../../../elements/BackButton";
import { WEBSITE_URL } from "./../../../../api/index";
import { getStudent } from '../../../../redux/Parent/user/action';

class GradeCard extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.setState({ loading: true });
        this.props.getStudent(user.data.auth_token, student_id.value);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    downloadMark = (e) => {
        e.preventDefault();
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        let term_id = JSON.parse(localStorage.getItem("term_id"));
        let term = term_id.id;
        let stdnt_id = student_id ? student_id.value : user?.data?.students[0]?.id;
        window.location = WEBSITE_URL + "/download-mark?token=" + user.data.auth_token + "&id=" + stdnt_id + "&term=" + term;
    };
    render() {
        const { marks, student } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 320 658"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#FFFFFF"
                        >
                            <rect
                                x="19"
                                y="34"
                                rx="8"
                                ry="8"
                                width="22"
                                height="22"
                            />
                            <rect
                                x="120"
                                y="34"
                                rx="8"
                                ry="8"
                                width="110"
                                height="22"
                            />
                            <rect
                                x="18"
                                y="83"
                                rx="8"
                                ry="8"
                                width="286"
                                height="30"
                            />
                            <rect
                                x="18"
                                y="120"
                                rx="8"
                                ry="8"
                                width="286"
                                height="390"
                            />
                            <rect
                                x="8"
                                y="598"
                                rx="8"
                                ry="8"
                                width="303"
                                height="43"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="px-3 py-4">
                            <BackButton title={"Grade Card"} />
                        </div>

                        <div className="name-term d-flex justify-content-between px-4">
                            <div>
                                Name:{student?.student?.user?.name}
                            </div>
                            <div>
                                Roll no: {student?.student?.roll_number}
                            </div>
                        </div>
                        <div className="px-3 mt-2">
                            <div className="panel-board px-2 py-3 ">
                                <table className="w-100">
                                    <tr className="panel-head  px-3">
                                        <th className="panel-head-1 ps-3">
                                            Subject
                                        </th>
                                        <th className="panel-head-2 py-2">
                                            Grade
                                        </th>
                                        <th className="panel-head-2 py-2">
                                            Mark
                                        </th>
                                        <th className="panel-head-3 py-2 text-center">
                                            Out of
                                        </th>
                                    </tr>
                                    {marks?.mark?.length > 0 && marks?.mark?.map((mark, index) => (
                                        <tr className="panel-content">
                                            <td className="ps-3 py-2">
                                                {mark.subject?.name}
                                            </td>
                                            <td className="py-2 px-3">
                                                {mark.grade}
                                            </td>
                                            <td className="py-2 px-3">
                                                {mark.mark}
                                            </td>
                                            <td className="py-2 text-center">
                                                {mark.total_mark}
                                            </td>
                                        </tr>
                                    ))}
                                    {/* <tr className="panel-top">
                                        <td className="ps-3 py-1">Total</td>
                                        <td className="py-1">600/800</td>
                                        <td className="py-1 text-center">
                                            Grade: B+
                                        </td>
                                    </tr> */}
                                </table>
                            </div>
                        </div>

                        <div className="gap-2 px-3 attendence-btn-div w-100">
                            <button
                                className="attendence-btn w-100 py-3"
                                onClick={(e) => this.downloadMark(e)}
                            >
                                Download
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    marks: state.mark.marks,
    user: state.user.parent,
    student: state.user.student
});

export default connect(mapStateToProps, { getStudent })(GradeCard);
