import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { getTeacherSchoolData } from '../../../redux/Teacher/user/action'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { getTeacherMarkData } from './../../../redux/Teacher/mark/action';
export class ViewTeacherMarks extends Component {
    state = {
        sections: [],
        class_id: '',
        section_id: '',
        term_id: '',
    };
    componentDidMount() {
        const { teacher } = this.props
        this.props.getTeacherSchoolData(teacher.data.auth_token)
    }
    getSections = (val) => {
        const { schooldata } = this.props
        const class_sections = schooldata?.data?.student_classes?.find((data) => data.id == val)?.sections?.map((section) => ({
            value: section.id,
            label: section.name
        }))
        this.setState({ sections: class_sections })
    }
    handleInput(e,name) {
        if (name == 'class_id') {
            this.setState({ class_id: e })
        } else if (name == 'section_id') {
            this.setState({ section_id: e })
        } else if (name == 'term_id') {
            this.setState({ term_id: e })
        }
        
    }
    __submit = (e) => {
        // console.log(e)
        e.preventDefault();
        const { teacher } = this.props
        const { class_id, section_id, term_id } = this.state;
        console.log(class_id, section_id, term_id)
        if (class_id && section_id && term_id) {
            localStorage.setItem('class_id', JSON.stringify({ id: this.state.class_id }));
            localStorage.setItem('section_id', JSON.stringify({ id: this.state.section_id }));
            localStorage.setItem('term_id', JSON.stringify({ id: this.state.term_id }));
            this.props.getTeacherMarkData(teacher.data.auth_token, this.state.class_id, this.state.section_id, this.state.term_id)
            // console.log(this.state.class_id, this.state.section_id, this.state.term_id)  
                .then((response) => {
                //    console.log(response);
                 if (response.payload.success) {
                        this.props.history.push('/download-teacher-mark')
                    }
                })
        }
        
    }

    render() {
        const { schooldata } = this.props
        const { sections } = this.state
        const terms = schooldata?.data?.terms?.map((term) => ({
            value: term.id,
            label: term.name
        }))
        const classes = schooldata?.data?.student_classes?.map((cls) => ({
            value: cls.id,
            label: cls.name
        }))
        return (
            <React.Fragment>
                <BackButton title={"View Marks"} />
                <Form onSubmit={(e) => this.__submit(e)}>
                    <div className='px-3 mt-5'>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Class' styles={customStyles} options={classes}
                                onChange={(e) => {
                                    this.handleInput(e.value, 'class_id')
                                    this.getSections(e.value)
                                }}
                            />
                        </div>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Section' styles={customStyles} options={sections}
                            onChange={(e) => {
                                this.handleInput(e.value,'section_id')
                            }} />
                        </div>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Term' styles={customStyles} options={terms}
                            onChange={(e) => {
                                this.handleInput(e.value,'term_id')
                            }} />
                        </div>
                        {/* <div className="mt-3">
                        <Select className="text-muted attendence-input px-2" placeholder='Subject' styles={customStyles} options={options} />
                    </div>
                    <div className="mt-3">
                        <Select className="text-muted attendence-input px-2" placeholder='Student' styles={customStyles} options={options} />
                    </div> */}
                    </div>
                    <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' type='submit' >
                            Submit
                        </button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    schooldata: state.teacher.schooldata,
    teacherMarks: state.teacherMark.teacherMark,
})

export default connect(mapStateToProps, { getTeacherSchoolData, getTeacherMarkData })(ViewTeacherMarks)
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        border: 'none', // tried border: 'none'
        boxShadow: 'none', // tried border: 'none'
        outline: 'none',

    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "88vw",
        border: "none",
        boarderRadius: "10px",
        fontSize: '13px',
        borderStyle: 'none',
        background: 'rgba(190, 175, 179, 0.05)',
        outline: 'none',
        fontWeight: '400',
        lineHeight: '14px',
        letterSpacing: '1px',
        color: '#86878E',
    })

}
