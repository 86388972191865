import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import BackButton from '../../../../elements/BackButton'
import Select from 'react-select'
import { uploadHomework } from '../../../../redux/Parent/class/action'
import { TbFilePlus } from 'react-icons/tb'
import Truncate from 'react-truncate';
import Modal from "react-modal";
import Ink from 'react-ink'
import { Link } from 'react-router-dom'
import animationData from "../../../../assets/lottie/lf30_editor_bspltctv.json";
import Lottie from "react-lottie";

export class UploadHomeWork extends Component {
    state = {
        loading: false,
        homework: [],
        title: '',
        date: '',
        file: {},
        open: false,
    }

    uploadHomeWork = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { user, homework } = this.props;
        const { title, date, file } = this.state;
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.uploadHomework(user.data.auth_token, student_id ? student_id.value : user?.data?.students[0]?.id, homework?.homework?.id, title, file, date).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, open: true });
            }
        })
    }
    GoBack = (e) => {
        e.preventDefault();
        this.props.history.goBack()
    }

    render() {
        const { title, date, file } = this.state;
        const { homework } = this.props;
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader viewBox="0 0 280 550" backgroundColor={'#F3F3F3'}
                            foregroundColor={'#FFFFFF'}>
                            <rect x="13" y="66" rx="10" ry="10" width="255" height="50" />
                            <rect x="13" y="218" rx="10" ry="10" width="255" height="50" />
                            <rect x="13" y="300" rx="10" ry="10" width="255" height="75" />
                            <rect x="180" y="395" rx="10" ry="10" width="90" height="30" />
                            <rect x="13" y="480" rx="10" ry="10" width="255" height="50" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"Upload Homework"} />
                        <Form onSubmit={(e) => this.uploadHomeWork(e)}>
                            <div className="position-relative mt-5 px-3">
                                <div className='d-flex justify-content-between mt-3 homework-input'>
                                    <div className='text-muted'> Class  </div>
                                    <div style={{ fontWeight: '600' }}>  {homework?.homework?.section?.student_class?.name}  </div>
                                </div>
                                <div className='d-flex justify-content-between mt-3 homework-input'>
                                    <div className='text-muted'> Section  </div>
                                    <div style={{ fontWeight: '600' }}>  {homework?.homework?.section?.name}  </div>
                                </div>
                                <div className='d-flex justify-content-between mt-3 homework-input'>
                                    <div className='text-muted'> Subject  </div>
                                    <div style={{ fontWeight: '600' }}>  {homework?.homework?.subject?.name}  </div>
                                </div>
                                <input type='text' className='input-homework mt-3 w-100'
                                    placeholder='Homework title' required
                                    onChange={(e) => {
                                        let title = e.target.value;
                                        this.setState({ title: title });
                                    }} />
                                {/* <input type='date' className='input-homework mt-3 w-100'
                                    placeholder='Date' required
                                    onChange={(e) => {
                                        let date = e.target.value;
                                        this.setState({ date: date });
                                    }} /> */}
                                <div className='d-flex align-items-center justify-content-between mt-3 homework-input'>
                                    <div className='flex-grow-1'>
                                        <Truncate lines={1}>
                                            <span>{file?.name ? file.name : 'upload pdf file'}</span>
                                        </Truncate>
                                    </div>
                                    <label for="image" className="user-select-none px-2 image-input"  >
                                        Upload files <TbFilePlus size={'1.4em'} />
                                    </label>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        style={{ display: "none" }}
                                        id="image"
                                        onChange={(e) => {
                                            let file = e.target.files[0];
                                            this.setState({ file: file });
                                        }}
                                        required
                                    />
                                </div>

                            </div>
                            <div className="d-grid gap-2 px-3 position-fixed bottom-10  w-100">
                                <button type='submit' className="appointment-btn py-3">
                                    <span >Upload</span>
                                </button>
                            </div>
                        </Form>
                    </React.Fragment>
                )}
                <Modal
                    isOpen={this.state.open}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={this.onSheetClose}
                    style={modelCustomStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{ borderRadius: "5px", height: "180px", zIndex: '999999999999999999999' }}
                    >

                        <div className="px-10 col-12 d-flex justify-content-between align-items-center text-center">
                            <span className="mt-2 mb-0" style={{ fontSize: '1em', fontWeight: '700' }}>
                                Succesfully Uploaded Homework
                            </span>
                        </div>

                        <div className="">
                            <Lottie
                                options={defaultOptions}
                                height={100}
                                width={100}
                            />
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                            <div className="text-center w-100">
                                <button className="go-back-button p-2 px-3 text-center text-white position-relative" onClick={(e) => this.GoBack(e)}>
                                    Go Back
                                    <Ink duration={500} style={{ color: "#000" }} />
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    homework: state.class.homework
})
export default connect(mapStateToProps, {
    uploadHomework
})(UploadHomeWork)


const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: 'none',
        border: 'none', // tried border: 'none'
        boxShadow: 'none', // tried border: 'none'
        outline: 'none',

    }),
    control: (provided) => ({
        ...provided,
        height: '45px',
        border: "none",
        boarderRadius: "15px",
        fontSize: '13px',
        borderStyle: 'none',
        backgroundColor: '#F3F5F7',
        outline: 'none',
    }
    )

}

const modelCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        border: '2px solid #bf4672',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};
