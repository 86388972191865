import React, { Component } from "react";
import { connect } from "react-redux";
import { HiOutlineChevronLeft, HiOutlineLogout } from "react-icons/hi";
import Ink from "react-ink";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { logoutUser } from "../redux/Parent/user/action";
import { CgMenuLeft } from "react-icons/cg";
import { IoTvSharp } from "react-icons/io5";
// import component
import Drawer from "react-modern-drawer";
//import styles
import "react-modern-drawer/dist/index.css";
// import { AiFillHome } from "react-icons/ai";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { BsTable, BsUiChecks,BsImages } from "react-icons/bs";
// import { SiGoogleclassroom } from "react-icons/si";
import { FaAllergies, FaBriefcaseMedical, FaChild, FaPhotoVideo, FaTelegramPlane, FaUserAltSlash } from "react-icons/fa";
import { GiBookmarklet } from "react-icons/gi";
import { BiBookBookmark } from "react-icons/bi";
import { GoBook, GoSettings } from "react-icons/go";
import { MdOutlineLocalActivity } from "react-icons/md";

class HomeHead extends Component {
    state = {
        isOpen: false,
        open_logout: false,
    };

    toggleDrawer = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("hellooo");
    };
    handleAccountLogout = (e) => {
        e.preventDefault();
        this.setState({ open_logout: !this.state.open_logout, isOpen: false });
    };
    __handleLogout = (e) => {
        e.preventDefault();

        this.props.logoutUser();
        localStorage.clear();
        window.location.replace("/login");
        this.props.history.replace("/login");
    };
    render() {
        return (
            <React.Fragment>
                <div className="pt-2 ">
                    <div className="d-flex flex-row align-items-center justify-content-between position-relative">
                        <div
                            className="p-1 fw-bold"
                            style={{ borderRadius: "100px" }}
                            onClick={this.toggleDrawer}
                        >
                            <span className="fw-bold">
                                <CgMenuLeft size="1.7em" />
                            </span>
                        </div>
                        <div className="d-flex justify-content-center fw-bolder text-center">
                            <span
                                className=""
                                style={{
                                    fontSize: "22px",
                                    color: "373E43",
                                }}
                            >
                                Home
                            </span>
                        </div>
                        <div></div>
                    </div>
                </div>
                <Drawer
                    open={this.state.isOpen}
                    onClose={this.toggleDrawer}
                    direction="left"
                    className="bla bla bla pb-3"
                    style={{
                        background:
                            "linear-gradient(178.74deg, #BF4474 1.08%, #D9733A 156.57%)",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "19px",
                        letterSpacing: "0.03em",
                        overflowY: 'scroll',
                    }}
                >
                    <div
                        className="nav "
                        style={{
                            paddingTop: "20vw",
                        }}
                    >
                        {/* <div className="nav-tab d-flex align-items-center ms-3">
                            <div className="me-3 nav-tab-icon">
                                <AiFillHome size={25} />
                            </div>
                            <Link to="/home" className="text-white">
                                Home
                            </Link>
                        </div> */}
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <BsFillPlayCircleFill size={25} />
                            </div>
                            <Link to="/tutorial-library" className="text-white">
                                Tutorials/Online classes
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <BsTable size={25} />
                            </div>
                            <Link to="/time-table" className="text-white">
                                My Timetable
                            </Link>
                        </div>

                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <FaPhotoVideo size={25} />
                            </div>
                            <Link to="/post-on-wall" className="text-white">
                                School Media
                            </Link>
                        </div>

                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <FaChild size={25} />
                            </div>
                            <Link to="/student-details" className="text-white">
                                Child info
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <GiBookmarklet size={25} />
                            </div>
                            <Link to="/subjects" className="text-white">
                                Study materials
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <BsImages size={25} />
                            </div>
                            <Link to="/gallery-images" className="text-white">
                                Gallery
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <IoTvSharp size={25} />
                            </div>
                            <Link to="/occasional-videos" className="text-white">
                                Occasional Videos
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <FaAllergies size={25} />
                            </div>
                            <Link to="/allergies" className="text-white">
                                Allergies
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <FaBriefcaseMedical size={25} />
                            </div>
                            <Link to="/medical-instructions" className="text-white">
                                Medical Instruction
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <GoBook size={25} />
                            </div>
                            <Link to="/exam-schedule" className="text-white">
                                Exam shedule
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <GoSettings size={25} />
                            </div>
                            <Link to="/settings" className="text-white">
                                Settings
                            </Link>
                        </div>

                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <BsUiChecks size={25} />
                            </div>
                            <Link to="/answers" className="text-white">
                                Answers
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <BiBookBookmark size={25} />
                            </div>
                            <Link to="/today-class" className="text-white">
                                Today Classes
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <MdOutlineLocalActivity size={25} />
                            </div>
                            <Link to="/activity" className="text-white">
                                Todays Activity
                            </Link>
                        </div>
                        <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                            <div className="me-3 nav-tab-icon">
                                <FaTelegramPlane size={25} />
                            </div>
                            <Link to="/feedback" className="text-white">
                                Feedback
                            </Link>
                        </div>
                        <div
                            className="nav-tab d-flex align-items-center ms-3 mt-2"
                            onClick={(e) => this.handleAccountLogout(e)}
                        >
                            <div className="me-3 nav-tab-icon">
                                <HiOutlineLogout size={25} />
                            </div>
                            <div className="text-white">Logout</div>
                        </div>
                    </div>
                </Drawer>
                <Modal
                    isOpen={this.state.open_logout}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={() => this.handlePopup()}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="d-flex flex-column align-items-center"
                        style={{ backgroundColor: "#fff", padding: "13px" }}
                    >
                        <div className="text-center">
                            <span className="text-danger fs-4 fw-semibold">
                                Logout?
                            </span>
                        </div>
                        <div className="text-muted ms-4 mt-3">
                            <span>Are you sure you about logging out ?</span>
                        </div>

                        <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                            <div className="text-center mr-4 w-100">
                                <button
                                    className="delete-button-meals p-2 px-2 w-100 position-relative"
                                    onClick={(e) => this.handleAccountLogout(e)}
                                    style={{
                                        borderColor: "#000",
                                        color: "#000",
                                        border: "1px solid #000",
                                        width: "100%",
                                    }}
                                >
                                    {" "}
                                    NO
                                    <Ink
                                        duration={500}
                                        style={{ color: "#000" }}
                                    />
                                </button>
                            </div>

                            <div className="text-center w-100">
                                <button
                                    className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                    onClick={(e) => this.__handleLogout(e)}
                                    style={{
                                        width: "100%",
                                        backgroundColor: "#ce0060",
                                        borderColor: "#ce0060",
                                    }}
                                >
                                    YES
                                    <Ink
                                        duration={500}
                                        style={{ color: "#000" }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
});

export default connect(mapStateToProps, { logoutUser })(HomeHead);

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #BF4474",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
