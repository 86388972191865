import React, { Component } from "react";
import { BiArrowBack, BiSearch } from "react-icons/bi";
import {
    BsCheck2All,
    BsDot,
    BsFillEmojiLaughingFill,
    BsFillPlayFill,
    BsFillTelephoneFill,
} from "react-icons/bs";
import { RiEditLine } from "react-icons/ri";
import ProgressiveImage from "react-progressive-image";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../api";
import liveClass from "../../../assets/Images/live-class.png";
import cimg from "../../../assets/Images/chat-img.png";
import { IoMdAttach, IoMdCamera, IoMdFiling, IoMdSend } from "react-icons/io";
import {
    getTeacherSingleChatView,
    sendTeacherChat,
} from "../../../redux/Teacher/chat/action";
import Moment from "react-moment";
import teacher from "../../../assets/Images/bg.png";
import { RiMessage3Fill } from "react-icons/ri";

 class SingleTeacherChat extends Component {
    state = {
        loading: false,
        message: null,
        chats: [],
    };
    onHandleBack = () => {
        this.props.history.goBack();
    };
    componentDidMount() {
        this.__getChats();

        setTimeout(() => {
            // console.log(this.props.chats);
            // this.setState({chats:this.props.chats})
        }, 200);
    }
    __getChats = () => {
        this.setState({ loading: true });
        const { user } = this.props;
        console.log(this.props);
        this.props.getTeacherSingleChatView(
            user.data.auth_token,
            this.props.match.params.parent_id,
            this.props.match.params.teacher_id
        );
    };

    handleMessageInput = (e) => {
        this.setState({
            message: e.target.value,
        });
    };

    __sendChat = () => {
        const { user } = this.props;
        if (this.state.message?.length > 0) {
            this.props.sendTeacherChat(
                user.data.auth_token,
                this.props.match.params.parent_id,
                this.props.match.params.teacher_id,
                this.props.chats.convo_id,
                this.state.message
            );
            setTimeout(() => {
                this.props.getTeacherSingleChatView(
                    user.data.auth_token,
                    this.props.match.params.parent_id,
                    this.props.match.params.teacher_id
                );
            }, 200);
            setTimeout(() => {
                this.setState({
                    message: "",
                });
            }, 1000);
        }
    };

    static getDerivedStateFromProps(props) {
        if (
            props.chats &&
            props.chats.conversations &&
            props.chats.conversations.data &&
            props.chats.conversations.data.length > 0
        ) {
            console.log(props.chats.conversations.data);

            return {
                chats: props.chats.conversations.data,
                // ... other derived state properties
            };
        } else {
            return {
                chats: [],
                // ... other derived state properties
            };
        }
    }
    render() {
        const { chats,user } = this.props;

        return (
            <React.Fragment>
                <div className="name-bar d-flex flex-row justify-content-between align-items-center w-100  px-3 py-4">
                    <div className="d-flex flex-row justify-content align-items-center gap-2">
                        <div className="" onClick={() => this.onHandleBack()}>
                            <BiArrowBack style={{ fontSize: "1.2rem" }} />
                        </div>
                        <div>
                            {/* <div
                                    className=" align-items-center  "
                                    style={{ bottom: "1px",border:'3px solid green',borderRadius:'21px' }}
                                > */}
                            <ProgressiveImage
                                delay={20}
                                src={
                                    IMAGE_BASE_URL + chats?.user?.image
                                }
                                placeholder={teacher}
                            >
                                {(src, loading) => (
                                    <img
                                        src={src}
                                        className=""
                                        // src={
                                        //     this.state.file
                                        //         ? this.state.file
                                        //         : this.state.EditUser.image
                                        //         ? IMAGE_BASE_URL + this.state.EditUser.image
                                        //         : Profile
                                        // }
                                        style={{
                                            width: "19vw",
                                            objectFit: "cover",
                                            height: "19vw",
                                            borderRadius: "20%",
                                            backgroundRepeat: "round",
                                            // border: "3px solid green",
                                        }}
                                        alt="main-item"
                                    />
                                )}
                            </ProgressiveImage>
                        </div>
                        <div className="flex-frow-1 d-flex flex-column justify-content ">
                            <div>{chats?.user?.name}</div>
                            {/* <div style={{ color: "green" }}>Online</div> */}
                        </div>

                        {/* </div> */}
                    </div>

                    {/* <div>
                        <BsFillTelephoneFill style={{ fontSize: "1rem" }} />
                    </div> */}
                </div>
                <div>
                    {this.state.chats && this.state.chats.length > 0 ? (
                        <React.Fragment>
                            {this.state.chats.map((message, index) => (
                                <React.Fragment>
                                    <div>
                                        {/* <div className="px-3 pt-3 ">
                                            <div className="">
                                                <span className="message-bar ">
                                                    {message.body}
                                                </span>
                                                <div
                                                    className="text-muted p-1"
                                                    style={{
                                                        fontSize: "10px",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    10:25
                                                </div>
                                            </div>
                                        </div> */}

                                        <div
                                            className={
                                                message.sender?.id === user?.data?.staff?.id
                                                    ? "px-3 pt-3 text-end "
                                                    : "px-3 pt-3 "
                                            }
                                        >
                                            <div className="">
                                                <span className="message-bar ">
                                                    {message.body}{user?.data?.staff?.id}
                                                </span>
                                            </div>
                                            <div
                                                className="text-muted p-1"
                                                style={{
                                                    fontSize: "10px",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                <Moment format="hh:mm A">
                                                    {message.created_at}
                                                </Moment>
                                                {/* <BsCheck2All
                                                        style={{
                                                            color: "green",
                                                        }}
                                                    /> */}
                                            </div>
                                        </div>

                                        {/* <div className="px-3 pt-3 text-end ">
                        <div className="">
                            <ProgressiveImage
                                delay={20}
                                placeholder={
                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                }
                            >
                                {(src, loading) => (
                                    <img
                                        className=""
                                        src={cimg}
                                        // src={
                                        //     this.state.file
                                        //         ? this.state.file
                                        //         : this.state.EditUser.image
                                        //         ? IMAGE_BASE_URL + this.state.EditUser.image
                                        //         : Profile
                                        // }
                                        style={{
                                            width: "40vw",
                                            objectFit: "cover",
                                            height: "55vw",
                                            borderRadius: "14px",
                                            backgroundRepeat: "round",
                                        }}
                                        alt="main-item"
                                    />
                                )}
                            </ProgressiveImage>
                        </div>
                        <div
                            className="text-muted"
                            style={{ fontSize: "10px", fontWeight: "400" }}
                        >
                            10:30
                            <BsCheck2All />
                        </div>
                    </div> */}
                                    </div>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div
                                className="d-flex flex-column align-items-center justify-content-end"
                                style={{ height: "70vh" }}
                            >
                                <div>Start Chatting Now</div>
                                <div>
                                    <RiMessage3Fill color="#6c757d" size={30}/> ...
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div style={{ height: "100px" }}></div>
                </div>

                <div className="name-bar d-flex flex-row justify-content-around align-items-center w-100  px-2 py-3 text-muted position-fixed bottom-0">
                    {/* <div>
                        <IoMdFiling style={{ fontSize: "1.2rem" }} />
                    </div> */}

                    <div className="flex-grow-1 d-flex  flex-row justify-content-between align-items-center ">
                        <div className="">
                            <div className="d-flex flex-row align-items-center mobile-typeBar py-2 px-2 ms-2">
                                <input
                                    onChange={(e) => this.handleMessageInput(e)}
                                    type="text"
                                    className="chat-bar"
                                    value={this.state.message}
                                    defaultValue={this.state.message}
                                    style={{ width: "75vw" }}
                                    placeholder="Type something"
                                />
                                {/* <span className="">
                                    <BsFillEmojiLaughingFill
                                        set="curved"
                                        style={{ fontSize: "1.5em", color: "" }}
                                    />
                                </span> */}
                            </div>
                        </div>
                    </div>
                    <div onClick={this.__sendChat} className="ms-2">
                        <IoMdSend style={{ fontSize: "1.2rem" }} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    chats: state.teacherChat.chats,
    send_teacher_chat: state.teacherChat.send_teacher_chat,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, {
    getTeacherSingleChatView,
    sendTeacherChat,
})(SingleTeacherChat);
