import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";

class Address extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 550"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                                 <rect
                                x="6"
                                y="40"
                                rx="8"
                                ry="8"
                                width="27"
                                height="27"
                            />
                               <rect
                                x="123"
                                y="44"
                                rx="7"
                                ry="7"
                                width="54"
                                height="15"
                            />



                            <rect
                                x="13"
                                y="94"
                                rx="10"
                                ry="10"
                                width="258"
                                height="49"
                            />
                            <rect
                                x="13"
                                y="157"
                                rx="10"
                                ry="10"
                                width="258"
                                height="49"
                            />
                            <rect
                                x="13"
                                y="216"
                                rx="10"
                                ry="10"
                                width="258"
                                height="49"
                            />
                            <rect
                                x="13"
                                y="280"
                                rx="10"
                                ry="10"
                                width="258"
                                height="49"
                            />

                            <rect
                                x="13"
                                y="350"
                                rx="10"
                                ry="10"
                                width="258"
                                height="49"
                            />

                           <rect
                                x="13"
                                y="504"
                                rx="10"
                                ry="10"
                                width="258"
                                height="51"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="address-main-div py-4">
                            <div className="py-4">
                                <BackButton title={"Address"} />
                            </div>
                            <div>
                                <div
                                    className=" px-2"
                                    style={{ color: " #9B9B9B" }}
                                >
                                    <Form
                                        className=" px-3 pt-3"
                                        style={{ width: "100%",padding:"1vh" }}
                                    >
                                        <input
                                            type="text"
                                            className="input-content mt-3 p-4"
                                            placeholder="Full name"
                                            style={{
                                                width: "100%",

                                            }}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Address"
                                            className="input-content mt-3 p-4 "

                                        />
                                        <input
                                            type="text"
                                            placeholder="City"
                                            className="input-content mt-3 p-4"
                                            style={{
                                                width: "100%",
                                                color: "#F9F9F9",
                                            }}
                                        />
                                        <input
                                            type="number"
                                            placeholder="Pin code"
                                            className="input-content mt-3 p-4"
                                            style={{ width: "100%" }}
                                        />
                                        <input
                                            type="number"
                                            placeholder="Alternate phone number"
                                            className="input-content mt-3 p-4"
                                            style={{ width: "100%" }}
                                        />
                                    </Form>
                                    <div className="gap-2 px-2 save-btn-div w-100 d-flex justify-content-center">
                                        <button className="save-btn w-100">
                                            Save Address
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Address);
