import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAnswers } from '../../../redux/Parent/class/action'
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";
import image from '../../../assets/Images/noanswers.png'
import Ink from 'react-ink';
import { IMAGE_BASE_URL } from '../../../api';


export class Answers extends Component {
    state = { loading: false }
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getAnswers(user.data.auth_token, student_id ? student_id.value : user?.data?.students[0]?.id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false });
                }
            })
    }
    render() {
        const { answers } = this.props
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 539"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="15" y="26" rx="6" ry="9" width="24" height="24" />
                            <rect x="110" y="29" rx="6" ry="9" width="60" height="18" />
                            <rect x="20" y="87" rx="10" ry="10" width="235" height="72" />
                            <rect x="20" y="175" rx="10" ry="10" width="235" height="72" />
                            <rect x="20" y="264" rx="10" ry="10" width="235" height="72" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {answers?.success ?
                            <>
                                <div className="">
                                    <BackButton title={"Uploaded Answers"} />
                                    <div className="px-3 mt-3 pt-1" style={{ paddingBottom: '15vw' }}>
                                        {/* <div style={{ color: '#373E43', fontSize: '17px', fontWeight: '500' }} className='mt-2 px-2'>
                                            <span>Uploaded Answers</span>
                                        </div> */}
                                        {answers?.data?.length > 0 ?
                                            <>
                                                {answers?.data?.length > 0 && answers?.data?.map((data) => (
                                                    <div className='mt-3'>
                                                        <div className="tutorial-library bg-white px-2 w-100">
                                                            <div className=" tutorial-card p-2 mt-2 position-relative">
                                                                <div className="d-flex">
                                                                    <div className="ms-2 d-flex flex-column">
                                                                        <span className="tutorial-heading py-1">
                                                                            {data?.name}
                                                                        </span>
                                                                        <span className="tutorial-details">
                                                                            Class {data?.class?.name}{" "} {data?.section?.name}
                                                                        </span>
                                                                        <span className="tutorial-details pb-2">
                                                                            Subject :
                                                                            {data?.subject?.name} ({data?.term?.name})
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center position-absolute bottom-0 end-0 m-2">
                                                                    <a href={IMAGE_BASE_URL + data?.file} target="_blank" download >
                                                                        <div className="tutorial-details-btn me-1 p-2">
                                                                            Download
                                                                        </div>
                                                                        <Ink style={{ color: '#aaaaaa' }} />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                            : <div className="d-flex flex-column align-items-center pt-5 mt-5 ">
                                                <img src={image} alt="no-data" style={{ width: '85%', objectFit: 'cover', }} />
                                                <span style={{ fontSize: '11px' }}>Sorry..No Files Found...</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>
                            : null}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    answers: state.class.answers,
})

export default connect(mapStateToProps, { getAnswers })(Answers)
