import React, { Component } from "react";
import { connect } from "react-redux";
// import DatePicker from "react-horizontal-datepicker";
// import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";
import Footer from "../Footer";
import { getEvents } from '../../../redux/Parent/class/action'
import { IMAGE_BASE_URL } from "../../../api";
import ProgressiveImage from "react-progressive-image";
import Truncate from 'react-truncate';
import image from '../../../assets/Images/events.png'
import moment from "moment";

class Events extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getEvents(user.data.auth_token, student_id ? student_id.value : user?.data?.students[0]?.id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false });
                }
            })
    }
    // hii =(val)=>{
    //     console.log(val);
    // }
    render() {
        const { events } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader viewBox="0 0 280 539" backgroundColor={"#F3F3F3"} foregroundColor={"#FFFFFF"}>
                            <rect x="13" y="80" rx="10" ry="10" width="255" height="130" />
                            <rect x="13" y="220" rx="10" ry="10" width="255" height="130" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {events?.success && events?.data ?
                            <div className="px-2">
                                <div className="mt-2">
                                    <BackButton title={"Events"} />
                                </div>
                                <div className="px-2 pt-1 mt-3" style={{ paddingBottom:'30vw' }}>
                                    {/* <div className="mt-3">
                                        <DatePicker color={"linear-gradient(180deg, #BF4672 0%, #C65264 100%)"}
                                            getSelectedDay={this.hii} />
                                    </div> */}
                                    {events?.data?.length > 0 ?
                                        <>
                                            {events?.data?.length > 0 && events?.data?.map((event, i) => (
                                                <div className="mt-2" key={i}>
                                                    <div className=" position-relative">
                                                        <ProgressiveImage
                                                            delay={20}
                                                            src={
                                                                IMAGE_BASE_URL +
                                                                event?.image
                                                            }
                                                            placeholder={
                                                                "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                                            }
                                                        >
                                                            {(src, loading) => (
                                                                <img
                                                                    src={src}
                                                                    style={{
                                                                        width: "90vw",
                                                                        height: "50vw",
                                                                        borderRadius: "12px",
                                                                        objectFit: "cover",
                                                                        backgroundRepeat:
                                                                            "no repeat",
                                                                    }}
                                                                    className=""
                                                                    alt="event"
                                                                />
                                                            )}
                                                        </ProgressiveImage>
                                                        <div className="events-details p-2 position-absolute" style={{ bottom: '4%', left: "7px" }} onClick={() => this.hiiii()}>
                                                            <div className="m-0 text-white" style={{ fontSize: "19px", fontWeight: "bold", lineHeight: "18px" }}>
                                                                <Truncate lines={1}>
                                                                    <span >{event?.title}</span>
                                                                </Truncate>
                                                            </div>
                                                            <div className="text-white pt-1" style={{ fontSize: "14px", fontWeight: "400", lineHeight: "16px" }}>
                                                                <Truncate lines={1}>
                                                                    <span>{event?.description}</span>
                                                                </Truncate>
                                                            </div>
                                                            <div className="text-white pt-1" style={{ fontSize: "14px", fontWeight: "400", lineHeight: "16px" }}>
                                                                <Truncate lines={1}>
                                                                    <span>{moment(event?.from).format("DD MM YYYY")} - {moment(event?.to).format("DD MM YYYY")}</span>
                                                                </Truncate>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {/* <div className="mt-4">
                                                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2 }}                     >
                                                    <Masonry>
                                                        <img src={eventImg1} alt="event" />
                                                        <img src={eventImg3} alt="event" />
                                                        <img src={eventImg2} alt="event" />
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </div> */}
                                        </>
                                        : <div className="d-flex flex-column align-items-center pt-5 mt-5">
                                            <img src={image} style={{ width: '85%', objectFit: 'cover', }} />
                                            <span style={{ fontSize: "11px" }}>No Events Found...</span>
                                        </div>}
                                </div>
                            </div>
                            : null}
                    </React.Fragment>
                )}
                <Footer current={"wall"} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    events: state.class.events,
});

export default connect(mapStateToProps, { getEvents })(Events);
