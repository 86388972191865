import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProgressiveImage from 'react-progressive-image';
import { IMAGE_BASE_URL } from '../../../../../api';
import "react-awesome-lightbox/build/style.css";
import LightBox from 'react-awesome-lightbox';

export class SingleImage extends Component {
    state = {
        image: '',
        open: false,
    }
    openViewer = () => {
        this.setState({ open: !this.state.open })
    }

    render() {
        const { media } = this.props;
        return (
            <React.Fragment>
                {this.state.open &&
                    <LightBox image={IMAGE_BASE_URL + media.image}
                        onClose={this.openViewer} />
                }
                <div onClick={this.openViewer} >
                    <ProgressiveImage
                        delay={30}
                        src={IMAGE_BASE_URL + media.image}
                        placeholder={"https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"}
                    >
                        {(src, loading) => (
                            <img src={src}
                                style={{
                                    width: '30vw',
                                    height: '30vw',
                                    objectFit: 'cover',
                                    borderRadius: '12px',
                                    // margin:'3px',
                                    paddingLeft:"5px",
                                    paddingRight:"5px",
                                    paddingBottom:"5px",
                                    // border: '1px solid #fff'
                                }}
                                alt="gallery" />
                        )}
                    </ProgressiveImage>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(SingleImage)
