import Axios from "axios";
import {
    GET_TEACHER_REMARKS_URL,
    GET_TEACHER_LIVE_CLASSES_URL,
    DELETE_TEACHER_LIVE_CLASSES_URL,
    ADD_LIVE_CLASS_URL,
    GET_GALLERY_DATA_URL,
    ADD_REMARK_URL,
    GET_ALL_MEETING_APPOINTMENTS_URL,
    RESPONSE_TO_MEETING_APPOINTMENTS_URL,
    GET_ALL_TEACHER_STUDENTS_URL,
    GET_ALL_SINGEL_STUDENTS_INFO_URL,
    GET_TEACHER_TUTORIALS_URL,
    ADD_TEACHER_TUTORIAL_URL,
    GET_TEACHER_TIMETABLE_URL,
    GET_TEACHER_ALLERGIES_URL,
    GET_TEACHER_MEDICAL_URL,
    GET_SEARCH_MEDICAL_URL,
    GET_TEACHER_ATTENDENCE_DATA_URL,
    ADD_STUDENT_ATTENDENCE_URL,
    CHANGE_STUDENT_ATTENDENCE_URL,
    SUBMIT_TOILET_INFO_URL,
    GET_TOILET_INFO_URL,
    UPLOAD_ANSWER_SHEET_URL,
    GET_TEACHER_UPLOADED_ANSWERS_URL,
    GET_TEACHER_MATERIALS_URL,
    ADD_TEACHER_MATERIAL_URL
} from "../../../api";

import {
    GET_TEACHER_REMARKS,
    GET_TEACHER_LIVE_CLASSES,
    GET_GALLERY_DATA,
    GET_ALL_MEETING_APPOINTMENTS,
    GET_ALL_TEACHER_STUDENTS,
    GET_ALL_SINGEL_STUDENTS_INFO,
    GET_TEACHER_TUTORIALS,
    GET_TEACHER_TIMETABLE,
    GET_TEACHER_ALLERGIES,
    GET_TEACHER_MEDICAL,
    GET_TEACHER_ATTENDENCE_DATA,
    ADD_STUDENT_ATTENDENCE,
    CHANGE_STUDENT_ATTENDENCE,
    GET_TOILET_INFO,
    GET_TEACHER_UPLOADED_ANSWERS,
    GET_TEACHER_MATERIALS,
} from "./actionType";

//remarks
export const getTeacherRemarks = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_REMARKS_URL, { token }).then((response) => {
        const remarks = response.data;
        return dispatch({ type: GET_TEACHER_REMARKS, payload: remarks });
    });
};
export const addRemark = (formdata) => (dispatch) => {
    return Axios.post(ADD_REMARK_URL, formdata);
};

//liveclass
export const getTeacherOnlineClasses = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_LIVE_CLASSES_URL, { token }).then(
        (response) => {
            const liveclasses = response.data;
            return dispatch({
                type: GET_TEACHER_LIVE_CLASSES,
                payload: liveclasses,
            });
        }
    );
};
export const addOnlineClass = (formdata) => (dispatch) => {
    return Axios.post(ADD_LIVE_CLASS_URL, formdata);
};
export const deleteLiveClass = (token, id) => {
    return Axios.post(DELETE_TEACHER_LIVE_CLASSES_URL, { token, id })
}

//gallery
export const getGalleryData = (token) => (dispatch) => {
    return Axios.post(GET_GALLERY_DATA_URL, { token })
        .then((response) => {
            const gallery = response.data;
            return dispatch({ type: GET_GALLERY_DATA, payload: gallery });
        })
}

// appointments
export const getAllAppointments = (token) => (dispatch) => {
    return Axios.post(GET_ALL_MEETING_APPOINTMENTS_URL, { token }).then(
        (response) => {
            const appointment = response.data;
            return dispatch({
                type: GET_ALL_MEETING_APPOINTMENTS,
                payload: appointment,
            });
        }
    );
};

export const responseToAppointments = (token, id, status) => () => {
    return Axios.post(RESPONSE_TO_MEETING_APPOINTMENTS_URL, {
        token,
        id,
        status,
    });
};

// my students
export const getAllTeacherStudents = (token) => (dispatch) => {
    return Axios.post(GET_ALL_TEACHER_STUDENTS_URL, { token }).then(
        (response) => {
            const students = response.data;
            return dispatch({
                type: GET_ALL_TEACHER_STUDENTS,
                payload: students,
            });
        }
    );
};
export const getSingleStudentInfo = (token, id) => (dispatch) => {
    return Axios.post(GET_ALL_SINGEL_STUDENTS_INFO_URL, { token, id }).then(
        (response) => {
            const students = response.data;
            return dispatch({
                type: GET_ALL_SINGEL_STUDENTS_INFO,
                payload: students,
            });
        }
    );
};
//tutorials
export const getTeacherTutorials = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_TUTORIALS_URL, { token })
        .then((response) => {
            let tutorials = response.data;
            return dispatch({ type: GET_TEACHER_TUTORIALS, payload: tutorials });
        })
}
export const addTutorial = (formdata) => (dispatch) => {
    return Axios.post(ADD_TEACHER_TUTORIAL_URL, formdata)
}
//time table
export const getTeacherTimeTable = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_TIMETABLE_URL, { token })
        .then((response) => {
            let timetable = response.data;
            return dispatch({ type: GET_TEACHER_TIMETABLE, payload: timetable })
        })
}

export const getStudentAllergies = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_ALLERGIES_URL, { token })
        .then((response) => {
            let allergies = response.data;
            return dispatch({ type: GET_TEACHER_ALLERGIES, payload: allergies })
        })
}

export const getAttendenceData = (token, user_id) => (dispatch) => {
    return Axios.post(GET_TEACHER_ATTENDENCE_DATA_URL, { token, user_id })
        .then((response) => {
            let attendence = response.data;
            return dispatch({ type: GET_TEACHER_ATTENDENCE_DATA, payload: attendence })
        })
}

export const addStudentAttendence = (token, user_id, date, type) => (dispatch) => {
    return Axios.post(ADD_STUDENT_ATTENDENCE_URL, { token, user_id, date, type })
        .then((response) => {
            let attendence = response.data;
            return dispatch({ type: ADD_STUDENT_ATTENDENCE, payload: attendence })
        })
}

export const changeStudentAttendence = (token, id, type) => (dispatch) => {
    return Axios.post(CHANGE_STUDENT_ATTENDENCE_URL, { token, id, type })
        .then((response) => {
            let attendence = response.data;
            return dispatch({ type: CHANGE_STUDENT_ATTENDENCE, payload: attendence })
        })
}
export const getStudentMedical = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_MEDICAL_URL, { token })
        .then((response) => {
            let medical = response.data;
            return dispatch({ type: GET_TEACHER_MEDICAL, payload: medical })
        })
}

export const searchMedical = (value) => (dispatch) => {
    return Axios.post(GET_SEARCH_MEDICAL_URL, { value })
}
// toilet info
export const submitToilletInfo = (formData) => (dispatch) => {
    return Axios.post(SUBMIT_TOILET_INFO_URL, formData)
}

export const getToiletInfo = (token) => (dispatch) => {
    return Axios.post(GET_TOILET_INFO_URL, { token })
        .then((response) => {
            let medical = response.data;
            return dispatch({ type: GET_TOILET_INFO, payload: medical })
        })
}
//upload answer sheet
export const getUploadedAnswers = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_UPLOADED_ANSWERS_URL, { token }).then((response) => {
        const answers = response.data;
        return dispatch({ type: GET_TEACHER_UPLOADED_ANSWERS, payload: answers });
    });
};
export const uploadAnswerSheet = (formData) => (dispatch) => {
    return Axios.post(UPLOAD_ANSWER_SHEET_URL, formData)
}
//study materials
export const getStudyMaterials = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_MATERIALS_URL, { token }).then((response) => {
        const materials = response.data;
        return dispatch({ type: GET_TEACHER_MATERIALS, payload: materials });
    });
};
export const uploadMaterial = (formData) => (dispatch) => {
    return Axios.post(ADD_TEACHER_MATERIAL_URL, formData)
}

