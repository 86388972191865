import React, { Component } from "react";
import './mystudent.css'
import { IoIosCall } from "react-icons/io";
import student_profile from '../../../assets/Images/my-student-imag.png'
import BackButton from "../../../elements/BackButton";
import { Link } from "react-router-dom";
import { GrNext } from "react-icons/gr";
import { connect } from 'react-redux'
import { getAllTeacherStudents } from "../../../redux/Teacher/student/action";
import { IMAGE_BASE_URL } from "../../../api";
import img from '../../../assets/Images/noreview.png'
import ContentLoader from "react-content-loader";
export class MyStudent extends Component {
    state = {
        id: null,
        show: false,
        students: [],
        loading: false,
    }
    componentDidMount() {
        this.setState({ loading: true})
        const { user } = this.props;
        this.props.getAllTeacherStudents(user.data.auth_token);
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1500)
    }
    showData = (val) => {
        this.setState({ id: val, show: !this.state.show });
        const { classes } = this.props;
        const students = classes?.students?.filter((data) => data.student_class_id == val)
        this.setState({ students: students })

    };
    render() {
        const { classes } = this.props;
        const { students } = this.state;
        console.log(students)
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 236 470"
                            backgroundColor="#F3F3F3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="10" y="30" rx="5" ry="5" width="25" height="25" />
                            <rect x="75" y="29" rx="6" ry="9" width="90" height="25" />
                            <rect x="13" y="65" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="111" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="158" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="206" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="252" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="298" rx="10" ry="10" width="210" height="40" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title="My Students" />
                        {classes?.data?.length > 0 ? (
                            <div className="px-3 mt-4">
                                {classes?.data?.map((cls) => (
                                    <React.Fragment>
                                        <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                            <div className=''><span>{cls.name}</span></div>
                                            <div className='' onClick={() => this.showData(cls.id)}><GrNext style={this.state.id == cls.id && this.state.show ? { transform: 'rotate(90deg)' } : null} /></div>
                                        </div>
                                        <div style={this.state.id == cls.id && this.state.show ? { display: "block" } : { display: "none" }}>
                                            {students?.length > 0 ? (
                                                <React.Fragment>
                                                    {students?.map((student) => (
                                                        <div className="d-flex flex-column  my-student-container mt-2" >
                                                            <Link to={"/view-single-student/" + student?.id}>
                                                                <div className="my_student_details_container p-2 " >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <div className="d-flex  fw-semibold" style={{ color: '#2B2C2F' }}>
                                                                                <span className="me-2 ">Name </span>
                                                                                <span className="me-1" >:</span>
                                                                                <span>{student?.user?.name}</span>
                                                                            </div>
                                                                            <div className="d-flex my-student-text " >
                                                                                <span className="me-2">Class : {cls.name}</span>
                                                                                <span>Section : {student?.section?.name}</span>
                                                                            </div>
                                                                            <div className="d-flex my-student-text" >
                                                                                <span className="me-2">Roll No</span>
                                                                                <span className="me-1">:</span>
                                                                                <span >{student?.roll_number}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <img className="rounded-circle" width="60px" height="60px" src={IMAGE_BASE_URL + student?.user?.image} alt="Class_report" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <div className="my_student_contact_details_container p-2">
                                                                {/* <div className="my_student_call_icon">
                                                    <IoIosCall className="fs-1" style={{ color: '#85B6FF' }} />
                                                </div> */}
                                                                <div className="d-flex my-student-text" >
                                                                    <span className="me-1">Contact</span>
                                                                    <span className="me-1">:</span>
                                                                    <span>{student?.father_phone}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </React.Fragment>
                                            ) : (<span className="d-flex justify-content-center fw-500 fs-5">"No Student In this Class"</span>)}
                                        </div>
                                    </React.Fragment>
                                ))}

                            </div>) : (
                            <div className='mt-5 pt-5 w-100 d-flex flex-column justify-content-center align-items-center'>
                                <img src={img} alt="no reviews" style={{ width: '65vw' }} className='' />
                                <span>No Class Assigned</span>
                            </div>
                        )}


                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    classes: state.student.myStudents,
})

export default connect(mapStateToProps, { getAllTeacherStudents })(MyStudent)
