import React, { Component } from "react";
import { connect } from "react-redux";
import Item1 from "../../../../assets/Images/item1.png";
import Item2 from "../../../../assets/Images/item2.png";
import ProgressiveImage from "react-progressive-image";
import { AiOutlineHeart } from "react-icons/ai";
import { IMAGE_BASE_URL } from "../../../../api/index";
import { Link } from "react-router-dom";

export class Items extends Component {
    __getOfferPercnetage = (mrp, price) => {
        return (mrp * price) / 100;
    };
    render() {
        const { items, is_kit } = this.props;
        return (
            <React.Fragment>
                {items && items.length > 0 ? (
                    <div
                        className=" d-grid px-2 mt-3"
                        style={{
                            gridTemplateColumns: "repeat(2,1fr)",
                            gap: "8px",
                            paddingBottom: "25vw",
                        }}
                    >
                        <React.Fragment>
                            {items.map((item, index) => (
                                <Link
                                    to={
                                        is_kit
                                            ? "/single-item/kit/" + item.id
                                            : "/single-item/item/" + item.id
                                    }
                                >
                                    <div className="position-relative">
                                        {is_kit ? (
                                            <ProgressiveImage
                                                delay={20}
                                                src={
                                                    IMAGE_BASE_URL + item.image
                                                }
                                                placeholder={
                                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                                }
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        className=""
                                                        style={{
                                                            width: "45vw",
                                                            height: "53vw",
                                                            borderRadius:
                                                                "20px",
                                                            objectFit: "cover",
                                                            backgroundRepeat:
                                                                "round",
                                                        }}
                                                        src={src}
                                                        alt="main-item"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        ) : (
                                            <ProgressiveImage
                                                delay={20}
                                                src={
                                                    IMAGE_BASE_URL +
                                                    item.item_image1
                                                }
                                                placeholder={
                                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                                }
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        className=""
                                                        style={{
                                                            width: "45vw",
                                                            height: "53vw",
                                                            borderRadius:
                                                                "20px",
                                                            objectFit: "cover",
                                                            backgroundRepeat:
                                                                "round",
                                                        }}
                                                        src={src}
                                                        alt="main-item"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        )}

                                        {this.__getOfferPercnetage(
                                            item.price,
                                            0
                                        ) > 0 && (
                                            <div
                                                className="store-percentage position-absolute  px-2 "
                                                style={{
                                                    top: "5px",
                                                    right: "5px",
                                                }}
                                            >
                                                {this.__getOfferPercnetage(
                                                    item.price,
                                                    0
                                                )}
                                            </div>
                                        )}

                                        <div className="d-flex flex-row justify-content-between px-1">
                                            <div>
                                                <h1 className="store-items-content">
                                                    {item.name}
                                                </h1>
                                            </div>
                                            {/* <div>
                                        <AiOutlineHeart />
                                    </div> */}
                                        </div>
                                        <div>
                                            <h1 className="store-items-price px-1">
                                                Rs {item.price}
                                            </h1>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </React.Fragment>
                    </div>
                ) : (
                    <div className="d-flex align-items-center justify-content-center w-100">
                        No Items Found
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Items);
