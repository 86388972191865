import React, { Component } from "react";
import { connect } from "react-redux";
import noImage from '../../../../assets/Images/noImage.png'
import { getSingleTeacher } from "../../../../redux/Parent/class/action";
import ContentLoader from "react-content-loader"
import BackButton from '../../../../elements/BackButton';
import ProgressiveImage from "react-progressive-image";
import { IMAGE_BASE_URL } from "../../../../api";
import { AiFillStar } from "react-icons/ai";

class SingleTeacher extends Component {
    state = {
        loading: false,
        teacher: [],
        staff: []
    }

    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true })
        this.props.getSingleTeacher(user.data.auth_token, this.props.match.params.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, teacher: response.payload.teacher, staff: response.payload.staff })
            }
        })
    }

    contactSupprt = (phone) => {
        console.log(phone)
    }
    render() {
        const { teacher, staff } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                viewBox="0 0 236 470"
                                backgroundColor="#F3F3F3"
                                foregroundColor="#ecebeb"  >
                                <circle cx="64" cy="74" r="41" />
                                <rect x="120" y="50" rx="10" ry="10" width="100" height="15" />
                                <rect x="120" y="90" rx="10" ry="10" width="100" height="15" />
                                <rect x="10" y="166" rx="10" ry="10" width="220" height="52" />
                                <rect x="10" y="240" rx="10" ry="10" width="220" height="52" />
                                <rect x="10" y="315" rx="10" ry="10" width="220" height="52" />
                                <rect x="10" y="385" rx="10" ry="10" width="220" height="52" />
                                <rect x="10" y="455" rx="10" ry="10" width="220" height="52" />
                                <rect x="10" y="515" rx="10" ry="10" width="220" height="52" />
                            </ContentLoader>
                        </React.Fragment>
                    ) :
                    (

                        <React.Fragment>
                            <div className='teacher-image position-relative' >
                                <div className="pt-1">
                                    <BackButton color='white' />
                                </div>
                                {teacher.is_class_teacher ?
                                    <div className="position-absolute badge-teacher p-1 px-2 fw-bolder" style={{ top: "2vw", right: "2vw" }}>
                                        Class Teacher <AiFillStar style={{ fontSize: "1.3rem", color: "yellow", }} />
                                    </div>
                                    : ""}
                                <div className="d-flex flex-row position-absolute pt-2" style={{ left: '8px', bottom: '5px' }}>
                                    <div className="py-2 px-3">
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + teacher?.user?.image}
                                            placeholder={noImage}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    style={{
                                                        width: "25vw",
                                                        height: "25vw",
                                                        borderRadius: "50px",
                                                        objectFit: "cover",
                                                        backgroundRepeat: "round",
                                                        border: '2px solid #fff',
                                                        outline: '1px soild #fff'
                                                    }}
                                                    className=""
                                                    alt="grosav"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>

                                    <div className="d-flex flex-column align-items-center ms-2 mt-2 text-white">
                                        <span className="text-white fw-bold" style={{fontSize:'1.8em'}}>{teacher?.user?.name}</span>
                                        <span className="text-white fw-bold">Subject - {teacher?.subject?.name}</span>

                                    </div>
                                </div>
                            </div>
                            <div className="px-3 pt-2">
                                <div className='Teacher-details mt-2'>
                                    <span className="text-muted">Phone</span>
                                    <span className="fw-bold">{staff?.emergancy_contact}</span>
                                </div>
                            </div>
                            <div className="px-3">
                                <div className='Teacher-details mt-2'>
                                    <span className="text-muted">Email</span>
                                    <span className="fw-bold">{teacher?.user?.email}</span>
                                </div>
                            </div>
                            <div className="px-3">
                                <div className='Teacher-details mt-2'>
                                    <span className="text-muted">Place</span>
                                    <span className="fw-bold">{staff?.current_address}</span>
                                </div>
                            </div>
                            <div className="px-3">
                                <div className='Teacher-details mt-2'>
                                    <span className="text-muted">Qualification</span>
                                    <span className="fw-bold">{staff?.qualification}</span>
                                </div>
                            </div>
                            <div className="px-3">
                                <div className='Teacher-details mt-2'>
                                    <span className="text-muted">Experience</span>
                                    <span className="fw-bold">{staff?.experience}</span>
                                </div>
                            </div>
                            {/* <div className="px-3">
                                <div className='Teacher-details mt-2'>
                                    <span className="text-muted">Previous School</span>
                                    <span className="fw-bold">{teacher?.user?.name}</span>
                                </div>
                            </div> */}

                            <div className="py-2 d-grid gap-2 bg-white px-2 w-100 position-fixed bottom-0 start-50 translate-middle-x" onClick={(e) => { this.contactSupprt(staff.emergancy_contact ? staff.emergancy_contact : teacher.user.phone) }}>
                                <button className="contact-btn btn">Contact Now</button>
                            </div>
                        </React.Fragment>
                    )}

            </React.Fragment>
        )

    }
}
const mapStateToProps = (state) => ({
    user: state.user.parent
});

export default connect(mapStateToProps, {
    getSingleTeacher
})(SingleTeacher);
