import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import { BiErrorCircle } from 'react-icons/bi'
import { connect } from 'react-redux'
import Select from 'react-select'
import BackButton from '../../../elements/BackButton'
import { getFeesDatas, viewAllFees } from '../../../redux/Parent/class/action'
import Shake from "react-reveal/Shake";


class Fees extends Component {
    state = {
        loading: false,
        student: [],
        fee_types: [],
        selected_feetype: "",
        not_selected: false
    }
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getFeesDatas(user.data.auth_token, student_id.value).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, student: response.payload.student, fee_types: response.payload.fee_types });
            }
        })
    }

    filterValue = (values) => {
        let filterValue = []
        values.forEach((item) => {
            filterValue.push({ value: item.id, label: item.type })
        })
        return filterValue
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        if (this.state.selected_feetype) {
            this.setState({ not_selected: false })
            this.props.viewAllFees(user.data.auth_token, this.state.selected_feetype.value,student_id.value).then((response) => {
                if (response && response.payload && response.payload.success) {
                    window.location.replace("/view-fees")
                }
            })
        } else {
            this.setState({ not_selected: true })
        }
    }
    render() {
        const { student, fee_types, not_selected } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader viewBox="0 0 280 550" backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="13" y="100" rx="10" ry="10" width="255" height="50" />
                                <rect x="13" y="160" rx="10" ry="10" width="255" height="50" />
                                <rect x="13" y="220" rx="10" ry="10" width="255" height="50" />
                                <rect x="13" y="280" rx="10" ry="10" width="255" height="50" />
                                <rect x="13" y="340" rx="10" ry="10" width="255" height="50" />
                                <rect x="13" y="400" rx="10" ry="10" width="255" height="50" />
                                <rect x="13" y="500" rx="10" ry="10" width="255" height="50" />
                            </ContentLoader>

                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <BackButton title={"Fees"} />
                            <Form onSubmit={(e) => { this.onSubmit(e) }}>
                                <div className="p-3">
                                    <div className='d-flex justify-content-between mt-3 homework-input'>
                                        <div className='text-muted'> Class  </div>
                                        <div style={{ fontWeight: '600' }}> {student?.student_class?.name}  </div>
                                    </div>

                                    <div className='d-flex justify-content-between mt-3 homework-input'>
                                        <div className='text-muted'> Section  </div>
                                        <div style={{ fontWeight: '600' }}>  {student?.section?.name} </div>
                                    </div>

                                    <div className='d-flex justify-content-between mt-3 homework-input'>
                                        <div className='text-muted'> Student  </div>
                                        <div style={{ fontWeight: '600' }}>  {student?.user?.name}  </div>
                                    </div>

                                    <div className='d-flex justify-content-between mt-3 homework-input'>
                                        <div className='text-muted'> Admission No  </div>
                                        <div style={{ fontWeight: '600' }}>  {student?.admission_number}  </div>
                                    </div>

                                    <div className="flex-column mt-3">
                                        <Select className="text-muted attendence-input ps-2" placeholder='Fees Type' styles={customStyles} options={this.filterValue(fee_types)}
                                            onChange={e => this.setState({ selected_feetype: e, not_selected: false })} />
                                    </div>
                                    {not_selected &&
                                        <Shake duration={250} right>
                                            <div className="text-center mt-3" style={{ color: 'red' }} >
                                                <BiErrorCircle size={18} set="bulk" /> <span style={{ fontSize: '.9em' }}>Need to Select Fee Type</span>
                                            </div>
                                        </Shake>
                                    }

                                    {/* <div className="flex-column mt-3">
                                        <input className="text-muted attendence-input ps-3" style={{ fontSize: '13px' }} placeholder='Remarks' required />
                                    </div> */}
                                </div>
                                <div className="gap-2 px-3 attendence-btn-div w-100">
                                    <button type='submit' className="attendence-btn w-100 py-3">
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        </React.Fragment>
                    )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent
})

export default connect(mapStateToProps, {
    getFeesDatas,
    viewAllFees
})(Fees)

const options = [
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
]

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        border: 'none', // tried border: 'none'
        boxShadow: 'none', // tried border: 'none'
        outline: 'none',

    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "88vw",
        border: "none",
        boarderRadius: "10px",
        fontSize: '13px',
        borderStyle: 'none',
        background: 'rgba(190, 175, 179, 0.05)',
        outline: 'none',
        fontWeight: '400',
        lineHeight: '14px',
        letterSpacing: '1px',
        color: '#86878E',
        // boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1) inset",
        // shadowOpacity: 0.5,
        // shadowRadius: 50,

    })
}
