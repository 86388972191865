import React, { Component } from 'react'
import { BiEdit } from 'react-icons/bi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { RiEditLine } from 'react-icons/ri'
import ProgressiveImage from 'react-progressive-image'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import noImage from '../../../assets/Images/noImage.png'
import Footer from '../Footer'
import { IMAGE_BASE_URL } from '../../../api'
import ContentLoader from 'react-content-loader'

export class Profile extends Component {
    state = {
        loading: false,
    }
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    render() {
        const { teacher } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 320 658"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="110" y="34" rx="6" ry="9" width="96" height="29" />
                            <rect x="32" y="96" rx="20" ry="20" width="88" height="89" />
                            <rect x="133" y="106" rx="6" ry="9" width="120" height="32" />
                            <rect x="133" y="148" rx="6" ry="9" width="120" height="32" />
                            <rect x="30" y="235" rx="6" ry="9" width="90" height="32" />
                            <rect x="23" y="280" rx="15" ry="15" width="277" height="55" />
                            <rect x="23" y="352" rx="15" ry="15" width="277" height="55" />
                            <rect x="23" y="424" rx="15" ry="15" width="277" height="55" />
                            <rect x="23" y="496" rx="15" ry="15" width="277" height="55" />
                            <rect x="23" y="568" rx="15" ry="15" width="277" height="55" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="profile-info text-white">
                            <div className="px-3 py-4">
                                <div className="pt-2 ">
                                    <div className="d-flex flex-row align-items-center justify-content-between position-relative">
                                        <div>
                                        </div>
                                        <div className="d-flex justify-content-center fw-bolder text-center">
                                            <span
                                                className=""
                                                style={{
                                                    fontSize: "22px",
                                                    color: "373E43",
                                                }}
                                            >
                                                Profile
                                            </span>
                                        </div>
                                        <div>
                                            {/* <BsThreeDotsVertical size={"22px"} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" d-flex flex-column align-items-start justify-content-start px-3 w-100 mt-2">
                                <div className="d-flex flex-row justify-content-around align-items-center px-3">
                                    <div
                                        className="flex-shrink-1"
                                        style={{ borderRadius: "18%", border: '2px solid #fff' }}
                                    >
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + teacher.data?.image}
                                            placeholder={
                                                "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                            }
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "25vw",
                                                        objectFit: "cover",
                                                        height: "25vw",
                                                        borderRadius: "15px",
                                                        backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat:
                                                            "none",
                                                        // boxShadow: "0px 0px 15px 8px #cccccc50",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div className="flex-grow-1 ms-4">
                                        <div className="d-flex flex-column ">
                                            <span className="text-white fw-bold fs-4">
                                                {teacher.data?.name}
                                            </span>
                                            <span className="text-white fs-6 mt-2">
                                                Teacher
                                                <Link to="/teacher-profile-edit">
                                                    <span className="position-absolute ps-2 ">
                                                        <BiEdit
                                                            color="white"
                                                            size={22}
                                                        />
                                                    </span>
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="d-flex flex-column p-3 position-absolute"
                            style={{
                                backgroundColor: "#ffffff",
                                height: "100%",
                                width: "100%",
                                marginTop: "220px",
                                borderRadius: "18px 18px 0px 0px",
                            }}
                        >
                            <div>
                                <span className="px-3 text-black fw-bold fs-4">
                                    General
                                </span>
                            </div>
                            <div className="flex-column p-1">
                                <div className="profileform mt-2 p-3">
                                    <span className="text-muted">Phone</span>
                                    <span className="fw-bold">
                                        {teacher.data?.phone}
                                    </span>
                                </div>
                            </div>

                            <div className="flex-column p-1">
                                <div className="profileform mt-2 p-3">
                                    <span className="text-muted">DOB</span>
                                    <span className="fw-bold">
                                        {teacher.data?.date_of_birth}
                                    </span>
                                </div>
                            </div>
                            <div className="flex-column p-1">
                                <div className="profileform mt-2 p-3">
                                    <span className="text-muted">E-mail</span>
                                    <span className="fw-bold">
                                        {teacher.data?.email}
                                    </span>
                                </div>
                            </div>
                            <div className="flex-column p-1">
                                <div className="profileform mt-2 p-3">
                                    <span className="text-muted">Highest qualification</span>
                                    <span className="fw-bold">
                                        {teacher.data?.staff?.qualification}
                                    </span>
                                </div>
                            </div>
                            <div className="flex-column p-1">
                                <div className="profileform mt-2 p-3">
                                    <span className="text-muted">Work Experience</span>
                                    <span className="fw-bold">
                                        {teacher.data?.staff?.experience}
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div>
                            <Footer current={"profile"} />
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
})
export default connect(mapStateToProps, {})(Profile)
