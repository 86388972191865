import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";
import { Link } from "react-router-dom";
import { getClassTutorials, getOnlineClasses } from "../../../redux/Parent/class/action";
import youtube from "../../../assets/Images/YouTube.png";
import gmeet from "../../../assets/Images/gmeet.png";
import image from '../../../assets/Images/tutorials.png'
import image2 from '../../../assets/Images/noclass.png'
import { MdOutlineContentCopy, MdDone } from "react-icons/md"
import { CopyToClipboard } from 'react-copy-to-clipboard';

<ReactPlayer url="https://youtu.be/XpQ_je8PPWU" />;
class TutorialLibrary extends Component {
    state = {
        loading: false,
        copied: ''
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getOnlineClasses(user.data.auth_token, student_id ? student_id.value : user?.data?.students[0]?.id)
            .then((response) => {
                if (response && response.payload) {
                    this.props.getClassTutorials(user.data.auth_token, student_id ? student_id.value : user?.data?.students[0]?.id)
                        .then((response) => {
                            if (response && response.payload && response.payload.success) {
                                this.setState({ loading: false });
                            }
                        })
                }
            })
    }
    copyLink = (data) => {
        this.setState({ copied: data.id })
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    }
    render() {
        const { tutorials, onlineclasses } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 539"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="13" y="85" rx="10" ry="10" width="255" height="90" />
                            <rect x="13" y="185" rx="10" ry="10" width="255" height="90" />
                            <rect x="13" y="285" rx="10" ry="10" width="255" height="90" />
                            <rect x="13" y="410" rx="10" ry="10" width="255" height="110" />
                            <rect x="13" y="525" rx="10" ry="10" width="255" height="90" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="">
                            <div className="mt-2">
                                <BackButton />
                            </div>
                            {tutorials?.success ?
                                <>
                                    <div className="px-3 mt-2">
                                        {/* tutorials */}
                                        <div>
                                            <div className="d-flex justify-content-between px-1">
                                                <div style={{ color: '#373E43', fontWeight: '500', fontSize: '17px' }}>
                                                    <span>Tutorials</span>
                                                </div>
                                                <Link to="/tutorials">
                                                    <div style={{ color: '#C24A6B', fontWeight: '500', fontSize: '14px' }}>
                                                        <span>Show All</span>
                                                    </div>
                                                </Link>
                                            </div>
                                            {tutorials?.data?.length > 0 ?
                                                <div>
                                                    {tutorials?.data?.map((data, i) => {
                                                        if (i < 3) {
                                                            return (
                                                                <div className="tutorial-library bg-white px-2 w-100vw">
                                                                    <div className=" tutorial-card p-2 mt-2 position-relative">
                                                                        <div className="d-flex">
                                                                            <div className="">
                                                                                <ReactPlayer
                                                                                    width="80px"
                                                                                    height="80px"
                                                                                    url={data.url}
                                                                                />
                                                                            </div>
                                                                            <div className="ms-2 d-flex flex-column">
                                                                                <h2 className="tutorial-heading">
                                                                                    {data.title}
                                                                                </h2>
                                                                                <span className="tutorial-details">
                                                                                    Subject :
                                                                                    {data?.subject?.name}
                                                                                </span>
                                                                                <span className="tutorial-details">
                                                                                    Class
                                                                                    {data?.class?.name}
                                                                                    | Section
                                                                                    {data?.section?.name}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center position-absolute bottom-0 end-0 m-2">
                                                                            <span className="tutorial-details me-2">
                                                                                YouTube
                                                                            </span>
                                                                            <img
                                                                                src={youtube}
                                                                                alt="youtube"
                                                                                width="15"
                                                                                height="15"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                                : <div className="d-flex flex-column align-items-center pt-4 mb-5 pb-3">
                                                    <img src={image} style={{ width: '50%', objectFit: 'cover', }} />
                                                    <span>Sorry..No Tutorials Found...</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                                : null}
                            {/* online classes */}
                            {onlineclasses?.success ?
                                <>
                                    <div className="px-3 mt-3" style={{ paddingBottom: '15vw' }}>
                                        <div>
                                            <div className="d-flex justify-content-between px-1">
                                                <div style={{ color: '#373E43', fontWeight: '500', fontSize: '17px' }}>
                                                    <span>Online Classes</span>
                                                </div>
                                                <Link to="/online-classes">
                                                    <div style={{ color: '#C24A6B', fontWeight: '500', fontSize: '14px' }}>
                                                        <span>Show All</span>
                                                    </div>
                                                </Link>
                                            </div>
                                            {onlineclasses?.data?.length > 0 ?
                                                <div>
                                                    {onlineclasses?.data?.map((data, i) => {
                                                        if (i < 3) {
                                                            return (
                                                                <div className="tutorial-library bg-white px-2 w-100vw">
                                                                    <div className=" tutorial-card p-2 mt-2 position-relative">
                                                                        <div className="d-flex">
                                                                            <div className="ms-2 d-flex flex-column">
                                                                                <h2 className="tutorial-heading">
                                                                                    <img
                                                                                        src={gmeet}
                                                                                        alt="youtube"
                                                                                        width="25"
                                                                                    />
                                                                                    {data.title}
                                                                                </h2>
                                                                                <span className="tutorial-details">
                                                                                    {/* {console.log(Date(data?.to).toString())} */}
                                                                                    Subject :
                                                                                    {data?.subject?.name} {data?.section?.student_class?.name}-{data?.section?.name}
                                                                                </span>
                                                                                <span className="tutorial-details">
                                                                                    On :
                                                                                    {data?.date}
                                                                                </span>
                                                                                <span className="tutorial-details">
                                                                                    Time :
                                                                                    {data?.from} - {data?.to}
                                                                                </span>
                                                                                <div style={{ width: '70%' }}>
                                                                                    <span className="tutorial-details">
                                                                                        {data?.link}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center position-absolute bottom-0 end-0 m-2 py-2 px-3">
                                                                            <div className="mt-3 pt-1 d-flex justify-content-between" >
                                                                                {data?.id == this.state.copied ?
                                                                                    <div className=''>
                                                                                        <MdDone size={'1.2em'} style={{ color: '#699BF7' }} />
                                                                                        <span style={{ color: '#699BF7' }}>Copied</span>
                                                                                    </div>
                                                                                    :
                                                                                    <CopyToClipboard text={data?.link}
                                                                                        onCopy={() => this.copyLink(data)}>
                                                                                        <div className="ps-3 pt-2" style={{ fontWeight: '680' }} >
                                                                                            <MdOutlineContentCopy size={"1.2em"} style={{ color: '#a09a9c' }} />
                                                                                        </div>
                                                                                    </CopyToClipboard>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                                :
                                                <div className="d-flex flex-column align-items-center mt-2">
                                                    <img src={image2} style={{ width: '50%', objectFit: 'cover', }} />
                                                    <span>No Live Class Now...</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                                : null}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    tutorials: state.class.tutorials,
    onlineclasses: state.class.onlineclasses,
});

export default connect(mapStateToProps, { getClassTutorials, getOnlineClasses })(TutorialLibrary);
