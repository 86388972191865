import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { AiFillCamera } from 'react-icons/ai';
import { TiCloudStorage } from 'react-icons/ti'
import Modal from "react-modal";
import { CgCheckO } from 'react-icons/cg'
import BackButton from '../../../elements/BackButton'
import ProgressiveImage from "react-progressive-image";
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { getTeacherSchoolMedia, addSchoolMedia } from '../../../redux/Teacher/media/action'
import { IMAGE_BASE_URL } from '../../../api'
import { Link } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { HiOutlineChevronRight } from 'react-icons/hi';
export class TeacherPostOnWall extends Component {
    state = {
        file_name: '',
        file: '',
        media: {},
        open: false,
        categorysheet: false,
        category: '',
        categoryname: '',
    }
    componentDidMount() {
        const { teacher } = this.props
        this.props.getTeacherSchoolMedia(teacher.data.auth_token)
    }
    __onSubmit = (e) => {
        e.preventDefault();
        const { teacher } = this.props;
        const { media, category } = this.state;
        if (media.image?.size > 500000) {
            const alert = document.getElementById('alertdiv')
            alert.innerHTML = '<small>*The file may not be greater than 500 kilobytes.</small>'
        } else {
            let formData = new FormData();
            formData.append("category_id", category)
            formData.append("file", media.image)
            formData.append("token", teacher.data.auth_token)
            this.props.addSchoolMedia(formData).then((response) => {
                if (response?.data?.success) {
                    this.modalOpen()
                    document.getElementById('alertdiv').innerHTML = '';
                    this.setState({ category: '', categoryname: '', file_name: '' })
                }
            })
        }
    }
    handleInput(e, name) {
        let data = this.state.media;
        data[name] = e;
        this.setState({ media: data });
    }
    selectCategory = (category) => {
        const id = category.id;
        const name = category.name;
        this.setState({
            category: id,
            categoryname: name,
        })
        this.closeCategoryMenu();
    }
    modalOpen = () => {
        this.setState({ open: true });
    };
    modalClose = () => {
        this.setState({ open: false });
    };
    closeCategoryMenu = () => {
        this.setState({ categorysheet: false })
    };
    openCategoryMenu = () => {
        this.setState({ categorysheet: true })
    }
    render() {
        const { teacher_media } = this.props
        return (
            <React.Fragment>
                {teacher_media?.success ?
                    <>
                        <BackButton title={"School Media"} />
                        <div className='px-3 pb-3'>
                            <Form onSubmit={this.__onSubmit} id="mediaform">
                                <div className="post-on-wall-card px-3 py-2 mt-3 position-relative">
                                    <div className='d-flex justify-content-center align-items-center post-on-wall-title py-1'>
                                        <span>Upload Files</span>
                                    </div>
                                    <div className="post-on-wall-file-select d-flex justify-content-between px-2 py-2 w-100 mt-2 ">
                                        <div>
                                            <span style={{ color: '#86878E' }} className='ps-2'>
                                                {this.state?.file_name ? this.state.file_name : 'File'}
                                            </span>
                                        </div>
                                        <div>
                                            {/* <AiFillCamera size="25" color='#0c8ce9' /> */}
                                            <label
                                                for="image"
                                                className="user-select-none px-2 image-input"
                                            >
                                                <TiCloudStorage size="28" color='#0c8ce9' />

                                            </label>
                                            <input
                                                type="file"
                                                // accept=".pdf"
                                                style={{ display: "none" }}
                                                id="image"
                                                onChange={(e) => {
                                                    let file = e.target.files[0];
                                                    this.setState({ file: URL.createObjectURL(file), file_name: file.name });
                                                    this.handleInput(file, "image");
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div id="alertdiv" className='text-danger'></div>
                                    <div className="mt-2 pb-5 mb-2">
                                        <InputGroup
                                            className="mt-3 school-media-input pe-2 pt-3 d-flex justify-content-between"
                                            style={{ boxSizing: 'border-box' }}
                                            onClick={() => this.openCategoryMenu()}
                                        >
                                            <div>
                                                <input type="hidden" value={this.state.category} required />
                                                <label for='category-type' className='text-muted px-3 py-1'>
                                                    {this.state.categoryname ? (
                                                        <>
                                                            {this.state.categoryname}
                                                        </>
                                                    ) : (
                                                        <>
                                                            select an option
                                                        </>
                                                    )}
                                                </label>
                                            </div>
                                            <div>
                                                <span className='px-1 py-2 ml-3' id='category-type'  ><HiOutlineChevronRight style={{ color: '#DA6317' }} /></span>
                                            </div>
                                        </InputGroup>
                                        <BottomSheet
                                            open={this.state.categorysheet}
                                            onDismiss={() => this.closeCategoryMenu()}
                                            snapPoints={({ maxHeight }) => 0.36 * maxHeight}
                                        >
                                            <div className='px-3 pb-5' >
                                                <div className="mt-1 mb-2 " style={{
                                                    fontWeight: '600',
                                                    fontSize: '16px'
                                                }}>
                                                    Select Category
                                                </div>
                                                <div className='px-3 pb-5' >
                                                    <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                                        {teacher_media?.data?.length > 0 ? (
                                                            <>
                                                                <div className="d-grid gap-2">
                                                                    {teacher_media?.data?.map((category) =>
                                                                        <>
                                                                            <button className={this.state.category === category.id ? "media-sheet-btn py-2 text-bold border-dark" : "media-sheet-btn py-2 text-muted"}
                                                                                onClick={() => this.selectCategory(category)} >
                                                                                {category.name}
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </BottomSheet>
                                    </div>
                                    <div className='position-absolute bottom-0 py-2 w-100 px-3' style={{ left: '0' }}>
                                        <button className='btn attendence-dwld-btn mt-2 py-2 w-100' type="submit"
                                            style={{ background: 'linear-gradient(95.13deg, #C34A6D 21.02%, #CB595B 66.71%)' }}>
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </Form>
                            <div className='d-flex justify-content-center align-items-center post-on-wall-title py-1 mt-4'>
                                <span>School Media</span>
                            </div>
                            {teacher_media?.data?.length > 0 ?
                                <>
                                    {teacher_media?.data?.length > 0 && teacher_media?.data?.map((data) => (
                                        <Link to={"/media-gallery/" + data.id}>
                                            <div className="post-on-wall-card px-3 py-2 mt-3">
                                                <div className="mt-2">
                                                    <div className=" position-relative">
                                                        <ProgressiveImage
                                                            delay={20}
                                                            src={IMAGE_BASE_URL + data.file}
                                                            placeholder={
                                                                "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                                            }
                                                        >
                                                            {(src, loading) => (
                                                                <img
                                                                    src={src}
                                                                    style={{
                                                                        width: "80vw",
                                                                        height: "50vw",
                                                                        borderRadius: "12px",
                                                                        objectFit: "cover",
                                                                        backgroundRepeat:
                                                                            "no repeat",
                                                                        boxShadow: '-4px 4px 5px rgba(0, 0, 0, 0.1)'
                                                                    }}
                                                                    className=""
                                                                    alt="media"
                                                                />
                                                            )}
                                                        </ProgressiveImage>
                                                        <div className='mt-2 ps-2' style={{ fontSize: '12px', fontWeight: '400', color: '#393433' }}>
                                                            <span>{data.name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </>
                                : null}
                        </div>
                        {/* upload success modal */}
                        <Modal
                            isOpen={this.state.open}
                            ariaHideApp={false}
                            onRequestClose={() => this.modalClose()}
                            style={modalStyles}
                            contentLabel="Example Modal"
                        >
                            <div
                                className="container"
                                style={{
                                    borderRadius: "5px",
                                    height: "70vw",
                                    width: '70vw',
                                    zIndex: "9999",
                                }}
                            >
                                <React.Fragment>
                                    <div className="d-flex flex-column justify-content-center align-items-between">
                                        <div className='d-flex justify-content-center mt-5'
                                            style={{ fontSize: '18px', fontWeight: '600', color: '#2B2C2F' }}>
                                            <CgCheckO color={"#7ECB40"} size={"4em"} />
                                        </div>
                                        <div className='d-flex justify-content-center mt-4'
                                            style={{ fontSize: '15px', fontWeight: '400', color: '#2B2C2F' }}>
                                            File Uploaded Successfully</div>
                                        <div className='d-flex justify-content-between w-100 position-fixed' style={{ bottom: '20%', left: '0%' }}>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>
                        </Modal>
                    </>
                    : null}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    teacher_media: state.media.teacher_media,
})

export default connect(mapStateToProps, { getTeacherSchoolMedia, addSchoolMedia })(TeacherPostOnWall)
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #C24A6B",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
