import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import BackButton from "../../../../elements/BackButton";
import { Form } from "react-bootstrap";
import { sentHelp } from "../../../../redux/Parent/user/action";

export class Help extends Component {
    state = {
        loading: false,
        txt_help:"",

    };
    text_help = (e) => {
        this.setState({ txt_help: e });
    };
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        this.props.sentHelp(user.data.auth_token,this.state.txt_help)
        .then((response)=>{
            if(response.data.success){
                this.props.history.goBack()
            }
        })
    };



    componentDidMount() {
        const { user } = this.props;
        
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 320 658"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#FFFFFF">
                            <rect x="19" y="36" rx="8" ry="8" width="22" height="22" />
                            <rect x="140" y="36" rx="8" ry="8" width="75" height="22" />
                            <rect x="28" y="177" rx="8" ry="8" width="264" height="30" />
                            <rect x="28" y="220" rx="8" ry="8" width="264" height="142" />
                            <rect x="13" y="572" rx="8" ry="8" width="293" height="44" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                    <div className="help-bg">
                        <div className="py-4 px-3">
                            <BackButton title={"Help"} color={"#fff"} />
                        </div>
                    </div>

                    <div className="help-div">
                    <Form onSubmit={this.onSubmit}>

                        <div className="help-sub">
                            <div>
                                <span>Describe your problem</span>
                            </div>

                            <div
                                className="form-group shadow-textarea mt-3"
                                style={{
                                    boxShadow: "inset 0px 1px 4px #f6f6f6",
                                    borderRadius: "12px",
                                }}
                            >
                                <textarea
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.text_help(value);
                                }}
                                    className="form-control z-depth-1 input-div"
                                    id="exampleFormControlTextarea6"
                                    rows="3"
                                    placeholder="Text your message here"
                                ></textarea>
                            </div>
                        </div>
                        <div className="help-btn-div px-3 w-100">
                            <button className="help-btn">Submit</button>
                        </div>
                        </Form>
                    </div>
                </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    
});

export default connect(mapStateToProps,{sentHelp})(Help);

