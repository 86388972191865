import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContentLoader from "react-content-loader"
import BackButton from '../../../elements/BackButton'
import { getTodayInClass } from './../../../redux/Parent/class/action';
import { index } from './../TimeTable/index';
import Truncate from 'react-truncate'


class TodayClass extends Component {
    state = {
        loading: false,
        todayInClass: [],
    }

    componentDidMount() {
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id")).value;
        this.props.getTodayInClass(this.props.user.data.auth_token, student_id).then((response) => {
            this.setState({ todayInClass: response.payload.todayinclasses, loading: false });
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                viewBox="0 0 236 470"
                                backgroundColor="#F3F3F3"
                                foregroundColor="#ecebeb"                                                     >
                                <rect x="15" y="65" rx="10" ry="10" width="205" height="115" />
                                <rect x="15" y="190" rx="10" ry="10" width="205" height="115" />
                                {/* <rect x="15" y="318" rx="10" ry="10" width="205" height="115" /> */}
                            </ContentLoader>
                        </React.Fragment>
                    ) :
                    (

                        <React.Fragment>
                            <div className='w-100 pt-2 px-2 '>
                                <BackButton title={"Today In Class"} />
                            </div>
                            <div className="d-flex flex-column align-content-around justify-content-around h-100 mt-1 px-3 pb-3">
                                {this.state.todayInClass && this.state.todayInClass.length > 0 ? this.state.todayInClass.map((data, index) =>(
                                    <div className="tdcls-card py-4 px-2 b-r-10 mt-4" key={data.id}>
                                        <div className=" d-flex flex-row align-items-center justify-content-around mb-2">
                                            <div className="flex-shrink-1 ">
                                                <div className="tdcls-circle mr-8" style={{ backgroundColor: "#BABDDB" }}></div>
                                            </div>
                                            <div className='flex-grow-1'>
                                                <div className='d-flex flex-column '>
                                                    <div className="tdcls-content" style={{ color: "#4D56A2" }}>{data.subject?.name}</div>
                                                    <div className="tdcls-time" style={{ color: "#939AD6" }}>{data.from} - {data.to}</div>
                                                </div>
                                            </div>
                                            <div className='tdcls-name pe-2' style={{ color: "#4D56A2" }}>{data.staff?.user?.name}</div>
                                        </div>
                                        <div className='d-flex flex-column w-100'>
                                            <div className="fs-6 mt-2 fw-400" style={{ color: "#737BBC" }}>{data.title}</div>
                                            <div className=" w-100" style={{ color: "#939AD6",fontSize:'.9em' }}>
                                                <Truncate lines={2}>
                                                    {data.description}
                                                </Truncate>
                                            </div>
                                        </div>
                                    </div>
                                )):(
                                    <div className='d-flex align-items-center justify-content-center' style={{height: '100vh', color: '#c24a6b'}}>No Data Found</div>
                                )}

                            </div>
                        </React.Fragment>
                    )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,

})



export default connect(mapStateToProps, { getTodayInClass })(TodayClass)
