import Axios from "axios";
import { GET_ALL_LEAVE_APPLICATION_URL, GET_ALL_TEACHER_LEAVE_URL, RESPONSE_TO_APPLICATION_URL, SUBMIT_LEAVE_APPLICATION_URL }
from "../../../api";


import { GET_ALL_LEAVE_APPLICATION, GET_ALL_TEACHER_LEAVE } from "./actionType"


export const getAllLeaveApplication = (token,staff_id) => (dispatch) => {
    return Axios.post(GET_ALL_LEAVE_APPLICATION_URL, { token,staff_id }).then((response) => {

        let test = response.data;
        return dispatch({
            type: GET_ALL_LEAVE_APPLICATION,
            payload: test,
        });
    });
};

export const responseToApplication = (token,leave_id,status) => (dispatch) => {
    return Axios.post(RESPONSE_TO_APPLICATION_URL, { token,leave_id ,status})
};


export const getAllTeacherLeave = (token) => (dispatch) => {
    return Axios.post(GET_ALL_TEACHER_LEAVE_URL, { token }).then((response) => {

        let test = response.data;
        return dispatch({
            type: GET_ALL_TEACHER_LEAVE,
            payload: test,
        });
    });
};


export const submitLeaveApplication = (formdata) => (dispatch) => {
    return Axios.post(SUBMIT_LEAVE_APPLICATION_URL, formdata)
};
