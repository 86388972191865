import { GET_TEACHER_MARK } from "./actionType";


const initialState = {
    teacherMark: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TEACHER_MARK:
            return { ...state, teacherMark: action.payload };
        default:
            return state;
    }
}