import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import BackButton from '../../../elements/BackButton'
import Select from 'react-select'
import { getTeacherSchoolData, addTodayInClass } from './../../../redux/Teacher/user/action';
import { CgCheckO } from "react-icons/cg";
import Modal from "react-modal";


export class TeacherTodayClass extends Component {

    state = {
        sections: [],
        subjects: [],
        todayClass: {},
        open: false,
        tab: false,

    }
    componentDidMount() {
        const { teacher } = this.props;
        this.props.getTeacherSchoolData(teacher.data.auth_token)
    }


    uploadTodayClasses = (e) => {
        e.preventDefault();
        const { teacher } = this.props;
        const { todayClass } = this.state;
        if (todayClass.class_id && todayClass.section_id && todayClass.subject_id) {
            let formData = new FormData();
            formData.append("class_id", todayClass.class_id);
            formData.append("section_id", todayClass.section_id);
            formData.append("subject_id", todayClass.subject_id);
            formData.append("from_time", todayClass.from_time);
            formData.append("to_time", todayClass.to_time);
            formData.append("title", todayClass.title);
            formData.append("description", todayClass.description);
            formData.append("token", teacher.data.auth_token);
            addTodayInClass(formData).then((response) => {
                if (response && response.data && response.data.success) {
                    this.setState({ todayClass: {}, open: true });
                }
            });
        } else {
            this.setState({ tab:true })
        }
    };
    getSubjectSections = (val) => {
        const { schooldata } = this.props;
        const class_sections = schooldata?.data?.student_classes?.find((data) => data.id == val)?.sections?.map((section) => ({
            value: section.id,
            label: section.name
        }))
        this.setState({ sections: class_sections })

        const class_subjects = schooldata?.data?.student_classes?.find((data) => data.id == val)?.subjects?.map((subject) => ({
            value: subject.id,
            label: subject.name
        }))
        this.setState({ subjects: class_subjects })
    }

    modalOpen = () => {
        this.setState({ open: true });
    };
    modalClose = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    };

    handleInput(e, name) {
        const data = this.state.todayClass;
        data[name] = e;
        this.setState({ todayClass: data, tab: false });

    }
    render() {
        const { schooldata } = this.props;
        const { sections } = this.state;
        const { subjects } = this.state;
        const classes = schooldata?.data?.student_classes?.map((cls) => ({
            value: cls.id,
            label: cls.name
        }))
        // console.log(this.state.todayClass)
        return (
            <React.Fragment>
                <BackButton title={"Today In Class"} />
                <Form onSubmit={this.uploadTodayClasses}>
                    <div className='px-3 mt-4'>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Class' styles={customStyles} options={classes}
                                onChange={(e) => {
                                    this.handleInput(e.value, 'class_id')
                                    this.getSubjectSections(e.value)

                                }}
                            />
                        </div>

                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Section' styles={customStyles} options={sections}
                                onChange={(e) => {
                                    this.handleInput(e.value, 'section_id')
                                }}
                            />
                        </div>

                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Subject' styles={customStyles} options={subjects}
                                onChange={(e) => {
                                    this.handleInput(e.value, 'subject_id')
                                }}
                            />
                        </div>
                        <div className="mt-3 text-muted">
                            <label className='px-3'>From time</label>
                            <input className="attendence-input px-3" type="time" placeholder='From' style={{ fontSize: '13px' }} required
                                onChange={(e) => {
                                    this.handleInput(e.target.value, 'from_time')
                                }}
                            />
                        </div>
                        <div className="mt-3 text-muted">
                            <label className='px-3'>To time</label>
                            <input className="attendence-input px-3" type="time" placeholder='To' style={{ fontSize: '13px' }} required
                                onChange={(e) => {
                                    this.handleInput(e.target.value, 'to_time')
                                }}
                            />
                        </div>
                        <div className="mt-3">
                            <input className="text-muted attendence-input px-3" placeholder='Title' style={{ fontSize: '13px' }} required
                                onChange={(e) => {
                                    this.handleInput(e.target.value, 'title');
                                }}
                            />
                        </div>
                        <div className="mt-3">
                            <textarea className="text-muted attendence-input px-3 py-2" rows="2" placeholder='Description' style={{ fontSize: '13px', height: '25vw' }} required
                                onChange={(e) => {
                                    this.handleInput(e.target.value, 'description')
                                }}
                            />
                        </div>
                        {this.state.tab &&
                                <div className='text-center text-danger'>
                                    Please fill all fields! 
                                </div>
                            }
                    </div>
                    <div style={{ height: '100px' }}></div>
                    <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' type='submit'>
                            Submit
                        </button>
                    </div>
                </Form>

                {/* Modal */}
                <Modal
                    isOpen={this.state.open}
                    ariaHideApp={false}
                    onRequestClose={(e) => this.modalClose(e)}
                    style={modelCustomStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "45vw",
                            width: "70vw",
                            zIndex: "9999",
                        }}
                    >
                        <React.Fragment>
                            <div className="d-flex flex-column justify-content-between align-items-center">
                                <div
                                    className="d-flex justify-content-center"
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        color: "#2B2C2F",
                                    }}
                                >
                                    <CgCheckO color={"#7ECB40"} size={50} />
                                </div>
                                <div
                                    className="d-flex justify-content-center text-center mt-1"
                                    style={{
                                        fontSize: "1.1em",
                                        fontWeight: "400",
                                        color: "#2B2C2F",
                                    }}
                                >
                                    Today Class Added Successfully
                                </div>

                                <button
                                    style={{
                                        color: "#fff",
                                    }}
                                    className="btn btn-sm btn-success px-3 mt-1"
                                    onClick={(e) => this.modalClose(e)}
                                >
                                    OK
                                </button>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    schooldata: state.teacher.schooldata,
})

export default connect(mapStateToProps, { getTeacherSchoolData })(TeacherTodayClass)
// const options = [
//     { value: '8', label: '8' },
//     { value: '9', label: '9' },
//     { value: '10', label: '10' }
// ]

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        border: 'none', // tried border: 'none'
        boxShadow: 'none', // tried border: 'none'
        outline: 'none',

    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "88vw",
        border: "none",
        boarderRadius: "10px",
        fontSize: '13px',
        borderStyle: 'none',
        background: 'rgba(190, 175, 179, 0.05)',
        outline: 'none',
        fontWeight: '400',
        lineHeight: '14px',
        letterSpacing: '1px',
        color: '#86878E',
    })


}

const modelCustomStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #bf4672",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};