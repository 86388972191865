import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../../../elements/BackButton'
import img from "../../../../../assets/Images/question.png";
import SingleQuestion from './SingleQuestion'
import { getAllQuestionsEachExam } from '../../../../../redux/Teacher/onlineTest/action'
import EditSingleQuestion from './EditSingleQuestion'
import ContentLoader from 'react-content-loader';
export class CreateSingleTest extends Component {
    state = {
        examId: this.props.match.params.id,
        open: false,
        loading: true,
        questions: {}
    }

    componentDidMount() {

        const { user } = this.props;
        this.props.getAllQuestionsEachExam(user.data.auth_token, this.state.examId).then((response) =>{

            if(response.payload.success){
                this.setState({ questions: response.payload.data ,loading: false})
            }
        })

    }


    handleOpen = () => {
        const { user } = this.props;
        this.setState({
            open: !this.state.open,
        })
        if (this.state.open) {
            this.props.getAllQuestionsEachExam(user.data.auth_token, this.state.examId)
        }
    }
    render() {
        console.log(this.state.questions);
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 236 470"
                            backgroundColor="#F3F3F3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="10" y="30" rx="5" ry="5" width="25" height="25" />
                            <rect x="75" y="29" rx="6" ry="9" width="90" height="25" />
                            <rect x="13" y="65" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="111" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="158" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="206" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="252" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="298" rx="10" ry="10" width="210" height="40" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"Online Test"} />
                        {this.state.questions?.length > 0 ? (
                            <div className='px-3 pb-5' style={this.state.open ? { display: "none" } : { display: "block" }}>
                                {this.state.questions?.map((question) => (
                                    <EditSingleQuestion examId={this.state.examId} question={question} />
                                ))}
                            </div>
                        )
                            : (<div
                                className=" flex-column align-items-center justify-content-center mt-5 appointment-no-data px-3"
                                style={this.state.open ? {
                                    display: "none"
                                } : { height: "50vh", display: "block" }}
                            >
                                <img
                                    src={img}
                                    alt="leave"
                                    style={{
                                        width: "100%",
                                    }}
                                ></img>
                                <div className="d-flex justify-content-center fs-5 fw-500">
                                    No Question In Exam
                                </div>
                            </div>)}
                        <div className="px-3 " style={this.state.open ? { display: "block" } : { display: "none" }}>
                            <span className='fw-500 fs-8 mt-2'>Add New Question</span>
                            <SingleQuestion examId={this.state.examId} />

                        </div>

                        <div className="position-fixed bottom-0 py-2 w-100 px-3">
                            <button
                                className="btn attendence-dwld-btn mt-2 py-2 w-100" onClick={this.handleOpen}

                            >
                                {this.state.open ? "View Test Questions" : "Add Test Questions"}
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    questions: state.onlineExam.singleExamQus

})


export default connect(mapStateToProps, { getAllQuestionsEachExam })(CreateSingleTest)
