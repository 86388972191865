import React, { Component } from "react";
import { Button } from "react-bootstrap";
import BackButton from "../../../elements/BackButton";
import "./myAppointments.css";
import { connect } from "react-redux";
import { getAllAppointments, responseToAppointments } from "../../../redux/Teacher/student/action";
import ContentLoader from 'react-content-loader';
import img from '../../../assets/Images/noreview.png'

export class TeacherAppointments extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        this.props.getAllAppointments(user.data.auth_token);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500)
    }
    onReject = (id) => {
        let status = "REJECTED";
        const { user } = this.props;

        this.props
            .responseToAppointments(user.data.auth_token, id, status)
            .then((response) => {
                if (response.data.success) {
                    this.props.getAllAppointments(
                        user.data.auth_token,

                    );
                }
            });
    };
    onAccept = (id) => {
        let status = "ACCEPTED";
        const { user } = this.props;
        this.props
            .responseToAppointments(user.data.auth_token, id, status)
            .then((response) => {
                if (response.data.success) {
                    this.props.getAllAppointments(
                        user.data.auth_token,

                    );
                }
            });
    };
    render() {
        const { appointments } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="-8 0 280 580"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#ecebeb"}
                        >
                            <rect x="3" y="30" rx="10" ry="10" width="260" height="140" />
                            <rect x="3" y="180" rx="10" ry="10" width="260" height="140" />
                            <rect x="3" y="330" rx="10" ry="10" width="260" height="140" />


                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title="My Appointments" />
                        {appointments?.data?.length > 0 ? (
                            <div className="px-3 mt-3">
                                {appointments?.data?.map((appointment) => (
                                    <div className="px-2 py-2 my_appointments_container">
                                        <div className="d-flex justify-content-between align-items-center student_details text-white">
                                            <div>
                                                <span>Child</span>
                                                <span className="mx-1">:</span>
                                                <span>{appointment?.student?.user?.name}</span>
                                            </div>
                                            <div>
                                                <span>Class</span>
                                                <span className="mx-1">:</span>
                                                <span>{appointment?.student?.student_class?.name}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column mt-3 appointment_details px-2">
                                            <div className="d-flex align-items-center justify-content-between ">
                                                <span>Appointment Date :</span>
                                                <span>{appointment?.appointment_date}</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Appointment Time :</span>
                                                <span>{appointment?.appointment_time}</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Subject :</span>
                                                <span>{appointment?.subject?.name}</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <span className="w-50">Reason:</span>
                                                <span classNAme="w-50">{appointment?.reason}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end mt-2">
                                            <Button className="me-2" onClick={() => this.onReject(appointment?.id)}>Reject</Button>
                                            <Button onClick={() => this.onAccept(appointment?.id)} style={{ background: "7ECC41" }}>Accept</Button>
                                        </div>
                                        <div />
                                    </div>
                                ))}
                            </div>) : (<div className='mt-5 pt-5 w-100 d-flex flex-column justify-content-center align-items-center'>
                                <img src={img} alt="no reviews" style={{ width: '65vw' }} className='' />
                                <span>No Appointments Found...</span>
                            </div>)}
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    appointments: state.student.appointments,
});

export default connect(mapStateToProps, { getAllAppointments, responseToAppointments })(
    TeacherAppointments
);
