export const GET_TEACHER_REMARKS = 'GET_TEACHER_REMARKS';
export const GET_TEACHER_LIVE_CLASSES = 'GET_TEACHER_LIVE_CLASSES';
export const GET_GALLERY_DATA = 'GET_GALLERY_DATA';
export const GET_ALL_MEETING_APPOINTMENTS = 'GET_ALL_MEETING_APPOINTMENTS';
export const GET_ALL_TEACHER_STUDENTS = 'GET_ALL_TEACHER_STUDENTS';
export const GET_ALL_SINGEL_STUDENTS_INFO = 'GET_ALL_SINGEL_STUDENTS_INFO';
export const GET_TEACHER_TUTORIALS = 'GET_TEACHER_TUTORIALS';
export const GET_TOILET_INFO = 'GET_TOILET_INFO';
export const GET_TEACHER_MEDICAL ="GET_TEACHER_MEDICAL";
export const GET_TEACHER_TIMETABLE = "GET_TEACHER_TIMETABLE";
export const GET_TEACHER_ALLERGIES = "GET_TEACHER_ALLERGIES";
export const GET_TEACHER_ATTENDENCE_DATA = "GET_TEACHER_ATTENDENCE_DATA";
export const ADD_STUDENT_ATTENDENCE = "ADD_STUDENT_ATTENDENCE";
export const CHANGE_STUDENT_ATTENDENCE = "CHANGE_STUDENT_ATTENDENCE"
export const GET_TEACHER_UPLOADED_ANSWERS = "GET_TEACHER_UPLOADED_ANSWERS";
export const GET_TEACHER_MATERIALS = "GET_TEACHER_MATERIALS_URL";
