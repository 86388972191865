import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Items } from "../Items/index";
import { StoreSearchBar } from "../StoreSearchBar/index";
import { StoreBanner } from "../StoreBanner/index";
import { BiSearch } from "react-icons/bi";
import { BsHandbag } from "react-icons/bs";

export class ItemCategories extends Component {
    state = {
        show_kit_items: true,
        active_item_catgory: 0,
        item_category_items: [],
        searching_clicked: false,
        all_items: [],
    };
    showKitItems = () => {
        this.setState({ show_kit_items: true, active_item_catgory: 0 });
    };
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    handleActiveCategory = (chosen_category) => {
        this.setState({
            active_item_catgory: chosen_category?.id,
            show_kit_items: false,
        });
        const { store_data } = this.props;

        console.log(chosen_category);
        this.setState({ item_category_items: chosen_category?.items });
    };
    __handleSearch = () => { };
    inputFocus = () => {
        this.setState({ searching_clicked: true });
        this.refs.searchGroup.classList.add("search-shadow-light");
    };

    // handleClickOutside = (event) => {
    //     this.setState({ searching_clicked: false });

    //     if (
    //         this.refs.searchGroup &&
    //         !this.refs.searchGroup.contains(event.target)
    //     ) {
    //         this.refs.searchGroup.classList.remove("search-shadow-light");
    //     }
    // };
    searchItem = (event) => {
        const { searchable_items } = this.props;
        if (event.target.value.length > 0) {
            this.setState({ filterText: event.target.value });
            this.setState({ searching: true, searching_clicked: true });
            const results = searchable_items.filter((o) =>
                o.name.toLowerCase().includes(event.target.value.toLowerCase())
            );
            console.log(results);
            this.setState({ all_items: results });
        }
        if (event.target.value.length === 0) {
            this.setState({ filterText: null });
            // console.log("Cleared");
            this.setState({ searching: false, searching_clicked: false });
        }
    };
    render() {
        const { user, store_data, cartProducts,banners } = this.props;
        const { show_kit_items, item_category_items, all_items } = this.state;
        return (
            <React.Fragment>
                <React.Fragment>
                    <div className="d-flex flex-row justify-content-between px-3 mt-4 align-items-center">
                        <div
                            className="flex-grow-1 d-flex flex-row justify-content-between "
                            style={{
                                backgroundColor: "#F3F3F4",
                                borderRadius: "10px",
                            }}
                        >
                            <div
                                ref="searchGroup"
                                onClick={this.inputFocus}
                                className=" "
                            >
                                <input
                                    id="input"
                                    onChange={this.searchItem}
                                    type="text"
                                    className="store-search-bar w-100"
                                    placeholder="Search"
                                />
                            </div>
                            <div className="p-2">
                                <BiSearch
                                    set="curved"
                                    style={{
                                        fontSize: "2em",
                                        color: "black",
                                    }}
                                />
                            </div>
                        </div>
                        <Link to={"/my-cart"}>
                            <div className="position-relative">
                                <div className="ms-2 store-cart-box px-2 p-2 d-flex align-items-center justify-content-center">
                                    <BsHandbag size={20} color={"#000"} />
                                </div>
                                {cartProducts?.length > 0 && (
                                    <div className="position-absolute cart-quantity">
                                        {cartProducts?.length}
                                    </div>
                                )}
                            </div>
                        </Link>
                    </div>
                </React.Fragment>
                <StoreBanner banners={banners} />
                {this.state.searching_clicked ? (
                    <Items items={all_items} is_kit={false} />
                ) : (
                    <React.Fragment>
                        <div
                            className="main-menu hidden-scroll-bar gap-4 mt-3 px-3"
                            style={{
                                gridTemplateColumns:
                                    "repeat(" +
                                    (store_data?.item_categories?.length + 1) +
                                    ", 1fr)"
                            }}
                        >
                            <div onClick={this.showKitItems}>
                                <button
                                    className={
                                        show_kit_items
                                            ? "store-recommend-btn p-2"
                                            : "store-recommend-btn-2 p-2"
                                    }
                                // className="store-recommend-btn p-2"
                                >
                                    Kits
                                </button>
                            </div>

                            {store_data &&
                                store_data.item_categories &&
                                store_data.item_categories.map(
                                    (category, index) => (
                                        <React.Fragment>
                                            <div
                                                onClick={() =>
                                                    this.handleActiveCategory(
                                                        category
                                                    )
                                                }
                                            >
                                                <button
                                                    className={
                                                        this.state
                                                            .active_item_catgory ==
                                                            category.id
                                                            ? "store-recommend-btn p-2"
                                                            : "store-recommend-btn-2 p-2"
                                                    }
                                                >
                                                    {category.name}
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )
                                )}
                        </div>

                        {show_kit_items == true ? (
                            <React.Fragment>
                                <Items items={store_data?.kits} is_kit={true} />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Items
                                    items={item_category_items}
                                    is_kit={false}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
});

export default connect(mapStateToProps)(ItemCategories);
