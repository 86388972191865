import {
    GET_TUTORIALS,
    GET_SINGLE_HOME_WORKS,
    GET_STUDENT_TIME_TABLE,
    VIEW_ALL_FEES,
    GET_ONLINE_CLASSES,
    GET_EXAM_SCHEDULE,
    GET_ALL_MEDIA,
    GET_EVENTS,
    GET_ATTENDENCE_DATA,
    GET_ALL_ACTIVITY,
    GET_TODAY_IN_CLASS,
    GET_MEDIA_WISE_IMAGES,
    GET_ANSWERS,
    GET_GALLERY_IMAGES
} from "./actionType";

const initialState = {
    tutorials: [],
    homework: [],
    fees: [],
    onlineclasses: [],
    time_table: {},
    exam_schedule: [],
    time_table: {},
    events: [],
    attendence: [],
    activities: [],
    today_in_class: [],
    media: [],
    mediaimages: [],
    answers: {},
    gallery:{}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TUTORIALS:
            return { ...state, tutorials: action.payload };
        case GET_SINGLE_HOME_WORKS:
            return { ...state, homework: action.payload };
        case GET_STUDENT_TIME_TABLE:
            return { ...state, time_table: action.payload };
        case VIEW_ALL_FEES:
            return { ...state, fees: action.payload };
        case GET_ONLINE_CLASSES:
            return { ...state, onlineclasses: action.payload };
        case GET_EXAM_SCHEDULE:
            return { ...state, exam_schedule: action.payload };
        case GET_EVENTS:
            return { ...state, events: action.payload };
        case GET_ATTENDENCE_DATA:
            return { ...state, attendence: action.payload };
        case GET_ALL_ACTIVITY:
            return { ...state, activities: action.payload }
        case GET_TODAY_IN_CLASS:
            return { ...state, today_in_class: action.payload };
        case GET_ALL_MEDIA:
            return { ...state, media: action.payload };
        case GET_MEDIA_WISE_IMAGES:
            return { ...state, mediaimages: action.payload };
        case GET_ANSWERS:
            return { ...state, answers: action.payload };
        case GET_GALLERY_IMAGES:
            return { ...state, gallery: action.payload };
        default:
            return state;
    }
}
