import { GET_ALL_EXAM_RESULT, GET_ALL_ONLINE_EXAM, GET_QUESTIONS_EACH_EXAM } from "./actionType";

const initialState = {
    allOnlineExam: [],
    singleExamQus: [],
    allTestResults: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ONLINE_EXAM:
            return { ...state, allOnlineExam: action.payload };
        case GET_QUESTIONS_EACH_EXAM:
            return { ...state, singleExamQus: action.payload };
        case GET_ALL_EXAM_RESULT:
            return { ...state, allTestResults: action.payload };
        default:
            return state;
    }
}
