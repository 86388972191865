import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BackButton from "../../../../elements/BackButton";

import { getAllTestScore } from "../../../../redux/Parent/onlineTest/action";
export class TestScore extends Component {
    componentDidMount() {
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getAllTestScore(user.data.auth_token, student_id.value);
    }
    render() {
        const { testScore } = this.props;
        return (
            <React.Fragment>
                <div className="px-3 py-4">
                    <BackButton title={"Score"} />
                </div>
                {testScore?.data?.length > 0 ? (
                <div className="px-3 mt-2">
                {testScore?.data?.map((item) =>(
                    <React.Fragment>
                        <div className="panel-board px-2 py-3 ">
                            <table className="w-100">
                                <tr className="panel-head  px-3">
                                    <th className="panel-head-1 ps-3">
                                        Subject
                                    </th>

                                    <th className="panel-head-3 py-2 text-center">
                                        No.Qus
                                    </th>
                                </tr>

                                <tr className="panel-content">
                                    <td className="ps-3 py-2">{item?.subject?.name}</td>

                                    <td className="py-2 text-center">{item?.questions_count}</td>
                                </tr>
                            </table>
                        </div>
                        <div className="text-center mt-3">
                            <Link
                                to={"/test-answer/" + item.id}
                                style={{
                                    color: "#809CC8",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    textDecoration: "underline",
                                }}
                            >
                                Show my answers
                            </Link>
                        </div>
                    </React.Fragment>
                ))}

                </div>
                ): (
                    <div className="text-center ">
                        <span className="fs-4 fw-bold">No Result to Show</span>
                    </div>
                )}

                <div className="gap-2 px-2 attendence-btn-div w-100">
                    <button className="attendence-btn w-100">
                        Back To Home
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    testScore: state.onlineTest.testScore,
});

export default connect(mapStateToProps, { getAllTestScore })(TestScore);
