import {
    GET_STORE_DATA,
    GET_SINGLE_ITEM_DATA
} from "./actionType";

const initialState = {
    store_data: [],
    single_item: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STORE_DATA:
            return { ...state, store_data: action.payload };
        case GET_SINGLE_ITEM_DATA:
            return { ...state, single_item: action.payload };
        default:
            return state;
    }
}
