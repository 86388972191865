import { GET_ALL_APPOINTMENTS, GET_STUDENT_DETAILS } from "./actionType";

const initialState = {

    makeAppointment:[],
    allAppointments: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STUDENT_DETAILS:
            return { ...state, makeAppointment: action.payload };
        case GET_ALL_APPOINTMENTS:
            return { ...state, allAppointments: action.payload };
        default:
            return state;
    }
}
