import React, { Component } from 'react'
import { BsSearch } from 'react-icons/bs';

import { connect } from 'react-redux'

import BackButton from '../../../elements/BackButton'
import "./medicalInstrucion.css";
import img from "../../../assets/Images/no-appointments.png";
import { getStudentMedical, searchMedical } from '../../../redux/Teacher/student/action';
import ContentLoader from 'react-content-loader';
export class StudentMedicalInstruction extends Component {
    state = {
        medical: {},
        value: '',
        loading: false,
    }
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        this.props.getStudentMedical(user.data.auth_token);
        this.setState({
            medicals: this.props.medicals
        })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1500)
    }
    onSearch = (value) => {
        const { user } = this.props;

        this.props.searchMedical(value).then((response) => {
            if (response.data.success) {
                this.setState({ medicals: response.data });
            } else {
                this.setState({
                    medicals: this.props.medicals
                })
            }
        });
    }
    render() {
        const { medicals } = this.state;

        return (

            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 580"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#ecebeb"}
                        >
                            <rect x="10" y="30" rx="5" ry="5" width="25" height="25" />
                            <rect x="100" y="30" rx="10" ry="10" width="80" height="25" />
                            <rect x="10" y="70" rx="10" ry="10" width="260" height="120" />
                            <rect x="10" y="220" rx="10" ry="10" width="260" height="120" />
                            <rect x="10" y="370" rx="10" ry="10" width="260" height="120" />


                        </ContentLoader>
                    </React.Fragment>

                ) : (
                    <React.Fragment>
                        <BackButton title="Medical instructions" />
                        <div className='px-3'>
                            <div className='d-flex  px-3 mt-2 medical-search-bar justify-content-between align-items-center'>
                                <input type="text" className='w-75' placeholder='Student Name or Class' onChange={(e) => {
                                    let value = e.target.value;
                                    this.onSearch(value)
                                }} />

                                <BsSearch size={30} className="w-25 " style={{
                                    background: "#C24A6B",
                                    color: "#fff",
                                    padding: "4px",
                                    borderRadius: "25px"
                                }} />

                            </div>
                        </div>
                        {medicals?.data?.length > 0 ? (
                        <div className="px-3 mt-3">
                            {medicals?.data?.map((medical) => (
                                <div className="px-2 py-2 medical_container">
                                    <div className="d-flex justify-content-between align-items-center student_details text-white">
                                        <div>

                                            <span>{medical?.student?.user?.name}</span>
                                        </div>
                                        <div>
                                            <span>Class</span>
                                            <span className="mx-1">:</span>
                                            <span>{medical?.class?.name}</span>
                                            <span> - {medical?.section?.name}</span>
                                        </div>

                                    </div>
                                    <div className="d-flex flex-column mt-3 medical_details px-2">
                                        <div className="d-flex align-items-center justify-content-between ">
                                            <span>From Date :</span>
                                            <span>{medical?.from_date}</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>To Date :</span>
                                            <span>{medical?.to_date}</span>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Instruction:</span>
                                            <span>{medical?.instructions}</span>
                                        </div>
                                    </div>

                                    <div />
                                </div>
                            ))}
                        </div>
                        ): (<div
                                className="d-flex flex-column align-items-center justify-content-center mt-5 appointment-no-data px-3"
                                style={{
                                    height: "50vh",
                                }}
                            >
                                <img
                                    src={img}
                                    alt="medical"
                                    style={{
                                        width: "100%",
                                    }}
                                ></img>
                                <span className="text-center fs-5 fw-500">
                                    No Medical Instruction
                                </span>
                            </div>)}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    medicals: state.student.medical
})


export default connect(mapStateToProps, { getStudentMedical, searchMedical })(StudentMedicalInstruction)
