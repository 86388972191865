import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
    addProduct,
    removeProduct,
    updateCart,
} from "../../../../redux/Parent/cart/action";
import { BiShoppingBag } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import Button from "react-bootstrap/esm/Button";
import ShowMore from "react-show-more";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

class ItemDetail extends Component {
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            this.props.forceUpdate();
        }, 100);
    };

    removeProductQuantity = (product, addon) => {
        const { cartProducts, updateCart } = this.props;

        const index = cartProducts.findIndex(
            (p) => p.selectedaddons && p.selectedaddons[0].addon_id === addon.id
        );
        //if product is in the cart then index will be greater than 0
        // alert(index)
        if (index >= 0) {
            cartProducts.forEach((cp) => {
                if (
                    cp.selectedaddons &&
                    cp.selectedaddons[0].addon_id === addon.id
                ) {
                    // if (JSON.stringify(cp) === JSON.stringify(product)) {
                    if (cp.quantity === 1) {
                        //if quantity is 1 then remove product from cart
                        cartProducts.splice(index, 1);
                    } else {
                        //else decrement the quantity by 1
                        cp.quantity -= 1;
                    }
                    // }
                }
            });

            updateCart(cartProducts);
        }
    };

    render() {
        const { single_item, addProduct, cartProducts, removeProduct, type } =
            this.props;
        console.log(type, "typex");
        return (
            <div>
                <div className="d-flex flex-row justify-content-between  px-3 mt-4">
                    <div
                        className=""
                        style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            color: "#0F172A",
                        }}
                    >
                        {single_item.item.name}
                    </div>
                </div>
                <div
                    className="px-3 mt-3"
                    style={{
                        color: "#0F172A",
                        fontSize: "15px",
                        fontWeight: "700",
                    }}
                >
                    {single_item.item?.item_category?.name}
                </div>
                <div className="mt-3 single-store-button-save">
                    <span
                        style={{
                            color: "#fff",
                            fontWeight: "700",
                            padding: "0.2em",
                            fontSize: "13px",
                        }}
                    >
                        RS {single_item.item.price}
                    </span>
                </div>
                <div
                    className="mt-3 px-3"
                    style={{
                        fontWeight: "700",
                        color: "#0F172A",
                    }}
                >
                    Information
                </div>
                <div className="px-3" style={{}}>
                    <ShowMore
                        lines={1}
                        more={"Show More"}
                        less={"Show Less"}
                        anchorclassName="show-more px-3 ml-1"
                    >
                        <div
                            className="px-3"
                            style={{
                                color: " #68686C",
                                fontWeight: "500",
                            }}
                        >
                            {single_item.item.description}
                        </div>
                    </ShowMore>
                </div>

                {type == "kit" ? (
                    <React.Fragment>
                        {cartProducts &&
                        cartProducts.length > 0 &&
                        cartProducts.find(
                            (cp) =>
                                cp.type == "kit" &&
                                cp.id === single_item.item.id
                        ) !== undefined ? (
                            <div
                                className=" position-fixed bottom-0 bg-white pb-5 w-100 pt-2"
                                style={{
                                    boxShadow:
                                        "0px -1px 4px rgba(0, 0, 0, 0.25)",
                                }}
                            >
                                <div className="d-flex align-items-center justify-content-around">
                                    <div
                                        onClick={() => {
                                            single_item.item.quantity = 1;
                                            removeProduct(single_item.item);
                                            this.forceStateUpdate();
                                        }}
                                        className="p-1 d-flex btn-single-store-minus align-items-center justify-content-center"
                                    >
                                        <AiOutlineMinus
                                            style={{
                                                fontSize: "15px",
                                            }}
                                        />
                                    </div>
                                    <div className="bg-white">
                                        <div className="">
                                            <button className="btn-single-store-quantity">
                                                <span
                                                    style={{
                                                        color: "#000",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    {
                                                        cartProducts.find(
                                                            (cp) =>
                                                                cp.type ==
                                                                    "kit" &&
                                                                cp.id ===
                                                                    single_item
                                                                        .item.id
                                                        ).quantity
                                                    }
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => {
                                            single_item.item.quantity = 1;
                                            single_item.item.type = type;
                                            addProduct(single_item.item);
                                            this.forceStateUpdate();
                                        }}
                                        className="p-1 btn-single-store-plus d-flex  align-items-center justify-content-center"
                                    >
                                        <AiOutlinePlus
                                            style={{
                                                fontSize: "15px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <Link to={"/my-cart"}>
                                    <div
                                        className="position-relative"
                                        style={{
                                            background: "#FFFFFF",
                                        }}
                                    >
                                        <div className="">
                                            <button className="btn-single-store ">
                                                <span
                                                    style={{
                                                        color: "#fff",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    View Cart
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <div
                                onClick={() => {
                                    single_item.item.quantity = 1;
                                    single_item.item.type = type;
                                    addProduct(single_item.item);
                                    this.forceStateUpdate();
                                }}
                                className="position-relative"
                                style={{
                                    background: "#FFFFFF",
                                    boxShadow:
                                        "0px -1px 4px rgba(0, 0, 0, 0.25)",
                                }}
                            >
                                <div className="">
                                    <button className="btn-single-store position-fixed">
                                        <span
                                            style={{
                                                color: "#fff",
                                                fontWeight: "700",
                                            }}
                                        >
                                            Buy Now
                                        </span>
                                    </button>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {cartProducts &&
                        cartProducts.length > 0 &&
                        cartProducts.find(
                            (cp) =>
                                cp.type == "item" &&
                                cp.id === single_item.item.id
                        ) !== undefined ? (
                            <div
                                className=" position-fixed bottom-0 bg-white pb-5 w-100 pt-2"
                                style={{
                                    boxShadow:
                                        "0px -1px 4px rgba(0, 0, 0, 0.25)",
                                }}
                            >
                                <div className="d-flex align-items-center justify-content-around">
                                    <div
                                        onClick={() => {
                                            single_item.item.quantity = 1;
                                            removeProduct(single_item.item);
                                            this.forceStateUpdate();
                                        }}
                                        className="p-1 d-flex btn-single-store-minus align-items-center justify-content-center"
                                    >
                                        <AiOutlineMinus
                                            style={{
                                                fontSize: "15px",
                                            }}
                                        />
                                    </div>
                                    <div className="bg-white">
                                        <div className="">
                                            <button className="btn-single-store-quantity">
                                                <span
                                                    style={{
                                                        color: "#000",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    {
                                                        cartProducts.find(
                                                            (cp) =>
                                                                cp.type ==
                                                                    "item" &&
                                                                cp.id ===
                                                                    single_item
                                                                        .item.id
                                                        ).quantity
                                                    }
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => {
                                            single_item.item.quantity = 1;
                                            single_item.item.type = type;
                                            addProduct(single_item.item);
                                            this.forceStateUpdate();
                                        }}
                                        className="p-1 btn-single-store-plus d-flex  align-items-center justify-content-center"
                                    >
                                        <AiOutlinePlus
                                            style={{
                                                fontSize: "15px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <Link to={"/my-cart"}>
                                    <div
                                        className="position-relative"
                                        style={{
                                            background: "#FFFFFF",
                                        }}
                                    >
                                        <div className="">
                                            <button className="btn-single-store ">
                                                <span
                                                    style={{
                                                        color: "#fff",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    View Cart
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <div
                                onClick={() => {
                                    single_item.item.quantity = 1;
                                    single_item.item.type = type;
                                    addProduct(single_item.item);
                                    this.forceStateUpdate();
                                }}
                                className="position-relative"
                                style={{
                                    background: "#FFFFFF",
                                    boxShadow:
                                        "0px -1px 4px rgba(0, 0, 0, 0.25)",
                                }}
                            >
                                <div className="">
                                    <button className="btn-single-store position-fixed">
                                        <span
                                            style={{
                                                color: "#fff",
                                                fontWeight: "700",
                                            }}
                                        >
                                            Buy Now
                                        </span>
                                    </button>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
});

export default connect(mapStateToProps, {
    addProduct,
    removeProduct,
    updateCart,
})(withRouter(ItemDetail));
