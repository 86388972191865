import React, { Component } from 'react'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import BackButton from '../../../../elements/BackButton'

export class ViewFees extends Component {
    state = {
        loading: false
    }
    render() {
        const { fees } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 320 658"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#FFFFFF"
                        >
                            <rect x="19" y="34" rx="8" ry="8" width="22" height="22" />
                            <rect x="120" y="34" rx="8" ry="8" width="110" height="22" />
                            <rect x="18" y="83" rx="8" ry="8" width="286" height="30" />
                            <rect x="18" y="120" rx="8" ry="8" width="286" height="390" />
                            <rect x="8" y="598" rx="8" ry="8" width="303" height="43" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"Fees"} />

                        <div className="name-term d-flex flex-column justify-content-between px-4 mt-3" >
                            <div className="d-flex" style={{ fontSize: '1em' }}>
                                <div>Name</div>
                                <div className='ps-1'>:{fees?.student?.user?.name} </div>
                            </div>
                            <div className="d-flex" style={{ fontSize: '1em', whiteSpace: 'nowrap' }}>
                                <div>Roll No</div>
                                <div className='ps-1'>:{fees?.student?.roll_number}</div>
                            </div>
                        </div>
                        <div className="px-3 mt-2" style={{ paddingBottom: '25vw' }}>
                            {fees?.fees.map((value, index) => (
                                <div className="panel-board px-3 py-3 mt-3">
                                    <table className="w-100">
                                        <tr className="panel-head  px-3">
                                            <th className="panel-head-1 ps-3">
                                                Type: {value?.feetype?.type}
                                            </th>
                                            <th className="panel-head-3 py-2 text-end pe-4" colspan="2">
                                                Rs: {Math.round(value?.amount)}
                                            </th>
                                        </tr>
                                        <tr className="panel-content">
                                            <td className="ps-3 py-3"> {value?.feetype?.type}</td>
                                            <td className="py-1 text-center"></td>
                                            <td className="py-2 text-end pe-4">
                                                {Math.round(value?.amount)}
                                            </td>
                                        </tr>

                                        <tr className="panel-top">
                                            <td className="ps-3 py-2">Total</td>
                                            <td className="py-2 text-center">Rs: {Math.round(value?.amount)}</td>
                                            <td className="py-2 text-end pe-4" style={{ color: '#DB3665', fontWeight: '600' }} colSpan={2}>
                                                {value?.fee_student ? value?.fee_student?.is_paid ? "Fee Paid" : "Fee Pending" : "Fee Pending"}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            ))}
                        </div>

                        <div className="download-btn-bg w-100 px-3 py-3">
                            <button className="attendence-btn w-100 py-3">
                                Download
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    fees: state.class.fees
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ViewFees)
