import React, { Component } from 'react'
import { RiDeleteBin7Line } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import BackButton from '../../../elements/BackButton'
import { getTeacherOnlineClasses, deleteLiveClass } from '../../../redux/Teacher/student/action'
import Modal from "react-modal";
import image from '../../../assets/Images/noclass.png'
import moment from 'moment'
import ContentLoader from "react-content-loader";
import { MdOutlineContentCopy, MdDone } from "react-icons/md"
import { CopyToClipboard } from 'react-copy-to-clipboard';

export class LiveCLasses extends Component {
    state = {
        loading: false,
        open: false,
        deleting_class: '',
        copied: ''
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { teacher } = this.props
        this.props.getTeacherOnlineClasses(teacher.data.auth_token)
            .then((response) => {
                if (response?.payload?.success) {
                    this.setState({ loading: false })
                }
            })
    }
    deleteLiveClass = (data) => {
        const { teacher } = this.props
        this.props.deleteLiveClass(teacher.data.auth_token, data.id)
            .then((response) => {
                if (response.success) {
                    this.modalClose();
                }
            })
    }
    modalOpen = (data) => {
        this.setState({ open: true, deleting_class: data });
    };
    modalClose = () => {
        this.setState({ open: false, deleting_class: '' });
    };
    copyLink = (data) => {
        this.setState({ copied: data.id })
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    }
    render() {
        const { liveclasses } = this.props
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 539"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="15" y="26" rx="6" ry="9" width="24" height="24" />
                            <rect x="110" y="29" rx="6" ry="9" width="68" height="18" />
                            <rect x="20" y="70" rx="10" ry="10" width="235" height="170" />
                            <rect x="20" y="250" rx="10" ry="10" width="235" height="170" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {liveclasses?.success ?
                            <>
                                <BackButton title={"Live Classes"} />
                                <div className='mt-3 px-3' style={{ paddingBottom: '25vw' }}>
                                    {liveclasses?.data?.length > 0 ?
                                        <>
                                            {liveclasses?.data?.length > 0 && liveclasses?.data?.map((data) => (
                                                <div className='d-flex flex-column px-3 py-2 position-relative mt-2' style={{ backgroundColor: '#f4f4f4', borderRadius: '12px' }}>
                                                    <div className='mt-2' style={{ color: '#b3b3b3' }}>Meeting On : <span className='fw-bolder text-muted'>{moment(data?.date).format("DD-MM-YYYY")}</span></div>
                                                    <div className='mt-1' style={{ color: '#b3b3b3' }}>Subject : <span className='fw-bolder text-muted'>{data?.subject?.name}</span></div>
                                                    <div className='mt-1' style={{ color: '#b3b3b3' }}>Class & Section : <span className='fw-bolder text-muted'>{data?.class?.name} {data?.section?.name}</span></div>
                                                    <div className='mt-1' style={{ color: '#b3b3b3' }}>Starts at :<span className='fw-bolder text-muted'>{moment(new Date("1970-01-09T" + data?.from).getTime()).format("LT")}</span></div>
                                                    <div className='mt-1' style={{ color: '#b3b3b3' }}>Meeting URL : <span className='fw-bolder text-muted' style={{ color: '#72AFBC' }}>{data.link}</span></div>
                                                    <div className='btn teacher-style-btn mt-2 w-100' style={{}}>
                                                        {data?.id == this.state.copied ?
                                                            <div className=''>
                                                                <MdDone size={'1.2em'} />
                                                                <span>Copied</span>
                                                            </div>
                                                            :
                                                            <CopyToClipboard text={data?.link}
                                                                onCopy={() => this.copyLink(data)}>
                                                                <div style={{ fontWeight: '680' }} >
                                                                    <MdOutlineContentCopy size={"1.2em"} />
                                                                    <span>Copy URL</span>
                                                                </div>
                                                            </CopyToClipboard>
                                                        }
                                                    </div>
                                                    <div className='position-absolute' style={{ top: '5px', right: '8px', color: '#C22727' }} onClick={() => this.modalOpen(data)}>
                                                        Delete <RiDeleteBin7Line />
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        <div className="d-flex flex-column align-items-center mt-5 pt-5">
                                            <img src={image} style={{ width: '85%', objectFit: 'cover', }} />
                                            <span style={{ fontSize: '11px' }}>No Live Class Now...</span>
                                        </div>
                                    }
                                </div>
                                <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                                    <Link to="/create-meeting">
                                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                                            Create Meeting
                                        </button>
                                    </Link>
                                </div>

                                <Modal
                                    isOpen={this.state.open}
                                    ariaHideApp={false}
                                    onRequestClose={() => this.modalClose()}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <div
                                        className="container"
                                        style={{
                                            borderRadius: "5px",
                                            height: "70vw",
                                            width: '70vw',
                                            zIndex: "9999",
                                        }}
                                    >
                                        <React.Fragment>
                                            <div className="d-flex flex-column justify-content-center align-items-between">
                                                <div className='d-flex justify-content-center mt-5'
                                                    style={{ fontSize: '18px', fontWeight: '600', color: '#2B2C2F' }}>
                                                    Delete Live Class
                                                </div>
                                                <div className='d-flex justify-content-center mt-3'
                                                    style={{ fontSize: '12px', fontWeight: '400', color: '#2B2C2F' }}>
                                                    Do you Really want to remove this live session of {this.state?.deleting_class?.date}?</div>
                                                <div className='d-flex justify-content-between w-100 position-fixed' style={{ bottom: '10%', left: '0%' }}>
                                                    <div className='btn modal-yes-btn px-2 py-2 w-100 ms-1' onClick={() => this.deleteLiveClass(this.state.deleting_class)}>
                                                        Yes
                                                    </div>
                                                    <div className='btn modal-no-btn px-2 py-2 w-100 mx-1' onClick={() => this.modalClose()}>
                                                        No
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    </div>
                                </Modal>
                            </>
                            : null}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    liveclasses: state.student.liveclasses
})
export default connect(mapStateToProps, {
    getTeacherOnlineClasses,
    deleteLiveClass
})(LiveCLasses)
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #C24A6B",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
