import {
    GET_TEACHER_REMARKS,
    GET_TEACHER_LIVE_CLASSES,
    GET_GALLERY_DATA,
    GET_ALL_MEETING_APPOINTMENTS,
    GET_ALL_TEACHER_STUDENTS,
    GET_ALL_SINGEL_STUDENTS_INFO,
    GET_TEACHER_TUTORIALS,
    GET_TEACHER_TIMETABLE,
    GET_TEACHER_ALLERGIES,
    GET_TEACHER_MEDICAL,
    ADD_STUDENT_ATTENDENCE,
    GET_TOILET_INFO,
    GET_TEACHER_UPLOADED_ANSWERS,
    GET_TEACHER_MATERIALS,
} from "./actionType";
const initialState = {
    remarks: {},
    liveclasses: {},
    gallery: {},
    appointments: {},
    myStudents: {},
    student: {},
    allergies: {},
    medical: {},
    teacher_tutorials: {},
    teacher_timetable: {},
    info: {},
    attendence: [],
    answers: {},
    materials: {},
};
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TEACHER_REMARKS:
            return { ...state, remarks: action.payload };
        case GET_TEACHER_LIVE_CLASSES:
            return { ...state, liveclasses: action.payload };
        case GET_GALLERY_DATA:
            return { ...state, gallery: action.payload };
        case GET_ALL_MEETING_APPOINTMENTS:
            return { ...state, appointments: action.payload };
        case GET_ALL_TEACHER_STUDENTS:
            return { ...state, myStudents: action.payload };
        case GET_ALL_SINGEL_STUDENTS_INFO:
            return { ...state, student: action.payload };
        case GET_TEACHER_TUTORIALS:
            return { ...state, teacher_tutorials: action.payload };
        case GET_TEACHER_ALLERGIES:
            return { ...state, allergies: action.payload };
        case GET_TEACHER_MEDICAL:
            return { ...state, medical: action.payload };
        case GET_TOILET_INFO:
            return { ...state, info: action.payload };
        case GET_TEACHER_TIMETABLE:
            return { ...state, teacher_timetable: action.payload };
        case ADD_STUDENT_ATTENDENCE:
            return { ...state, attendence: action.payload };
        case GET_TEACHER_UPLOADED_ANSWERS:
            return { ...state, answers: action.payload }
        case GET_TEACHER_MATERIALS:
            return { ...state, materials: action.payload }
        default:
            return state;
    }
}
