import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getHomeWork } from '../../../../redux/Teacher/user/action';
import ContentLoader from "react-content-loader";
import BackButton from "../../../../elements/BackButton";
import image from '../../../../assets/Images/noanswers.png'
import { Link } from 'react-router-dom';
import Ink from 'react-ink';
import { IMAGE_BASE_URL } from '../../../../api';


export class HomeWork extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        const { teacher } = this.props
        this.props.getHomeWork(teacher.data.auth_token)
    }
     render() {
        const {homework } = this.props
 
    return (
        <React.Fragment>
        {this.state.loading ? (
            <React.Fragment>
                <ContentLoader
                    viewBox="0 0 280 539"
                    backgroundColor={"#F3F3F3"}
                    foregroundColor={"#FFFFFF"}
                >
                    <rect x="15" y="26" rx="6" ry="9" width="24" height="24" />
                    <rect x="110" y="29" rx="6" ry="9" width="60" height="18" />
                    <rect x="20" y="80" rx="10" ry="10" width="235" height="85" />
                    <rect x="20" y="173" rx="10" ry="10" width="235" height="85" />
                    <rect x="20" y="266" rx="10" ry="10" width="235" height="85" />
                </ContentLoader>
            </React.Fragment>
        ) : (
            <React.Fragment>
                {homework?.success ?
                    <>
                        <div className="">
                            <BackButton title={"Uploaded Homework"} />
                            <div className="px-3 mt-3 pt-1" style={{ paddingBottom: '25vw' }}>
                                {/* <div style={{ color: '#373E43', fontSize: '17px', fontWeight: '500' }} className='mt-2 px-2'>
                                    <span>Uploaded Homework</span>
                                </div> */}
                                {homework?.data?.length > 0 ?
                                    <>
                                        {homework?.data?.length > 0 && homework?.data?.map((data) => (
                                            <div className='mt-3'>
                                                <div className="tutorial-library bg-white px-2 w-100">
                                                    <div className=" tutorial-card p-2 mt-2 position-relative">
                                                        <div className="d-flex">
                                                            <div className="ms-2 d-flex flex-column">
                                                                
                                                                <span className="tutorial-details">
                                                                    Class {data?.studentClass?.name}{" "} {data?.section?.name}
                                                                </span>
                                                                <span className="tutorial-details pb-2">
                                                                    Subject :
                                                                    {data?.subject?.name} 
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center position-absolute bottom-0 end-0 m-2">
                                                                    <a href={IMAGE_BASE_URL + data?.assignment} target="_blank" download >
                                                                        <div className="tutorial-details-btn me-1 p-2">
                                                                            Download
                                                                        </div>
                                                                        <Ink style={{ color: '#aaaaaa' }} />
                                                                    </a>
                                                                </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    : <div className="d-flex flex-column align-items-center pt-5 mt-5 ">
                                        <img src={image} alt="no-data" style={{ width: '85%', objectFit: 'cover', }} />
                                        <span style={{ fontSize: '11px' }}>Sorry..No Uploaded Files Found...</span>
                                    </div>}
                                <Link to="/teacher-homework">
                                    <div className='position-fixed bottom-0 py-2 w-100 px-3' style={{ left: '0%' }}>
                                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' type="submit">
                                            Upload
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </>
                    : null}
            </React.Fragment>
        )}
    </React.Fragment>)
    
  }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    schooldata: state.teacher.schooldata,
    homework:  state.teacher.homework,
});

export default connect(mapStateToProps, { getHomeWork})( HomeWork);