import React, { Component } from 'react'
import { connect } from 'react-redux'
import gmeet from "../../../assets/Images/gmeet.png";
import image2 from '../../../assets/Images/noclass.png'
import { MdOutlineContentCopy, MdDone } from "react-icons/md"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";
import { getOnlineClasses } from "../../../redux/Parent/class/action";


export class OnlineClasses extends Component {
    state = {
        loading: false,
        copied: ''
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getOnlineClasses(user.data.auth_token, student_id ? student_id.value : user?.data?.students[0]?.id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false });
                }
            })
    }
    copyLink = (data) => {
        this.setState({ copied: data.id })
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    }
    render() {
        const { onlineclasses } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 539"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="13" y="80" rx="10" ry="10" width="255" height="117" />
                            <rect x="13" y="205" rx="10" ry="10" width="255" height="117" />
                            <rect x="13" y="330" rx="10" ry="10" width="255" height="117" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="">
                            <div className="mt-2">
                                <BackButton title={"Online Classes"} />
                            </div>
                            {/* online classes */}
                            {onlineclasses?.success ?
                                <>
                                    <div className="px-3 mt-3 pt-1" style={{ paddingBottom: '15vw' }}>
                                        {onlineclasses?.data?.length > 0 ?
                                            <div>
                                                {onlineclasses?.data?.map((data, i) => (
                                                    <div className="tutorial-library bg-white px-2 w-100vw">
                                                        <div className=" tutorial-card p-2 mt-2 position-relative">
                                                            <div className="d-flex">
                                                                <div className="ms-2 d-flex flex-column">
                                                                    <h2 className="tutorial-heading">
                                                                        <img
                                                                            src={gmeet}
                                                                            alt="youtube"
                                                                            width="25"
                                                                        />
                                                                        {data.title}
                                                                    </h2>
                                                                    <span className="tutorial-details">
                                                                        {/* {console.log(Date(data?.to).toString())} */}
                                                                        Subject :
                                                                        {data?.subject?.name} {data?.section?.student_class?.name}-{data?.section?.name}
                                                                    </span>
                                                                    <span className="tutorial-details">
                                                                        On :
                                                                        {data?.date}
                                                                    </span>
                                                                    <span className="tutorial-details">
                                                                        Time :
                                                                        {data?.from} - {data?.to}
                                                                    </span>
                                                                    <div style={{ width: '70%' }}>
                                                                        <span className="tutorial-details">
                                                                            {data?.link}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center position-absolute bottom-0 end-0 m-2 py-2 px-3">
                                                                <div className="mt-3 pt-1 d-flex justify-content-between" >
                                                                    {data?.id == this.state.copied ?
                                                                        <div className=''>
                                                                            <MdDone size={'1.2em'} style={{ color: '#699BF7' }} />
                                                                            <span style={{ color: '#699BF7' }}>Copied</span>
                                                                        </div>
                                                                        :
                                                                        <CopyToClipboard text={data?.link}
                                                                            onCopy={() => this.copyLink(data)}>
                                                                            <div className="ps-3 pt-2" style={{ fontWeight: '680' }} >
                                                                                <MdOutlineContentCopy size={"1.2em"} style={{ color: '#a09a9c' }} />
                                                                            </div>
                                                                        </CopyToClipboard>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className="d-flex flex-column align-items-center mt-5 pt-5">
                                                <img src={image2} style={{ width: '85%', objectFit: 'cover', }} />
                                                <span style={{ fontSize: '11px' }}>No Live Class Now...</span>
                                            </div>
                                        }
                                    </div>
                                </>
                                : null}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    onlineclasses: state.class.onlineclasses,
});


export default connect(mapStateToProps, { getOnlineClasses })(OnlineClasses)
