
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Footer from '../../Parent/Footer'
import ContentLoader from 'react-content-loader'
import BackButton from '../../../elements/BackButton'
import ProgressiveImage from "react-progressive-image";
import { getMediaImages } from "../../../redux/Parent/class/action";
import { IMAGE_BASE_URL } from "../../../api";
import {Link} from "react-router-dom";
class PostOnWall extends Component {
    state = {
        loading: false
    }
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.getMediaImages(user?.data?.auth_token, student_id.value);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    render() {
        const { media } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader viewBox="0 0 280 576" backgroundColor={'#F3F3F3'} foregroundColor={'#FFFFFF'}>
                                <rect x="13" y="55" rx="10" ry="10" width="255" height="175" />
                                <rect x="13" y="250" rx="10" ry="10" width="255" height="170" />
                                <rect x="13" y="430" rx="10" ry="10" width="255" height="140" />
                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {media?.success ?
                                <>
                                    <div className='mt-2'>
                                        <BackButton title={"School Media"} />
                                    </div>
                                    {media?.data?.length > 0 ?
                                        <div className='px-3'>
                                            {media?.data?.length > 0 && media?.data?.map((data) => (
                                                <Link to={"/media-images/" + data.id}>
                                                <div className="post-on-wall-card px-3 py-2 mt-3">
                                                    <div className="mt-2">
                                                        <div className=" position-relative">
                                                            <ProgressiveImage
                                                                delay={20}
                                                                src={
                                                                    IMAGE_BASE_URL + data.file
                                                                }
                                                                placeholder={
                                                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                                                }
                                                            >
                                                                {(src, loading) => (
                                                                    <img
                                                                        src={src}
                                                                        style={{
                                                                            width: "80vw",
                                                                            height: "50vw",
                                                                            borderRadius: "12px",
                                                                            objectFit: "cover",
                                                                            backgroundRepeat:
                                                                                "no repeat",
                                                                            // boxShadow: '-4px 4px 5px rgba(0, 0, 0, 0.1)'
                                                                        }}
                                                                        className=""
                                                                        alt="media"
                                                                    />
                                                                )}
                                                            </ProgressiveImage>
                                                            <div className='mt-2 ps-2' style={{ fontSize: '14px', fontWeight: '500', color: '#393433' }}>
                                                                <span>{data.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Link>
                                            ))}
                                        </div>
                                        : null}
                                </>
                                : null}
                        </React.Fragment>
                    )
                }
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    media: state.class.media,
})

export default connect(mapStateToProps, { getMediaImages })(PostOnWall)
