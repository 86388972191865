import React, { Component } from "react";
import { connect } from "react-redux";
import ProgressiveImage from "react-progressive-image";
import Item2 from "../../../../assets/Images/item2.png";
import { FaPlusSquare } from "react-icons/fa";
import { FaMinusSquare } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { IMAGE_BASE_URL } from "../../../../api/index";

class OrderItems extends Component {
    render() {
        const {
            addProductQuantity,
            removeProductQuantity,
            item,
            removeProduct,
        } = this.props;

        return (
            <React.Fragment>
                <div>
                    <div className="cart-obj">
                        {item.type == "item" ? (
                            <ProgressiveImage
                                delay={20}
                                src={IMAGE_BASE_URL + item?.item_image1}
                                placeholder={
                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                }
                            >
                                {(src, loading) => (
                                    <img
                                        className=""
                                        style={{
                                            width: "28vw",
                                            height: "28vw",
                                            borderRadius: "20px",
                                            objectFit: "cover",
                                            backgroundRepeat: "round",
                                        }}
                                        src={src}
                                        alt="main-item"
                                    />
                                )}
                            </ProgressiveImage>
                        ) : (
                            <ProgressiveImage
                                delay={20}
                                src={IMAGE_BASE_URL + item?.image}
                                placeholder={
                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                }
                            >
                                {(src, loading) => (
                                    <img
                                        className=""
                                        style={{
                                            width: "28vw",
                                            height: "28vw",
                                            borderRadius: "20px",
                                            objectFit: "cover",
                                            backgroundRepeat: "round",
                                        }}
                                        src={src}
                                        alt="main-item"
                                    />
                                )}
                            </ProgressiveImage>
                        )}

                        <div className="d-flex flex-wrap w-50 ms-3">
                            <span
                                className="mt-1 w-100"
                                style={{
                                    fontWeight: "700",
                                    fontSize: "16px",
                                }}
                            >
                                {item.name}
                            </span>
                            <div className=" w-100">
                                <span
                                    className="text-muted"
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "11px",
                                    }}
                                >
                                    {item?.item_category?.name}
                                </span>
                                {/* <span
                                    className=""
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "11px",
                                    }}
                                >
                                    Black
                                </span> */}
                            </div>

                            <div class=" d-flex align-items-center ">
                                <div class="btn-cart-minus">
                                    <AiOutlineMinus
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    />
                                </div>
                                <div className="cart-quantity-text">1</div>
                                <div class="btn-cart-plus">
                                    <AiOutlinePlus
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column justify-content-between align-items-end p-2">
                            <IoMdMore style={{ color: "gray" }} />
                            <span className="cart-price">Rs {item.price}</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    cartTotal: state.cart.data,
    user: state.user.user,
});
export default connect(mapStateToProps)(OrderItems);
