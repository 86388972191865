import { GET_ALL_HOME_PAGE_DATA } from "./actionType";

const initialState = {
    home: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_HOME_PAGE_DATA:
            return { ...state, home: action.payload };
        default:
            return state;
    }
}
