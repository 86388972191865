import React, { Component } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { connect } from "react-redux";
import teacher from "../../../../assets/Images/bg.png";
import Tab from "../../../../assets/Images/tab.png";
import cap from "../../../../assets/Images/glass.png";
import ProgressiveImage from "react-progressive-image";
import { IMAGE_BASE_URL, WEBSITE_BASE_URL } from "../../../../api";

export class ViewTeacher extends Component {
    state = {
        openTab: [],
        teachers: [1, 2, 3]
    }


    onChangeTab = (id) => {
        if (this.state.openTab.length > 0 && this.state.openTab.includes(id)) {
            let data = this.state.openTab
            data.splice(data.indexOf(id), 1)
            this.setState({ openTab: data })
        } else {
            let data = this.state.openTab;
            data.push(id)
            this.setState({ openTab: data })
        }
    }
    render() {
        const { teachers } = this.props;
        return (
            <React.Fragment>
                {teachers && teachers.length > 0 &&
                    <React.Fragment>
                        <div className="px-4 py-3">
                            <div style={{ fontSize: "18px", fontWeight: "500" }}>
                                View teachers
                            </div>
                        </div>

                        <div
                            className="px-3 viewteacher hidden-scroll-bar gap-3"
                            style={{
                                gridTemplateColumns: `repeat(${teachers.length},1fr)`,
                                overflowX: "scroll",
                            }}
                        >
                            {/* teacher1 */}
                            {teachers && teachers.map((value, index) =>

                                <div className="teacher-div d-flex flex-column align-items-center justify-content-between">
                                    <ProgressiveImage
                                        delay={20}
                                        src={IMAGE_BASE_URL + value?.user?.image}
                                        placeholder={teacher}
                                    >
                                        {(src, loading) => (
                                            <img
                                                src={src}
                                                style={{
                                                    width: "75vw",
                                                    height: "62vw",
                                                    borderRadius: "35px",
                                                    objectFit: "cover",
                                                    backgroundRepeat: "round",
                                                }}
                                                className=""
                                                alt="grosav"
                                            />
                                        )}
                                    </ProgressiveImage>
                                    {!(this.state.openTab.length > 0 && this.state.openTab.includes(value)) ?
                                        <>
                                            <div className="position-absolute" style={{
                                                bottom: "15vw",
                                                zIndex: "1",
                                            }} onClick={() => this.onChangeTab(value)}>
                                                <IoIosArrowUp
                                                    style={{
                                                        fontSize: "1.3rem",
                                                        color: "white",
                                                    }}
                                                />
                                            </div>
                                            <div className="wrapper d-flex flex-row align-items-center justify-content-between w-100"
                                                style={{ backgroundImage: `url(${Tab})` }}   >
                                                <div className="w-100 position-relative">
                                                    <div className="px-2 pt-3 no-blur d-flex align-items-center justify-content-between w-100">
                                                        <div className="no-blur d-flex flex-column   justify-content-start">
                                                            <div style={{ fontSize: "16px", fontWeight: "500", }}    >
                                                                {value?.user?.name}
                                                            </div>
                                                            <div style={{ fontSize: "11px", fontWeight: "400", }}   >
                                                                {value?.subject?.name}
                                                            </div>
                                                        </div>
                                                        <div onClick={() => this.onChangeTab(value)}   >
                                                            <IoIosArrowUp style={{ fontSize: "1.3rem", backgroundColor: "white", borderRadius: "8px", }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {/* tab 2 */}

                                            <div className="position-absolute" style={{
                                                bottom: "40vw",
                                                zIndex: "1",
                                            }} onClick={() => this.onChangeTab(value)}>
                                                <IoIosArrowDown style={{ fontSize: "1.3rem", color: "white", }} />
                                            </div>

                                            <div className="d-flex flex-column align-items-center justify-content-between">
                                                <div
                                                    className="wrapper1 d-flex flex-row align-items-center justify-content-between"
                                                    style={{ backgroundImage: `url(${cap})` }}
                                                >
                                                    <div
                                                        className="w-100 position-relative "
                                                        style={{ marginTop: "20px" }}
                                                    >
                                                        <div
                                                            className="position-absolute"
                                                            style={{
                                                                transform: "translate(555%, -100%)",
                                                            }}
                                                        >

                                                        </div>
                                                        <div className="px-2  no-blur d-flex align-items-center justify-content-between ">
                                                            <div className="no-blur d-flex flex-column   justify-content-start">
                                                                <div
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    {value?.user?.name}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        fontSize: "11px",
                                                                        fontWeight: "400",
                                                                    }}
                                                                >
                                                                    {value?.subject?.name}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="mt-1 px-1">
                                                            <div className="d-flex flex-row align-items-center justify-content-between px-3 py-1 mb-1">
                                                                <div className="teacher-details">
                                                                    Online classes -0
                                                                </div>
                                                                <div className="teacher-details">
                                                                    Home works -0
                                                                </div>
                                                            </div>

                                                            <div className="d-flex flex-row align-items-center justify-content-between  px-3 py-1 ">
                                                                <div className="teacher-details ">
                                                                    Tutorial video -0
                                                                </div>
                                                                <div className="teacher-details">
                                                                    Report card -0
                                                                </div>
                                                            </div>

                                                            <div className="d-flex flex-row align-items-center  justify-content-around p-2 ">
                                                                <div
                                                                    className="px-2 message-details"
                                                                    style={{
                                                                        backgroundColor: "#FBD772",
                                                                        borderRadius: "7px",
                                                                    }}
                                                                >
                                                                    Message
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }


                                </div>

                            )



                            }

                            {/* Teacher2 */}

                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTeacher);
