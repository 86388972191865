import Axios from "axios"
import { GET_TEACHER_HOME_DATA_URL } from "../../../api/index";
import { GET_TEACHER_HOME_DATA } from "./actionType";

export const getTeacherHomeData = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_HOME_DATA_URL, { token })
        .then((response) => {
            let home = response.data;
            return dispatch({ type: GET_TEACHER_HOME_DATA, payload: home })
        })
}
