import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../elements/BackButton";
import Footer from "../Footer";
// import Items from "./Items";
import ItemCategories from "./ItemCategories";
// import StoreBanner from "./StoreBanner";
// import StoreSearchBar from "./StoreSearchBar";
import { getStoreData } from "../../../redux/Parent/store/action";
// import { Link } from 'react-router-dom';
class Store extends Component {
    state = {
        searchable_items: [],
        banners: [],
    }
    componentDidMount() {
        const { user, getStoreData, store_data } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.getStoreData(user.data.auth_token, student_id.value);
        if (store_data.success) {
            console.log(store_data.items, 'store_data.items');
            localStorage.setItem("vendor_id", store_data?.vendor?.id);
            this.setState({ searchable_items: store_data.items })
            this.setState({ banners: store_data.banners })
        }
    }
    render() {
        const { user, store_data } = this.props;
        return (
            <React.Fragment>
                <div className="px-2 py-2">
                    <BackButton title={"Store"} />
                </div>
                <ItemCategories store_data={store_data} searchable_items={this.state.searchable_items} banners={this.state.banners} />
                <Footer current={"store"} />
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    store_data: state.store_data.store_data,
    user: state.user.parent,
});

export default connect(mapStateToProps, { getStoreData })(Store);
