import React, { Component } from 'react'
import { connect } from 'react-redux';
import ReactPlayer from "react-player";
import Truncate from 'react-truncate';

export class HomeViewTutorial extends Component {
    render() {
        const { tutorials } = this.props;
        return (
            <React.Fragment>
                {tutorials?.length > 0 &&
                    <React.Fragment>
                        <div className='px-4 py-3' style={{ fontSize: '18px', fontWeight: '500' }}>Last viewed tutorial</div>
                        <div className='px-4  d-flex flex-column justify-content-between align-items-center'>
                            {tutorials.map((value, index) => (
                                <div className='py-2 d-flex  justify-content-start align-items-center'>
                                    <div className='px-1' style={{ width: '40%', height: '40%' }}>
                                        <ReactPlayer
                                            width="100%"
                                            height="60px"
                                            url={value?.url}
                                            // url={"https://youtu.be/qog4M6nyRpE"}
                                        />
                                    </div>
                                    <div className='d-flex flex-column ps-2'>
                                        <div style={{ fontSize: "14px", fontWeight: '400' }}>
                                            Class  {value?.class?.name} {value?.subject?.name}
                                        </div>
                                        <div style={{ fontSize: "14px", fontWeight: '400' }}>
                                            Section: {value?.title}
                                        </div>
                                        <div className='text-muted' style={{ fontSize: "12px", fontWeight: '400' }}>
                                            <Truncate lines={1}>
                                                <span className=''>
                                                    {value?.description}
                                                </span>
                                            </Truncate>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(HomeViewTutorial)
