import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactPlayer from "react-player";
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";
import { getTeacherTutorials } from "../../../redux/Teacher/student/action";
import youtube from "../../../assets/Images/YouTube.png";
import image from '../../../assets/Images/tutorials.png'
import { Link } from 'react-router-dom';
<ReactPlayer url="https://youtu.be/XpQ_je8PPWU" />;

export class TeacherTutorials extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        const { teacher } = this.props;
        this.setState({ loading: true });
        this.props.getTeacherTutorials(teacher?.data?.auth_token)
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500)
    }
    render() {
        const { teacher_tutorials } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 539"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="15" y="26" rx="6" ry="9" width="24" height="24" />
                            <rect x="110" y="29" rx="6" ry="9" width="60" height="18" />
                            <rect x="20" y="80" rx="10" ry="10" width="235" height="85" />
                            <rect x="20" y="173" rx="10" ry="10" width="235" height="85" />
                            <rect x="20" y="266" rx="10" ry="10" width="235" height="85" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="">
                            <BackButton title={"Tutorials"} />
                            {/* Tutorials */}
                            {teacher_tutorials?.success ? (
                                <>
                                    <div className="px-3 mt-3 pt-1" style={{ paddingBottom: '15vw' }}>
                                        {teacher_tutorials?.data?.length > 0 ?
                                            <div>
                                                {teacher_tutorials?.data?.map((data, i) => (
                                                    <div className="tutorial-library bg-white px-2 w-100vw">
                                                        <div className=" tutorial-card p-2 mt-2 position-relative">
                                                            <div className="d-flex">
                                                                <div className="">
                                                                    <ReactPlayer
                                                                        width="80px"
                                                                        height="80px"
                                                                        url={data.url}
                                                                    />
                                                                </div>
                                                                <div className="ms-2 d-flex flex-column">
                                                                    <h2 className="tutorial-heading">
                                                                        {data.title}
                                                                    </h2>
                                                                    <span className="tutorial-details">
                                                                        Subject :
                                                                        {data?.subject?.name}
                                                                    </span>
                                                                    <span className="tutorial-details">
                                                                        Class
                                                                        {data?.class?.name}
                                                                        | Section
                                                                        {data?.section?.name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center position-absolute bottom-0 end-0 m-2">
                                                                <span className="tutorial-details me-2">
                                                                    YouTube
                                                                </span>
                                                                <img
                                                                    src={youtube}
                                                                    alt="youtube"
                                                                    width="15"
                                                                    height="15"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <Link to={"/create-tutorial"}>
                                                    <div className='position-fixed bottom-0 py-2 w-100 px-3' style={{ left: '0%' }}>
                                                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' type="submit">
                                                            Add Tutorial
                                                        </button>
                                                    </div>
                                                </Link>
                                            </div>
                                            : <div className="d-flex flex-column align-items-center pt-5 mt-5 ">
                                                <img src={image} alt="no-data" style={{ width: '85%', objectFit: 'cover', }} />
                                                <span style={{ fontSize: '11px' }}>Sorry..No Tutorials Found...</span>
                                            </div>
                                        }
                                    </div>
                                    <Link to="/create-tutorial">
                                        <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                                            <button className='btn attendence-dwld-btn mt-2 py-3 w-100' type="submit" >
                                                Add Tutorial
                                            </button>
                                        </div>
                                    </Link>
                                </>
                            ) : ("")}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    teacher_tutorials: state.student.teacher_tutorials,
});
export default connect(mapStateToProps, { getTeacherTutorials })(TeacherTutorials)
