import React, { Component } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import BackButton from "../../../elements/BackButton";
import Select from "react-select";
import { TbFilePlus } from "react-icons/tb";

import moment from "moment";
import Ink from "react-ink";

import animationData from "../../../assets/lottie/lf30_editor_bspltctv.json";

import {
    getTeacherSchoolData,
    addActivity,
    getActivity,
} from "../../../redux/Teacher/user/action";
import { CgCheckO } from "react-icons/cg";

export class TeacherActivity extends Component {
    state = {
        loading: false,
        sections: [],
        subjects: [],
        students: [],
        activity: {

        },
        open: false,
    };
    componentDidMount() {
        const { teacher } = this.props;
        this.props
            .getTeacherSchoolData(teacher.data?.auth_token)
            .then((response) => {
                this.setState({ EditUser: response.payload.data });
            });
        this.props.getActivity(teacher.data.auth_token);
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
        console.log(this.state.file);
    }

    uploadactivity = (e) => {
        e.preventDefault();
        const { teacher } = this.props;
        const { activity } = this.state;
        let formData = new FormData();
        formData.append("class_id", activity.class_id);
        formData.append("section_id", activity.section_id);
        formData.append("subject_id", activity.subject_id);
        formData.append("date", activity.date);
        formData.append("discription", activity.discription);
        formData.append("task", activity.task);
        formData.append("token", teacher.data.auth_token);
        this.props.addActivity(formData).then((response) => {

            if (response && response.data && response.data.success) {
                this.setState({ open: true, activity: null });
                e.target.reset();
                this.props.getTeacherSchoolData(teacher.data?.auth_token);
            }
        });
    };



    getSubjects = (val) => {
        const { schooldata } = this.props;

        const section_subjects = schooldata?.data?.student_classes
            ?.find((data) => data.id == val)
            ?.subjects?.map((subject) => ({
                value: subject.id,
                label: subject?.name,
            }));

        this.setState({ subjects: section_subjects });

        const sections = schooldata?.data?.student_classes
            ?.find((data) => data.id == val)
            ?.sections?.map((section) => ({
                value: section.id,
                label: section.name,
            }));
        this.setState({ sections: sections });
    };

    modalOpen = () => {
        this.setState({ open: true });
    };
    modalClose = (e) => {
        e.preventDefault();
        this.props.history.goBack();
        // this.setState({ open: false });
    };

    handleInput(e, name) {
        let data = this.state.activity;
        data[name] = e;
        this.setState({ activity: data });
    }

    GoBack = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    };

    today = () => {
        let date = moment().format("YYYY-MM-DD");
        console.log(date);
        return date;
    };

    render() {
        const { title, date, file } = this.state;
        console.log(this.state.subjects);
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };

        const { schooldata ,activities} = this.props;
        const { sections, subjects, students } = this.state;
        const classes = schooldata?.data?.student_classes?.map((data) => ({
            value: data.id,
            label: data.name,
        }));
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 550"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="13"
                                y="66"
                                rx="10"
                                ry="10"
                                width="255"
                                height="50"
                            />
                            <rect
                                x="13"
                                y="218"
                                rx="10"
                                ry="10"
                                width="255"
                                height="50"
                            />
                            <rect
                                x="13"
                                y="300"
                                rx="10"
                                ry="10"
                                width="255"
                                height="75"
                            />
                            <rect
                                x="180"
                                y="395"
                                rx="10"
                                ry="10"
                                width="90"
                                height="30"
                            />
                            <rect
                                x="13"
                                y="480"
                                rx="10"
                                ry="10"
                                width="255"
                                height="50"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"Activity"} />
                        <Form onSubmit={this.uploadactivity}>
                            <div className="position-relative mt-4 px-3">
                                <div className="teacher-homework-input mt-3 ">
                                    <Select
                                        className="text-muted "
                                        styles={customStyles}
                                        placeholder="Class"
                                        options={classes}
                                        onChange={(e) => {
                                            let value = e.value;
                                            this.handleInput(value, "class_id");
                                            this.getSubjects(value);
                                        }}
                                    />
                                </div>
                                <div className="teacher-homework-input mt-3 ">
                                    <Select
                                        className="text-muted "
                                        styles={customStyles}
                                        placeholder="Section"
                                        options={sections}
                                        onChange={(e) => {
                                            let value = e.value;
                                            this.handleInput(
                                                value,
                                                "section_id"
                                            );
                                        }}
                                    />
                                </div>
                                <div className="teacher-homework-input mt-3 ">
                                    <Select
                                        className="text-muted "
                                        styles={customStyles}
                                        placeholder="Subject"
                                        options={subjects}
                                        onChange={(e) => {
                                            let value = e.value;
                                            this.handleInput(
                                                value,
                                                "subject_id"
                                            );
                                        }}
                                    />
                                </div>
                                <div className="mt-3">
                                <label className="text-muted px-3" >Date</label>
                                <input
                                    type="date"
                                    className="input-homework mt-3 w-100 "
                                    placeholder="Date"
                                    required
                                    onChange={(e) => {
                                        let date = e.target.value;
                                        console.log(date);
                                        this.handleInput(date, "date");
                                    }}
                                />
                                </div>

                                <div className="mt-3">
                                    <InputGroup
                                        hasValidation
                                        className="mt-2 attendence-input"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <FormControl
                                            type="text"
                                            placeholder="description"
                                            className="attendence-input"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(
                                                    value,
                                                    "discription"
                                                );
                                            }}
                                            required
                                        />
                                    </InputGroup>
                                </div>

                                <div className="mt-3">
                                    <InputGroup
                                        hasValidation
                                        className="mt-2 attendence-input"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <FormControl
                                            type="text"
                                            placeholder="Add Task"
                                            className="attendence-input"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(value, "task");
                                            }}
                                            required
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="d-grid gap-2 px-3 py-3 w-100">
                                <button
                                    type="submit"
                                    className="appointment-btn py-3"
                                >
                                    <span>Submit</span>
                                </button>
                            </div>
                        </Form>
                        <React.Fragment>
                            <div className="px-3 py-3">
                            {activities?.data?.map((activity, index) => (
                                <div className="activity-card py-2 px-2 b-r-10 -4">
                                    <div
                                        className="activity-content p-2 rounded"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#B6BEF2",
                                        }}
                                    >
                                        <span>
                                            Subject - {activity?.subject?.name}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row h-100 justify-content-between align-content-between mt-2">
                                        <span
                                            className="activity-content ps-1"
                                            style={{
                                                color: "#696A6F",
                                            }}
                                        >
                                            {activity?.discription}
                                        </span>
                                        <span
                                            className="activity-date"
                                            style={{
                                                color: "#8E96CE",
                                            }}
                                        >
                                            Date:{moment(activity?.date).format('DD-MM-YYYY')}
                                        </span>
                                    </div>
                                    <div
                                        className="activity-content ps-1  "
                                        style={{ color: "#696A6F" }}
                                    >
                                        {activity?.task}
                                    </div>
                                </div>

                            ))}
                             </div>
                        </React.Fragment>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    schooldata: state.teacher.schooldata,
    activities: state.teacher.activity
});
export default connect(mapStateToProps, {
    getTeacherSchoolData,
    addActivity,
    getActivity,
})(TeacherActivity);

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: "none",
        border: "none", // tried border: 'none'
        boxShadow: "none", // tried border: 'none'
        outline: "none",
    }),
    control: (provided) => ({
        ...provided,
        height: "45px",
        border: "none",
        boarderRadius: "15px",
        fontSize: "13px",
        borderStyle: "none",
        backgroundColor: "#F3F5F7",
        outline: "none",
    }),
};

const modelCustomStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #bf4672",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
