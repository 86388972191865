import React, { Component } from "react";
import { BiSearch } from "react-icons/bi";
import { BsHandbag } from "react-icons/bs";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export class StoreSearchBar extends Component {
    render() {
        const { cartProducts } = this.props;
        return (
            <React.Fragment>
                <div className="d-flex flex-row justify-content-between px-3 mt-4 align-items-center">
                    <div
                        className="flex-grow-1 d-flex flex-row justify-content-between "
                        style={{
                            backgroundColor: "#F3F3F4",
                            borderRadius: "10px",
                        }}
                    >
                        <div className=" ">
                            <input
                                type="text"
                                className="store-search-bar w-100"
                                placeholder="Search"
                            />
                        </div>
                        <div className="p-2">
                            <BiSearch
                                set="curved"
                                style={{
                                    fontSize: "2em",
                                    color: "black",
                                }}
                            />
                        </div>
                    </div>
                    <Link to={"/my-cart"}>
                        <div className="position-relative">
                            <div className="ms-2 store-cart-box px-2 p-2 d-flex align-items-center justify-content-center">
                                <BsHandbag size={20} color={'#000'}/>
                            </div>
                            {cartProducts?.length > 0 && (
                                <div className="position-absolute cart-quantity">
                                    {cartProducts?.length}
                                </div>
                            )}
                        </div>
                    </Link>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
});

export default connect(mapStateToProps)(StoreSearchBar);
