import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../../elements/BackButton'
import { getTeacherMarkData } from './../../../../redux/Teacher/mark/action';
import { WEBSITE_URL } from './../../../../api/index';

export class DownloadMarks extends Component {
    componentDidMount() {
        const { teacher } = this.props;
        let class_id = JSON.parse(localStorage.getItem("class_id"));
        let section_id = JSON.parse(localStorage.getItem("section_id"));
        let term_id = JSON.parse(localStorage.getItem("term_id"));
        // console.log( class_id, section_id, term_id)
        this.props.getTeacherMarkData(teacher.data.auth_token, class_id.id, section_id.id, term_id.id);
    }

    downloadTeacherMark = (e) => {
        e.preventDefault();
        const { teacher } = this.props;
        let class_id = JSON.parse(localStorage.getItem("class_id"));
        let section_id = JSON.parse(localStorage.getItem("section_id"));
        let term_id = JSON.parse(localStorage.getItem("term_id"));
        // console.log(class_id,section_id,term_id);
        let cls = class_id.id;
        let section = section_id.id;
        let term = term_id.id;
        let school = teacher?.data?.staff?.school_id;
        window.location = WEBSITE_URL + "/download-teacher-mark?token=" + teacher.data.auth_token + "&school=" + school + "&cls=" + cls + "&section=" + section + "&term=" + term
    };

    render() {
        const { teacherMarks } = this.props;

        return (
            <React.Fragment>
                <BackButton title={"Mark"} />
                    {teacherMarks?.students?.length > 0 && teacherMarks?.students?.map((data, index) => (

                        <div className='mt-3'>
                            <div className="name-term d-flex justify-content-between px-4">
                                <div className='flex-grow-1 w-75'>Name:{data?.user?.name}</div>
                                <div className='flex-shrink-1'>Roll no:{data.roll_number}</div>
                            </div>
                            <div className="px-3 mt-2">
                                <div className="panel-board px-2 py-3 ">
                                    <table className="w-100">
                                        <tr className="panel-head  px-3">
                                            <th className="panel-head-1 ps-3">
                                                Subject
                                            </th>
                                            <th className="panel-head-2 py-2">
                                                Grade
                                            </th>
                                            <th className="panel-head-2 py-2">
                                                Mark
                                            </th>
                                            <th className="panel-head-3 py-2 text-center">
                                                Out of
                                            </th>
                                        </tr>
                                        {data.marks?.length > 0 && data.marks?.map((val, index) => (
                                            <tr className="panel-content">
                                                <td className="ps-3 py-2">{val.subject?.name}</td>
                                                <td className="py-2 px-3">{val?.grade}</td>
                                                <td className="py-2 px-3">{val?.mark}</td>
                                                <td className="py-2 text-center">
                                                    {val?.total_mark}
                                                </td>
                                            </tr>
                                        ))}
                                        {/* <tr className="panel-top">
                                    <td className="ps-3 py-1">Total</td>
                                    <td className="py-1">600/800</td>
                                    <td className="py-1 text-center">
                                        Grade: B+
                                    </td>
                                </tr> */}
                                    </table>
                                </div>
                            </div>
                        </div>

                    ))}
                <div style={{height: '100px'}}></div>
                <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                    <button className='btn attendence-dwld-btn mt-2 py-3 w-100' onClick={(e) => this.downloadTeacherMark(e)}>
                        Download
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    teacherMarks: state.teacherMark.teacherMark,
})

export default connect(mapStateToProps, { getTeacherMarkData })(DownloadMarks)
