import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { DatePicker, TimePicker } from "antd";
import BackButton from "../../../elements/BackButton";
import moment from "moment";
import { getStudentAppointmentDetails,submitAppointment } from "../../../redux/Parent/appointment/action";

export class MakeAppointment extends Component {
    state = {
        selectedSubject:"",
        selectedTeacher:"",
        teacherId:0,
        subjectId:0,
        reason:'',
        date: "",
        time: "",
    };
    componentDidMount() {
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.getStudentAppointmentDetails(
            user.data.auth_token,
            student_id.value
        );
    }
    handleSubject = (selectedSubject) => {
        this.setState({ selectedSubject ,subjectId: selectedSubject.value});
      }
    handleTeacher = (selectedTeacher) => {
        this.setState({ selectedTeacher ,teacherId: selectedTeacher.value});
      }
    handleInput = (e) => {
        this.setState({ reason : e.target.value});
      }
    onChangeDate = (date,dateString) =>{

        this.setState({date: dateString});

    }
    onChangeTime = (time, timeString) =>{

        this.setState({time: timeString});

    }

    onSubmit = (e) =>{
        e.preventDefault();
        const {user} = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));

        let formdata = new FormData();
        formdata.append("student_id", student_id.value);
        formdata.append("subject_id", this.state.subjectId);
        formdata.append("teacher_id", this.state.teacherId);
        formdata.append("reason", this.state.reason);
        formdata.append("date", this.state.date);
        formdata.append("time", this.state.time);
        formdata.append('token',user.data.auth_token);

        console.log(formdata)
        this.props.submitAppointment(formdata).then((response) =>{

            if(response.data.success){
                window.location.replace('/appointments')
            }
        })
    }

    render() {

        let student_id = JSON.parse(localStorage.getItem("student_id"));
        const { makeAppointment } = this.props;

        const subjects = makeAppointment?.subjects?.map((sub)=>({
            value: sub.id, label: sub.name
        }))
        const teachers = makeAppointment?.staffs?.map((sub)=>({
            value: sub.id, label: sub.user?.name
        }))

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: "#fff",
                border: "none", // tried border: 'none'
                boxShadow: "none", // tried border: 'none'
                outline: "none",
            }),
            control: (provided) => ({
                ...provided,
                height: "45px",
                border: "none",
                boarderRadius: "10px",
                fontSize: "13px",
                borderStyle: "none",
                backgroundColor: "rgba(190, 175, 179, 0.05)",
                outline: "none",
                boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.02) inset",
                shadowOpacity: 0.5,
                shadowRadius: 50,
            }),
        };

        const disabledDate = (current) => {
            // Can not select days before today
            return current && current <= moment().startOf("day");
        };


        return (
            <React.Fragment>
                <div className="mt-2">
                    <BackButton title={"Make Appointment"} color="#C24A6B" />
                </div>
                <div className="position-relative mt-5 pb-5">
                    <Form onSubmit={this.onSubmit}>
                    <div className="position-relative px-2">
                        <div className=" flex-column p-1">
                            <div className="d-flex justify-content-between mt-3 homework-input">
                                <div className="text-muted"> Student </div>
                                <div style={{ fontWeight: "600" }}>
                                    {student_id?.label}
                                </div>
                            </div>
                        </div>
                        <div className=" flex-column p-1">
                        <div className="d-flex justify-content-between mt-3 homework-input">
                                <div className="text-muted"> Class </div>
                                <div style={{ fontWeight: "600" }}>
                                   {makeAppointment?.students?.student_class?.name}

                                </div>
                            </div>
                        </div>
                        <div className=" flex-column p-1">
                            <div className="  pt-2 ">
                                <Select
                                    className="text-muted"
                                    styles={customStyles}
                                    placeholder="Subject"
                                    options={subjects}
                                    value={this.state.selectedSubject}
                                    onChange={this.handleSubject}
                                />
                            </div>
                        </div>

                        <div className=" flex-column p-1">
                            <div className="  pt-2 ">
                                <Select
                                    className="text-muted"
                                    styles={customStyles}
                                    placeholder="Teacher"
                                    options={teachers}
                                    value={this.state.selectedTeacher}
                                    onChange={this.handleTeacher}
                                />
                            </div>
                        </div>

                        <div className=" flex-column p-1">
                            <div className="  pt-2 ">
                                <Form.Group className=" ">
                                    <textarea
                                        className="appointment-textarea pt-2 px-2"
                                        rows={3}
                                        placeholder="Reason"
                                        required
                                        onChange={(e)=>{
                                            this.handleInput(e)
                                        }}
                                    ></textarea>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="pt-2">
                            <DatePicker
                                className="appointment-date"
                                placeholder="Appointment Date"
                                disabledDate={disabledDate}
                                onChange={this.onChangeDate}
                                required
                            />
                        </div>
                        <div className="pt-2">
                            <TimePicker
                                use12Hours
                                format="h:mm A"
                                required
                                className="appointment-time"
                                placeholder="Appointment Time"
                                onChange={this.onChangeTime}
                            ></TimePicker>
                        </div>
                    </div>
                    <div
                        className="d-flex fixed-bottom mb-3"
                        style={{ margin: "0 18px" }}
                    >
                        <button type="submit" className="appointment-btn">
                            Make Appointment
                        </button>
                    </div>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    makeAppointment : state.appointment.makeAppointment
});

export default connect(mapStateToProps, { getStudentAppointmentDetails,submitAppointment })(
    MakeAppointment
);
