import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton'
import image from '../../../assets/Images/tutorials.png'
import ReactPlayer from 'react-player'
import youtube from "../../../assets/Images/YouTube.png";
import { getOccasionalVideo } from '../../../redux/Parent/class/action'
import { FaPlayCircle } from 'react-icons/fa'
import Truncate from 'react-truncate'
import { MdDone, MdOutlineContentCopy } from 'react-icons/md'
import CopyToClipboard from 'react-copy-to-clipboard'
import { IMAGE_BASE_URL } from '../../../api'


export class OccasionalVideos extends Component {
    state = {
        loading: false,
        occasional_video: []
    }
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getOccasionalVideo(user.data.auth_token, student_id?.value)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false, occasional_video: response.payload.occasional_video });
                }
            })
    }

    copyLink = (data) => {
        this.setState({ copied: data.id })
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    }
    render() {
        const { occasional_video } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>

                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"Occasional Videos"} />
                        {occasional_video?.length > 0 ?
                            <div className='pt-3'>
                                {occasional_video?.map((data, i) => {
                                    if (i < 3) {
                                        return (
                                            <div className="px-3">
                                                <div className="mt-2 position-relative">
                                                    <div className="w-100 p-2">
                                                        <img src={IMAGE_BASE_URL + data?.image} style={{ width: '100%', height: '40vw', borderRadius: '12px', objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center',boxShadow:'0px 0px 20px 5px #e5e5e5' }} />
                                                    </div>
                                                    <div className="position-absolute fw-bolder px-2 back-occasional" style={{ bottom: '10px', left: '10px', color: '#000' }}>
                                                        {data.title}
                                                    </div>
                                                    <div>
                                                    {console.log(data?.url)}
                                                        <a href={data?.url} target="_blank" className="btn position-absolute" style={{border:'none',top:'50%',left:'50%', transform: 'translate(-50%, -50%)' }}>
                                                            <div className='text-center'>
                                                                <FaPlayCircle size={40} color={"#fff"} />
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="d-flex align-items-center position-absolute bottom-0 end-0 m-2 py-1 px-2">
                                                        <div className="d-flex justify-content-between back-occasional p-1" >
                                                            {data?.id == this.state.copied ?
                                                                <div className=''>
                                                                    <MdDone size={'1.2em'} style={{ color: '#000' }} />
                                                                    <span style={{ color: '#000' }}>Copied</span>
                                                                </div>
                                                                :
                                                                <CopyToClipboard text={data?.link}
                                                                    onCopy={() => this.copyLink(data)}>
                                                                    <div className="" style={{ fontWeight: '680' }} >
                                                                        <MdOutlineContentCopy size={"1.2em"} style={{ color: '#000' }} />
                                                                    </div>
                                                                </CopyToClipboard>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            : <div className="d-flex flex-column align-items-center pb-3" style={{ paddingTop: '20vw' }}>
                                <img src={image} style={{ width: '80%', objectFit: 'cover', }} />
                                <div className='w-100 mt-3 text-center fw-bolder' style={{ color: "#C47B8B" }}>Occasional Videos Found</div>
                            </div>
                        }
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
})
export default connect(mapStateToProps, {
    getOccasionalVideo
})(OccasionalVideos)
