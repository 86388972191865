import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton'
import { Link } from 'react-router-dom'

export class AddAttendence extends Component {
    render() {
        return (
            <React.Fragment>
                <BackButton title="Attendence" />
                <div className='px-2 mt-5'>
                    <Link to={'/select-class/Add'}>
                        <div className='mt-1 py-2 w-100 px-3'>
                            <button className='btn attendence-dwld-btn mt-1 py-3 w-100' style={{ fontWeight: '400' }}>
                                Add Attendence
                            </button>
                        </div>
                    </Link>
                    <Link to={'/mark-holiday'}>
                        <div className='py-2 w-100 px-3'>
                            <button className='btn attendence-dwld-btn mt-1 py-3 w-100' style={{ fontWeight: '400' }}>
                                Add Holiday
                            </button>
                        </div>
                    </Link>
                    <Link to={'/select-class/Edit'}>
                        <div className='py-2 w-100 px-3'>
                            <button className='btn attendence-dwld-btn mt-1 py-3 w-100' style={{ backgroundColor: '#DB3665', fontWeight: '400' }}>
                                Edit Attendence
                            </button>
                        </div>
                    </Link>
                </div>
            </React.Fragment>)
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(AddAttendence)
