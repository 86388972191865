import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GrNext } from "react-icons/gr";
import Footer from "../Footer";
import { getTeacherTimeTable } from '../../../redux/Teacher/student/action'
import ContentLoader from "react-content-loader";
export class TeacherTimetable extends Component {
    state = {
        loading: false,
        day: '',
        show: false,
    }
    componentDidMount() {
        this.setState({ loading: true });
        const { teacher } = this.props
        this.props.getTeacherTimeTable(teacher.data.auth_token)
            .then((response) => {
                // console.log(response);
                if (response?.payload?.success) {
                    this.setState({ loading: false });
                }
            })
    }
    showData = (val) => {
        this.setState({ day: val, show: !this.state.show });
    }
    render() {
        const { teacher_timetable } = this.props
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 236 470"
                            backgroundColor="#F3F3F3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="75" y="29" rx="6" ry="9" width="90" height="18" />
                            <rect x="13" y="65" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="111" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="158" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="206" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="252" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="298" rx="10" ry="10" width="210" height="40" />
                        </ContentLoader>
                    </React.Fragment>) : (
                    <React.Fragment>
                        {teacher_timetable?.success ?
                            <>
                                <div className='mt-3'>
                                    <div className='d-flex flex-row align-items-center justify-content-center position-relative ms-2 pt-4 '>
                                        <div className='subject-head d-flex justify-content-center'>
                                            <span className='' style={{ color: "#C24A6B" }}>My TimeTable</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 px-3" style={{ paddingBottom: '35vw' }}>
                                    {/* monday */}
                                    <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                        <div className=''><span>Monday</span></div>
                                        <div className='' onClick={() => this.showData(1)}><GrNext style={this.state.day == 1 && this.state.show ? { transform: 'rotate(90deg)' } : null} /></div>
                                    </div>
                                    <div className='mt-2' style={this.state.day == 1 && this.state.show ? { display: 'block' } : { display: 'none' }}>
                                        {teacher_timetable?.data?.length > 0 && teacher_timetable?.data?.filter((data) => data.day == 'monday').map((data) => (
                                            <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                                <div style={{ color: '#696A6F', fontSize: '12px' }}>
                                                    <div><span>Class {data?.section?.student_class?.name} {data?.section?.name}</span></div>
                                                    <div><span>Sub : {data?.subject?.name}</span></div>
                                                </div>
                                                <div style={{ color: '#439CB8', fontSize: '12px' }}>{data?.period?.duration} mins ({data?.period?.name})</div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* tuesday */}
                                    <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                        <div className=''><span>Tuesday</span></div>
                                        <div className='' onClick={() => this.showData(2)}><GrNext style={this.state.day == 2 && this.state.show ? { transform: 'rotate(90deg)' } : null} /></div>
                                    </div>
                                    <div className='mt-2' style={this.state.day == 2 && this.state.show ? { display: 'block' } : { display: 'none' }}>
                                        {teacher_timetable?.data?.length > 0 && teacher_timetable?.data?.filter((data) => data.day == 'tuesday').map((data) => (
                                            <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                                <div style={{ color: '#696A6F', fontSize: '12px' }}>
                                                    <div><span>Class {data?.section?.student_class?.name} {data?.section?.name}</span></div>
                                                    <div><span>Sub : {data?.subject?.name}</span></div>
                                                </div>
                                                <div style={{ color: '#439CB8', fontSize: '12px' }}>{data?.period?.duration} mins ({data?.period?.name})</div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* wednesday */}
                                    <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                        <div className=''><span>Wednesday</span></div>
                                        <div className='' onClick={() => this.showData(3)}><GrNext style={this.state.day == 3 && this.state.show ? { transform: 'rotate(90deg)' } : null} /></div>
                                    </div>
                                    <div className='mt-2' style={this.state.day == 3 && this.state.show ? { display: 'block' } : { display: 'none' }}>
                                        {teacher_timetable?.data?.length > 0 && teacher_timetable?.data?.filter((data) => data.day == 'wenesday').map((data) => (
                                            <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                                <div style={{ color: '#696A6F', fontSize: '12px' }}>
                                                    <div><span>Class {data?.section?.student_class?.name} {data?.section?.name}</span></div>
                                                    <div><span>Sub : {data?.subject?.name}</span></div>
                                                </div>
                                                <div style={{ color: '#439CB8', fontSize: '12px' }}>{data?.period?.duration} mins ({data?.period?.name})</div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* thursday */}
                                    <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                        <div className=''><span>Thursday</span></div>
                                        <div className='' onClick={() => this.showData(4)}><GrNext style={this.state.day == 4 && this.state.show ? { transform: 'rotate(90deg)' } : null} /></div>
                                    </div>
                                    <div className='mt-2' style={this.state.day == 4 && this.state.show ? { display: 'block' } : { display: 'none' }}>
                                        {teacher_timetable?.data?.length > 0 && teacher_timetable?.data?.filter((data) => data.day == 'thursday').map((data) => (
                                            <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                                <div style={{ color: '#696A6F', fontSize: '12px' }}>
                                                    <div><span>Class {data?.section?.student_class?.name} {data?.section?.name}</span></div>
                                                    <div><span>Sub : {data?.subject?.name}</span></div>
                                                </div>
                                                <div style={{ color: '#439CB8', fontSize: '12px' }}>{data?.period?.duration} mins ({data?.period?.name})</div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* friday */}
                                    <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                        <div className=''><span>Friday</span></div>
                                        <div className='' onClick={() => this.showData(5)}><GrNext style={this.state.day == 5 && this.state.show ? { transform: 'rotate(90deg)' } : null} /></div>
                                    </div>
                                    <div className='mt-2' style={this.state.day == 5 && this.state.show ? { display: 'block' } : { display: 'none' }}>
                                        {teacher_timetable?.data?.length > 0 && teacher_timetable?.data?.filter((data) => data.day == 'friday').map((data) => (
                                            <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                                <div style={{ color: '#696A6F', fontSize: '12px' }}>
                                                    <div><span>Class {data?.section?.student_class?.name} {data?.section?.name}</span></div>
                                                    <div><span>Sub : {data?.subject?.name}</span></div>
                                                </div>
                                                <div style={{ color: '#439CB8', fontSize: '12px' }}>{data?.period?.duration} mins ({data?.period?.name})</div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* saturday */}
                                    <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                        <div className=''><span>Saturday</span></div>
                                        <div className='' onClick={() => this.showData(6)}><GrNext style={this.state.day == 1 && this.state.show ? { transform: 'rotate(90deg)' } : null} /></div>
                                    </div>
                                    <div className='mt-2' style={this.state.day == 6 && this.state.show ? { display: 'block' } : { display: 'none' }}>
                                        {teacher_timetable?.data?.length > 0 && teacher_timetable?.data?.filter((data) => data.day == 'saturday').map((data) => (
                                            <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                                <div style={{ color: '#696A6F', fontSize: '12px' }}>
                                                    <div><span>Class {data?.section?.student_class?.name} {data?.section?.name}</span></div>
                                                    <div><span>Sub : {data?.subject?.name}</span></div>
                                                </div>
                                                <div style={{ color: '#439CB8', fontSize: '12px' }}>{data?.period?.duration} mins ({data?.period?.name})</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                            : null}
                        <div>
                            <Footer current={"timetable"} />
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    teacher_timetable: state.student.teacher_timetable,
})

export default connect(mapStateToProps, { getTeacherTimeTable })(TeacherTimetable)
