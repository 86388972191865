import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from "../../../elements/BackButton";
import ButtonExam from './ButtonExam';
import Schedule from './Schedule';
import { getExamSchedule } from '../../../redux/Parent/class/action';
import img from "../../../assets/Images/online-test-nodata.svg";

export class ExamSchedule extends Component {
    state = {
        exam_schedule:[],
    }
    componentDidMount(){
       const {user} = this.props
       const {value:student_id} = JSON.parse(localStorage.getItem('student_id'))
       this.props.getExamSchedule(user.data.auth_token,student_id)
       .then((response)=>{
         if(response.payload){
            this.setState({exam_schedule:response.payload})
         }
       })
    }

    render() {
        return (
            <React.Fragment>
                    <BackButton title={"Exam Schedule"} />
                {/* <ButtonExam/> */}
                {this.state.exam_schedule && this.state.exam_schedule.length>0 ?
                <Schedule schedules = {this.state.exam_schedule}/>
                :
                <div className='d-flex flex-column align-items-center justify-content-center' style={{
                    height:"70vh"
                }}>
                    <img className='ps-2' src={img} style={{
                        width:'70%',
                    }}/>
                   <span className='w-75 text-center fw-bolder' style={{color:"#C47B8B"}}>Currently there is no exam scheduled for this class</span>
                </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user:state.user.parent
})


export default connect(mapStateToProps,{getExamSchedule})(ExamSchedule)
