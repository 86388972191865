import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton'
import Select from 'react-select'

export class AddMark extends Component {
    render() {
        return (
            <React.Fragment>
                <BackButton title={"Add Mark"} />
                <div className='px-3 mt-4'>
                    <div className="mt-3">
                        <Select className="text-muted attendence-input px-2" placeholder='Term' styles={customStyles} options={options} />
                    </div>
                    <div className="mt-3">
                        <Select className="text-muted attendence-input px-2" placeholder='Class' styles={customStyles} options={options} />
                    </div>

                    <div className="mt-3">
                        <Select className="text-muted attendence-input px-2" placeholder='Section' styles={customStyles} options={options} />
                    </div>

                    <div className="mt-3">
                        <Select className="text-muted attendence-input px-2" placeholder='Subject' styles={customStyles} options={options} />
                    </div>
                    <div className="mt-3">
                        <input className="text-muted attendence-input px-3" type='number' min='0' style={{ fontSize: '13px' }} placeholder='Mark' required />
                    </div>
                    <div className="mt-3">
                        <input className="text-muted attendence-input px-3" type='number' min='0' style={{ fontSize: '13px' }} placeholder='Out Of' required />
                    </div>
                </div>
                <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                    <button className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                        Submit
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(AddMark)
const options = [
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
]

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        border: 'none', // tried border: 'none'
        boxShadow: 'none', // tried border: 'none'
        outline: 'none',

    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "88vw",
        border: "none",
        boarderRadius: "10px",
        fontSize: '13px',
        borderStyle: 'none',
        background: 'rgba(190, 175, 179, 0.05)',
        outline: 'none',
        fontWeight: '400',
        lineHeight: '14px',
        letterSpacing: '1px',
        color: '#86878E',
    })

}
