import React, { Component } from 'react'
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux'
import BackButton from '../../../../elements/BackButton'

export class ViewAttendence extends Component {
    state = {
        loading: false,
    }
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500)
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 320 658"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="16" y="31" rx="6" ry="9" width="24" height="24" />
                            <rect x="98" y="31" rx="6" ry="9" width="123" height="24" />
                            <rect x="15" y="83" rx="6" ry="9" width="288" height="32" />
                            <rect x="15" y="129" rx="10" ry="10" width="288" height="450" />
                            <rect x="15" y="593" rx="10" ry="10" width="290" height="57   " />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"View Attendence"} />
                        <div className='px-3 mt-4'>
                            <div className='d-flex justify-content-between mt-2 px-2 teacher-date-class'>
                                <div>
                                    Date: <span style={{ color: '#c24a6b' }}>15 May 2022</span>
                                </div>
                                <div>
                                    Class: <span style={{ color: '#c24a6b' }}>10-A</span>
                                </div>
                            </div>
                            <div className="panel-board px-3 py-3 mt-3">
                                <table className="w-100">
                                    <tr className="panel-head  px-3">
                                        <th className="panel-head-1 text-center ">
                                            Name
                                        </th>
                                        <th className="panel-head-2 text-center  " >
                                            Roll No.
                                        </th>
                                        <th className="panel-head-3 text-center  " >
                                            Status
                                        </th>
                                    </tr>
                                    <tr className="panel-content">
                                        <td className="py-2 text-center">Mini</td>
                                        <td className="text-center">1</td>
                                        <td className="text-center">
                                            <span className="px-2 py-1" style={{ backgroundColor: "#DB3665", borderRadius: "6px", color: "#fff" }} >
                                                Absent
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="panel-content">
                                        <td className="py-2 text-center">Deepak</td>
                                        <td className="text-center">2</td>
                                        <td className="text-center">
                                            <span className="px-2 py-1" style={{ backgroundColor: "#58B151", borderRadius: "6px", color: "#fff" }} >
                                                Present
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="panel-content">
                                        <td className="py-2 text-center">Ashik</td>
                                        <td className="text-center">3</td>
                                        <td className="text-center">
                                            <span className="px-2 py-1" style={{ backgroundColor: "#51BBD2", borderRadius: "6px", color: "#fff" }} >
                                                Half day
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="panel-content">
                                        <td className="py-2 text-center">Ashik</td>
                                        <td className="text-center">4</td>
                                        <td className="text-center">
                                            <span className="px-2 py-1" style={{ backgroundColor: "#58B151", borderRadius: "6px", color: "#fff" }} >
                                                Present
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                            <button className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                                Download
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})
export default connect(mapStateToProps, {

})(ViewAttendence)
