import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../../elements/BackButton'
import noSection from '../../../../assets/Images/noSection.png'
import moment from 'moment'
import { getAttendenceData, addStudentAttendence } from "../../../../redux/Teacher/student/action"
import { CgCheckO } from 'react-icons/cg'
import Modal from "react-modal";

export class MarkHoliday extends Component {
    state = {
        loading: false,
        date: '',
        open: '',
        classTeacher: [],
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { teacher } = this.props;
        this.props.getAttendenceData(teacher?.data?.auth_token, teacher?.data?.staff?.user_id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, classTeacher: response.payload.classTeacher })
            }
        })
    }

    markHoliday = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const { teacher } = this.props;
        const { date } = this.state;
        this.props.addStudentAttendence(teacher?.data?.auth_token, teacher?.data?.staff?.user_id, date ? date : moment().format("Y-M-D"), 'HOLIDAY').then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, open: true })
            }
        })
    }

    modalClose = (e) => {
        e.preventDefault();
        // this.props.history.goBack();
        this.setState({ open: false });
    };
    render() {
        const { loading, classTeacher, date } = this.state;
        return (
            <React.Fragment>
                {loading ? (
                    <React.Fragment>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"Mark Holiday"} />
                        {classTeacher ? (
                            <React.Fragment>
                                <div className='px-3 mt-5'>
                                    <div className="position-relative mt-5">
                                        <div className='d-flex justify-content-between mt-3 homework-input'>
                                            <div className='text-muted'> Class  </div>
                                            <div style={{ fontWeight: '600' }}>  {classTeacher?.section?.student_class?.name}  </div>
                                        </div>
                                        <div className='d-flex justify-content-between mt-3 homework-input'>
                                            <div className='text-muted'> Section  </div>
                                            <div style={{ fontWeight: '600' }}>  {classTeacher?.section?.name}  </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className='px-2 text-muted' style={{ fontSize: '.9em' }}>
                                            Select Date
                                        </div>
                                        <input type="date" className="text-muted attendence-input px-3" style={{ fontSize: '13px' }} placeholder='Date'
                                            value={date ? date : moment().format("Y-M-D")}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                console.log(value)
                                                this.setState({ date: value })
                                            }} required />
                                    </div>
                                </div>
                                <div className='position-fixed bottom-0 py-2 w-100 px-3' onClick={(e) => this.markHoliday(e)}>
                                    {/* add submit */}
                                    <button className='btn attendence-dwld-btn py-3 w-100' >
                                        Add
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className='mt-5 pt-5 w-100 d-flex flex-column justify-content-center align-items-center fw-bolder'>
                                <img src={noSection} alt="no reviews" style={{ width: '65vw' }} className='' />
                                <span> You are Not A Class Teacher...</span>
                            </div>
                        )}
                    </React.Fragment>
                )}

                <Modal
                    isOpen={this.state.open}
                    ariaHideApp={false}
                    onRequestClose={(e) => this.modalClose(e)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "45vw",
                            width: "70vw",
                            zIndex: "9999",
                        }}
                    >
                        <React.Fragment>
                            <div className="d-flex flex-column justify-content-between align-items-center">
                                <div className="d-flex justify-content-center"
                                    style={{ fontSize: "18px", fontWeight: "600", color: "#2B2C2F", }}  >
                                    <CgCheckO color={"#7ECB40"} size={50} />
                                </div>
                                <div className="d-flex justify-content-center text-center mt-1"
                                    style={{ fontSize: "1.1em", fontWeight: "400", color: "#2B2C2F", }}>
                                    Holiday Added Successfully
                                </div>
                                <button style={{ color: "#fff", }} className="btn btn-sm btn-success px-3 mt-1" onClick={(e) => this.modalClose(e)}  >
                                    OK
                                </button>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher
})
export default connect(mapStateToProps, {
    getAttendenceData, addStudentAttendence
})(MarkHoliday)

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #bf4672",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
