import {
    GET_TUTORIALS,
    GET_HOMEWORKS,
    GET_SINGLE_HOME_WORKS,
    UPLOAD_HOME_WORK,
    GET_ALL_SUBJECTS,
    GET_SINGLE_SUBJECT,
    GET_ALL_TEACHERS,
    GET_SINGLE_TEACHER,
    GET_FEES_DATA,
    GET_STUDENT_TIME_TABLE,
    VIEW_ALL_FEES,
    GET_ONLINE_CLASSES,
    GET_EXAM_SCHEDULE,
    GET_EVENTS,
    GET_ATTENDENCE_DATA,
    DOWNLOAD_STUDENT_ATTENDENCE,
    GET_ALL_ACTIVITY,
    GET_TODAY_IN_CLASS,
    GET_OCCASIONAL_VIDEO,
    GET_ALL_MEDIA,
    GET_MEDIA_WISE_IMAGES,
    GET_ANSWERS,
    GET_GALLERY_IMAGES,
} from "./actionType";

import {
    GET_TUTORIALS_URL,
    GET_HOMEWORKS_URL,
    GET_SINGLE_HOME_WORKS_URL,
    UPLOAD_HOME_WORK_URL,
    GET_ALL_SUBJECTS_URL,
    GET_SINGLE_SUBJECT_URL,
    GET_ALL_TEACHERS_URL,
    GET_SINGLE_TEACHER_URL,
    GET_FEES_DATA_URL,
    GET_STUDENT_TIME_TABLE_URL,
    VIEW_ALL_FEES_URL,
    GET_ONLINE_CLASSES_URL,
    GET_EXAM_SCHEDULE_URL,
    GET_EVENTS_URL,
    GET_ATTENDENCE_DATA_URL,
    DOWNLOAD_STUDENT_ATTENDENCE_URL,
    GET_ALL_ACTIVITY_URL,
    GET_TODAY_IN_CLASS_URL,
    GET_OCCASIONAL_VIDEO_URL,
    GET_ALL_MEDIA_URL,
    GET_MEDIA_WISE_IMAGES_URL,
    GET_ANSWERS_URL,
    GET_GALLERY_IMAGES_URL,
} from "../../../api";

import Axios from "axios";
import axios from "axios";

//tutorial
export const getClassTutorials = (token, id) => (dispatch) => {
    return Axios.post(GET_TUTORIALS_URL, { token, id })
        .then((response) => {
            let tutorials = response.data;
            return dispatch({
                type: GET_TUTORIALS,
                payload: tutorials
            })
        })
}

//homework
export const getHomeWorks = (token, id) => (dispatch) => {
    return Axios.post(GET_HOMEWORKS_URL, { token, id })
        .then((response) => {
            let homeworks = response.data;
            return dispatch({
                type: GET_HOMEWORKS,
                payload: homeworks
            })
        })
}

export const getSingleHomeWork = (token, id) => (dispatch) => {
    return Axios.post(GET_SINGLE_HOME_WORKS_URL, { token, id })
        .then((response) => {
            let homeworks = response.data;
            return dispatch({
                type: GET_SINGLE_HOME_WORKS,
                payload: homeworks
            })
        })
}

export const uploadHomework = (token, student_id, id, title, file, date) => (dispatch) => {
    return Axios.post(UPLOAD_HOME_WORK_URL, { token, student_id, id, title, file, date }, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    },)
        .then((response) => {
            let homeworks = response.data;
            return dispatch({
                type: UPLOAD_HOME_WORK,
                payload: homeworks
            })
        })
}

//subject
export const getAllSubjects = (token, student_id) => (dispatch) => {
    return Axios.post(GET_ALL_SUBJECTS_URL, { token, student_id })
        .then((response) => {
            let subjects = response.data;
            return dispatch({
                type: GET_ALL_SUBJECTS,
                payload: subjects
            })
        })
}

export const getSingleSubject = (token, subject_id) => (dispatch) => {
    return Axios.post(GET_SINGLE_SUBJECT_URL, { token, subject_id })
        .then((response) => {
            let subject = response.data;
            return dispatch({
                type: GET_SINGLE_SUBJECT,
                payload: subject
            })
        })
}

//teacher
export const getAllTeachers = (token, student_id) => (dispatch) => {
    return Axios.post(GET_ALL_TEACHERS_URL, { token, student_id })
        .then((response) => {
            let teachers = response.data;
            return dispatch({
                type: GET_ALL_TEACHERS,
                payload: teachers
            })
        })
}

export const getSingleTeacher = (token, id) => (dispatch) => {
    return Axios.post(GET_SINGLE_TEACHER_URL, { token, id })
        .then((response) => {
            let teachers = response.data;
            return dispatch({
                type: GET_SINGLE_TEACHER,
                payload: teachers
            })
        })
}

//fees
export const getFeesDatas = (token, student_id) => (dispatch) => {
    return Axios.post(GET_FEES_DATA_URL, { token, student_id })
        .then((response) => {
            let fees = response.data;
            return dispatch({
                type: GET_FEES_DATA,
                payload: fees
            })
        })
}


//get student timetable data action creator
export const getStudentTimeTable = (token, student_id) => async (dispatch) => {
    let response = await axios.post(GET_STUDENT_TIME_TABLE_URL, { token, student_id })
    if (response.data.success) {
        let time_table_data = response.data.data
        return dispatch({
            type: GET_STUDENT_TIME_TABLE,
            payload: time_table_data
        })
    }
}


export const viewAllFees = (token, type, student_id) => (dispatch) => {
    return Axios.post(VIEW_ALL_FEES_URL, { token, type, student_id })
        .then((response) => {
            let fees = response.data;
            return dispatch({
                type: VIEW_ALL_FEES,
                payload: fees
            })
        })
}

//online class
export const getOnlineClasses = (token, id) => (dispatch) => {
    return Axios.post(GET_ONLINE_CLASSES_URL, { token, id })
        .then((response) => {
            let onlineclasses = response.data;
            return dispatch({
                type: GET_ONLINE_CLASSES,
                payload: onlineclasses
            })
        })
}


export const getExamSchedule = (token, student_id) => async (dispatch) => {
    let response = await axios.post(GET_EXAM_SCHEDULE_URL, { token, student_id })
    if (response.data.success) {
        let exam_schedule_data = response.data.data
        return dispatch({
            type: GET_EXAM_SCHEDULE,
            payload: exam_schedule_data
        })
    }
}
//events
export const getEvents = (token, id) => (dispatch) => {
    return Axios.post(GET_EVENTS_URL, { token, id })
        .then((response) => {
            let events = response.data;
            return dispatch({
                type: GET_EVENTS,
                payload: events
            })
        })
}

//tutorial
export const getOccasionalVideo = (token, student_id) => (dispatch) => {
    return Axios.post(GET_OCCASIONAL_VIDEO_URL, { token, student_id })
        .then((response) => {
            let tutorials = response.data;
            return dispatch({
                type: GET_OCCASIONAL_VIDEO,
                payload: tutorials
            })
        })
}

//Attendence
export const getAttendenceData = (token, student_id, start_date, end_date) => (dispatch) => {
    return Axios.post(GET_ATTENDENCE_DATA_URL, { token, student_id, start_date, end_date })
        .then((response) => {
            let attendence = response.data;
            return dispatch({
                type: GET_ATTENDENCE_DATA,
                payload: attendence
            })
        })
}

export const downloadAttendenceData = (token, student_id, start_date, end_date) => (dispatch) => {
    return Axios.post(DOWNLOAD_STUDENT_ATTENDENCE_URL, { token, student_id, start_date, end_date })
        .then((response) => {
            let attendence = response.data;
            return dispatch({
                type: DOWNLOAD_STUDENT_ATTENDENCE,
                payload: attendence
            })
        })
}


export const getAllActivity = (token, student_id) => (dispatch) => {
    return Axios.post(GET_ALL_ACTIVITY_URL, { token, student_id })
        .then((response) => {
            let activities = response.data;
            return dispatch({
                type: GET_ALL_ACTIVITY,
                payload: activities
            })
        })
}
//today in class
export const getTodayInClass = (token, student_id) => (dispatch) => {
    return Axios.post(GET_TODAY_IN_CLASS_URL, { token, student_id })
        .then((response) => {
            let today_in_class_data = response.data;
            return dispatch({
                type: GET_TODAY_IN_CLASS,
                payload: today_in_class_data
            })
        })
}


//school media
export const getMediaImages = (token, student_id) => (dispatch) => {
    return Axios.post(GET_ALL_MEDIA_URL, { token, student_id })
        .then((response) => {
            let media = response.data;
            return dispatch({
                type: GET_ALL_MEDIA,
                payload: media
            })
        })
}
export const getMediaWiseImages = (token, id) => (dispatch) => {
    return Axios.post(GET_MEDIA_WISE_IMAGES_URL, { token, id })
        .then((response) => {
            let mediaimages = response.data;
            return dispatch({
                type: GET_MEDIA_WISE_IMAGES,
                payload: mediaimages
            })
        })
}

//answers
export const getAnswers = (token, id) => (dispatch) => {
    return Axios.post(GET_ANSWERS_URL, { token, id })
        .then((response) => {
            let answers = response.data;
            return dispatch({
                type: GET_ANSWERS,
                payload: answers
            })
        })
}
//gallery
export const getGalleryImages = (token, id) => (dispatch) => {
    return Axios.post(GET_GALLERY_IMAGES_URL, { token, id })
        .then((response) => {
            let images = response.data;
            return dispatch({
                type: GET_GALLERY_IMAGES,
                payload: images
            })
        })
}
