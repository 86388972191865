import moment from "moment";
import React, { Component } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillChatTextFill } from "react-icons/bs";
import { TbMessageCircle } from "react-icons/tb";
import { VscChromeClose } from "react-icons/vsc";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
export class MyAppointments extends Component {
    render() {
        const { appointments } = this.props;
        return (
            <React.Fragment>
                {appointments?.length > 0 &&
                    <React.Fragment>
                        <div className=" py-3 px-4">
                            <div style={{ fontSize: "18px", fontWeight: "500" }}>
                                My Appointments
                            </div>
                        </div>

                        <div
                            className="appointment hidden-scroll-bar gap-3 px-4"
                            style={{
                                gridTemplateColumns: 'repeat(' + appointments?.length + ',1fr)',
                                overflowX: "scroll",
                            }}
                        >
                            {appointments.map((value, index) =>
                                <div className="appointment1-card py-2 px-2   ">
                                    <div className="d-flex flex-row justify-content-around align-items-center ">
                                        <div className="flex-shrink-1 px-3">
                                            <Link to="/list-appointments" style={{ color: '#9c8ec3' }}>
                                                <BsFillChatTextFill className="msgbtn" />
                                            </Link>
                                        </div>
                                        <div className="flex-grow-1 ps-2">
                                            <Link to="/list-appointments" style={{ color: '#9c8ec3' }}>
                                                <div className="d-flex flex-column h-100 justify-content-between align-content-between" >
                                                    <span className="fw-nomal fw-500" style={{ fontSize: '14px' }}>
                                                        Parent meeting
                                                    </span>
                                                    <span className="mt-1" style={{ fontSize: '12px' }}>
                                                        {value?.student?.user?.name},({value?.student?.student_class?.name}{value?.student?.section?.name}){" "}
                                                        at{" "}{value?.appointment_time}
                                                    </span>
                                                    {/* <span className="mt-1" style={{ fontSize: '12px' }}>
                                                    {value?.reason}
                                                </span> */}
                                                </div>
                                            </Link>
                                        </div>
                                        {/* <div className="flex-shrink-1 pe-2 align-self-start">
                                            <VscChromeClose className="fs-3" />
                                        </div> */}
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyAppointments);
