import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import logoImage from "../../../assets/Images/loginlogo1.png";
import background from "../../../assets/Images/loginbg.png";
import { Link, Redirect } from "react-router-dom";
import { parentSignUp } from "../../../redux/Parent/user/action";
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottie/login1.json';
class ParentLogin extends Component {
    state = {
        username: "",
        password: "",
        invalid: false,
        login: false,
    };
    handleInputPassword = (e) => {
        this.setState({ password: e.target.value });
    };
    handleInputEmail = (e) => {
        this.setState({ username: e.target.value });
    };
    onSubmit = (e) => {
        this.setState({ login: true })
        e.preventDefault();
        this.props
            .parentSignUp(this.state.username, this.state.password)
            .then((response) => {
                if (
                    response.payload.success === false &&
                    response.payload.data === "Invalid Username or Password"
                ) {
                    this.setState({ invalid: true, login: false });
                }
                else if (response.payload.success) {
                    window.location.replace("/home");
                }
            });
    };
    render() {
        const { user } = this.props;
        if (user?.success) {
            return <Redirect to={"/home"} />;
        }
        return (
            <React.Fragment>
                {this.state.login ? (
                    <React.Fragment>
                        <div style={{ height: '100vh' }} className="d-flex justify-content-center align-items-center">
                            <Lottie
                                options={defaultOptions}
                                height={240}
                                width={250}
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    <div
                        className="d-flex flex-column "
                        style={{
                            // backgroundImage: `url(${background})`,
                            height: "100vh",
                            backgroundSize: "cover",
                            width: "100%",
                        }}
                    >
                        <div className="logo-image mb-5">
                            <img src={logoImage} alt="logo" width="120px" height="120px" />
                        </div>
                        {this.state.invalid ? (
                            <span className="text-white p-2 text-center">
                                Invalid Username or Password
                            </span>
                        ) : (
                            ""
                        )}
                        <div className="login-form">
                            <Form
                                className="mt-3 text-white"
                                onSubmit={(e) => this.onSubmit(e)}
                            >
                                <input
                                    type="text"
                                    className="username-input-login w-100"
                                    placeholder="Username"
                                    required
                                    onChange={(e) => {
                                        this.handleInputEmail(e);
                                    }}
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    required
                                    className="username-input-login mt-4 w-100"
                                    onChange={(e) => {
                                        this.handleInputPassword(e);
                                    }}
                                />
                                <div className="login-btn-div">
                                    <button
                                        type="submit"
                                        className="login-button w-100"
                                        size="lg"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Log In
                                    </button>
                                </div>
                            </Form>
                            {/* <div className="forget-link">
                            <Link
                                to="/home"
                                style={{
                                    color: "white",
                                    textDecoration: "underline",
                                }}
                            >
                                Forget username or password
                            </Link>
                        </div> */}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
});

export default connect(mapStateToProps, { parentSignUp })(ParentLogin);
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
