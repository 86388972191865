import React, { Component } from "react";
import { connect } from "react-redux";
// import { CgMenuLeft } from "react-icons/cg";
import HomeHead from "../../../elements/HomeHead";
import LiveClass from "./LiveClass";
import { ViewTeacher } from "./ViewTeacher";
import Catagories from "./catagories";
import { MyAppointment } from "./My Appointments";
import LastViewedTutorial from "./LastViewedTutorial";
import Footer from "../Footer";
import Select from 'react-select'
import { getAllHomePageData } from '../../../redux/Parent/home/action'
import { getStudent } from '../../../redux/Parent/user/action'
// import { Redirect } from "react-router";
import noStudet from '../../../assets/Images/noStudet.png'
import { HiOutlineLogout } from "react-icons/hi";
import { getParentProfile, logoutUser } from "../../../redux/Parent/user/action";
import Ink from "react-ink";
import Modal from "react-modal";
import ContentLoader from "react-content-loader";

export class Home extends Component {
    state = {
        loading: false,
        live_class: null,
        teachers: null,
        categories: null,
        appointments: null,
        tutorials: null,
        students: [],
        student_id: [],
        noStudent: false,
        open_logout: false,
        isOpen: false

    }
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        if (user && user.data && user.data.students && user.data.students.length > 0) {
            let student_id = JSON.parse(localStorage.getItem("student_id"))
            this.props.getParentProfile(user.data.auth_token).then((response) => {
                if (response && response.payload && response.payload.success) {
                    // console.log(response.payload.data.students)
                    this.setState({ students: response?.payload?.data?.students })
                }
            })
            if (!student_id) {
                localStorage.setItem("student_id", JSON.stringify({ value: user?.data?.students[0]?.id, label: user?.data?.students[0]?.user?.name }));
            }
            let id = JSON.parse(localStorage.getItem("student_id"))
            this.props.getAllHomePageData(user?.data?.auth_token, student_id ? student_id.value : id.value).then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false, live_class: response.payload.live_class, students: user?.data?.students,
                        student_id: student_id ? student_id : id,
                        teachers: response.payload.teachers, categories: response.payload.categories,
                        appointments: response.payload.appointments, tutorials: response.payload.tutorials,
                    })
                }
            })
            this.props.getStudent(user?.data?.auth_token, student_id ? student_id.value : id.value)
        } else {
            this.setState({ noStudent: true, loading: false });
        }

    }
    filterValue = (values) => {
        let filterValue = []
        values.forEach((item) => {
            filterValue.push({ value: item.id, label: item.user.name })
            // console.log(filterValue, 'jhjhjhjhj')
        })
        return filterValue
    }
    selectedStudent = (e) => {
        const { user } = this.props;
        this.setState({ student_id: e.value, loading: true })
        localStorage.setItem("student_id", JSON.stringify(e));
        this.props.getAllHomePageData(user.data.auth_token, e.value).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({
                    student_id: e,
                    loading: false, live_class: response.payload.live_class,
                    teachers: response.payload.teachers, categories: response.payload.categories,
                    appointments: response.payload.appointments, tutorials: response.payload.tutorials,
                })
            }
        })
        this.props.getStudent(user?.data?.auth_token, e.value)
    }
    handleAccountLogout = (e) => {
        e.preventDefault();
        this.setState({ open_logout: !this.state.open_logout, isOpen: false });
    };
    __handleLogout = (e) => {
        e.preventDefault();

        this.props.logoutUser();
        localStorage.clear()
        window.location.replace("/login");
        this.props.history.replace("/login");
    };
    render() {
        const { user } = this.props;
        const { live_class, teachers, categories, appointments, tutorials, students, student_id } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 236 470"
                            backgroundColor="#F3F3F3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="15" y="24" rx="6" ry="9" width="24" height="24" />
                            <rect x="100" y="24" rx="6" ry="9" width="60" height="26" />
                            <rect x="13" y="74" rx="8" ry="15" width="70" height="14" />
                            <rect x="113" y="68" rx="2" ry="2" width="110" height="28" />
                            {/* live class */}
                            <rect x="15" y="110" rx="8" ry="15" width="70" height="18" />
                            <rect x="15" y="140" rx="10" ry="10" width="91" height="127" />
                            <rect x="117" y="140" rx="10" ry="10" width="91" height="127" />
                            <rect x="220" y="140" rx="6" ry="10" width="20" height="127" />
                            {/* teachers */}
                            <rect x="15" y="280" rx="8" ry="15" width="96" height="18" />
                            <rect x="15" y="318" rx="20" ry="14" width="170" height="145" />
                            <rect x="200" y="318" rx="20" ry="14" width="50" height="145" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {this.state.noStudent ?
                            (
                                <div className='d-flex flex-column' style={{ paddingTop: '25vw' }}>
                                    <img src={noStudet} alt="No Item" style={{ width: '90%' }} />
                                    <div className='text-center' style={{ fontWeight: '600', color: '#C47B8B' }}>No Student Found</div>

                                    <div className="position-fixed bottom-0 w-100 px-3 py-2">
                                        <div className="btn home-login-btn w-100 py-3"
                                            onClick={(e) => this.handleAccountLogout(e)}  >
                                            <HiOutlineLogout size={25} />
                                            Logout
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="px-3 py-4">
                                        <HomeHead user={user} />
                                    </div>
                                    {user && user.data && user.data.students && user.data.students.length > 1 &&
                                        <div className="d-flex justify-content-between align-items-center px-3">
                                            <div>
                                                Select Student
                                            </div>
                                            <Select className="text-muted" styles={customStyles} options={this.filterValue(students)}
                                                onChange={e => { this.selectedStudent(e); }} value={student_id}
                                            />
                                        </div>
                                    }
                                    {live_class || teachers || categories || appointments || tutorials ? (
                                        <div style={{ paddingBottom:'20vw' }}>
                                            {live_class && live_class.length > 0 &&
                                                <LiveClass live_class={live_class} />
                                            }
                                            {teachers && teachers.length > 0 &&
                                                <ViewTeacher teachers={teachers} />
                                            }
                                            <Catagories />
                                            {appointments && appointments.length > 0 &&
                                                <MyAppointment appointments={appointments} />
                                            }
                                            {tutorials && tutorials.length > 0 &&
                                                <LastViewedTutorial tutorials={tutorials} />
                                            }
                                        </div>
                                    ) : (
                                        <div>
                                            No data Found
                                        </div>
                                    )}
                                    {/* <div>
                                <LiveClass live_class={live_class} />
                                <ViewTeacher teachers={teachers} />
                                <Catagories  />
                                <MyAppointment appointments={appointments} />
                                <LastViewedTutorial tutorials={tutorials} />
                            </div> */}


                                    <Footer />
                                </div>
                            )}
                    </React.Fragment>
                )}
                <Modal
                    isOpen={this.state.open_logout}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={() => this.handlePopup()}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="d-flex flex-column align-items-center"
                        style={{ backgroundColor: "#fff", padding: "13px" }}
                    >
                        <div className="text-center">
                            <span className="fs-4 fw-semibold" style={{ color: '#C47BAF' }}>
                                Logout?
                            </span>
                        </div>
                        <div className="text-muted mt-3">
                            <span>Are you sure you about logging out ?</span>
                        </div>

                        <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-4">
                            <div className="text-center mr-4 w-100">
                                <button className="btn  p-2 px-2 w-100 position-relative"
                                    onClick={(e) => this.handleAccountLogout(e)}
                                    style={{
                                        color: "#000",
                                        backgroundColor: '#d4d4d4'
                                    }}
                                >
                                    {" "}
                                    NO
                                    <Ink
                                        duration={500}
                                        style={{ color: "#000" }}
                                    />
                                </button>
                            </div>

                            <div className="text-center w-100">
                                <button
                                    className="btn home-login-btn p-2 px-2 w-100 text-center text-white position-relative"
                                    onClick={(e) => this.__handleLogout(e)}
                                    style={{
                                        backgroundColor: "#ba5d9f",
                                    }}
                                >
                                    YES
                                    <Ink
                                        duration={500}
                                        style={{ color: "#000" }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent
});
export default connect(mapStateToProps, {
    getAllHomePageData,
    getStudent,
    logoutUser,
    getParentProfile
})(Home);
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        border: '1px solid #C47BAF',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};

