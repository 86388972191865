import React, { Component, Profiler } from 'react'
import { connect } from 'react-redux'
import { AiOutlineBell, AiOutlineMenuUnfold } from 'react-icons/ai'
import ProgressiveImage from "react-progressive-image";
import Profile from '../../../../assets/Images/proimg.png'
import moment from 'moment';
import { IMAGE_BASE_URL } from '../../../../api';


export class Schedule extends Component {

    __findTeacher = (id)=>{
        const {teacher} = this.props
        if(teacher.teachers && teacher.teachers.length>0){
           const user = teacher.teachers.find((subject)=> subject.id == id)
           return user.user
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='d-flex flex-row justify-content-between px-3 mt-4'>
                    <div className='d-flex flex-row'>
                        <div className='exam-schedule-time'>
                            Time
                        </div>
                        <div className='ps-3 exam-schedule-time'>
                            Exam
                        </div>
                    </div>
                    <div>
                        <AiOutlineMenuUnfold style={{ fontSize: "26px", color: "#BCC1CD" }} />
                    </div>
                </div>
                {this.props.schedules && this.props.schedules.map((schedule,index)=>
                  {
                     const user = this.__findTeacher(schedule.subject.id)
                     return <>
                     {index%2==0
                      ?
                      <div className='d-flex flex-row justify-content-between px-3 mt-3'>
                      <div className='d-flex flex-column flex-shrink-1 me-1'>
                          <div className='' style={{ fontSize: "16px", color: "#212525", fontWeight: "500" }}>
                              {moment(schedule.from_time).format('LT')}
                          </div>
                          <div className='' style={{ fontSize: "14px", color: "#BCC1CD", fontWeight: "300" }}>
                              {moment(schedule.exam_date).format('L')}
                          </div>
                      </div>
                      <div className='exam-schedule-card-1 d-flex justify-content-between px-3 w-75 p-2'>
                          <div className='py-2  '>
                              <div>
                                  <span className='' style={{ fontSize: "16px", color: "white", fontWeight: "700" }}>{schedule.subject?.name}</span>
                              </div>
                              <div>
                                  <span className='' style={{ fontSize: "12px", color: "white", fontWeight: "500" }}>{schedule.portion.lessons}</span>
                              </div>
                              <div className='mt-2'>
                                  <ProgressiveImage
                                      delay={20}
                                      src={Profile}
                                      placeholder={
                                          "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                      }
                                  >
                                      {(src, loading) => (
                                          <img
                                              className=""
                                              style={{
                                                  width: "7vw",
                                                  objectFit: "cover",
                                                  height: "7vw",
                                                  borderRadius: "20px",
                                                  backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                  backgroundRepeat: "round",
                                                  boxShadow: "0px 0px 15px 8px #cccccc50",
                                              }}
                                              src={user?.image ? IMAGE_BASE_URL+ user?.image :  "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder" }
                                              alt="main-item"
                                          />
                                      )}
                                  </ProgressiveImage>
                                  <span className='ps-2' style={{ fontSize: "14px", color: "white", fontWeight: "300" }}>{user?.first_name +" " + user?.last_name}</span>
                              </div>
                          </div>
                          {/* <div className='mt-2'>
                              <AiOutlineBell size={15} style={{ color: '#fff' }} />
                          </div> */}
                      </div>
                  </div>

                      :

                      <div className='d-flex flex-row justify-content-between px-3 mt-3'>
                      <div className='d-flex flex-column'>
                          <div className='' style={{ fontSize: "16px", color: "#212525", fontWeight: "500" }}>
                          {moment(schedule.from_time).format('LT')}
                          </div>
                          <div className='' style={{ fontSize: "14px", color: "#BCC1CD", fontWeight: "300" }}>
                          {moment(schedule.exam_date).format('L')}
                          </div>
                      </div>
                      <div className='exam-schedule-card-2 d-flex justify-content-between px-3 w-75 p-2'>
                          <div className='py-2  '>
                              <div>
                                  <span className='' style={{ fontSize: "16px", color: "white", fontWeight: "700" }}>{schedule.subject?.name}</span>
                              </div>
                              <div>
                                  <span className='' style={{ fontSize: "12px", color: "white", fontWeight: "500" }}>{schedule.portion.lessons}</span>
                              </div>
                              <div className='mt-2'>
                                  <ProgressiveImage
                                      delay={20}
                                      src={Profile}
                                      placeholder={
                                          "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                      }
                                  >
                                      {(src, loading) => (
                                          <img
                                              className=""
                                              style={{
                                                  width: "7vw",
                                                  objectFit: "cover",
                                                  height: "7vw",
                                                  borderRadius: "20px",
                                                  backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                  backgroundRepeat: "round",
                                                  boxShadow: "0px 0px 15px 8px #cccccc50",
                                              }}
                                              src={user?.image ? IMAGE_BASE_URL+ user?.image :  "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"}
                                              alt="main-item"
                                          />
                                      )}
                                  </ProgressiveImage>
                                  <span className='ps-2' style={{ fontSize: "14px", color: "white", fontWeight: "300" }}>{user?.name}</span>
                              </div>
                          </div>
                          <div className='mt-2'>
                              <AiOutlineBell size={15} style={{ color: '#fff' }} />
                          </div>
                      </div>
                  </div>


                     }
                   </>
                  }


                )}




                {/* <div className='d-flex flex-row justify-content-between px-3 mt-3'>
                    <div className='d-flex flex-column'>
                        <div className='' style={{ fontSize: "16px", color: "#212525", fontWeight: "500" }}>
                            10:00
                        </div>
                        <div className='' style={{ fontSize: "14px", color: "#BCC1CD", fontWeight: "300" }}>
                            15/06/22
                        </div>
                    </div>
                    <div className='exam-schedule-card-3 d-flex justify-content-between px-3 w-75 p-2'>
                        <div className='py-2  '>
                            <div>
                                <span className='' style={{ fontSize: "16px", color: "white", fontWeight: "700" }}>Mathematics</span>
                            </div>
                            <div>
                                <span className='' style={{ fontSize: "12px", color: "white", fontWeight: "500" }}>Chapter 1: Introduction</span>
                            </div>
                            <div className='mt-2'>
                                <ProgressiveImage
                                    delay={20}
                                    src={Profile}
                                    placeholder={
                                        "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                    }
                                >
                                    {(src, loading) => (
                                        <img
                                            className=""
                                            style={{
                                                width: "7vw",
                                                objectFit: "cover",
                                                height: "7vw",
                                                borderRadius: "20px",
                                                backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                backgroundRepeat: "round",
                                                boxShadow: "0px 0px 15px 8px #cccccc50",
                                            }}
                                            src={src}
                                            alt="main-item"
                                        />
                                    )}
                                </ProgressiveImage>
                                <span className='ps-2' style={{ fontSize: "12px", color: "white", fontWeight: "400" }}>Mathematics</span>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <AiOutlineBell size={15} style={{ color: '#fff' }} />
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-between px-3 mt-3'>
                    <div className='d-flex flex-column'>
                        <div className='' style={{ fontSize: "16px", color: "#212525", fontWeight: "500" }}>
                            10:00
                        </div>
                        <div className='' style={{ fontSize: "12px", color: "#BCC1CD", fontWeight: "300" }}>
                            15/06/22
                        </div>
                    </div>
                    <div className='exam-schedule-card-4 d-flex justify-content-between px-3 w-75 p-2'>
                        <div className='py-2  '>
                            <div>
                                <span className='' style={{ fontSize: "16px", color: "white", fontWeight: "700" }}>Mathematics</span>
                            </div>
                            <div>
                                <span className='' style={{ fontSize: "12px", color: "white", fontWeight: "500" }}>Chapter 1: Introduction</span>
                            </div>
                            <div className='mt-2'>
                                <ProgressiveImage
                                    delay={20}
                                    src={Profile}
                                    placeholder={
                                        "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                    }
                                >
                                    {(src, loading) => (
                                        <img
                                            className=""
                                            style={{
                                                width: "7vw",
                                                objectFit: "cover",
                                                height: "7vw",
                                                borderRadius: "20px",
                                                backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                backgroundRepeat: "round",
                                                boxShadow: "0px 0px 15px 8px #cccccc50",
                                            }}
                                            src={src}
                                            alt="main-item"
                                        />
                                    )}
                                </ProgressiveImage>
                                <span className='ps-2' style={{ fontSize: "12px", color: "white", fontWeight: "400" }}>Mathematics</span>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <AiOutlineBell size={15} style={{ color: '#fff' }} />
                        </div>
                    </div>
                </div> */}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher :state.parentHome.home
})



export default connect(mapStateToProps)(Schedule)
