import React, { Component } from "react";
import { connect } from "react-redux";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import Footer from "../Footer";
import ProgressiveImage from "react-progressive-image";
import chat from "../../../assets/Images/chat.png";
import ContentLoader from "react-content-loader";
import { getParentChatList } from "../../../redux/Parent/chat/action";
import { ChatList } from "./ChatList/index";

export class Chat extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"));

        const { user } = this.props;
        this.props.getParentChatList(user.data.auth_token, student_id.value);
        // console.log(this.props.chat);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    render() {
        const { user, chat } = this.props;

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 550"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="8"
                                y="18"
                                rx="8"
                                ry="8"
                                width="25"
                                height="29"
                            />
                            <rect
                                x="245"
                                y="18"
                                rx="8"
                                ry="8"
                                width="25"
                                height="29"
                            />
                            <rect
                                x="8"
                                y="67"
                                rx="5"
                                ry="5"
                                width="80"
                                height="20"
                            />
                            <rect
                                x="8"
                                y="100"
                                rx="5"
                                ry="5"
                                width="50"
                                height="10"
                            />
                            <rect
                                x="8"
                                y="130"
                                rx="5"
                                ry="5"
                                width="57"
                                height="22"
                            />
                            <rect
                                x="80"
                                y="130"
                                rx="5"
                                ry="5"
                                width="45"
                                height="22"
                            />
                            <rect
                                x="15"
                                y="180"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="185"
                                rx="5"
                                ry="5"
                                width="40"
                                height="15"
                            />
                            <rect
                                x="70"
                                y="205"
                                rx="5"
                                ry="5"
                                width="30"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="240"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="240"
                                rx="5"
                                ry="5"
                                width="45"
                                height="15"
                            />
                            <rect
                                x="70"
                                y="265"
                                rx="5"
                                ry="5"
                                width="65"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="300"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="300"
                                rx="10"
                                ry="10"
                                width="45"
                                height="15"
                            />

                            <rect
                                x="70"
                                y="330"
                                rx="5"
                                ry="5"
                                width="65"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="365"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="365"
                                rx="10"
                                ry="10"
                                width="45"
                                height="15"
                            />
                            <rect
                                x="70"
                                y="390"
                                rx="5"
                                ry="5"
                                width="65"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="430"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="430"
                                rx="10"
                                ry="10"
                                width="45"
                                height="15"
                            />
                            <rect
                                x="70"
                                y="460"
                                rx="5"
                                ry="5"
                                width="65"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="525"
                                rx="12"
                                ry="12"
                                width="250"
                                height="35"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="">
                            <div className="d-flex justify-content-between align-items-center px-3 py-4">
                                <div className="">
                                    <BsSearch style={{ fontSize: "16px" }} />
                                </div>
                                <AiOutlinePlus style={{ fontSize: "16px" }} />
                            </div>
                            <div className="px-3">
                                <span
                                    style={{
                                        color: "#212529",
                                        fontWeight: "300",
                                        fontSize: "22px",
                                    }}
                                >
                                  Chat With Teachers
                                </span>
                            </div>
                            {chat.success && (
                                <div className="px-3">
                                    <span
                                        style={{
                                            color: "#ADB5BD",
                                            fontWeight: "400",
                                            fontSize: "10px",
                                        }}
                                    >
                                        {chat.chat_list?.length} Teachers
                                    </span>
                                </div>
                            )}
                            {/* <div className="d-flex chat-div px-3">
                                <div
                                    className=""
                                    style={{ borderBottom: "1px solid black" }}
                                >
                                    <span
                                        style={{
                                            color: "#212529",
                                            fontWeight: "400",
                                            fontSize: "13px",
                                        }}
                                    >
                                        Students
                                    </span>
                                </div>
                                <div className="px-4">
                                    <span
                                        style={{
                                            color: "#ADB5BD",
                                            fontWeight: "400",
                                            fontSize: "13px",
                                        }}
                                    >
                                        Media
                                    </span>
                                </div>
                            </div> */}
                            <ChatList chat={chat}
                                user={user}
                            />
                            <div>
                                <Footer current={"message"} />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    chat: state.chat.chat_list,
});

export default connect(mapStateToProps, {
    getParentChatList,
})(Chat);
