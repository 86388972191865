import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RiFolder2Fill, RiFileFill } from "react-icons/ri";
import { BsCalendarDateFill, BsFileEarmarkPdfFill, BsFillCameraVideoFill, BsTable } from "react-icons/bs";
import { FaUserCheck, FaUserGraduate } from "react-icons/fa";
import { BsPlusLg, BsChatRightDotsFill, BsFillGrid1X2Fill, BsFillGridFill } from "react-icons/bs";
import { HiClipboardCheck, HiUser } from "react-icons/hi";
import { Link } from "react-router-dom";
import Ink from "react-ink";
import { IoVideocamSharp } from 'react-icons/io5';

export class Footer extends Component {
    state = {
        home: false,
        message: false,
        timetable: false,
        profile: false,
        plus: true,
    };
    componentDidMount() {
        const { current } = this.props;
        if (current === "home") {
            this.setState({ home: true });
        } else if (current === "message") {
            this.setState({ message: true });
        } else if (current === "timetable") {
            this.setState({ timetable: true });
        } else if (current === "profile") {
            this.setState({ profile: true });
        }
    }
    showOptions = () => {
        this.setState({ plus: !this.state.plus });
    }
    render() {
        return (
            <React.Fragment>
                <div className="bottom-bar pt-4" >
                    <div className="d-flex justify-content-between align-items-center position-relative mx-2">
                        {/* home */}
                        <Link to="/teacher-home" className="position-relative b-r-10 px-2 footer-link">
                            <Ink className="touch-ink" />
                            <div className="d-flex flex-column align-items-center justify-content-around h-100 pb-4 ms-2">
                                <BsFillGridFill
                                    className={
                                        this.state.home
                                            ? "footer-active"
                                            : "footer-inactive"
                                    }
                                />
                                {/* {this.state.home && (
                                    <span className={"keke-color fw-bold fs-7"}>
                                        Home
                                    </span>
                                )} */}
                            </div>
                        </Link>
                        {/* message */}
                        <Link to="/teacher-chatlist" className="position-relative b-r-10 px-2 footer-link me-2">
                            <Ink className="touch-ink" />
                            <div className="d-flex flex-column align-items-center justify-content-around h-100 pb-4 me-2">
                                <BsChatRightDotsFill
                                    className={
                                        this.state.message
                                            ? "footer-active"
                                            : "footer-inactive"
                                    }
                                />
                                {/* {this.state.message && (
                                    <span className={"keke-color fw-bold fs-7"}>
                                        Message
                                    </span>
                                )} */}
                            </div>
                        </Link>
                        {/* plus */}
                        <div>
                            <ul id="menu">
                                <div className="home-icon m-1">
                                    <div style={this.state.plus ? { display: 'block' } : { display: 'none' }} onClick={() => this.showOptions()}>
                                        <a class="icon-plus" id="open-menu" href="#menu" title="Show navigation">
                                            <BsPlusLg className="text-white mb-2" size={'0.4em'} />
                                        </a>
                                    </div>
                                    <div style={this.state.plus ? { display: 'none' } : { display: 'block' }} onClick={() => this.showOptions()}>
                                        <a class="icon-minus" href="#0" title="Hide navigation">
                                            <BsPlusLg className="text-white mb-2" size={'0.4em'} style={{ transform: 'rotate(45deg)' }} />
                                        </a>
                                    </div>
                                </div>
                                <li class="menu-item" style={{ display: 'none' }}> </li>
                                {/* add marks */}
                                <li class="menu-item" title="Add Marks">
                                    <Link to="/add-mark">
                                        <div className="home-icon" style={{ backgroundColor: '#FFE494' }} >
                                            <RiFolder2Fill className="text-white" size={"0.6em"} />
                                        </div>
                                    </Link>
                                </li>
                                {/* upload answersheet */}
                                <li class="menu-item" title="Upload AnswerSheet">
                                    <Link to="/uploaded-files">
                                        <div className="home-icon" style={{ backgroundColor: '#FF7976' }} >
                                            <BsFileEarmarkPdfFill className="text-white" size={"0.6em"}  />
                                        </div>
                                    </Link>
                                </li>
                                {/* post tutorial */}
                                <li class="menu-item" title="Post Tutorial">
                                    <Link to="/create-tutorial">
                                        <div className="home-icon" style={{ backgroundColor: '#697A98' }} >
                                            <IoVideocamSharp className="text-white" size={"0.6em"} />
                                        </div>
                                    </Link>
                                </li>
                                {/* add attendance */}
                                <li class="menu-item" title="Add Attendence">
                                    <Link to="/add-attendence-home">
                                        <div className="home-icon" style={{ backgroundColor: '#83739E' }} >
                                            <HiClipboardCheck className="text-white" size={"0.6em"} style={{ transform: 'rotate(180deg)' }}/>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* timetable */}
                        <Link to="/teacher-time-table" className="position-relative b-r-10 px-2 footer-link ms-2">
                            <Ink className="touch-ink" />
                            <div className="d-flex flex-column align-items-center justify-content-around h-100 pb-4 ms-2">
                                <BsCalendarDateFill
                                    className={
                                        this.state.timetable
                                            ? "footer-active"
                                            : "footer-inactive"
                                    }
                                    size={20}
                                />
                                {/* {this.state.timetable && (
                                    <span className={"keke-color fw-bold fs-7 ps-2"}>
                                        Time Table
                                    </span>
                                )} */}
                            </div>
                        </Link>
                        {/* profile */}
                        <Link to="/teacher-profile" className="position-relative b-r-10 px-2 footer-link">
                            <Ink className="touch-ink" />
                            <div className="d-flex flex-column align-items-center justify-content-around h-100 pb-4 me-2">
                                <HiUser
                                    className={
                                        this.state.profile
                                            ? "footer-active"
                                            : "footer-inactive"
                                    }
                                    size={"1.2em"}
                                />
                                {/* {this.state.profile && (
                                    <span className={"keke-color fw-bold fs-7"}>
                                        Profile
                                    </span>
                                )} */}
                            </div>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(Footer)
