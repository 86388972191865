import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../../elements/BackButton";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { changePassword } from "../../../../redux/Parent/user/action";
import Fade from "react-reveal/Fade";
import Modal from "react-modal";
import Shake from "react-reveal/Shake";

export class ChangePassword extends Component {
    state = {
        loading: false,
        passwordChange: false,
        show: false,
        old_password: "",
        password_1: "",
        password_2: "",
        modalVisible: false,
        wrongpassmodal: false,
        notSame: false,
    };
    openChangePassword() {
        if (this.state.passwordChange == false) {
            this.setState({ passwordChange: true });
        } else {
            this.setState({ passwordChange: false });
        }
    }
    handleClick() {
        this.setState({ show: !this.state.show });
    }
    oldPasswordChange = (e) => {
        this.setState({ old_password: e });
    };

    PasswordNew1 = (e) => {
        this.setState({ password_1: e });
    };
    PasswordNew2 = (e) => {
        this.setState({ password_2: e });
    };

    onSubmit = (e) => {
        const { user } = this.props;
        e.preventDefault();
        this.setState({ loading: true });
        if (this.state.password_1 === this.state.password_2) {
            this.setState({ notSame: false })
            this.props.changePassword(user.data.auth_token, this.state.old_password, this.state.password_2).then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        modalVisible: true, title: "Password changed", old_password: null, password_1: null, loading: false, password_2: null,
                    });
                } else {
                    this.setState({
                        wrongpassmodal: true, title: "Something went wrong", old_password: null, password_1: null, loading: false, password_2: null,
                    });
                    // return "Password did not change";
                }
            });
        } else {
            this.setState({ loading: false, notSame: true, });
        }
    };
    componentDidMount() {
        const { user } = this.props;
        console.log(user);
    }
    render() {
        // const customStyles = {
        //     content: {
        //         top: "40%",
        //         left: "20%",
        //         right: "20%",
        //         bottom: "auto",
        //         backgroundColor: "#fff",
        //         color: "#000000",
        //         borderRadius: "10px",
        //         boxShadow: " 0px 8px  15px 10px #f6f6f6",
        //         fontSize: "15px",
        //         border: "none",
        //         outline: "none",
        //     },
        // };
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-38%',
                boxShadow: " 0px 8px  15px 10px #f6f6f6",
                border: "none",
                outline: "none",
                transform: 'translate(-50%, -50%)',
                borderRadius: '15px'
            },
        };
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                    </React.Fragment>
                ) : (


                    <div className="">
                        <div className="password-bg px-2">
                            <BackButton color={this.props.color ? this.props.color : "#fff"} title={"Change password"} />
                        </div>
                        <div className="password-div">
                            <Form onSubmit={this.onSubmit}>
                                <div className="password-sub">
                                    <div className="py-4">
                                        <div className="">
                                            <span style={{
                                                color: "#2F2F2F",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                            }}
                                            >
                                                Old Password
                                            </span>
                                        </div>
                                        <div className="password-input">

                                            <input
                                                type="password"
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.oldPasswordChange(
                                                        value
                                                    );
                                                }}
                                                className="password-input1 py-3 px-2 b-r-10"
                                                placeholder="Enter Old Password"
                                            />
                                        </div>


                                        <div className="mt-2">
                                            <span
                                                style={{
                                                    color: "#2F2F2F",
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                New Password
                                            </span>
                                        </div>
                                        <div className="password-input">

                                            <input
                                                type="password"
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.PasswordNew1(value);
                                                }}
                                                className="password-input1 py-3 px-2 b-r-10"
                                                placeholder="Example"
                                            />
                                        </div>

                                        <div className="mt-2">
                                            <span
                                                style={{
                                                    color: "#2F2F2F",
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Re-enter password
                                            </span>
                                        </div>
                                        <div className="password-input">
                                            <input
                                                type="password"
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.PasswordNew2(value);
                                                }}
                                                className="password-input1 py-3 px-2 b-r-10"
                                                placeholder="Example"
                                            />
                                        </div>
                                        {this.state.notSame &&
                                            <Shake>
                                                <div className='text-center text-danger mt-2'>
                                                    (Password Not Match)
                                                </div>
                                            </Shake>
                                        }
                                    </div>
                                </div>

                                <div className=" position-fixed w-100 bottom-0 py-2 px-3">
                                    <button type="submit" className="btn-password w-100 py-3">
                                        <span
                                            style={{
                                                color: "#fff",
                                                fontWeight: "700",
                                                textAlign: "center",
                                            }}
                                        >
                                            Change
                                        </span>
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                )}
                <Modal
                    isOpen={this.state.modalVisible}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false} //password success modal
                    className=""
                >
                    <div className="sucess-modal py-4 px-4">
                        <p className=" text-cenetr">
                            Password was updated successfully
                        </p>
                        <div
                            className="text-center"
                            onClick={() => {
                                this.setState({ modalVisible: false });
                            }}
                        >
                            <span className="btn  btn-success">OK</span>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.wrongpassmodal}
                    style={customStyles}
                    contentLabel="Example Modal" //wrong password modal
                    ariaHideApp={false}
                    className=""
                >
                    <div className="sucess-modal py-4 px-4">
                        <p className=" text-cenetr">
                            Your Password did not match
                        </p>
                        <div
                            className="text-center"
                            onClick={() => {
                                this.setState({ wrongpassmodal: false });
                            }}
                        >
                            <span className="btn  btn-success">OK</span>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
});

export default connect(mapStateToProps, { changePassword })(ChangePassword);
