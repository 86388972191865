import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../elements/BackButton";
import ContentLoader from "react-content-loader";
import OrderItems from "./OrderItems";
import {
    addProduct,
    emptyCart,
    removeProduct,
    updateCart,
} from "../../../redux/Parent/cart/action";
import { formatPrice } from "../../../helpers/formatPrice";
import {
    emptyCheckOut,
    placeOrder,
} from "../../../redux/Parent/checkout/actions";
import order_success from "../../../assets/Images/order_success.png";

class MyCart extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        loading: false,
        order_placed: false,
    };

    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }

    addProductQuantity = (product) => {
        const { cartProducts, updateCart } = this.props;
        let productAlreadyInCart = false;

        cartProducts.forEach((cp) => {
            if (cp.id === product.id) {
                if (
                    JSON.stringify(cp.selectedaddons) ===
                    JSON.stringify(product.selectedaddons)
                ) {
                    if (cp.max_qty) {
                        if (cp.max_qty != cp.quantity) {
                            cp.quantity += 1;
                        }
                    } else {
                        cp.quantity += 1;
                    }
                    productAlreadyInCart = true;
                }
            }
        });

        if (!productAlreadyInCart) {
            cartProducts.push(product);
        }

        updateCart(cartProducts);
    };

    removeProductQuantity = (product) => {
        const { cartProducts, updateCart } = this.props;

        const index = cartProducts.findIndex(
            (p) =>
                p.id === product.id &&
                JSON.stringify(p) === JSON.stringify(product)
        );
        //if product is in the cart then index will be greater than 0
        if (index >= 0) {
            cartProducts.forEach((cp) => {
                if (cp.id === product.id) {
                    if (JSON.stringify(cp) === JSON.stringify(product)) {
                        if (cp.quantity === 1) {
                            //if quantity is 1 then remove product from cart
                            cartProducts.splice(index, 1);
                        } else {
                            //else decrement the quantity by 1
                            cp.quantity -= 1;
                        }
                    }
                }
            });

            updateCart(cartProducts);
        }
    };

    removeProduct = (product) => {
        // console.log(product);
        // console.log(product.id);

        const { cartProducts, updateCart } = this.props;
        const index = cartProducts.findIndex((cp) => cp.id === product.id);

        // console.log(index);
        cartProducts.splice(index, 1);
        // console.log(cartProducts);
        updateCart(cartProducts);

        this.checkForItemsAvailability();
    };

    getTotalAfterCalculation = () => {
        const { cartTotal, coupon, store_info, tax, totalPrice } = this.props;

        let calc = 0;
        var total_discount = 0;

        calc = formatPrice(parseFloat(totalPrice));

        return calc;
    };
    __placeOrder = () => {
        const { user, placeOrder, coupon, cartProducts, checkout, tax } =
            this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        let vendor_id = JSON.parse(localStorage.getItem("vendor_id"));

        if (user && user.success) {
            this.setState({ loading: true });
            placeOrder(
                user,
                student_id.value,
                cartProducts[0]?.school_id,
                vendor_id,
                cartProducts,
                this.getTotalAfterCalculation(),
                "COD"
            ).then((response) => {
                this.setState({ loading: false });
            });
        }
    };

    static getDerivedStateFromProps(props,state) {
        const { checkout, emptyCheckOut, emptyCart } = props;
        if (checkout && checkout.success) {
            emptyCheckOut();
            emptyCart();
            return {
                order_placed: checkout.success,
            }
        }
        // 
    }
    render() {
        const { user, cartProducts, cartTotal, store_data, totalPrice } =
            this.props;

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 539"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="13"
                                y="75"
                                rx="10"
                                ry="10"
                                width="255"
                                height="80"
                            />
                            <rect
                                x="13"
                                y="170"
                                rx="10"
                                ry="10"
                                width="255"
                                height="80"
                            />
                            <rect
                                x="13"
                                y="474"
                                rx="4"
                                ry="4"
                                width="90"
                                height="10"
                            />
                            <rect
                                x="190"
                                y="470"
                                rx="10"
                                ry="10"
                                width="75"
                                height="15"
                            />
                            <rect
                                x="13"
                                y="490"
                                rx="10"
                                ry="10"
                                width="255"
                                height="40"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="" style={{ marginTop: "2vh" }}>
                            <div className="px-3 w-100">
                                <BackButton title={"My Cart"} />
                            </div>
                            {cartProducts.map((item, index) => (
                                <OrderItems
                                    item={item}
                                    addProductQuantity={this.addProductQuantity}
                                    removeProductQuantity={
                                        this.removeProductQuantity
                                    }
                                    removeProduct={this.removeProduct}
                                    key={item.name + item.id + index}
                                />
                            ))}
                        </div>
                        {cartProducts && cartProducts.length > 0 && (
                            <div className="d-grid gap-2 px-3 position-fixed bottom-10  w-100">
                                <div className="d-flex justify-content-between position-relative">
                                    <span className="cart-total-amt">
                                        Total Amount :
                                    </span>
                                    <span className="cart-total-rs">
                                        Rs.{this.getTotalAfterCalculation()}
                                    </span>
                                </div>
                                <button
                                    onClick={() => this.__placeOrder()}
                                    className="check-out-btn w-100"
                                    size="lg"
                                >
                                    Check Out
                                </button>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {this.state.order_placed && (
                    <div
                        className="d-flex flex-column align-items-center justify-content-center mt-5 appointment-no-data"
                        style={{
                            height: "50vh",
                        }}
                    >
                        <img
                            src={order_success}
                            alt="online-test"
                            style={{
                                width: "100%",
                            }}
                        ></img>
                        <span className="text-center fs-5 fw-500 mt-2">
                            Order Placed Successfully
                        </span>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    totalPrice: state.cart.data.totalPrice,
    cartTotal: state.cart.data.subTotal,
    store_data: state.store_data.store_data,
    user: state.user.parent,
    cartProducts: state.cart.products,
    checkout: state.checkout.checkout,
});

export default connect(mapStateToProps, {
    addProduct,
    removeProduct,
    updateCart,
    emptyCart,
    placeOrder,
    emptyCheckOut,
})(MyCart);
