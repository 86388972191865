import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../elements/BackButton";
import ContentLoader from "react-content-loader";
import { getAllActivity } from "../../../redux/Parent/class/action";

class Activity extends Component {
    state = {
        loading: false,
        activities: [],

    };
    componentDidMount() {
        this.setState({ loading: true });
        const {user} = this.props;
        // console.log(token)
        let student_id = JSON.parse(localStorage.getItem("student_id")).value;
        this.props.getAllActivity(user?.data?.auth_token, student_id).then((response) => {
            let data = response.payload?.activities;
            this.setState({ activities: data });
            this.setState({ loading: false });
        });
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 236 470"
                            backgroundColor="#F3F3F3"
                            foregroundColor="#ecebeb"
                        >
                            <rect
                                x="15"
                                y="65"
                                rx="10"
                                ry="10"
                                width="205"
                                height="65"
                            />
                            <rect
                                x="15"
                                y="150"
                                rx="10"
                                ry="10"
                                width="205"
                                height="65"
                            />
                            <rect
                                x="15"
                                y="235"
                                rx="10"
                                ry="10"
                                width="205"
                                height="65"
                            />
                            <rect
                                x="15"
                                y="320"
                                rx="10"
                                ry="10"
                                width="205"
                                height="65"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div style={{ marginTop: "5%" }}>
                            <BackButton title="Activity" />
                        </div>
                        <div className="d-flex flex-column align-content-around justify-content-around  px-3">
                            {this.state.activities &&
                            this.state.activities.length > 0 ? (
                                <React.Fragment>
                                    {this.state.activities.map(
                                        (activity, index) => (
                                            <div className="activity-card py-2 px-2 b-r-10 mt-4">
                                                <div
                                                    className="activity-content p-2 rounded"
                                                    style={{
                                                        color: "white",
                                                        backgroundColor:
                                                            "#B6BEF2",
                                                    }}
                                                >
                                                    <span>
                                                        Subject -{" "}
                                                        {activity.subject.name}
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-row h-100 justify-content-between align-content-between mt-2">
                                                    <span
                                                        className="activity-content ps-1"
                                                        style={{
                                                            color: "#696A6F",
                                                        }}
                                                    >
                                                        {activity.discription}
                                                    </span>
                                                    <span
                                                        className="activity-date"
                                                        style={{
                                                            color: "#8E96CE",
                                                        }}
                                                    >
                                                        Date:{activity.date}
                                                    </span>
                                                </div>
                                                <div
                                                    className="activity-content ps-1  "
                                                    style={{ color: "#696A6F"}}
                                                >
                                                    {activity.task}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </React.Fragment>
                            ) : (
                                <div className="d-flex align-items-center justify-content-center"
                                style={{ color: "#C24A6B" ,height:'100vh'}}>
                                    No Activity Found
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
});

export default connect(mapStateToProps, { getAllActivity })(Activity);
