import {
    GET_TEACHER_CHAT_LIST,
    GET_TEACHER_SINGLE_CHAT_VIEW,
    SEND_TEACHER_CHAT,
} from "./actionType";

const initialState = {
    chat_list: [],
    chats: [],
    send_teacher_chat: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TEACHER_CHAT_LIST:
            return { ...state, chat_list: action.payload };
        case GET_TEACHER_SINGLE_CHAT_VIEW:
            return { ...state, chats: action.payload };
        case SEND_TEACHER_CHAT:
            return { ...state, send_teacher_chat: action.payload };
        default:
            return state;
    }
}
