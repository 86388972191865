import React, { Component } from "react";
import { connect } from "react-redux";
import { FaPlay } from "react-icons/fa";
import star from "../../../assets/Images/star.svg";
import student from "../../../assets/Images/Student.svg";
import level from "../../../assets/Images/Level.svg";
import liveClass from "../../../assets/Images/live-class.png";
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";
class SingleLiveClass extends Component {
    state = {
        loading: false,
    };
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 600"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            {/* Only SVG shapes */}
                            <rect
                                x="13"
                                y="40"
                                rx="10"
                                ry="10"
                                width="255"
                                height="255"
                            />
                            <rect
                                x="13"
                                y="170"
                                rx="10"
                                ry="10"
                                width="255"
                                height="110"
                            />
                            <rect
                                x="13"
                                y="300"
                                rx="10"
                                ry="10"
                                width="255"
                                height="80"
                            />
                            <rect
                                x="13"
                                y="400"
                                rx="5"
                                ry="5"
                                width="255"
                                height="10"
                            />
                            <rect
                                x="13"
                                y="500"
                                rx="10"
                                ry="10"
                                width="255"
                                height="40"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div
                            className=""
                            style={{ backgroundColor: "#f5f5f5" }}
                        >
                            <BackButton title={"Live Class"} />
                        </div>
                        <div className="single-live-section px-2 pt-3">
                            <div className="">
                                <div
                                    className="single-live-banner"
                                    style={{ background: `url(${liveClass})` }}
                                >
                                    <div className="single-live-btn">
                                        <FaPlay />
                                        <button className="play-btn">
                                            Maths Live Class
                                        </button>
                                    </div>
                                </div>
                                <div className="single-live-details px-2 pt-2">
                                    <h1
                                        style={{
                                            fontSize: "1em",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Maths Class 2 - Portion: Multiplication{" "}
                                    </h1>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            marginTop: "3px",
                                        }}
                                    >
                                        Teacher - sindhu miss
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            marginTop: "3px",
                                        }}
                                    >
                                        Duration - 1 Hour
                                    </span>
                                </div>
                                <div className="single-live-icons">
                                    <div className="">
                                        <img src={star} alt="class-report" />
                                        <span className="icon-text">4.8</span>
                                    </div>
                                    <div className="">
                                        <img src={student} alt="icon" />
                                        <span className="icon-text">
                                            8 Participents
                                        </span>
                                    </div>
                                    <div className="">
                                        <img src={level} alt="icon" />
                                        <span className="icon-text">
                                            3rd Class
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-grid gap-2 px-2 position-fixed bottom-10 w-100">
                            <button className="single-live-join-btn btn">
                                Join Now
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SingleLiveClass);
