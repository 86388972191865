import React, { Component } from 'react'
import { connect } from 'react-redux'
import maths from '../../../assets/Images/maths.png'
import qstn1 from '../../../assets/Images/qstn1.png'
import qstn2 from '../../../assets/Images/qstn2.png'
import qstn3 from '../../../assets/Images/qstn3.png'
import { DatePicker } from 'antd';
import ContentLoader from 'react-content-loader'
import BackButton from '../../../elements/BackButton'
import { getSingleSubject } from '../../../redux/Parent/class/action'
import Truncate from 'react-truncate'
import { IMAGE_BASE_URL, WEBSITE_BASE_URL } from '../../../api'
import moment from "moment";
import noStudy from '../../../assets/Images/Studying.png'
import noStudy2 from '../../../assets/Images/Studying-2.png'

class SingleSubject extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id,
        type: false,
        subject: [],
        studyMaterials: []
        // date: ''
    }
    componentDidMount() {
        if (this.props.match.params.type == 'red') {
            this.setState({ type: true })
        } else {
            this.setState({ type: false })
        }
        this.setState({ loading: true });
        const { user } = this.props;
        this.props.getSingleSubject(user.data.auth_token, this.props.match.params.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, subject: response.payload.subject, studyMaterials: response.payload.studyMaterials });
            }
        })
    }

    // getDate = () => {
    //     let date;
    //     if (this.state.date) {
    //         date = this.state.date;
    //     } else {
    //         date = moment().format('Y-M-D');
    //     }
    //     return date
    // }
    render() {
        const { subject, type, studyMaterials } = this.state;
        return (

            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader viewBox="0 0 280 550" backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="13" y="66" rx="10" ry="10" width="255" height="130" />
                                <rect x="13" y="218" rx="10" ry="10" width="255" height="50" />
                                <rect x="13" y="300" rx="10" ry="10" width="255" height="75" />
                                <rect x="180" y="395" rx="10" ry="10" width="90" height="30" />
                                <rect x="13" y="440" rx="10" ry="10" width="255" height="75" />
                            </ContentLoader>

                        </React.Fragment>
                    ) : (
                        <React.Fragment>

                            <BackButton title={subject.name} />

                            <div className='mt-5 px-3' style={{ paddingBottom: '5vw' }}>
                                <div className='subject d-flex justify-content-between' style={type ? { background: 'linear-gradient(90deg, #DD5E89 0%, #F7BB97 100%)' } : { background: 'linear-gradient(90deg, #4CA1AF 0%, #C4E0E5 100%)' }}>
                                    <div className='sub-image2'>
                                        <img src={WEBSITE_BASE_URL + subject?.subject_category?.Image} alt="social science" />
                                    </div>
                                    <div className='flex-grow-1'  style={{ textAlign: 'end' }}>
                                        <h2 style={type ? { margin: '6px 6px 0px 6px', fontSize: '20px', fontWeight: '500', color: '#FAE9EB' } : { margin: '6px 6px 0px 6px', fontSize: '20px', fontWeight: '500', color: '#14525D' }}>{subject?.name}</h2>
                                        <div style={type ? { marginLeft: '6px', fontSize: '14px', fontWeight: '400', color: '#FAE9EB' } : { marginLeft: '6px', fontSize: '14px', fontWeight: '400', color: '#14525D' }}>
                                            <Truncate lines={2}>
                                                {subject?.short_note}
                                            </Truncate>
                                        </div>
                                    </div>
                                </div>
                                {studyMaterials && studyMaterials.length > 0 ? (
                                    studyMaterials.map((item, index) => (
                                        <div className='mt-4 p-3' style={type ? { backgroundColor: '#dd5e895e', borderRadius: '12px' } : { backgroundColor: '#52a4b25e', borderRadius: '12px' }}>
                                            <div className="d-flex justify-content-between">
                                                <h6>{item.name}</h6>
                                            </div>
                                            <div>
                                                <Truncate lines={3} >
                                                    {item.description}
                                                </Truncate>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                <div style={type ? { marginTop: '2px', fontSize: '1em', fontWeight: '600', color: '#a9496a' } : { marginTop: '2px', fontSize: '1em', fontWeight: '600', color: '#14525D' }}>{item.class.name} Class</div>
                                                {/* {type ?"btn subject-download-red position-relative":"btn subject-download position-relative"} */}
                                                <a href={IMAGE_BASE_URL + item?.file} target="_blank" download className={type ? "btn subject-download-red position-relative" : "btn subject-download position-relative"} >
                                                    <span >Download</span>
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='d-flex flex-column align-items-center text-center mt-3'>
                                        <img src={type ? noStudy : noStudy2} style={{ width: '100%' }} />
                                        <span className="text-center fw-bolder mt-2">No Study Materials</span>
                                    </div>
                                )}

                            </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent
})


export default connect(mapStateToProps, {
    getSingleSubject
})(SingleSubject)
