import { GET_TEACHER_HOME_DATA } from "./actionType";
const initialState = {
    teacher_home: {},
};
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TEACHER_HOME_DATA:
            return { ...state, teacher_home: action.payload };
        default:
            return state;
    }
}
