import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    addProduct,
    loadCart,
    removeProduct,
    updateCart,
} from "../../../../redux/Parent/cart/action";
class FloatCart extends Component {
    state = {
        active_home: false,
        active_explore: false,
        active_cart: false,
        active_account: false,
        cart_item_count: null,

        isOpen: false,
        maxQtyReached: false,
        item: [],
        open: false,
        update: true,
    };
    componentDidMount() {
        if (this.props.active_home === true) {
            this.setState({ active_home: true });
        }
        if (this.props.active_explore === true) {
            this.setState({ active_explore: true });
        }
        if (this.props.active_cart === true) {
            this.setState({ active_cart: true });
        }
        if (this.props.active_account === true) {
            this.setState({ active_account: true });
        }

        const { cartProducts } = this.props;
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.newProduct !== this.props.newProduct) {
            this.addProduct(nextProps.newProduct);
        }

        if (nextProps.productToRemove !== this.props.productToRemove) {
            this.removeProduct(nextProps.productToRemove);
        }
    }
    openFloatCart = () => {
        this.setState({ isOpen: true });
    };

    closeFloatCart = () => {
        this.setState({ isOpen: false });
    };

    addProduct = (product) => {
        const { cartProducts, updateCart, type } = this.props;
        console.log("HEre");
        //get restaurant id and save to localStorage as active restaurant

        let productAlreadyInCart = false;
        cartProducts.forEach((cp) => {
            // first check if the restaurent id matches with items in cart
            // if restaurant id doesn't match, then remove all products from cart
            // then continue to add the new product to cart
            if (cp.restaurant_id === product.restaurant_id) {
                // then add the item to cart or increment count
                if (cp.type == type && cp.id === product.id) {
                    //check if product has customizations, and if the customization matches with any
                    if (
                        JSON.stringify(cp.selectedaddons) ===
                        JSON.stringify(product.selectedaddons)
                    ) {
                        // increment the item quantity by 1

                        cp.quantity += 1;
                        productAlreadyInCart = true;
                    }
                }
            } else {
                // else if restaurant id doesn't match, then remove all products from cart
                this.setState({ removeProductFromPreviousRestaurant: true });

                setTimeout(() => {
                    this.setState({
                        removeProductFromPreviousRestaurant: false,
                    });
                }, 4 * 1000);

                cartProducts.splice(0, cartProducts.length);
            }
        });

        if (!productAlreadyInCart) {
            cartProducts.push(product);
        }

        updateCart(cartProducts);
        this.openFloatCart();
    };

    removeProduct = (product) => {
        const { cartProducts, updateCart ,type} = this.props;
        console.log("HEre");

        const index = cartProducts.findIndex((p) => p.id === product.id);

        //if product is in the cart then index will be greater than 0
        if (index >= 0) {
            cartProducts.forEach((cp) => {
                if ( cp.type == type && cp.id === product.id) {
                    if (cp.quantity === 1) {
                        //if quantity is 1 then remove product from cart
                        cartProducts.splice(index, 1);
                    } else {
                        //else decrement the quantity by 1
                        cp.quantity -= product.quantity;
                    }
                }
            });

            updateCart(cartProducts);
            if (cartProducts.length < 1) {
                this.closeFloatCart();
                localStorage.removeItem("activeRestaurant");
            }
        }
    };
    getTotalItemsInCart = () => {
        if (
            localStorage.getItem("countQuantityAsTotalItemsOnCart") === "true"
        ) {
            let total = 0;
            this.props.cartProducts.forEach((item) => {
                total += item.quantity;
                console.log(total);
            });
            return total;
        } else {
            return this.props.cartTotal.productQuantity;
        }
    };
    handleCartPopupClose = () => {
        this.setState({ cartPopup: false });
    };
    render() {
        const { cartTotal, cartProducts } = this.props;

        return (
            <>
                {this.props.hidden_footer === false && (
                    <div className="footer">
                        <div
                            className="position-relative"
                            style={{
                                background: "#FFFFFF",
                                boxShadow: "0px -1px 4px rgba(0, 0, 0, 0.25)",
                            }}
                        >
                            <div className="">
                                <button className="btn-single-store position-fixed">
                                    <span
                                        style={{
                                            color: "#fff",
                                            fontWeight: "700",
                                        }}
                                    >
                                        View Cart
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    cartTotal: state.cart.data,
    user: state.user.user,
});
export default connect(mapStateToProps, {
    loadCart,
    removeProduct,
    addProduct,
    updateCart,
})(FloatCart);
