import { PLACE_ORDER ,CHECKOUT_EMPTY} from "./actionTypes";
import { PLACE_ORDER_URL } from "../../../api";

import Axios from "axios";
import { updateCart } from "../cart/action";

export const placeOrder = (
	user,
	student_id,
	school_id,
	vendor_id,
	order,
	total,
	method,
) => (dispatch, getState) => {
	return Axios.post(PLACE_ORDER_URL, {
		token: user?.data?.auth_token,
		user: user,
		student_id:student_id,
		school_id:school_id,
		vendor_id:vendor_id,
		order: order,
		total: total,
		method: method,
	})
		.then((response) => {
			const checkout = response.data;
			if (checkout) {
				dispatch({ type: PLACE_ORDER, payload: checkout });

				const state = getState();
				// console.log(state);
				const cartProducts = state.cart.products;
				// const user = state.user.user;
				localStorage.removeItem("orderComment");
				localStorage.removeItem("tables");
				localStorage.removeItem("tableReserved");

				// for (let i = cartProducts.length - 1; i >= 0; i--) {
				// 	// remove all items from cart
				// 	cartProducts.splice(i, 1);
				// }

				// dispatch(updateCart(cartProducts));

				localStorage.removeItem("appliedCoupon");

			} else {
				return checkout;
			}
		})
		.catch(function(error) {
			return error.response;
		});
};

export const emptyCheckOut = (checkout = []) => ({
	type: CHECKOUT_EMPTY,
	payload: checkout,
  });