import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import BackButton from '../../../elements/BackButton'
import { viewMark, getMark } from './../../../redux/Parent/marks/action';
import { Form } from 'react-bootstrap';


class DownloadGradeCard extends Component {
    state = {
        term_id: '',
        selectedTerm: "",
        student_id: JSON.parse(localStorage.getItem('student_id')),
        tab: false
    };

    componentDidMount() {
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem('student_id'));
        this.props.viewMark(user.data.auth_token, student_id.value);
    }

    __submit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { term_id } = this.state;
        if (term_id) {
            let student_id = JSON.parse(localStorage.getItem('student_id'));
            localStorage.setItem('term_id', JSON.stringify({ id: this.state.term_id }));
            this.props.getMark(user.data.auth_token, student_id.value, this.state.term_id)
                .then((response) => {
                    if (response.payload.success) {
                        this.props.history.push('/grade-card')
                    }
                })
        } else {
            this.setState({ tab: true })
        }


    }
    selectTerm = (selectedTerm) => {
        console.log(selectedTerm)
        this.setState({ selectedTerm, term_id: selectedTerm.value, tab: false });


    }
    render() {
        const { marks } = this.props;

        const term = this.props.marks?.term?.map((term) => ({
            value: term.id,
            label: term.name
        }))
        return (
            <React.Fragment>
                <BackButton title={"Download Grade Card"} />
                <Form onSubmit={(e) => this.__submit(e)}>
                    <div className="p-3">
                        <div className='d-flex justify-content-between mt-3 homework-input'>
                            <div className='text-muted'> Class  </div>
                            <div style={{ fontWeight: '600' }}>{marks?.students?.student_class?.name} </div>
                        </div>

                        <div className='d-flex justify-content-between mt-3 homework-input'>
                            <div className='text-muted'> Section  </div>
                            <div style={{ fontWeight: '600' }}>{marks?.students?.section?.name} </div>
                        </div>

                        <div className='d-flex justify-content-between mt-3 homework-input'>
                            <div className='text-muted'> Student  </div>
                            <div style={{ fontWeight: '600' }}> {this.state.student_id?.label}  </div>
                        </div>
                        <Select className="text-muted attendence-input mt-3" styles={customStyles} placeholder='Term' options={term} value={this.state.selectedTerm} onChange={this.selectTerm} />
                        {this.state.tab &&
                            <div className='text-center text-danger'>
                                No Term Selected
                            </div>
                        }
                    </div>

                    <div className="gap-2 px-3 attendence-btn-div w-100">
                        <button className="attendence-btn w-100 py-3" >
                            Submit
                        </button>
                    </div>

                </Form>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    marks: state.mark.mark_info,

})

export default connect(mapStateToProps, { viewMark, getMark })(DownloadGradeCard)



const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        border: 'none', // tried border: 'none'
        boxShadow: 'none', // tried border: 'none'
        outline: 'none',

    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "100%",
        border: "none",
        boarderRadius: "14px",
        fontSize: '13px',
        borderStyle: 'none',
        background: 'rgba(190, 175, 179, 0.05)',
        outline: 'none',
        fontWeight: '400',
        lineHeight: '14px',
        letterSpacing: '1px',
        color: '#86878E',
        // boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1) inset",
        // shadowOpacity: 0.5,
        // shadowRadius: 50,

    })
}
