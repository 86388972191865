import React, { Component } from "react";
import { connect } from "react-redux";
import note from "../../../assets/Images/note.png";

class SingleSubjectNotes extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="h-100vh">
                    <div className="mt-5 px-2">
                        <div
                            className=" single-subject-note "
                            style={{ backgroundImage: `url(${note})` }}
                        >
                            <button className="note-download">Download</button>
                        </div>
                        <div className="px-2 d-flex justify-content-between">
                            <span
                                style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    color: "#524A4D",
                                }}
                            >
                                Model question paper
                            </span>
                            <span
                                style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#A09A9C",
                                }}
                            >
                                Class 10
                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(SingleSubjectNotes);
