import React, { Component } from 'react'
import { IoMdAddCircle } from 'react-icons/io'
import { connect } from 'react-redux'
import BackButton from '../../../../elements/BackButton'
import noImage from '../../../../assets/Images/noImage.png'
import { editTeacherProfile } from '../../../../redux/Teacher/user/action'
import { Form } from 'react-bootstrap'
import { IMAGE_BASE_URL } from '../../../../api'
import Profile from "../../../../assets/Images/no-user.png"
import ProgressiveImage from 'react-progressive-image'
export class EditProfile extends Component {
    state = {
        teacherProfile: {},
        file: "",
    }
    componentDidMount() {
        const { teacher } = this.props
        const teacherDetails = {
            id: teacher.data?.id,
            image: teacher.data?.image,
            first_name: teacher.data?.first_name,
            last_name: teacher.data?.last_name,
            email: teacher.data?.email,
            phone: teacher.data?.phone,
            date_of_birth: teacher.data?.date_of_birth,
            place: teacher.data?.staff?.place,
            qualification: teacher.data?.staff?.qualification,
            experience: teacher.data?.staff?.experience,
        }
        this.setState({ teacherProfile: teacherDetails });
    }
    __changeProfileDetails = (value, name) => {
        const details = this.state.teacherProfile;
        details[name] = value;
        this.setState({ teacherProfile: details });
        // console.log(details)
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { teacherProfile } = this.state;
        let formData = new FormData();
        formData.append('token', this.props.teacher.data.auth_token);
        formData.append('id', teacherProfile.id);
        formData.append('image', teacherProfile.image);
        formData.append('first_name', teacherProfile.first_name);
        formData.append('last_name', teacherProfile.last_name);
        formData.append('email', teacherProfile.email);
        formData.append('phone', teacherProfile.phone);
        formData.append('date_of_birth', teacherProfile.date_of_birth);
        formData.append('place', teacherProfile.place);
        formData.append('qualification', teacherProfile.qualification);
        formData.append('experience', teacherProfile.experience);
        this.props.editTeacherProfile(formData).then((response) => {
            if (response) {
                // console.log(response);
                if (response.payload.success) {
                    this.props.history.goBack();
                }
            }
        })

    }
    render() {
        const { teacherProfile } = this.state;
        console.log(teacherProfile)
        return (
            <React.Fragment>
                <Form onSubmit={this.onSubmit}>
                    <div className="position-relative">
                        <div className="edit-parent-bg">
                            <div className='px-3 py-2'>
                                <BackButton color="white" />
                            </div>
                        </div>
                        <div className="position-absolute edit-parent-pic" style={{ boarderRadius: "30px 30px 0px 0px", }}>
                            <div className='d-flex flex-row justify-content-center posititon-relative'>
                                <div style={{ marginTop: '-40px' }}>
                                    <ProgressiveImage
                                        delay={20}
                                        placeholder={
                                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                        }
                                    >
                                        {(src, loading) => (
                                            <img className="profile-img-style p-2"
                                                src={
                                                    this.state.file
                                                        ? this.state.file
                                                        : teacherProfile.image
                                                            ? IMAGE_BASE_URL + teacherProfile.image
                                                            : Profile
                                                }
                                                alt="class-report" />
                                        )}
                                    </ProgressiveImage>
                                </div>
                                <div className="fs-5 position-absolute " style={{ marginTop: '5px', marginLeft: '80px', color: '#C34B6E' }}>
                                    <label
                                        for="image"
                                        className="fs-6 user-select-none mt-2"
                                        style={{ color: "#3795e6" }}
                                    >
                                        <IoMdAddCircle />
                                    </label>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        id="image"
                                        onChange={(e) => {
                                            let file = e.target.files[0];
                                            this.setState({
                                                file: URL.createObjectURL(file),
                                            });
                                            this.__changeProfileDetails(file, "image");
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='px-2'>
                        <div className="position-relative px-2">
                            <div className=" flex-column p-1">
                                <span className="text-black ps-3">First Name:</span>
                                <div className='profile-edit-form mt-2 pt-2 '>
                                    <input className='input-box-edit '
                                        placeholder='Enter your first name'
                                        value={teacherProfile.first_name}
                                        type="text"
                                        style={{ border: 'none', outline: 'none' }}
                                        onChange={(e) => { this.__changeProfileDetails(e.target.value, 'first_name') }}
                                    />
                                </div>
                            </div>
                            <div className=" flex-column p-1">
                                <span className="text-black ps-3">Last Name:</span>
                                <div className='profile-edit-form mt-2 pt-2 '>
                                    <input className='input-box-edit '
                                        placeholder='Enter your last name'
                                        value={teacherProfile.last_name}
                                        type="text"
                                        style={{ border: 'none', outline: 'none' }}
                                        onChange={(e) => { this.__changeProfileDetails(e.target.value, 'last_name') }}
                                    />
                                </div>
                            </div>
                            <div className="  flex-column p-1 ">
                                <span className="text-black p-3">Email:</span>
                                <div className='profile-edit-form mt-2 pt-2'>
                                    <input className=' input-box-edit' placeholder='Enter your email'
                                        value={teacherProfile.email}
                                        type="email" style={{ border: 'none', outline: 'none' }}
                                        onChange={(e) => { this.__changeProfileDetails(e.target.value, 'email') }}
                                    />
                                </div>
                            </div>
                            <div className=" flex-column p-1 ">
                                <span className="text-black p-3">Phone:</span>
                                <div className='profile-edit-form mt-2 pt-2'>
                                    <input className='input-box-edit ' placeholder='Enter your phone number' minLength={10} maxLength={10}
                                        value={teacherProfile.phone} pattern="[6-9][0-9]{9}" title='Phone Number Should Start With [6-9] Max Length 10'
                                        type="tel" style={{ border: 'none', outline: 'none' }}
                                        onChange={(e) => { this.__changeProfileDetails(e.target.value, 'phone') }}
                                    />
                                </div>
                            </div>
                            <div className=" flex-column p-1 ">
                                <span className="text-black p-3">Date of birth:</span>
                                <div className='profile-edit-form mt-2 pt-2'>
                                    <input className=' input-box-edit' placeholder='Enter your DOB'
                                        value={teacherProfile.date_of_birth}
                                        type="dob" style={{ border: 'none', outline: 'none' }}
                                        onChange={(e) => { this.__changeProfileDetails(e.target.value, 'date_of_birth') }}
                                    />
                                </div>
                            </div>
                            <div className=" flex-column p-1 ">
                                <span className="text-black p-3">Place:</span>
                                <div className='profile-edit-form mt-2 pt-2'>
                                    <input className=' input-box-edit' placeholder='Enter your place'
                                        value={teacherProfile.place}
                                        type="text" style={{ border: 'none', outline: 'none' }}
                                        onChange={(e) => { this.__changeProfileDetails(e.target.value, 'place') }}
                                    />
                                </div>
                            </div>
                            <div className=" flex-column p-1 ">
                                <span className="text-black p-3">Qualification:</span>
                                <div className='profile-edit-form mt-2 pt-2'>
                                    <input className=' input-box-edit' placeholder='Enter your qualification'
                                        value={teacherProfile.qualification}
                                        type="text" style={{ border: 'none', outline: 'none' }}
                                        onChange={(e) => { this.__changeProfileDetails(e.target.value, 'qualification') }}
                                    />
                                </div>
                            </div>
                            <div className=" flex-column p-1 ">
                                <span className="text-black p-3">Work experience:</span>
                                <div className='profile-edit-form mt-2 pt-2'>
                                    <input className=' input-box-edit' placeholder='Enter your work experience'
                                        value={teacherProfile.experience}
                                        type="text" style={{ border: 'none', outline: 'none' }}
                                        onChange={(e) => { this.__changeProfileDetails(e.target.value, 'experience') }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className=" d-grid gap-2 bg-white px-3 w-100 bottom-0 mt-4 pb-3">
                        <button type='submit' className="edit-profile-btn">Submit</button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
})

export default connect(mapStateToProps, { editTeacherProfile })(EditProfile)
