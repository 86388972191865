import Axios from "axios";
import { GET_TEACHER_MARK_URL } from "../../../api";
import { GET_TEACHER_MARK } from "./actionType";


export const getTeacherMarkData = (token,class_id, section_id, term_id) => (dispatch) => {
    return Axios.post(GET_TEACHER_MARK_URL, {token,class_id, section_id, term_id })
        .then((response) => {
            let teacherMark = response.data;
            return dispatch({
                type: GET_TEACHER_MARK,
                payload: teacherMark
            })
        })
}