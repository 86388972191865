import {
    GET_PARENT_CHAT_LIST,
    GET_PARENT_SINGLE_CHAT_VIEW,
    SEND_PARENT_CHAT,
} from "./actionType";

import {
    GET_PARENT_CHAT_LIST_URL,
    GET_PARENT_SINGLE_CHAT_VIEW_URL,
    SEND_PARENT_CHAT_URL
} from "../../../api";
import Axios from "axios";

export const getParentChatList = (token, student_id) => (dispatch) => {
    return Axios.post(GET_PARENT_CHAT_LIST_URL, { token, student_id }).then(
        (response) => {
            let chat_list = response.data;
            return dispatch({
                type: GET_PARENT_CHAT_LIST,
                payload: chat_list,
            });
        }
    );
};

export const getParentSingleChatView =
    (token, parent_id, teacher_id) => (dispatch) => {
        return Axios.post(GET_PARENT_SINGLE_CHAT_VIEW_URL, {
            token,
            parent_id,
            teacher_id,
        }).then((response) => {
            let chats = response.data;
            return dispatch({
                type: GET_PARENT_SINGLE_CHAT_VIEW,
                payload: chats,
            });
        });
    };

export const sendParentChat =
    (token, parent_id, teacher_id,convo_id,message) => (dispatch) => {
        return Axios.post(SEND_PARENT_CHAT_URL, {
            token,
            parent_id,
            teacher_id,
            convo_id,
            message
        }).then((response) => {
            let send_parent_chat = response.data;

            return dispatch({
                type: SEND_PARENT_CHAT,
                payload: send_parent_chat,
            });
        });
    };
