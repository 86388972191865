import Axios from "axios";
import {
    GET_TEACHER_SCHOOL_MEDIA,
    GET_MEDIA_WISE_GALLERY,
} from "./actionType"
import {
    GET_TEACHER_SCHOOL_MEDIA_URL,
    ADD_SCHOOL_MEDIA_URL,
    GET_MEDIA_WISE_GALLERY_URL,
} from '../../../api/index'
export const getTeacherSchoolMedia = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_SCHOOL_MEDIA_URL, { token })
        .then((response) => {
            let schoolmedia = response.data;
            return dispatch({ type: GET_TEACHER_SCHOOL_MEDIA, payload: schoolmedia })
        })
}
export const addSchoolMedia = (formData) => () => {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }
    return Axios.post(ADD_SCHOOL_MEDIA_URL, formData, config)
}
export const getMediaWiseGallery = (token, id) => (dispatch) => {
    return Axios.post(GET_MEDIA_WISE_GALLERY_URL, { token, id })
        .then((response) => {
            let gallery = response.data
            return dispatch({ type: GET_MEDIA_WISE_GALLERY, payload: gallery })
        })
}
