import React, { Component } from "react";
import { connect } from "react-redux";
import img1 from "../../../assets/Images/Vector.png";
import img2 from "../../../assets/Images/face.png";
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";
import { getAllRemarks } from "../../../redux/Parent/user/action";
import ProgressiveImage from "react-progressive-image";
import { IMAGE_BASE_URL } from "../../../api";
import img from "../../../assets/Images/online-test-nodata.svg";
class Review extends Component {
    state = {
        loading: false,
    };

    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.getAllRemarks(user?.data?.auth_token, student_id.value);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }

    render() {
        const { remarks } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 539"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="13"
                                y="75"
                                rx="10"
                                ry="10"
                                width="255"
                                height="80"
                            />
                            <rect
                                x="13"
                                y="170"
                                rx="10"
                                ry="10"
                                width="255"
                                height="80"
                            />
                            <rect
                                x="13"
                                y="270"
                                rx="10"
                                ry="10"
                                width="255"
                                height="80"
                            />
                            <rect
                                x="13"
                                y="370"
                                rx="10"
                                ry="10"
                                width="255"
                                height="80"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="px-2 w-100">
                            <BackButton title={"Remarks"} />
                        </div>
                        {remarks?.remarks?.length >0 ? (
                        <div className="d-flex flex-column align-content-around justify-content-around h-50 mt-5 px-3">
                            {remarks?.remarks?.map((remark) => (
                                <div className="review-card py-3 px-2 b-r-10 mt-4">
                                    <div className="d-flex flex-row justify-content-around align-items-center">
                                        <div className="flex-grow-1 ps-2">
                                            <div className="d-flex flex-column h-50 justify-content-between align-content-between">
                                                <img
                                                    src={img1}
                                                    className="quote-icon "
                                                    alt="class-report"
                                                />
                                                <span className="fw-nomal ps-2 fs-6 mt-2">
                                                    {remark?.remark}
                                                </span>
                                                <span className=" fs-5 mt-2 fw-500">
                                                    {remark?.staff?.user?.name}{" "}
                                                    - {}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-1 pe-2">
                                            <ProgressiveImage
                                                delay={20}
                                                src={
                                                    IMAGE_BASE_URL +
                                                    remark?.staff?.user?.image
                                                }
                                                placeholder={
                                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                                }
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            width: "20vw",
                                                            height: "20vw",
                                                            borderRadius: "50%",
                                                            objectFit: "cover",
                                                            backgroundRepeat:
                                                                "round",
                                                        }}
                                                        className=""
                                                        alt="teacher"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        ) : (
                            <div className="text-center mt-5">
                                <img src={img} alt="review"></img>
                                <span className="text-center fs-5 fw-500">No Remarks Available</span>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    remarks: state.user.remarks,
});

export default connect(mapStateToProps, { getAllRemarks })(Review);
