import {
  LOAD_CART,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  UPDATE_CART,
  EMPTY_CART
} from "./actionTypes";

const initialState = {
  products: [],
  data: {
    productQuantity: 0,
    totalPrice: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_CART:
      return {
        ...state,
        products: action.payload,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        productToAdd: Object.assign({}, action.payload),
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        productToRemove: Object.assign({}, action.payload),
      };
    case UPDATE_CART:
      return {
        ...state,
        data: action.payload,
      };
      case EMPTY_CART:
        return {
          ...state,
          data: action.payload.checkout,products:action.payload.products
        };
    default:
      return state;
  }
}
