import { combineReducers } from "redux";
import classReducer from './Parent/class/reducer';
import parentHomeReducer from './Parent/home/reducer';
import userReducer from './Parent/user/reducer';
import teacherReducer from './Teacher/user/reducer';
import onlineTestReducer from './Parent/onlineTest/reducer';
import markReducer from './Parent/marks/reducer';
import appointmentReducer from './Parent/appointment/reducer';
import storeReducer from './Parent/store/reducer';
import cartReducer from "./Parent/cart/reducer";
import checkoutReducer from "./Parent/checkout/reducer";
import chatReducer from "./Parent/chat/reducer";
import teacherMarkReducer from "./Teacher/mark/reducer";
import onlineExamReducer from "./Teacher/onlineTest/reducer";
import studentReducer from "./Teacher/student/reducer";
import leaveReducer from "./Teacher/leave/reducer";
import teacherChatReducer from "./Teacher/chat/reducer";
import mediaReducer from "./Teacher/media/reducer";
import teacherHomeReducer from "./Teacher/home/reducer";

export default combineReducers({
    user: userReducer,
    class: classReducer,
    parentHome: parentHomeReducer,
    onlineTest: onlineTestReducer,
    mark: markReducer,
    appointment: appointmentReducer,
    store_data: storeReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    chat: chatReducer,


    // teacher app
    teacher: teacherReducer,
    teacherHome: teacherHomeReducer,
    student: studentReducer,
    leave: leaveReducer,
    onlineExam: onlineExamReducer,
    teacherMark: teacherMarkReducer,
    teacherChat: teacherChatReducer,
    media: mediaReducer,
});
