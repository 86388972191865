export const GET_TUTORIALS = "GET_TUTORIALS";
export const GET_HOMEWORKS = "GET_HOMEWORKS";
export const GET_SINGLE_HOME_WORKS = "GET_SINGLE_HOME_WORKS";
export const UPLOAD_HOME_WORK = "UPLOAD_HOME_WORK";
export const GET_ALL_SUBJECTS = "GET_ALL_SUBJECTS";
export const GET_SINGLE_SUBJECT = "GET_SINGLE_SUBJECT";
export const GET_ALL_TEACHERS = "GET_ALL_TEACHERS";
export const GET_SINGLE_TEACHER = "GET_SINGLE_TEACHER";
export const GET_FEES_DATA = "GET_FEES_DATA";
export const GET_STUDENT_TIME_TABLE = "GET_STUDENT_TIME_TABLE"
export const VIEW_ALL_FEES = "VIEW_ALL_FEES";
export const GET_ONLINE_CLASSES = "GET_ONLINE_CLASSES";
export const GET_EXAM_SCHEDULE = "GET_EXAM_SCHEDULE"
export const GET_EVENTS = "GET_EVENTS";
export const GET_ATTENDENCE_DATA = "GET_ATTENDENCE_DATA";
export const DOWNLOAD_STUDENT_ATTENDENCE = "DOWNLOAD_STUDENT_ATTENDENCE";
export const GET_ALL_ACTIVITY = "GET_ALL_ACTIVITY";
export const GET_TODAY_IN_CLASS = "GET_TODAY_IN_CLASS";
export const GET_OCCASIONAL_VIDEO = "GET_OCCASIONAL_VIDEO";
export const GET_ALL_MEDIA = "GET_ALL_MEDIA";
export const GET_MEDIA_WISE_IMAGES = "GET_MEDIA_WISE_IMAGES";
export const GET_ANSWERS = "GET_ANSWERS"
export const GET_GALLERY_IMAGES = "GET_GALLERY_IMAGES";
