import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../../elements/BackButton";
import EachStudentAttendence from "./EachStudentAttendence";
import { Switch } from "antd";

export class MarkAttendence extends Component {
    render() {
        return (
            <React.Fragment>
                <BackButton title="Attendence" />
                <div className="px-2 mt-5">
                    <div className="mt-2 mb-4 d-flex justify-content-end">
                        <div className="d-flex flex-column align-items-center">
                            <div>F/P</div>
                            <Switch defaultChecked className="ms-1 me-2 bg-success" />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <div>F/A</div>
                            <Switch defaultChecked className="ms-1 me-2 bg-danger" />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <div>H/P</div>
                            <Switch defaultChecked className="ms-1 me-2 bg-info" />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <div>F/P</div>
                            <Switch defaultChecked className="ms-1 me-2 bg-info" />
                        </div>
                    </div>
                    <EachStudentAttendence />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MarkAttendence);
