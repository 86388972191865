import Axios from 'axios';
import { VIEW_MARK_URL } from '../../../api';
import { GET_MARK, VIEW_MARK } from './actionType';
import { GET_MARK_URL } from './../../../api/index';


export const viewMark = (token,student_id) => (dispatch) => {
    return Axios.post(VIEW_MARK_URL, {token,student_id}).then((response) => {
        let student = response.data;
        return dispatch({type: VIEW_MARK, payload: student});
    });
};

export const getMark = (token,student_id,term_id) => (dispatch) => {
    return Axios.post(GET_MARK_URL, {token,student_id,term_id}).then((response) => {
        let mark = response.data;
        return dispatch({type: GET_MARK, payload: mark})
    });
};