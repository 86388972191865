import React, { Component } from "react";
import { connect } from "react-redux";
// import profileimg from "../../../assets/Images/allStudentDetailProfile.png";
// import ContentLoader from "react-content-loader";
import { IMAGE_BASE_URL } from "../../../api";
import ProgressiveImage from "react-progressive-image";
import moment from 'moment';
import noImage from '../../../assets/Images/noImage.png'
import { HiOutlineChevronLeft } from 'react-icons/hi';

class StudentInfo extends Component {
    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        const { student, classTeacher } = this.props;
        return (
            <React.Fragment>
                <div className='teacher-image position-relative' >
                    <div className='p-2 pt-3 fw-bold' style={{ borderRadius: '100px' }} onClick={() => this.onHandleBack()}>
                        <span className='fw-bold'>
                            <HiOutlineChevronLeft size="1.5em" color={"#fff"} />
                        </span>
                    </div>
                    <div className="d-flex flex-row position-absolute pt-2" style={{ left: '8px', bottom: '5px' }}>
                        <div className="py-2 px-3">
                            <ProgressiveImage
                                delay={20}
                                src={IMAGE_BASE_URL + student?.user?.image}
                                placeholder={noImage}
                            >
                                {(src, loading) => (
                                    <img
                                        src={src}
                                        style={{
                                            width: "25vw",
                                            height: "25vw",
                                            borderRadius: "50px",
                                            objectFit: "cover",
                                            backgroundRepeat: "round",
                                            border: '2px solid #fff',
                                            outline: '1px soild #fff'
                                        }}
                                        className=""
                                        alt="grosav"
                                    />
                                )}
                            </ProgressiveImage>
                        </div>

                        <div className="d-flex flex-column ms-2 mt-2 text-white">
                            <span className="text-white fw-bold" style={{ fontSize: '1.8em' }}>{student?.user?.name}</span>
                            <span className="text-white fw-bold">Section - {student?.section?.name}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="px-3 flex-column p-1 pt-4">
                        <div className="student-info-div mt-2 pt-3">
                            <span className="student-roles w-50">
                                Class teacher
                            </span>
                            <span className="student-details-row w-50 text-end">
                                {classTeacher?.user?.name ? classTeacher?.user?.name : 'No Teacher specified'}
                            </span>
                        </div>
                    </div>
                    <div className="px-3 flex-column p-1">
                        <div className="student-info-div mt-2 pt-3">
                            <span className="student-roles w-50">
                                Admission number
                            </span>
                            <span className="student-details-row w-50 text-end">
                                {student?.admission_number}
                            </span>
                        </div>
                    </div>
                    <div className="px-3 flex-column p-1">
                        <div className="student-info-div mt-2 pt-3">
                            <span className="student-roles w-50">
                                Roll no.
                            </span>
                            <span className="student-details-row w-50 text-end">
                                {student?.roll_number}
                            </span>
                        </div>
                    </div>
                    <div className="px-3 flex-column p-1">
                        <div className="student-info-div mt-2 pt-3">
                            <span className="student-roles w-50">
                                Bus Route
                            </span>
                            <span className="student-details-row w-50 text-end">
                                {student?.user?.routes?.name ? student?.user?.routes?.name : "Not Updated"}
                            </span>
                        </div>
                    </div>
                    <div className="px-3 flex-column p-1">
                        <div className="student-info-div mt-2 pt-3">
                            <span className="student-roles w-50">
                                Date of birth
                            </span>
                            <span className="student-details-row w-50 text-end">
                                {moment(student?.user?.date_of_birth).format('DD-MM-YYYY')}
                            </span>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    student: state.user.student.student,
    classTeacher: state.user.student.classTeacher,
    user: state.user.parent,
});

export default connect(mapStateToProps, {
})(StudentInfo);
