import { formatPrice } from "../../../helpers/formatPrice";
import {
    LOAD_CART,
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    UPDATE_CART,
    EMPTY_CART,
} from "./actionTypes";
export const loadCart = (products) => ({
    type: LOAD_CART,
    payload: products,
});

export const addProduct = (product) => ({
    type: ADD_PRODUCT,
    payload: product,
});

export const removeProduct = (product) => (dispatch) => {
    return dispatch({ type: REMOVE_PRODUCT, payload: product });
};

export const updateCart = (cartProducts) => (dispatch) => {
    let productQuantity = cartProducts.reduce((sum, p) => {
        // sum += p.quantity;
        sum++;
        return sum;
    }, 0);
    let total = cartProducts.reduce((sum, p) => {
        let addonTotal = 0;
        // let addonTax = 0;

       
        if (p.selectedaddons) {
            sum += addonTotal * p.quantity;
        } else {
            sum += p.price * p.quantity;
        }
        sum = parseFloat(sum);
        formatPrice(sum);
        return sum;
    }, 0);

    let totalPrice = formatPrice(total);
    // let tax = parseFloat(Math.abs(totalPrice - subTotal).toFixed(2));
    let cartTotal = {
        productQuantity,
        totalPrice,
    };

    dispatch({
        type: UPDATE_CART,
        payload: cartTotal,
    });
};

export const emptyCart = (checkout = [], products = []) => ({
    type: EMPTY_CART,
    payload: { checkout: checkout, products: products },
});
