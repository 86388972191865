import React, { Component } from "react";
import { connect } from "react-redux";
import { Radio } from "antd";
import BackButton from "../../../../../elements/BackButton";
import { changeStudentAttendence } from "../../../../../redux/Teacher/student/action"
import noStudents from "../../../../../assets/Images/no-student.png"
import { CgCloseO } from "react-icons/cg";
import moment from "moment";

export class EachStudentAttendence extends Component {
    changeAttendence = (e, id, type) => {
        e.preventDefault();
        const { teacher } = this.props;
        this.props.changeStudentAttendence(teacher?.data?.auth_token, id, type)
    }
    render() {
        const { attendence } = this.props;
        return (
            <React.Fragment>
                <BackButton title={"Mark Attendence"} />
                <div className="px-3 pt-3" style={{ paddingBottom: '8vw' }}>
                    <div className='d-flex justify-content-between homework-input'>
                        <div className='text-muted'> Class  </div>
                        <div style={{ fontWeight: '600' }}> {attendence?.class} - {attendence?.section}  </div>
                    </div>
                    <div className='d-flex justify-content-between mt-2 homework-input'>
                        <div className='text-muted'> Date  </div>
                        <div style={{ fontWeight: '600' }}> {moment(attendence?.date).format("DD-MMM-YYYY")}  </div>
                    </div>
                    {attendence?.weekStartDate &&
                        <div className='d-flex justify-content-between mt-2 homework-input'>
                            <div className='text-muted'> Sunday  </div>
                            <div style={{ fontWeight: '600', color: 'red' }}> <CgCloseO /></div>
                        </div>
                    }
                    <div className="mt-2 py-2 px-2" style={{ background: '#f5f5f5', borderRadius: '10px' }}>
                        {attendence?.students.length > 0 ? attendence?.students.map((value) => (
                            <div className="w-100 d-flex flex-column justify-content-between pt-2 mt-2"
                                style={{ background: "rgba(190, 175, 179, 0.05)", borderRadius: "10px", }} >
                                <div className="d-flex justify-content-between">
                                    <span>
                                        Roll No:<span style={{ fontWeight: '600', fontSize: '1em' }}> {value?.roll_number}</span>
                                    </span>

                                    <span>
                                        <span style={{ fontWeight: '600' }}>{value?.user?.name}</span>
                                    </span>
                                </div>
                                {console.log(value.details)}
                                <div className="w-100 d-flex justify-content-between align-items-center pt-2 mt-2"
                                    style={{ background: "rgba(190, 175, 179, 0.05)", borderRadius: "10px", }} >
                                    <Radio.Group defaultValue={value?.student_attendences[0]?.details} buttonStyle="solid" style={{ width: "100%", background: "#45C671", }}>
                                        <Radio.Button className="text-center" value="FULL" style={{ width: "25%", }}
                                            onClick={(e) => { this.changeAttendence(e, value?.student_attendences[0]?.id, "FULL") }}>
                                            Present
                                        </Radio.Button>
                                        <Radio.Button className="text-center" value="ABSENT" style={{ width: "25%", }}
                                            onClick={(e) => { this.changeAttendence(e, value?.student_attendences[0]?.id, "ABSENT") }}>
                                            Absent
                                        </Radio.Button>
                                        <Radio.Button className="text-center" value="MORNING" style={{ width: "25%", }}
                                            onClick={(e) => { this.changeAttendence(e, value?.student_attendences[0]?.id, "MORNING") }}>
                                            Morning
                                        </Radio.Button>
                                        <Radio.Button className="text-center" value="EVENING" style={{ width: "25%", }}
                                            onClick={(e) => { this.changeAttendence(e, value?.student_attendences[0]?.id, "EVENING") }}>
                                            Evening
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>
                            </div>
                        )) : (
                            <div className="text-center pt-4">
                                <img src={noStudents} style={{ width: "80%" }} alt="no_students" />
                                <div className="text-center fw-bolder mt-2">
                                    No Students Found
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    attendence: state.student.attendence
});
export default connect(mapStateToProps, {
    changeStudentAttendence
})(EachStudentAttendence);
