import React, { Component } from "react";
import { MdOutlineMenuBook } from "react-icons/md";
import { connect } from "react-redux";
import live from "../../../../assets/Images/liveclass.png";
import ProgressiveImage from "react-progressive-image";
import { MdOutlineContentCopy, MdDone } from "react-icons/md"
import { CopyToClipboard } from 'react-copy-to-clipboard';

export class LiveClass extends Component {
    state = {
        copied: ''
    }
    copyLink = (data) => {
        this.setState({ copied: data.id })
    }
    render() {
        const { live_class } = this.props;
        return (
            <React.Fragment>
                {live_class?.length > 0 ? (
                    <React.Fragment>
                        <div className="px-4 py-3">
                            <div style={{ fontSize: "18px", fontWeight: "500" }}>
                                Live Class
                            </div>
                        </div>

                        <div className="px-3 liveclass gap-3 hidden-scroll-bar" style={live_class?.length > 5 ?
                            { gridTemplateColumns: 'repeat(' + live_class?.length + ',1fr)', overflowX: "scroll", } : { gridTemplateColumns: 'repeat(5,1fr)', overflowX: "scroll", }} >
                            {live_class.map((value, index) => (
                                <div className="position-relative">
                                    <ProgressiveImage
                                        delay={20}
                                        src={live}
                                        placeholder={
                                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                        }   >
                                        {(src, loading) => (
                                            <img
                                                className=""
                                                style={{
                                                    width: "40vw",
                                                    objectFit: "cover",
                                                    height: "54vw",
                                                    borderRadius: "15px",
                                                    backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                    backgroundRepeat: "round",
                                                    boxShadow: "0px 0px 15px 8px #cccccc50",
                                                }}
                                                src={src}
                                                alt="main-item"
                                            />
                                        )}
                                    </ProgressiveImage>
                                    <div className="w-100 text-center position-absolute px-2 pb-2" style={{ bottom: '0px' }}>
                                        <div className="" style={{ fontSize: "12px", fontWeight: "400", color: "white", }}  >
                                            {value.subject.name} ({value.title})
                                        </div>
                                        <div className="text-center py-1"
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                color: "#642B1B",
                                                backgroundColor: "#E39A85",
                                                borderRadius: "10px",
                                            }}  >
                                            {value?.id == this.state.copied ?
                                                <div className=''>
                                                    <MdDone size={'1.2em'} />
                                                    <span>Copied</span>
                                                </div>
                                                :
                                                <CopyToClipboard text={value?.link}
                                                    onCopy={() => this.copyLink(value)}>
                                                    <div style={{ fontWeight: '680' }} >
                                                        <MdOutlineContentCopy size={"1.2em"} />
                                                        <span>Copy URL</span>
                                                    </div>
                                                </CopyToClipboard>
                                            }
                                        </div>
                                    </div>
                                    <div className="div-class position-absolute px-2" style={{ top: '5px', right: '5px' }}>
                                        <MdOutlineMenuBook style={{ color: "#fff" }} />
                                        <span className="ps-1" style={{ fontSize: "12px", fontWeight: "500", color: "white", }} >
                                            {value.class.name} class
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LiveClass);
