import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../elements/BackButton";
import ContentLoader from "react-content-loader";
import { getSingleItemData } from "../../../redux/Parent/store/action";
import ImageSlider from "./ImageSlider";
import { addProduct, removeProduct } from "../../../redux/Parent/cart/action";
import ItemDetails from "./ItemDetails";
import FloatCart from "./FloatCart";

export class index extends Component {
    state = {
        loading: false,
        update: true,
    };
    componentDidMount() {
        const { getSingleItemData, user } = this.props;
        // let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.forceUpdate();
        // this.setState({ loading: true });
        getSingleItemData(
            user.data.auth_token,
            this.props.match.params.type,
            this.props.match.params.id
        );
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }

    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    render() {
        const { single_item } = this.props;

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 550"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="6"
                                y="10"
                                rx="8"
                                ry="8"
                                width="27"
                                height="21"
                            />
                            <rect
                                x="130"
                                y="15"
                                rx="5"
                                ry="5"
                                width="31"
                                height="10"
                            />
                            <rect
                                x="14"
                                y="40"
                                rx="10"
                                ry="10"
                                width="250"
                                height="250"
                            />
                            <rect
                                x="10"
                                y="315"
                                rx="5"
                                ry="5"
                                width="55"
                                height="15"
                            />

                            <rect
                                x="150"
                                y="315"
                                rx="5"
                                ry="5"
                                width="35"
                                height="15"
                            />
                            <rect
                                x="210"
                                y="315"
                                rx="5"
                                ry="5"
                                width="55"
                                height="15"
                            />
                            <rect
                                x="10"
                                y="350"
                                rx="5"
                                ry="5"
                                width="65"
                                height="15"
                            />
                            <rect
                                x="10"
                                y="380"
                                rx="5"
                                ry="5"
                                width="59"
                                height="22"
                            />
                            <rect
                                x="10"
                                y="425"
                                rx="5"
                                ry="5"
                                width="70"
                                height="18"
                            />
                            <rect
                                x="10"
                                y="450"
                                rx="5"
                                ry="5"
                                width="250"
                                height="12"
                            />
                            <rect
                                x="10"
                                y="470"
                                rx="5"
                                ry="5"
                                width="250"
                                height="12"
                            />
                            <rect
                                x="10"
                                y="500"
                                rx="10"
                                ry="10"
                                width="250"
                                height="45"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div style={{ background: "#F9F9FA" }}>
                            <BackButton title={"Store"} />
                            {single_item && single_item.success ? (
                                <React.Fragment>
                                    <div className=" position-relative mt-4">
                                        <ImageSlider
                                            type={this.props.match.params.type}
                                            single_item={single_item}
                                        />
                                        {/* <div className="icon-single-store">
                                            <BiShoppingBag
                                                style={{
                                                    color: "#fff",
                                                    fontSize: "22px",
                                                }}
                                            />
                                        </div> */}
                                    </div>

                                    <ItemDetails
                                        single_item={single_item}
                                        addProduct={addProduct}
                                        forceUpdate={this.forceStateUpdate}
                                        type={this.props.match.params.type}
                                    />
                                </React.Fragment>
                            ) : null}
                        </div>
                    </React.Fragment>
                )}
                <FloatCart
                    type={this.props.match.params.type}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    store_data: state.store_data.store_data,
    user: state.user.parent,
    single_item: state.store_data.single_item,
    cartProducts: state.cart.products,
});

export default connect(mapStateToProps, {
    getSingleItemData,
    addProduct,
    removeProduct,
})(index);
