import React, { Component } from 'react'
import { connect } from 'react-redux'

export class ButtonExam extends Component {
    state={
        type:"CLASS"
    }

    changeType = (value) =>{
        this.setState({type:value})
        console.log(this.state.type)
    }
    render() {
        return (
            <React.Fragment>
                <div className='px-3 mt-3'>
                    <div className='exam-schedule-btn-div d-flex flex-row justify-content-between align-items-center px-2'>
                        <div className ={this.state.type == "CLASS" ? "exam-schedule-cls-tst px-4" : "px-4"} onClick={()=>this.changeType('CLASS')}>
                             Class Test
                             </div>
                        <div className ={this.state.type == "PUBLIC" ? "exam-schedule-cls-tst px-4" : "px-4"} onClick={()=>this.changeType('PUBLIC')}>
                           Public Test
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})



export default connect(mapStateToProps)(ButtonExam)