import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import BackButton from "../../../elements/BackButton";
import { Link } from "react-router-dom";
import {
    getAllTeacherLeave,
    submitLeaveApplication,
} from "../../../redux/Teacher/leave/action";
import { Form } from "react-bootstrap";
export class ApplyForLeave extends Component {
    state = {
        leaveType: "",
        typeId: null,
        data: {},
    };
    componentDidMount() {
        const { user } = this.props;

        this.props.getAllTeacherLeave(user?.data?.auth_token);
    }

    handleLeaveType = (leaveType) => {
        this.setState({ leaveType, typeId: leaveType.value });
    };
    handleInput = (value, name) => {
        let data = this.state.data;
        data[name] = value;
        this.setState({ data: data });
    };

    onSubmit = (e) => {
        e.preventDefault();
        console.log("helooo")
        const { data } = this.state;
        const { user } = this.props;
        let formData = new FormData();
        formData.append("leaveType", this.state.typeId);
        formData.append("fromDate", data.fromDate);
        formData.append("toDate", data.toDate);
        formData.append("reason", data.reason);
        formData.append("token", user.data.auth_token);

        this.props.submitLeaveApplication(formData).then((response) => {
           if(response.data.success){
            window.location.replace("/show-leave-history");
           }
        });
    };

    render() {
        const { leaveTypes } = this.props;
        const options = leaveTypes?.leaveTypes?.map((type) => ({
            value: type.id,
            label: type.name,
        }));

        return (
            <React.Fragment>
                <BackButton title={"Apply For Leave"} />
                <Form onSubmit={this.onSubmit}>
                    <div className="px-3 mt-4">
                        <div className="mt-3">
                            <Select
                                className="text-muted attendence-input px-2"
                                placeholder="Leave Type"
                                value={this.state.leaveType}
                                styles={customStyles}
                                options={options}
                                onChange={this.handleLeaveType}
                            />
                        </div>
                        <div className="mt-3">
                            <label className="text-muted px-3">From</label>
                            <input
                                type="date"
                                className="text-muted attendence-input px-3"
                                style={{ fontSize: "13px" }}
                                placeholder="From date"
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.handleInput(value, "fromDate");
                                }}
                                required
                            />
                        </div>
                        <div className="mt-3">
                        <label className="text-muted px-3">To</label>
                            <input
                                type="date"
                                className="text-muted attendence-input px-3"
                                style={{ fontSize: "13px" }}
                                placeholder="To date"
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.handleInput(value, "toDate");
                                }}
                                required
                            />
                        </div>
                        <div className="mt-3">
                            <textarea
                                className="form-control z-depth-1 textarea-box-meeting px-3 py-2"
                                id="exampleFormControlTextarea6"
                                rows="2"
                                placeholder="Reason"
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.handleInput(value, "reason");
                                }}
                            ></textarea>
                        </div>
                        <Link to="/show-leave-history">
                            <div
                                className="mt-4 d-flex justify-content-center"
                                style={{ color: "#809CC8" }}
                            >
                                <span>
                                    <u>Show Leave History</u>
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="position-fixed bottom-0 py-2 w-100 px-3">
                        <button type="submit" className="btn attendence-dwld-btn mt-2 py-3 w-100">
                            Submit
                        </button>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    leaveTypes: state.leave.teacherLeave,
});

export default connect(mapStateToProps, { getAllTeacherLeave,submitLeaveApplication })(ApplyForLeave);

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: "#fff",
        border: "none", // tried border: 'none'
        boxShadow: "none", // tried border: 'none'
        outline: "none",
    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "88vw",
        border: "none",
        boarderRadius: "10px",
        fontSize: "13px",
        borderStyle: "none",
        background: "rgba(190, 175, 179, 0.05)",
        outline: "none",
        fontWeight: "400",
        lineHeight: "14px",
        letterSpacing: "1px",
        color: "#86878E",
    }),
};
