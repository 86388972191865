import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../../elements/BackButton'
import Select from 'react-select'
import { CgFileAdd, CgCheckO } from 'react-icons/cg'
import { getTeacherSchoolData } from "../../../../redux/Teacher/user/action"
import { uploadMaterial } from '../../../../redux/Teacher/student/action'
import Modal from "react-modal";
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import Truncate from 'react-truncate'

export class AddMaterial extends Component {
  state = {
    loading: false,
    answersheet: [],
    subjects: [],
    open: false,
    filename: '',
  };
  componentDidMount() {
    const { teacher } = this.props
    this.props.getTeacherSchoolData(teacher.data.auth_token)
  }
  __onSubmit = (e) => {
    e.preventDefault();
    const { teacher, schooldata } = this.props;
    const { answersheet } = this.state;
    let formData = new FormData();
    formData.append("name", answersheet.name)
    formData.append("class_id", answersheet.class_id)
    formData.append("subject_id", answersheet.subject_id)
    formData.append("type", answersheet.type)
    formData.append("description", answersheet.description)
    formData.append("material", answersheet.file)
    formData.append("token", teacher.data.auth_token)
    this.props.uploadMaterial(formData).then((response) => {
      if (response?.data?.success) {
        this.modalOpen()
        document.getElementById('materialform').reset();
      }
    })
  }
  getSubjects = (val) => {
    const { schooldata } = this.props;
    const subjects = schooldata?.data?.student_classes?.find((data) => data.id == val)?.subjects?.map((sub) => ({
      value: sub.id,
      label: sub.name
    }))
    this.setState({ subjects: subjects })
  }
  handleInput(e, name) {
    let data = this.state.answersheet;
    data[name] = e;
    this.setState({ answersheet: data });
  }
  modalOpen = () => {
    this.setState({ open: true });
  };
  modalClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { schooldata } = this.props;
    const { subjects } = this.state;
    const classes = schooldata?.data?.student_classes?.map((data) => ({
      value: data.id,
      label: data.name
    }))
    const types = [
      { value: 'TEXTBOOK', label: 'Text Book' },
      { value: 'QUESTION', label: 'Model Question Paper' },
      { value: 'MATERIAL', label: 'Study Material' },

    ]
    return (
      <React.Fragment>
        <BackButton title={"Upload Material"} />
        <Form onSubmit={this.__onSubmit} id="materialform">
          <div className='px-3 mt-4'>
            <div className="mt-3">
              <InputGroup hasValidation className="mt-2 attendence-input" style={{ boxSizing: 'border-box' }} >
                <FormControl
                  type="text"
                  placeholder="Title"
                  className="attendence-input"
                  onChange={e => {
                    let value = e.target.value;
                    this.handleInput(value, "name");
                  }}
                  required
                />
              </InputGroup>
            </div>
            <div className="mt-3">
              <Select className="text-muted attendence-input px-2" placeholder='Class' styles={customStyles} options={classes}
                onChange={e => {
                  let value = e.value;
                  this.handleInput(value, "class_id");
                  this.getSubjects(value);
                }} />
            </div>
            <div className="mt-3">
              <Select className="text-muted attendence-input px-2" placeholder='Subject' styles={customStyles} options={subjects}
                onChange={e => {
                  let value = e.value;
                  this.handleInput(value, "subject_id");
                }} />
            </div>
            <div className="mt-3">
              <Select className="text-muted attendence-input px-2" placeholder='Type' styles={customStyles} options={types}
                onChange={e => {
                  let value = e.value;
                  this.handleInput(value, "type");
                }} />
            </div>
            <div className="mt-3">
              <InputGroup hasValidation className="mt-2 attendence-input" style={{ boxSizing: 'border-box' }} >
                <FormControl
                  type="text"
                  placeholder="Description"
                  className="attendence-input"
                  onChange={e => {
                    let value = e.target.value;
                    this.handleInput(value, "description");
                  }}
                  required
                />
              </InputGroup>
            </div>
            <div className="attendence-input mt-3 p-2 d-flex justify-content-between ">
              <div style={{ width: "50%" }}>
                {this.state?.filename ? (
                  <Truncate lines={2}>
                    {this.state?.filename}
                  </Truncate>
                ) : (
                  <label className='text-muted px-3'>
                    File<small className="text-danger">*</small>
                  </label>
                )}
              </div>
              <input className='upload-button' style={{ display: 'none' }} name='image' type="file" id="upload-button-file" onChange={(event) => {
                let file = event.target.files[0]
                this.setState({ file: URL.createObjectURL(file) });
                this.handleInput(file, "file");
                this.setState({ filename: file.name });
              }} required />
              <label className='upload-button px-3 py-2' for='upload-button-file' >
                Upload File <CgFileAdd size={'1.3em'} />
              </label>
            </div>
            <div id='filealert' className='text-secondary'>
              <small>*The file must be a file of type: zip, csv, txt, xlx, xls, pdf, ppt.</small>
            </div>
          </div>
          <div className='position-fixed bottom-0 py-2 w-100 px-3'>
            <button className='btn attendence-dwld-btn mt-2 py-3 w-100' type="submit" >
              Submit
            </button>
          </div>
        </Form>
        <Modal
          isOpen={this.state.open}
          ariaHideApp={false}
          onRequestClose={() => this.modalClose()}
          style={modalStyles}
          contentLabel="Example Modal"
        >
          <div
            className="container"
            style={{
              borderRadius: "5px",
              height: "70vw",
              width: '70vw',
              zIndex: "9999",
            }}
          >
            <React.Fragment>
              <div className="d-flex flex-column justify-content-center align-items-between">
                <div className='d-flex justify-content-center mt-5'
                  style={{ fontSize: '18px', fontWeight: '600', color: '#2B2C2F' }}>
                  <CgCheckO color={"#7ECB40"} size={"4em"} />
                </div>
                <div className='d-flex justify-content-center mt-4'
                  style={{ fontSize: '15px', fontWeight: '400', color: '#2B2C2F' }}>
                  Uploaded Successfully</div>
                <div className='d-flex justify-content-between w-100 position-fixed' style={{ bottom: '20%', left: '0%' }}>
                </div>
              </div>
            </React.Fragment>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  teacher: state.teacher.teacher,
  schooldata: state.teacher.schooldata,
})

export default connect(mapStateToProps, { getTeacherSchoolData, uploadMaterial })(AddMaterial)
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    border: 'none', // tried border: 'none'
    boxShadow: 'none', // tried border: 'none'
    outline: 'none',

  }),
  control: (provided) => ({
    ...provided,
    height: "50px",
    width: "88vw",
    border: "none",
    boarderRadius: "10px",
    fontSize: '13px',
    borderStyle: 'none',
    background: 'rgba(190, 175, 179, 0.05)',
    outline: 'none',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '1px',
    color: '#86878E',
  })

}
const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-38%",
    border: "2px solid #C24A6B",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
  },
};
