import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContentLoader from 'react-content-loader'
import BackButton from '../../../elements/BackButton'
import { getAllSubjects } from '../../../redux/Parent/class/action'
import { WEBSITE_BASE_URL } from '../../../api'
import { Link } from 'react-router-dom'
import Truncate from 'react-truncate'
import studyMatril from '../../../assets/Images/studyMatril.png'


class AllSubjects extends Component {
    state = {
        loading: false,
        subjects: []
    }
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getAllSubjects(user.data.auth_token, student_id ? student_id.value : user?.data?.students[0]?.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, subjects: response.payload.subjects });
            }
        })
    }
    render() {
        const { subjects } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    (

                        <React.Fragment>
                            <ContentLoader viewBox="0 0 280 550" backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="13" y="100" rx="10" ry="10" width="255" height="100" />
                                <rect x="13" y="215" rx="10" ry="10" width="255" height="100" />
                                <rect x="13" y="330" rx="10" ry="10" width="255" height="100" />
                                <rect x="13" y="445" rx="10" ry="10" width="255" height="100" />
                            </ContentLoader>

                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <BackButton title={"Study Materials"} />
                            <div className='mt-5 px-3' style={{ paddingBottom: '2vw' }}>
                                {subjects && subjects.length > 0 ? subjects.map((item, index) => (
                                    (index + 1) % 2 === 0 ? (
                                        <Link to={"/single-subject/" + item.id + "/red"}>
                                            <div className='subject d-flex justify-content-between' style={{ background: 'linear-gradient(90deg, #DD5E89 0%, #F7BB97 100%)' }}>
                                                <div className='flex-grow-1'>
                                                    <h2 style={{ margin: '6px 6px 0px 6px', fontSize: '20px', fontWeight: '500', color: '#FAE9EB' }}>{item?.name}</h2>
                                                    <div style={{ marginLeft: '6px', fontSize: '14px', fontWeight: '400', color: '#FAE9EB' }}>
                                                        <Truncate lines={2} style={{ color: '#FAE9EB' }}>
                                                            {item?.short_note}
                                                        </Truncate>
                                                    </div>
                                                </div>
                                                <div className='sub-image2'>
                                                    <img src={WEBSITE_BASE_URL + item?.subject_category?.Image} alt="social science" />
                                                </div>
                                            </div>
                                        </Link>
                                    ) : (
                                        <Link to={"/single-subject/" + item.id + "/blue"}>
                                            <div className='subject d-flex justify-content-between' style={{ background: 'linear-gradient(90deg, #4CA1AF 0%, #C4E0E5 100%)' }}>
                                                <div className='sub-image2'>
                                                    <img src={WEBSITE_BASE_URL + item?.subject_category?.Image} alt="english" />
                                                </div>
                                                <div className='flex-grow-1' style={{ textAlign: 'end' }}>
                                                    <h2 style={{ margin: '6px 6px 0px 6px', fontSize: '20px', fontWeight: '500', color: '#14525D' }}>{item.name}</h2>
                                                    <div style={{ marginLeft: '6px', fontSize: '14px', fontWeight: '400', color: '#FAE9EB' }}>
                                                        <Truncate lines={2} style={{ color: '#14525D' }}>
                                                            {item?.short_note}
                                                        </Truncate>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                ) : (
                                    <div className='d-flex flex-column'>
                                        <img src={studyMatril} alt="No Item" style={{ width: '100%' }} />
                                        <div className='text-center' style={{ fontWeight: '600', color: '#C47B8B' }}>No Study Materials Found</div>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>


        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
})


export default connect(mapStateToProps, {
    getAllSubjects
})(AllSubjects)
