import React, { Component } from 'react'
import { connect } from 'react-redux'
import avatar from '../../../../assets/Images/allStudentDetail1.png'
import { IoMdAddCircle } from 'react-icons/io';
import Select from 'react-select'
import { Form, FormControl, InputGroup } from "react-bootstrap";
import Shake from 'react-reveal/Shake';
import Modal from "react-modal";

import BackButton from '../../../../elements/BackButton'
// import Button from "react-bootstrap/Button";
import ContentLoader from 'react-content-loader';
import { getParentProfile, editParentProfile } from "../../../../redux/Parent/user/action";
import { IMAGE_BASE_URL } from '../../../../api';


export class ParentEdit extends Component {
    state = {
        loading: false,
        parent: {},
        file: "",
        password: "",
        imageError: false,
        emailError: false,
        usenameError: false,
        modal: false,

    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.getParentProfile(this.props.parent.data.auth_token).then((response) => {
            // console.log(response)
            // if(response && response.payload && response.payload.success){
            this.setState({ loading: false, parent: response.payload.data });
            // }else{
            this.setState({ loading: false });
            //     console.log('API error')
            // }
        });
    }

    __changeProfileDetails = (data, key) => {
        const details = { ...this.state.parent }
        details[key] = data
        this.setState({ parent: details })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { parent, imageError } = this.state;
        this.setState({ imageError: false })
        // console.log(parent?.image?.size);
        if (parent?.image?.size > 200000) {
            console.log(parent?.image?.size);

            setTimeout(() => {
                this.setState({ imageError: true })
            }, 10);
        } else {
            let formData = new FormData();
            formData.append('id', parent.id)
            formData.append("user_name", parent.user_name);
            formData.append("first_name", parent.first_name);
            formData.append("last_name", parent.last_name);
            // formData.append("image", parent.image);
            formData.append("email", parent.email);
            formData.append("phone", parent.phone);
            if (this.state.password) {

                formData.append("password", parent.password);
            }
            formData.append("date_of_birth", parent.date_of_birth);
            // formData.append("token", user.data.auth_token);
            if (this.state.file) {
                formData.append("image", parent.image);

            }

            this.props.editParentProfile(formData).then((response) => {
                if (response) {
                    if (response.data.success) {
                        this.setState({ imageError: false });
                        this.props.history.goBack()
                    } else if (!response.data.success && response.data.type === "EMAIL") {
                        this.setState({
                            emailError: true,
                            modal: true,
                        })
                    } else if (!response.data.success && response.data.type === "USERNAME") {
                        this.setState({
                            usernameError: true,
                            modal: true,
                        })
                    }

                }
            });
        }

    };

    render() {
        // const options = [
        //     { value: 'chocolate', label: 'Chocolate' },
        //     { value: 'strawberry', label: 'Strawberry' },
        //     { value: 'vanilla', label: 'Vanilla' }
        // ]

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: '#fff',
                border: 'none', // tried border: 'none'
                boxShadow: 'none', // tried border: 'none'
                outline: 'none',

            }),
            control: (provided) => ({
                ...provided,
                height: '45px',
                border: "none",
                boarderRadius: "10px",
                fontSize: '13px',
                borderStyle: 'none',
                backgroundColor: 'white',
                outline: 'none',
                boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1) inset",
                shadowOpacity: 0.5,
                shadowRadius: 50,

            }
            )

        }

        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <circle cx="140" cy="100" r="40" />
                                <rect x="11" y="180" rx="0" ry="0" width="251" height="37" />
                                <rect x="11" y="250" rx="0" ry="0" width="251" height="37" />
                                <rect x="11" y="320" rx="0" ry="0" width="251" height="37" />
                                <rect x="11" y="390" rx="0" ry="0" width="251" height="37" />
                                <rect x="11" y="460" rx="0" ry="0" width="251" height="37" />
                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Form onSubmit={this.onSubmit}>

                                <div className="position-relative">

                                    <div className="edit-parent-bg">
                                        <div className='px-3 py-2'>
                                            <BackButton color="white" />
                                        </div>
                                    </div>
                                    <div className="position-absolute edit-parent-pic" style={{ boarderRadius: "30px 30px 0px 0px", }}>
                                        <div className='d-flex flex-row justify-content-center posititon-relative'>
                                            <div style={{ marginTop: '-40px' }}>
                                                <img className="profile-img-style p-2" src={this.state.file ? this.state.file : this.state.parent?.image ? IMAGE_BASE_URL + this.state.parent?.image : avatar} alt="class-report" />
                                            </div>
                                            <div className="fs-5 position-absolute " style={{ marginTop: '5px', marginLeft: '80px', color: '#C34B6E' }}>
                                                <label for="image">
                                                    <IoMdAddCircle />
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <input
                                        type="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        style={{ display: "none" }}
                                        id="image"
                                        onChange={(e) => {
                                            let file = e.target.files[0];
                                            this.setState({ file: URL.createObjectURL(file), });
                                            this.__changeProfileDetails(file, "image");
                                        }}
                                    />
                                    {this.state.imageError &&
                                        <Shake Shake >
                                            <p className='text-center' style={{ color: 'red' }}><i>* Image should be lesser than 200kb</i></p>
                                        </Shake>
                                    }
                                </div>
                                <div className='px-2'>
                                    <div className="position-relative px-2">
                                        <div className=" flex-column p-1">
                                            <span className="text-black ps-3">User Name:</span>
                                            <div className='profile-edit-form mt-2 pt-2 '>
                                                <input className='input-box-edit '
                                                    placeholder='Enter your User Name'
                                                    value={this.state.parent?.user_name}
                                                    type="text"
                                                    style={{ border: 'none', outline: 'none' }}
                                                    onChange={(e) => {
                                                        this.__changeProfileDetails(e.target.value, 'user_name')

                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className=" flex-column p-1">
                                            <span className="text-black ps-3">Password:</span>
                                            <div className='profile-edit-form mt-2 pt-2 '>
                                                <input className='input-box-edit '
                                                    placeholder='Enter your Password'
                                                    value={this.state.parent?.password}
                                                    type="password"
                                                    style={{ border: 'none', outline: 'none' }}
                                                    onChange={(e) => {
                                                        this.__changeProfileDetails(e.target.value, 'password');
                                                        this.setState({
                                                            password: e.target.value,
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className=" flex-column p-1">
                                            <span className="text-black ps-3">First Name:</span>
                                            <div className='profile-edit-form mt-2 pt-2 '>
                                                <input className='input-box-edit '
                                                    placeholder='Enter your name'
                                                    value={this.state.parent?.first_name}
                                                    type="text"
                                                    style={{ border: 'none', outline: 'none' }}
                                                    onChange={(e) => { this.__changeProfileDetails(e.target.value, 'first_name') }}
                                                />
                                            </div>
                                        </div>
                                        <div className=" flex-column p-1">
                                            <span className="text-black ps-3">Last Name:</span>
                                            <div className='profile-edit-form mt-2 pt-2 '>
                                                <input className='input-box-edit '
                                                    placeholder='Enter your name'
                                                    value={this.state.parent?.last_name}
                                                    type="text"
                                                    style={{ border: 'none', outline: 'none' }}
                                                    onChange={(e) => { this.__changeProfileDetails(e.target.value, 'last_name') }}
                                                />
                                            </div>
                                        </div>
                                        <div className="  flex-column p-1 ">
                                            <span className="text-black p-3">Email:</span>
                                            <div className='profile-edit-form mt-2 pt-2'>
                                                <input className=' input-box-edit' placeholder='Enter your email'
                                                    value={this.state.parent?.email}
                                                    type="email" style={{ border: 'none', outline: 'none' }}
                                                    onChange={(e) => { this.__changeProfileDetails(e.target.value, 'email') }}
                                                />
                                            </div>
                                        </div>
                                        <div className=" flex-column p-1 ">
                                            <span className="text-black p-3">Phone:</span>
                                            <div className='profile-edit-form mt-2 pt-2'>
                                                <input className='input-box-edit ' placeholder='Enter your phone number'
                                                    value={this.state.parent?.phone}
                                                    type="tel" style={{ border: 'none', outline: 'none' }} minLength={10} maxLength={10} pattern="[6-9][0-9]{9}"
                                                    onChange={(e) => { this.__changeProfileDetails(e.target.value, 'phone') }} title='Phone Number Should Start With [6-9] Max Length 10'
                                                />
                                            </div>
                                        </div>
                                        <div className=" flex-column p-1 ">
                                            <span className="text-black p-3">Date of birth:</span>
                                            <div className='profile-edit-form mt-2 pt-2'>
                                                <input className=' input-box-edit' placeholder='Enter your DOB'
                                                    value={this.state.parent?.date_of_birth}
                                                    type="dob" style={{ border: 'none', outline: 'none' }}
                                                    onChange={(e) => { this.__changeProfileDetails(e.target.value, 'date_of_birth') }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className=" flex-column p-1">
                                        <span className="text-black p-3">Place:</span>
                                        <div className='profile-edit-form mt-2 pt-2'>
                                            <input className='input-box-edit ' placeholder='Enter your place' value={this.props.parent.data.name} type="name" style={{ border: 'none', outline: 'none' }} />
                                        </div>
                                    </div> */}
                                        {/* <div className=" flex-column p-1">
                                        <span className="text-black p-3">Class:</span>
                                        <div className='  pt-2 '>
                                            <Select className="text-muted" styles={customStyles} placeholder='Enter your class' options={options} />
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                                <div className=" d-grid gap-2 bg-white px-3 w-100 bottom-0 mt-4 pb-4">
                                    <button type='submit' className="edit-profile-btn">Submit</button>
                                </div>
                            </Form>
                        </React.Fragment>

                    )}
                <Modal
                    isOpen={this.state.modal}
                    style={modalStyle}
                    contentLabel="Example Modal"
                    ariaHideApp={false} //password success modal
                    className=""
                >
                    <div className="sucess-modal py-4 px-4">
                        <h3 className='text-warning font-weight-bolder text-center mb-3'>
                            Warning !
                        </h3>
                        {this.state.emailError &&
                            <p className=" text-center">
                                Email Already Exist Use Another Email
                            </p>
                        }
                        {this.state.usernameError &&
                            <p className=" text-center">
                                Username Already Exist Use Another Username
                            </p>
                        }
                        <div
                            className="text-center"
                            onClick={() => {
                                this.setState({
                                    modal: false,
                                    emailError: false,
                                    usernameError: false,
                                });
                            }}
                        >
                            <span className="btn  btn-success">OK</span>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    parent: state.user.parent,

})


export default connect(mapStateToProps, { getParentProfile, editParentProfile })(ParentEdit)
const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        boxShadow: " 0px 8px  15px 10px #f6f6f6",
        border: "1px solid #bf4672",
        outline: "none",
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};
