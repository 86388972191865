import React, { Component } from "react";
import BackButton from "../../../elements/BackButton";
import "./leaveApplications.css";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import {
    getAllLeaveApplication,
    responseToApplication,
} from "../../../redux/Teacher/leave/action";
import moment from "moment";
import img from "../../../assets/Images/no-appointments.png";
import ContentLoader from "react-content-loader";
import noSection from '../../../assets/Images/noSection.png'

export class LeaveApplication extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({
            loading: true,
        })
        let staff = JSON.parse(localStorage.getItem("teacher"));

        this.props.getAllLeaveApplication(user.data.auth_token, staff.staff_id);
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1500)
    }

    onReject = (id) => {
        let status = "REJECTED";
        const { user } = this.props;
        let staff = JSON.parse(localStorage.getItem("teacher"));
        this.props
            .responseToApplication(user.data.auth_token, id, status)
            .then((response) => {
                if (response.data.success) {
                    this.props.getAllLeaveApplication(
                        user.data.auth_token,
                        staff.staff_id
                    );
                }
            });
    };
    onAccept = (id) => {
        let status = "APPROVED";
        const { user } = this.props;
        let staff = JSON.parse(localStorage.getItem("teacher"));
        this.props
            .responseToApplication(user.data.auth_token, id, status)
            .then((response) => {
                if (response.data.success) {
                    this.props.getAllLeaveApplication(
                        user.data.auth_token,
                        staff.staff_id
                    );
                }
            });
    };
    render() {
        const { leaves } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 580"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#ecebeb"}
                        >
                            <rect x="10" y="30" rx="5" ry="5" width="25" height="25" />
                            <rect x="100" y="30" rx="10" ry="10" width="80" height="25" />
                            <rect x="10" y="70" rx="10" ry="10" width="260" height="140" />
                            <rect x="10" y="220" rx="10" ry="10" width="260" height="140" />
                            <rect x="10" y="370" rx="10" ry="10" width="260" height="140" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {leaves?.success ? (<>
                            <BackButton title="Leave Applications" />
                            {leaves?.classteacher ? (
                                <>
                                    {leaves?.data?.length > 0 ? (
                                        <div className="px-3 mt-3 pb-4">
                                            {leaves?.data?.map((leave) => (
                                                <div className="p-2 leave_application_container mt-3">
                                                    <div className="d-flex justify-content-between align-items-center student_details text-white">
                                                        <div className="w-50">
                                                            <span>
                                                                {leave?.user?.name}-
                                                                {
                                                                    leave?.student?.student_class
                                                                        ?.name
                                                                }
                                                            </span>
                                                            <span className="ms-1">
                                                                Section{" "}
                                                                {leave?.student?.section?.name}
                                                            </span>
                                                        </div>
                                                        <div className="w-50 d-flex justify-content-end">
                                                            <span>Date</span>
                                                            <span>:</span>
                                                            <span>
                                                                {moment(leave?.created_at).format(
                                                                    "dd-mm-yyyy"
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column mt-2 leave_details px-2">
                                                        <div className="d-flex align-items-center justify-content-between ">
                                                            <span>Reason :</span>
                                                            <span>{leave?.reason}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <span>Leave From :</span>
                                                            <span>{leave?.from}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <span>Leave to :</span>
                                                            <span>{leave?.to}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-end mt-2">
                                                        <Button
                                                            className="me-2"
                                                            onClick={() => this.onReject(leave?.id)}
                                                        >
                                                            Reject
                                                        </Button>
                                                        <Button
                                                            onClick={() => this.onAccept(leave?.id)}
                                                        >
                                                            Accept
                                                        </Button>
                                                    </div>
                                                    <div />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div
                                            className="d-flex flex-column align-items-center justify-content-center mt-5 appointment-no-data px-3"
                                            style={{
                                                height: "50vh",
                                            }}
                                        >
                                            <img
                                                src={img}
                                                alt="leave"
                                                style={{
                                                    width: "100%",
                                                }}
                                            ></img>
                                            <span className="text-center fs-5 fw-500">
                                                No Leave Applications
                                            </span>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className='mt-5 pt-5 w-100 d-flex flex-column justify-content-center align-items-center fw-bolder'>
                                    <img src={noSection} alt="no reviews" style={{ width: '65vw' }} className='' />
                                    <span> You are Not A Class Teacher...</span>
                                </div>
                            )}
                        </>
                        ) : null}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    leaves: state.leave.studentLeave,
});
export default connect(mapStateToProps, {
    getAllLeaveApplication,
    responseToApplication,
})(LeaveApplication);
