import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CgProfile } from 'react-icons/cg';
import { BiLibrary } from 'react-icons/bi';
import { FcGraduationCap } from 'react-icons/fc';
import { BsHddStack, BsNewspaper } from 'react-icons/bs';
import { MdEventNote, MdMapsHomeWork, MdOutlineSchool } from 'react-icons/md';
import { RiFileList2Line } from 'react-icons/ri';
import { FaChalkboardTeacher, FaUserGraduate } from 'react-icons/fa';
import { AiFillDollarCircle } from 'react-icons/ai';
import { TbNotebook } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { IoIosPaper } from 'react-icons/io';



export class ViewTeacher extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="px-4 py-3">
                    <div style={{ fontSize: '18px', fontWeight: '500' }}>Categories</div>
                </div>


                <div className="px-3 liveclass gap-2 hidden-scroll-bar" style={{ gridTemplateColumns: "repeat(4,1fr)", overflowX: "scroll", }}>

                    <Link to="/tutorial-library">
                        <div className='d-flex flex-column align-items-center '>
                            <div className='position-relative catagorie-div ' style={{ backgroundColor: '#9694FF' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <BiLibrary style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name  text-muted py-1' >Library</div>
                        </div>
                    </Link>

                    <Link to="/all-online-tests">
                        <div className='d-flex flex-column align-items-center ' >

                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#FFE494' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <BsNewspaper style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Online test</div>
                        </div>
                    </Link>

                    <Link to="/homeworks">
                        <div className='d-flex flex-column align-items-center ' >
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#018F9C' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <MdMapsHomeWork style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Home work</div>
                        </div>
                    </Link>

                    <Link to={"/subjects"}>
                        <div className='d-flex flex-column align-items-center ' >
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#57CAEB' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <BsHddStack style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Materials</div>
                        </div>
                    </Link>

                </div>

                <div className="px-3 liveclass gap-2 hidden-scroll-bar mt-2" style={{ gridTemplateColumns: "repeat(4,1fr)", overflowX: "scroll", }}>

                    <Link to={"/view-mark"}>
                        <div className='d-flex flex-column align-items-center'>
                            <div className='position-relative catagorie-div ' style={{ backgroundColor: '#3D5A80' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <MdOutlineSchool style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >View marks</div>
                        </div>
                    </Link>
                    <Link to={"/view-teachers"}>
                        <div className='d-flex flex-column align-items-center'>
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#98C1D9' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <FaChalkboardTeacher style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Teachers</div>
                        </div>
                    </Link>

                    <Link to="/review">
                        <div className='d-flex flex-column align-items-center'>
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#FF7976' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <RiFileList2Line style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Remarks</div>
                        </div>
                    </Link>

                    <Link to="/appointments">
                        <div className='d-flex flex-column align-items-center'>
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#293241' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <MdEventNote style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Appointments</div>
                        </div>
                    </Link>
                </div>


                <div className="px-3 liveclass gap-2 hidden-scroll-bar mt-2" style={{ gridTemplateColumns: "repeat(4,1fr)", overflowX: "scroll", }}>
                    <Link to="/student-leave-application">
                        <div className='d-flex flex-column align-items-center align-items-center'>
                            <div className='position-relative catagorie-div ' style={{ backgroundColor: '#9694FF' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <IoIosPaper style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Leave Application</div>
                        </div>
                    </Link>

                    <Link to={"/attendence"}>
                        <div className='d-flex flex-column align-items-center'>
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#FFE494' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <CgProfile style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Attendence</div>
                        </div>
                    </Link>

                    <Link to={"/fees"}>
                        <div className='d-flex flex-column align-items-center'>
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#018F9C' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <AiFillDollarCircle style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Fees</div>
                        </div>
                    </Link>

                    <Link to="/download-grade-card">
                        <div className='d-flex flex-column align-items-center'>
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#57CAEB' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <FaUserGraduate style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Grade Card</div>
                        </div>
                    </Link>

                </div>



            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTeacher)
