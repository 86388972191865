import React, { Component } from "react";
import { GrNext } from "react-icons/gr";
import { connect } from "react-redux";
import BackButton from "../../../../elements/BackButton";
import { getAllExamResult } from "../../../../redux/Teacher/onlineTest/action";
import img from "../../../../assets/Images/answer.png";
import ContentLoader from "react-content-loader";
export class OnlineTestStudentAnswer extends Component {
    state = {
        show: false,
        id: null,
        examId: this.props.match.params.id,
        answer: {},
        loading: false,
        correct_answer_count: null,
    }
    showData = (val) => {
        this.setState({ id: val, show: !this.state.show });
        const { result } = this.props;

        const res = result?.data?.results?.filter((data) => data.student_id === val)
        const count = result?.data?.results?.filter((data) => data.student_id === val)?.filter((data) => data.correct_answer === data.selected_answer)
        this.setState({ answer: res })
        this.setState({ correct_answer_count: count.length })
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { user } = this.props;
        this.props.getAllExamResult(user.data.auth_token, this.state.examId);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500)
    }

    render() {
        const { result } = this.props;
        const { answer, correct_answer_count } = this.state;

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="-2 -40 280 580"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#ecebeb"}
                        >

                            <rect x="3" y="20" rx="10" ry="10" width="270" height="50" />
                            <rect x="3" y="80" rx="10" ry="10" width="270" height="50" />
                            <rect x="3" y="140" rx="10" ry="10" width="270" height="50" />
                            <rect x="3" y="200" rx="10" ry="10" width="270" height="50" />
                            <rect x="3" y="260" rx="10" ry="10" width="270" height="50" />
                            <rect x="3" y="320" rx="10" ry="10" width="270" height="50" />
                            <rect x="3" y="20" rx="10" ry="10" width="270" height="50" />

                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>

                        <BackButton title={"Test Result"} />

                        {result?.data?.students?.length > 0 ? (
                            <div className="px-3 mt-5">

                                {result?.data?.students?.map((student) => (
                                    <React.Fragment>
                                        <div className='time-table-div py-3 d-flex justify-content-between mt-2 px-3'>
                                            <div className=''><span>{student?.user?.name}</span></div>
                                            <div className='' onClick={() => this.showData(student.id)}><GrNext style={this.state.id == student.id && this.state.show ? { transform: 'rotate(90deg)' } : null} /></div>
                                        </div>
                                        <div style={this.state.id == student.id && this.state.show ? { display: "block" } : { display: "none" }}>
                                            {answer?.length > 0 ? (
                                                <React.Fragment>
                                                    {answer?.map((data) => (
                                                        <React.Fragment>
                                                            <div className="question-card p-2 mt-3">
                                                                <div className="single-test-heading py-2 px-3">
                                                                    <h6
                                                                        style={{
                                                                            color: "#5762AB",
                                                                            fontSize: "14px",
                                                                            fontWeight: "500",
                                                                        }}
                                                                    >
                                                                        Question:
                                                                    </h6>
                                                                    <span
                                                                        style={{
                                                                            color: "#fff",
                                                                            fontSize: "14px",
                                                                            fontWeight: "400",
                                                                        }}
                                                                    >
                                                                        {data?.question?.question}
                                                                    </span>
                                                                    <br />
                                                                    <span
                                                                        className="pb-2"
                                                                        style={{
                                                                            color: "#fff",
                                                                            fontSize: "12px",
                                                                            fontWeight: "400",
                                                                        }}
                                                                    >
                                                                        Correct Answer: {data?.correct_answer}
                                                                    </span>
                                                                </div>
                                                                <div className="mt-2">
                                                                    <span
                                                                        className="pb-2"
                                                                        style={{
                                                                            color: "#2B2C2F",
                                                                            fontSize: "14px",
                                                                            fontWeight: "500",
                                                                        }}
                                                                    >
                                                                        Student Answer:
                                                                    </span>
                                                                    <br />

                                                                    <div className="w-100 question-option my-2">{data?.selected_answer}</div>
                                                                </div>
                                                            </div>

                                                        </React.Fragment>
                                                    ))}
                                                    <div
                                                        className="w-100 d-flex justify-content-between align-items-center px-3 py-3 mt-2"
                                                        style={{
                                                            background: "rgba(170, 201, 221, 0.3)",
                                                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                                                            borderRadius: "10px",
                                                        }}
                                                    >
                                                        <h5 className="m-0">Total</h5>
                                                        <h5 className="m-0">{correct_answer_count} out of {result?.data?.questions_count}</h5>
                                                    </div>
                                                </React.Fragment>
                                            ) : (<span className="d-flex justify-content-center fw-500 fs-5">""</span>)}
                                        </div>
                                    </React.Fragment>
                                ))}



                            </div>
                        ) : (<div
                            className="d-flex flex-column align-items-center justify-content-center mt-5 appointment-no-data px-3"
                            style={{

                                height: "50vh"
                            }}
                        >
                            <img
                                src={img}
                                alt="leave"
                                style={{
                                    width: "100%",
                                }}
                            ></img>
                            <div className=" fs-5 fw-500">
                                No Results
                            </div>
                        </div>)}
                    </React.Fragment>)}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    result: state.onlineExam.allTestResults,
});



export default connect(mapStateToProps, { getAllExamResult })(OnlineTestStudentAnswer);
