import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../../elements/BackButton";
import { getAllTeacherLeave } from "../../../../redux/Teacher/leave/action";
import image from '../../../../assets/Images/noleave.png'
export class LeaveHistory extends Component {
    componentDidMount() {
        const { user } = this.props;

        this.props.getAllTeacherLeave(user?.data?.auth_token);
    }
    render() {
        const { teacherLeaves } = this.props;
        return (
            <React.Fragment>
                <BackButton title={"Leave History"} />
                <div className="px-3 mt-4">
                    {/* pending leave */}
                    {teacherLeaves?.data?.length > 0 ?
                        <>
                            {teacherLeaves?.data?.map((leave) => (
                                <div
                                    className="mt-3 leave-history-container px-2 pb-4 pt-2"

                                >
                                    <div className="leave-history-head d-flex justify-content-between px-3 py-2 text-white" style={leave?.status == "PENDING" ? style : leave?.status == "APPROVED" ? style2 : style3}>
                                        <div>
                                            <span>{leave?.leave?.name}</span>
                                        </div>
                                        <div>
                                            <span>Status: {leave?.status}</span>
                                        </div>
                                    </div>
                                    <div className="text-muted px-1 pb-1 pt-2">
                                        <span>{leave?.reason}</span>
                                    </div>
                                </div>
                            ))}
                        </>
                        :
                        <div className="d-flex flex-column align-items-center mt-5 pt-5">
                            <img src={image} style={{ width: '85%', objectFit: 'cover', }} />
                            <span style={{ fontSize: '11px' }}>No History Found...</span>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    teacherLeaves: state.leave.teacherLeave,
});

const style = {
    fontSize: "12px", fontWeight: "500"
}
const style2 = {
    fontSize: "12px", fontWeight: "500",
    background: "#A9C7AC",
}
const style3 = {
    fontSize: "12px", fontWeight: "500",
    background: "rgba(219, 54, 101, 0.56)",
}

export default connect(mapStateToProps, { getAllTeacherLeave })(LeaveHistory);
