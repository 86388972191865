import React, { Component } from "react";
import { connect } from "react-redux";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import ProgressiveImage from "react-progressive-image";
import chat from "../../../../assets/Images/chat.png";
import ContentLoader from "react-content-loader";
import { IMAGE_BASE_URL } from "../../../../api/index";
import { Link } from "react-router-dom";
import teacher from "../../../../assets/Images/bg.png";
import Moment from "react-moment";

export class ChatList extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        let student_id = JSON.parse(localStorage.getItem("student_id"));

        const { user } = this.props;
    }
    render() {
        const { user, chat } = this.props;
        const calendarStrings = {
            lastDay: "[Yesterday]",
            sameDay: "LT",
            nextDay: "[Tomorrow at] LT",
            lastWeek: "L",
            nextWeek: "dddd [at] LT",
            sameElse: "L",
        };
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 550"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="8"
                                y="18"
                                rx="8"
                                ry="8"
                                width="25"
                                height="29"
                            />
                            <rect
                                x="245"
                                y="18"
                                rx="8"
                                ry="8"
                                width="25"
                                height="29"
                            />
                            <rect
                                x="8"
                                y="67"
                                rx="5"
                                ry="5"
                                width="80"
                                height="20"
                            />
                            <rect
                                x="8"
                                y="100"
                                rx="5"
                                ry="5"
                                width="50"
                                height="10"
                            />
                            <rect
                                x="8"
                                y="130"
                                rx="5"
                                ry="5"
                                width="57"
                                height="22"
                            />
                            <rect
                                x="80"
                                y="130"
                                rx="5"
                                ry="5"
                                width="45"
                                height="22"
                            />
                            <rect
                                x="15"
                                y="180"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="185"
                                rx="5"
                                ry="5"
                                width="40"
                                height="15"
                            />
                            <rect
                                x="70"
                                y="205"
                                rx="5"
                                ry="5"
                                width="30"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="240"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="240"
                                rx="5"
                                ry="5"
                                width="45"
                                height="15"
                            />
                            <rect
                                x="70"
                                y="265"
                                rx="5"
                                ry="5"
                                width="65"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="300"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="300"
                                rx="10"
                                ry="10"
                                width="45"
                                height="15"
                            />

                            <rect
                                x="70"
                                y="330"
                                rx="5"
                                ry="5"
                                width="65"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="365"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="365"
                                rx="10"
                                ry="10"
                                width="45"
                                height="15"
                            />
                            <rect
                                x="70"
                                y="390"
                                rx="5"
                                ry="5"
                                width="65"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="430"
                                rx="10"
                                ry="10"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="70"
                                y="430"
                                rx="10"
                                ry="10"
                                width="45"
                                height="15"
                            />
                            <rect
                                x="70"
                                y="460"
                                rx="5"
                                ry="5"
                                width="65"
                                height="10"
                            />
                            <rect
                                x="15"
                                y="525"
                                rx="12"
                                ry="12"
                                width="250"
                                height="35"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {chat.success && (
                            <React.Fragment>
                                <div
                                    className="chat-people mt-2"
                                    style={{
                                        height: "100vh",
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    {chat.chat_list.map((item, index) => (
                                        <Link
                                            to={
                                                "/single-chat/" +
                                                user.data.id +
                                                "/" +
                                                item.id
                                            }
                                        >
                                            <div className="d-flex align-items-center px-3">
                                                <div className="mt-4 position-relative">
                                                    <ProgressiveImage
                                                        delay={20}
                                                        src={
                                                            IMAGE_BASE_URL +
                                                            item?.user?.image
                                                        }
                                                        placeholder={teacher}
                                                    >
                                                        {(src, loading) => (
                                                            <img
                                                                src={src}
                                                                style={{
                                                                    width: "15vw",
                                                                    height: "15vw",
                                                                    borderRadius:
                                                                        "12px",
                                                                    objectFit:
                                                                        "cover",
                                                                    backgroundRepeat:
                                                                        "round",
                                                                    border: "2px solid rgba(191, 68, 116, 0.5)",
                                                                }}
                                                                className=""
                                                                alt="grosav"
                                                            />
                                                        )}
                                                    </ProgressiveImage>
                                                    {/* <div
                                                    className="position-absolute"
                                                    style={{
                                                        top: "57%",
                                                        right: "-4%",
                                                    }}
                                                >
                                                    <GoPrimitiveDot
                                                        style={{
                                                            color: "#56E39F",
                                                            fontSize: "17px",
                                                        }}
                                                    />
                                                </div> */}
                                                </div>
                                                <div className="d-flex flex-column  flex-grow-1">
                                                    <div className="mt-4 px-2">
                                                        <span
                                                            style={{
                                                                color: "#212529",
                                                                fontWeight:
                                                                    "400",
                                                            }}
                                                        >
                                                            {item?.user?.name}
                                                        </span>
                                                    </div>
                                                    <div className="px-2">
                                                        <span
                                                            style={{
                                                                color: "#212529",
                                                                fontWeight:
                                                                    "400",
                                                                fontSize:
                                                                    "12px",
                                                                color: "#adb5bd",
                                                            }}
                                                        >
                                                            {item.last_msg}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="text-end d-flex flex-column align-items-center justify-content-center"
                                                    style={{
                                                        color: "#000",
                                                        fontSize: "10px",
                                                    }}
                                                >
                                                    <div>
                                                        <Moment
                                                            calendar={
                                                                calendarStrings
                                                            }
                                                        >
                                                            {
                                                                item.last_msg_created_at
                                                            }
                                                        </Moment>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
});

export default connect(mapStateToProps, {})(ChatList);
