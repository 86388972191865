import {
    GET_ALL_HOME_PAGE_DATA,

} from "./actionType";

import {
    GET_ALL_HOME_PAGE_DATA_URL,

} from "../../../api";

import Axios from "axios";

export const getAllHomePageData = (token, student_id) => (dispatch) => {
    return Axios.post(GET_ALL_HOME_PAGE_DATA_URL, {
        token, student_id
    })
        .then((response) => {
            console.log(response)
            let data = response.data;
            return dispatch({ type: GET_ALL_HOME_PAGE_DATA, payload: data })
        })
}
