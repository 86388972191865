import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProgressiveImage from 'react-progressive-image'
import Banner from '../../../../assets/Images/1234.jpg'
import { IMAGE_BASE_URL } from '../../../../api';

export class StoreBanner extends Component {
    render() {
        const { banners } = this.props;
        // console.log(banners.length, 'banner');
        return (
            <React.Fragment>
                {banners?.length > 0 ? (
                    <div className="px-3 liveclass gap-3 hidden-scroll-bar" style={{ gridTemplateColumns: `repeat(${banners?.length - 5 < 0 ? '4' : banners?.length},1fr)`, overflowX: "scroll", }} >
                        {banners?.map((data) => (
                            <div className="mt-4 d-flex align-items-center justify-content-center">
                                <ProgressiveImage
                                    delay={20}
                                    src={IMAGE_BASE_URL + data.image}
                                    placeholder={'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder'}
                                >
                                    {(src, loading) => (
                                        <img
                                            className="" style={{
                                                objectFit: 'cover',
                                                width: "90vw",
                                                height: "35vw",
                                                borderRadius: "20px"
                                            }}
                                            src={src}
                                            alt='main-item'
                                        />
                                    )}
                                </ProgressiveImage>
                            </div>
                        ))}
                    </div>
                ) : null}
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({})


export default connect(mapStateToProps)(StoreBanner)
