import Axios from 'axios';
import { GET_STORE_DATA_URL, GET_SINGLE_ITEM_DATA_URL} from '../../../api';
import { GET_STORE_DATA ,GET_SINGLE_ITEM_DATA} from './actionType';


export const getStoreData = (token,id) => (dispatch) => {
    return Axios.post(GET_STORE_DATA_URL, {token,id}).then((response) => {
        let store_data = response.data;
        return dispatch({type: GET_STORE_DATA, payload: store_data});
    });
};


export const getSingleItemData = (token,type,id) => (dispatch) => {
    return Axios.post(GET_SINGLE_ITEM_DATA_URL, {token,type,id}).then((response) => {
        let single_item = response.data;
        return dispatch({type: GET_SINGLE_ITEM_DATA, payload: single_item});
    });
};