import React, { Component } from "react";
import Slider from "react-slick";
import { IMAGE_BASE_URL } from "../../../../api/index";
import ProgressiveImage from "react-progressive-image";
import store from "../../../../assets/Images/store-img.png";

class ImageSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };
        const { type, single_item } = this.props;

        return (
            <div>
                <div className="px-3">
                    {type == "item" ? (
                        <Slider {...settings}>
                            {single_item?.item?.item_image1 !== null && (
                                <div className="">
                                    <ProgressiveImage
                                        delay={20}
                                        
                                        className="image-slider"
                                        
                                        src={
                                            IMAGE_BASE_URL +
                                            single_item?.item?.item_image1
                                        }
                                        placeholder={
                                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                        }
                                    >
                                        {(src, loading) => (
                                            <img
                                                className="image-slider"
                                                src={src}
                                                alt="main-item"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>
                            )}
                            {single_item?.item?.item_image2 !== null && (
                                <div className="">
                                    <ProgressiveImage
                                    className="image-slider"
                                        delay={20}
                                        src={
                                            IMAGE_BASE_URL +
                                            single_item?.item?.item_image2
                                        }
                                        placeholder={
                                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                        }
                                    >
                                        {(src, loading) => (
                                            <img
                                                className="image-slider"
                                                src={src}
                                                alt="main-item"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>
                            )}
                            {single_item?.item?.item_image3 !== null && (
                                <div className="">
                                    <ProgressiveImage
                                        delay={20}
                                        src={
                                            IMAGE_BASE_URL +
                                            single_item?.item?.item_image3
                                        }
                                        placeholder={
                                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                        }
                                    >
                                        {(src, loading) => (
                                            <img
                                                className="image-slider"
                                                src={src}
                                                alt="main-item"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>
                            )}
                            {single_item?.item?.item_image4 !== null && (
                                <div className="">
                                    <ProgressiveImage
                                        delay={20}
                                        src={
                                            IMAGE_BASE_URL +
                                            single_item?.item?.item_image4
                                        }
                                        placeholder={
                                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                        }
                                    >
                                        {(src, loading) => (
                                            <img
                                                className="image-slider"
                                                src={src}
                                                alt="main-item"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>
                            )}
                        </Slider>
                    ) : (
                        <Slider {...settings}>
                            <div className="">
                                <ProgressiveImage
                                    delay={20}
                                    src={
                                        IMAGE_BASE_URL +
                                        single_item?.item?.image
                                    }
                                    placeholder={
                                        "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                    }
                                >
                                    {(src, loading) => (
                                        <img
                                            className="image-slider"
                                            src={src}
                                            alt="main-item"
                                        />
                                    )}
                                </ProgressiveImage>
                            </div>
                        </Slider>
                    )}
                </div>
            </div>
        );
    }
}

export default ImageSlider;
