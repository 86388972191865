import React, { Component } from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import Footer from "../Footer";
import profileP from "../../../assets/Images/profileP.png";
import { RiEditLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getParentProfile } from "../../../redux/Parent/user/action";
import { IMAGE_BASE_URL } from "../../../api";
import ProgressiveImage from "react-progressive-image";

class ParentProfile extends Component {
    state = {
        loading: false,
        parent: [],
    };

    componentDidMount() {
        this.setState({ loading: true });
        this.props.getParentProfile(this.props.parent.data.auth_token).then((response) => {
            // console.log(response)
            // if(response && response.payload && response.payload.success){
            this.setState({ loading: false, parent: response.payload.data });
            // }else{
            this.setState({ loading: false });
            //     console.log('API error')
            // }
        });
    }

    render() {
        const { parent } = this.state;
        // console.log(this.props,'propsss')
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="30"
                                y="60"
                                rx="18"
                                ry="18"
                                width="75"
                                height="75"
                            />
                            <rect
                                x="120"
                                y="70"
                                rx="0"
                                ry="0"
                                width="130"
                                height="35"
                            />
                            <rect
                                x="120"
                                y="110"
                                rx="0"
                                ry="0"
                                width="89"
                                height="22"
                            />
                            <rect
                                x="217"
                                y="112"
                                rx="0"
                                ry="0"
                                width="17"
                                height="18"
                            />
                            <rect
                                x="10"
                                y="180"
                                rx="0"
                                ry="0"
                                width="141"
                                height="34"
                            />
                            <rect
                                x="15"
                                y="230"
                                rx="0"
                                ry="0"
                                width="250"
                                height="50"
                            />
                            <rect
                                x="15"
                                y="290"
                                rx="0"
                                ry="0"
                                width="250"
                                height="50"
                            />
                            <rect
                                x="15"
                                y="350"
                                rx="0"
                                ry="0"
                                width="250"
                                height="50"
                            />
                            <rect
                                x="15"
                                y="410"
                                rx="0"
                                ry="0"
                                width="250"
                                height="50"
                            />
                            <rect
                                x="15"
                                y="470"
                                rx="0"
                                ry="0"
                                width="250"
                                height="50"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="profile-info text-white">
                            <div className=" d-flex flex-column align-items-start justify-content-start px-3 w-100 mt-2 pt-5">
                                <div className="d-flex flex-row justify-content-around align-items-center mt-3 px-3">
                                    <div
                                        className="flex-shrink-1"
                                        style={{ borderRadius: "18%",border:'2px solid #fff' }}
                                    >
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + parent.image}
                                            placeholder={
                                                "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                            }
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "25vw",
                                                        objectFit: "cover",
                                                        height: "25vw",
                                                        borderRadius: "15px",
                                                        backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat:
                                                            "none",
                                                        // boxShadow: "0px 0px 15px 8px #cccccc50",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div className="flex-grow-1 ms-4">
                                        <div className="d-flex flex-column ">
                                            <span className="text-white fw-bold fs-4">
                                                {parent?.name}
                                            </span>
                                            <span className="text-white fs-6 mt-2">
                                                Parent
                                                <Link to="/parent-edit">
                                                    <span className="position-absolute ps-2 ">
                                                        <RiEditLine
                                                            color="white"
                                                            size={22}
                                                        />
                                                    </span>
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="d-flex flex-column p-3 position-absolute"
                            style={{
                                backgroundColor: "#ffffff",
                                height: "100%",
                                width: "100%",
                                marginTop: "220px",
                                borderRadius: "18px 18px 0px 0px",
                            }}
                        >
                            <div>
                                <span className="px-3 text-black fw-bold fs-4">
                                    General
                                </span>
                            </div>
                            <div className="flex-column p-1">
                                <div className="profileform mt-2 p-3">
                                    <span className="text-muted">Phone</span>
                                    <span className="fw-bold">
                                        {parent?.phone}
                                    </span>
                                </div>
                            </div>

                            <div className="flex-column p-1">
                                <div className="profileform mt-2 p-3">
                                    <span className="text-muted">DOB</span>
                                    <span className="fw-bold">
                                        {parent?.date_of_birth}
                                    </span>
                                </div>
                            </div>
                            <div className="flex-column p-1">
                                <div className="profileform mt-2 p-3">
                                    <span className="text-muted">E-mail</span>
                                    <span className="fw-bold">
                                        {parent?.email} 
                                    </span>
                                </div>
                            </div>
                            {parent?.students?.map((data) => (
                                <div className=" mt-3 flex-column p-1 profileform">
                                    <div className="p-2 d-flex flex-row  justify-content-between">
                                        <span className="text-muted">
                                            Student
                                        </span>
                                        <span className="fw-bold">
                                            {data?.user?.name}
                                        </span>
                                    </div>
                                    <div className="p-2 d-flex flex-row  justify-content-between">
                                        <span className="text-muted">
                                            Class
                                        </span>
                                        <span className="fw-bold">
                                            {data?.student_class?.name}
                                        </span>
                                    </div>
                                    <div className="p-2 d-flex flex-row  justify-content-between">
                                        <span className="text-muted">
                                            Section
                                        </span>
                                        <span className="fw-bold">
                                            {data?.section?.name}
                                        </span>
                                    </div>
                                </div>
                            ))}

                            {/* <div className="px-3 flex-column p-1 ">
                                    <div className='profileform mt-2 pt-4'>
                                        <span className="text-muted">Parent job</span>
                                        <span className="fw-bold">{parent?.nam}</span>
                                    </div>
                                </div> */}
                        </div>
                        <div>
                            <Footer current={"profile"} />
                        </div>
                    </React.Fragment>    
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    parent: state.user.parent,
});

export default connect(mapStateToProps, { getParentProfile })(ParentProfile);
