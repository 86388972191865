import { TEACHER_SIGNUP, TEACHER_COMMON_API, LOGOUT_USER ,GET_ACTIVITY, GET_HOMEWORK, GET_TEACHER_FEEDBACK,} from "./actionType";


const initialState = {
    teacher: [],
    schooldata: [],
    activity: [],
    homework: [],
    feedback: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case TEACHER_SIGNUP:
            return { ...state, teacher: action.payload };
        case TEACHER_COMMON_API:
            return { ...state, schooldata: action.payload };
        case LOGOUT_USER:
            return { ...state, teacher: action.payload };
        case GET_ACTIVITY:
            return { ...state, activity: action.payload };
        case GET_HOMEWORK:
            return { ...state, homework: action.payload };
        case GET_TEACHER_FEEDBACK:
            return { ...state, feedback: action.payload };
        default:
            return state;
    }
}
