import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../../elements/BackButton";
import { downloadAttendenceData } from '../../../../redux/Parent/class/action'

export class AttendenceList extends Component {
    state = {
        loading: false
    }
    downloadAttendence = (e) => {
        e.preventDefault()
        this.setState({ loading: true });
        const { user, attendence } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.downloadAttendenceData(user?.data?.auth_token, student_id.value, attendence.data.start_date, attendence.data.end_date).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false });
                // window.location.replace("/attendence-list")
            }
        })
    }
    render() {
        const { attendence } = this.props;
        return (
            <React.Fragment>
                <div style={{ paddingBottom: '23vw' }}>
                    <div>
                        <BackButton title={"Attendence List"} />
                    </div>
                    <div className="d-flex flex-column px-3 mt-3" style={{ fontWeight: '600' }}>
                        <div>
                            Name: {attendence?.student?.user?.name}
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div>
                                Class: {attendence?.student?.student_class?.name}
                            </div>
                            <div>
                                Section: {attendence?.student?.section?.name}
                            </div>
                        </div>
                    </div>
                    <div className=" liveclass gap-2 hidden-scroll-bar px-3 py-3" style={{ gridTemplateColumns: "repeat(3,1fr)", overflowX: "scroll", }}>
                        <div className="present  d-flex flex-column align-items-center justify-content-between p-1 ">
                            <div className="pt-2" style={{ fontSize: "18px", fontWeight: "700" }}  >
                                {attendence?.data?.present}
                            </div>
                            <div className="px-2 py-1 mt-2 w-100 text-center present-col">
                                Present
                            </div>
                        </div>

                        <div className="present  d-flex flex-column align-items-center justify-content-between p-1 ">
                            <div className="pt-2" style={{ fontSize: "18px", fontWeight: "700" }}  >
                                {attendence?.data?.half_day}
                            </div>
                            <div className="px-2 py-1 mt-2 w-100 text-center half-col"   >
                                Half Day
                            </div>
                        </div>

                        <div className="present  d-flex flex-column align-items-center justify-content-between p-1 ">
                            <div className="pt-2" style={{ fontSize: "18px", fontWeight: "700" }} >
                                {attendence?.data?.absent}
                            </div>
                            <div
                                className="px-2 py-1 mt-2 w-100 text-center absent-col"  >
                                Absent
                            </div>
                        </div>
                        <div className="absent  d-flex flex-column align-items-center justify-content-between p-1">
                            <div className="pt-2" style={{ fontSize: "18px", fontWeight: "700" }}  >
                                {attendence?.data?.present > 0 ? Math.round((attendence?.data?.present / attendence?.data?.total) * 100) : 0}%
                            </div>
                            <div className="px-2 py-1 mt-2 w-100 text-center present-col" >
                                Present %
                            </div>
                        </div>

                        <div className="absent  d-flex flex-column align-items-center justify-content-between p-1">
                            <div className="pt-2" style={{ fontSize: "18px", fontWeight: "700" }}   >
                                {attendence?.data?.half_day > 0 ? Math.round((attendence?.data?.half_day / attendence?.data?.total) * 100) : 0}%
                            </div>
                            <div className="px-2 py-1 mt-2 w-100 text-center half-col" >
                                Half Day %
                            </div>
                        </div>

                        <div className="absent  d-flex flex-column align-items-center justify-content-between p-1">
                            <div className="pt-2" style={{ fontSize: "18px", fontWeight: "700" }}   >
                                {attendence?.data?.absent > 0 ? Math.round((attendence?.data?.absent / attendence?.data?.total) * 100) : 0}%
                            </div>
                            <div className="px-2 py-1 mt-2 w-100 text-center absent-col"     >
                                Absent %
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between px-3" style={{ fontWeight: '600' }}>
                        <div>
                            Total Days : <span>  {attendence?.data?.total > 0 ? attendence?.data?.total : 0}</span>
                        </div>
                        <div>
                            Total holidays : <span>  {attendence?.data?.holiday ? attendence?.data?.holiday : 0}</span>
                        </div>
                    </div>

                    <div className="px-3" >
                        <div className="panel-board px-3 py-3 mt-3">
                            <table className="w-100">
                                <tr className="panel-head  px-3">
                                    <th className="panel-head-1 text-center ">
                                        Date
                                    </th>
                                    <th className="panel-head-3 text-center  " >
                                        Status
                                    </th>
                                </tr>
                                {attendence && attendence.attendence && attendence.attendence.map((value, index) => (
                                    <tr className="panel-content">
                                        <td className="py-2 text-center"> {value?.date}</td>
                                        <td className="text-center">
                                            {value?.details == 'ABSENT' ? (
                                                <span className="px-2 py-1" style={{ backgroundColor: "#DB3665", borderRadius: "6px", color: "#fff" }} >
                                                    Absent
                                                </span>
                                            ) : value?.details == 'FULL' ? (
                                                <span className="px-2 py-1" style={{ backgroundColor: "#58B151", borderRadius: "6px", color: "#fff" }} >
                                                    Present
                                                </span>
                                            ) : value?.details == 'MORNING' || value?.details == 'MORNING' ? (
                                                <span className="px-2 py-1" style={{ backgroundColor: "#51BBD2", borderRadius: "6px", color: "#fff" }} >
                                                    Half day
                                                </span>
                                            ) : value?.details == 'HOLIDAY' ? (
                                                <span className="px-2 py-1" style={{ backgroundColor: "#b751d2", borderRadius: "6px", color: "#fff" }} >
                                                    Holiday
                                                </span>
                                            ) : value?.details == 'WEEKEND' ? (
                                                <span className="px-2 py-1" style={{ backgroundColor: "#ce3939", borderRadius: "6px", color: "#fff" }} >
                                                    Weekend
                                                </span>
                                            ) : ""}
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>
                    </div>

                </div>
                <div className=" px-3 py-2 position-fixed bottom-0 w-100 " style={{ backgroundColor: '#fff' }}>
                    <button className="attendence-dwld-btn py-3 w-100" onClick={(e) => this.downloadAttendence(e)}>
                        Download
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    attendence: state.class.attendence
});
export default connect(mapStateToProps, {
    downloadAttendenceData
})(AttendenceList);
