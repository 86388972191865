import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../elements/BackButton";
import { getAttendenceData } from '../../../redux/Parent/class/action'
// import {getStudent} from '../../../redux/Parent/user/action'
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import Shake from "react-reveal/Shake";

class Attendence extends Component {
    state = {
        student: [],
        start_date: '',
        end_date: '',
        noData: false
    };
    __submit = (e) => {
        e.preventDefault();
        this.setState({ loading: true, });
        const { start_date, end_date } = this.state;
        let start = start_date ? start_date : moment().format("YYYY-MM-DD");
        let end = end_date ? end_date : moment().format("YYYY-MM-DD");
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.getAttendenceData(user.data.auth_token, student_id.value, start, end).then((response) => {
            if (response && response.payload && response.payload.success) {
                if (response.payload.attendence?.length > 0) {
                    window.location.replace("/attendence-list")
                } else {
                    this.setState({ loading: false, noData: true });
                }
            }
        })
    }

    notTomorrow = () => {
        let date;
        date = moment().format("YYYY-MM-DD");
        return date;
    }
    render() {
        const { student } = this.props;
        const { start_date, end_date } = this.state;
        return (
            <React.Fragment>
                <BackButton title={"Attendence"} />
                <Form onSubmit={(e) => this.__submit(e)}>

                    <div className="p-3">
                        <div className='d-flex justify-content-between mt-3 homework-input'>
                            <div className='text-muted'> Class  </div>
                            <div style={{ fontWeight: '600' }}> {student?.student_class?.name}  </div>
                        </div>

                        <div className='d-flex justify-content-between mt-3 homework-input'>
                            <div className='text-muted'> Section  </div>
                            <div style={{ fontWeight: '600' }}>  {student?.section?.name} </div>
                        </div>

                        <div className='d-flex justify-content-between mt-3 homework-input'>
                            <div className='text-muted'> Student  </div>
                            <div style={{ fontWeight: '600' }}>  {student?.user?.name}  </div>
                        </div>

                        <Form.Group className="mt-2">
                            <Form.Label className="text-muted">Start Date</Form.Label>
                            <Form.Control type='date' className='input-homework w-100'
                                placeholder='Start Date' required
                                value={start_date ? start_date : this.notTomorrow()}
                                max={this.notTomorrow()}
                                onChange={(e) => {
                                    let start_date = e.target.value ? e.target.value : this.notTomorrow();
                                    this.setState({ start_date: start_date, noData: false });
                                }} />
                        </Form.Group>

                        <Form.Group className="mt-2" >
                            <Form.Label className="text-muted">End Date</Form.Label>
                            <Form.Control type='date' className='input-homework w-100'
                                placeholder='Start Date' required
                                value={end_date ? end_date : this.notTomorrow()}
                                min={start_date}
                                max={this.notTomorrow()}
                                onChange={(e) => {
                                    let end_date = e.target.value ? e.target.value : this.notTomorrow();
                                    this.setState({ end_date: end_date, noData: false });
                                }} />
                        </Form.Group>
                    </div>
                    {this.state.noData &&
                        <Shake duration={250} right>
                            <div className="text-center">
                                <div style={{ color: '#c24a6b', fontSize: '1.2em', fontWeight: '600' }}>
                                    No Data Found
                                </div>
                                <div style={{ color: '#c24a6b'}}>
                                    (Change Date)
                                </div>
                            </div>
                        </Shake>
                    }

                    <div className="gap-2 px-2 attendence-btn-div w-100">
                        <button type="submit" className="attendence-btn w-100 py-3">
                            Get
                        </button>

                    </div>
                </Form>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user.parent,
    student: state.user.student.student
});


export default connect(mapStateToProps, {
    getAttendenceData,
})(Attendence);
