import React, { Component } from 'react'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import Modal from "react-modal";
import { CgCheckO } from 'react-icons/cg';
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton';
import { sentAllergies, getAllAllergies } from '../../../redux/Parent/user/action';
import { BsFillChatTextFill } from 'react-icons/bs';
export class Allergies extends Component {

    state = {
        open: false,
        instruction: ""
    }
    componentDidMount() {
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.getAllAllergies(user.data.auth_token, student_id.value);
    }
    handleInput = (e) => {
        this.setState({ instruction: e });
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));

        this.props.sentAllergies(user.data.auth_token, student_id.value, this.state.instruction).then((response) => {
            console.log(response);
            if (response.data.success) {
                this.modalOpen();
                e.target.reset();
            } else {
                console.log("Something went wrong");
            }
        });

    }
    modalOpen = () => {
        this.setState({ open: true });
    };
    modalClose = () => {
        this.setState({ open: false });
    };
    render() {

        let student_id = JSON.parse(localStorage.getItem("student_id"));
        const {allergies} = this.props;
        return (
            <React.Fragment>
                <BackButton title={"Add Allergies"} />
                <Form onSubmit={this.onSubmit}>
                    <div className='px-3 mt-4'>

                        <div className="mt-3">
                            <div className=" flex-column p-1">
                                <div className="d-flex justify-content-between mt-3 homework-input">
                                    <div className="text-muted"> Student </div>
                                    <div style={{ fontWeight: "600" }}>
                                        {student_id?.label}
                                    </div>
                                </div>
                            </div>

                            <InputGroup hasValidation className="mt-2 " style={{ boxSizing: 'border-box' }} >
                                <FormControl

                                    type="text"
                                    placeholder="Instructions"
                                    className="attendence-input"
                                    onChange={e => {
                                        let value = e.target.value;
                                        this.handleInput(value);
                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <div className=' py-2 w-100 px-3'>
                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                            Submit
                        </button>
                    </div>
                </Form>


                <React.Fragment>
                    <div className="d-flex flex-column align-content-around justify-content-around mt-5 px-3 pb-7">
                        {allergies?.data?.map((data) => (
                        <div
                            className="appointment-card py-2 px-2 b-r-10 mt-4"
                        >
                            <div className="d-flex flex-row justify-content-around align-items-center ">

                                <div className="flex-grow-1 ps-2">
                                    <div className="d-flex flex-column h-100 justify-content-between align-content-between">

                                        <span className="  fs-8 fw-500">
                                            {student_id.label}
                                        </span>
                                        <span className=" fs-10 ">
                                            Instruction:<br/>
                                            {data?.instructions}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </React.Fragment>

                <Modal
                    isOpen={this.state.open}
                    ariaHideApp={false}
                    onRequestClose={() => this.modalClose()}
                    style={modalStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "70vw",
                            width: '70vw',
                            zIndex: "9999",
                        }}
                    >
                        <React.Fragment>
                            <div className="d-flex flex-column justify-content-center align-items-between">
                                <div className='d-flex justify-content-center mt-5'
                                    style={{ fontSize: '18px', fontWeight: '600', color: '#2B2C2F' }}>
                                    <CgCheckO color={"#7ECB40"} size={"4em"} />
                                </div>
                                <div className='d-flex justify-content-center mt-4'
                                    style={{ fontSize: '15px', fontWeight: '400', color: '#2B2C2F' }}>
                                    Allergie Added Successfully</div>
                                <div className='d-flex justify-content-between w-100 position-fixed' style={{ bottom: '20%', left: '0%' }}>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    allergies: state.user.allergies

})



export default connect(mapStateToProps, { sentAllergies, getAllAllergies })(Allergies)

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #C24A6B",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
