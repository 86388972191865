import React, { Component } from "react";
import { connect } from "react-redux";
import avatar from "../../../assets/Images/avatar.png";
import "../../../assets/css/custom.css";
import Button from "react-bootstrap/Button";
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";
import { getAllOnlineTest } from "../../../redux/Parent/onlineTest/action";
import moment from 'moment';
import { Link } from "react-router-dom";
import img from "../../../assets/Images/online-test-nodata.svg";
class AllOnlineTest extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
        const { user } = this.props;
        console.log("helloo");
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.getAllOnlineTest(user.data.auth_token,student_id?.value);
    }
    render() {
        const { onlineExam } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 550"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="8"
                                y="40"
                                rx="8"
                                ry="8"
                                width="27"
                                height="27"
                            />
                            <rect
                                x="125"
                                y="44"
                                rx="7"
                                ry="7"
                                width="54"
                                height="15"
                            />

                            <rect
                                x="13"
                                y="127"
                                rx="10"
                                ry="10"
                                width="255"
                                height="40"
                            />
                            <rect
                                x="13"
                                y="187"
                                rx="10"
                                ry="10"
                                width="255"
                                height="40"
                            />
                            <rect
                                x="13"
                                y="255"
                                rx="10"
                                ry="10"
                                width="255"
                                height="40"
                            />
                            <rect
                                x="13"
                                y="323"
                                rx="10"
                                ry="10"
                                width="255"
                                height="40"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className=" px-2">
                            <BackButton title={"Online Test"} />
                        </div>
                        {onlineExam?.data?.length ? (
                        <div
                            className="d-flex flex-column align-content-around justify-content-around  px-3 pb-4"
                            style={{ marginTop: "2rem" }}
                        >
                            {onlineExam?.data?.map((exam) => (
                                <div className="d-flex flex-row justify-content-between align-items-center mt-5 p-2" style={{
                                     boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                                     borderRadius: "10px"
                                }}>
                                    <div className="flex-grow-1 ms-1">
                                        <div className="d-flex flex-column align-items-start justify-content-center">
                                            <span
                                                className="pb-1"
                                                style={{
                                                    fontWeight: "500",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                {exam.name}
                                            </span>
                                            <span
                                                style={{
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                    color: "#4E5556",
                                                }}
                                            >
                                                {exam?.subject?.name}
                                            </span>
                                            <span
                                                style={{
                                                    fontWeight: "400",
                                                    fontSize: "10px",
                                                    color: "#4E5556",
                                                }}
                                            >
                                                {moment(exam?.from_time).format('LT') } to {moment(exam?.to_time).format('LT') }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="">
                                    <Link to={"/single-test/" + exam?.id}>
                                        <Button
                                            className="joining-btn"

                                        >
                                            Join
                                        </Button>
                                    </Link>
                                    </div>
                                </div>
                            ))}
                        </div>):(
                            <div className="text-center mt-5">
                                <img src={img} alt="online-test"></img>
                                <span className="text-center fs-5 fw-500">No Exam Available</span>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    onlineExam: state.onlineTest.onlineTest,
});

export default connect(mapStateToProps, { getAllOnlineTest })(AllOnlineTest);
