import React, { Component } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import ProgressiveImage from "react-progressive-image";
import { connect } from "react-redux";
import teacher from "../../../assets/Images/bg.png";
import Tab from "../../../assets/Images/tab.png";
import cap from "../../../assets/Images/glass.png";
import { IMAGE_BASE_URL } from "../../../api";
import BackButton from "../../../elements/BackButton";
import { getAllTeachers } from "../../../redux/Parent/class/action";
import noTeacher from "../../../assets/Images/teacher.png";
import { RiUserStarFill } from "react-icons/ri";
import { AiFillStar, AiFillWechat } from "react-icons/ai";
import { Link } from "react-router-dom";

export class ViewTeachers extends Component {
    state = {
        loading: false,
        teachers: [],
        classTeacher: [],
    };

    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props
            .getAllTeachers(user.data.auth_token, student_id.value)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        teachers: response.payload.teachers,
                        classTeacher: response.payload.classTeacher,
                    });
                }
            });

    }
    render() {
        const { teachers, classTeacher } = this.state;
        const { user } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment></React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"View Teachers"} />

                        {(teachers && teachers.length > 0) || classTeacher  ? (
                            <div className="px-4 viewteacher hidden-scroll-bar gap-3 mt-4"
                                style={{ gridTemplateRows: `repeat(${teachers.length + 1},1fr)`, overflowX: "scroll", paddingBottom: "5vw", }}>
                                {classTeacher && (
                                    <div className="teacher-div d-flex flex-column align-items-center justify-content-between">
                                        <ProgressiveImage delay={20} src={IMAGE_BASE_URL + classTeacher?.user?.image} placeholder={teacher}  >
                                            {(src, loading) => (
                                                <img src={src}
                                                    style={{ width: "100%", height: "62vw", borderRadius: "20px", objectFit: "cover", backgroundRepeat: "round", }}
                                                    className="" alt="grosav" />
                                            )}
                                        </ProgressiveImage>
                                        {classTeacher.is_class_teacher ? (
                                            <div className="position-absolute badge-teacher p-1 fw-bolder"
                                                style={{ top: "2vw", right: "2vw", }} >
                                                Class Teacher{" "}
                                                <AiFillStar style={{ fontSize: "1.3rem", color: "yellow", }} />
                                            </div>
                                        ) : ("")}
                                        <div className="position-absolute badge-teacher p-1 fw-bolder"
                                            style={{ top: "13vw", right: "2vw", }} >
                                            <Link to={"/single-chat/" + user?.data.id + "/" + classTeacher.id} style={{ color: "#000" }} >
                                                <AiFillWechat style={{ fontSize: "1.3rem", color: "#c24a6b", }} />
                                                Chat Now
                                            </Link>
                                        </div>
                                        <div className="wrapper-teacher d-flex flex-row align-items-center justify-content-between w-100">
                                            <div className="w-100 position-relative">
                                                <div className="px-2 py-3 no-blur d-flex align-items-center justify-content-between w-100">
                                                    <div className="no-blur d-flex flex-column   justify-content-start">
                                                        <div style={{ fontSize: "16px", fontWeight: "500", }}>{classTeacher?.user?.name}</div>
                                                        <div style={{ fontSize: "11px", fontWeight: "400", }}>{classTeacher?.subject?.name}</div>
                                                    </div>
                                                    <Link to={"/single-teacher/" + classTeacher.id} style={{ color: "#000", }}  >
                                                        <div className="p-2 " style={{ backgroundColor: "white", borderRadius: "12px", }}  >
                                                            View Teacher{" "} <IoIosArrowForward style={{ fontSize: "1.3rem", }} />
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {teachers.map((value, index) => (
                                    <div className="teacher-div d-flex flex-column align-items-center justify-content-between">
                                        <ProgressiveImage delay={20} src={IMAGE_BASE_URL + value?.user?.image} placeholder={teacher}  >
                                            {(src, loading) => (
                                                <img src={src} style={{ width: "100%", height: "62vw", borderRadius: "20px", objectFit: "cover", backgroundRepeat: "round", }}
                                                    className="" alt="grosav" />
                                            )}
                                        </ProgressiveImage>
                                        <div className="position-absolute badge-teacher p-1 fw-bolder" style={{ top: "2vw", right: "2vw" }} >
                                            <Link to={"/single-chat/" + user?.data.id + "/" + value.id} style={{ color: "#000" }}>
                                                <AiFillWechat style={{ fontSize: "1.3rem", color: "#c24a6b", }} />
                                                Chat Now
                                            </Link>
                                        </div>
                                        <div className="wrapper-teacher d-flex flex-row align-items-center justify-content-between w-100">
                                            <div className="w-100 position-relative">
                                                <div className="px-2 py-3 no-blur d-flex align-items-center justify-content-between w-100">
                                                    <div className="no-blur d-flex flex-column   justify-content-start">
                                                        <div style={{ fontSize: "16px", fontWeight: "500", }}>
                                                            {value?.user?.name}
                                                        </div>
                                                        <div style={{ fontSize: "11px", fontWeight: "400", }}>
                                                            {value?.subject?.name}
                                                        </div>
                                                    </div>
                                                    <Link to={"single-teacher/" + value.id} style={{ color: "#000", }}  >
                                                        <div className="p-2 " style={{ backgroundColor: "white", borderRadius: "12px", }} >
                                                            View Teacher{" "}  <IoIosArrowForward style={{ fontSize: "1.3rem", }} />
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center pt-4">
                                <img src={noTeacher} style={{ width: "80%" }} alt="grosav" />
                                <div className="text-center fw-bolder mt-2">
                                    No Teachers Found
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
});
export default connect(mapStateToProps, {
    getAllTeachers,
})(ViewTeachers);
