import React, { Component } from "react";
import { connect } from "react-redux";
import ContentLoader from 'react-content-loader'
import homework2 from '../../../assets/Images/homework2.png'
import BackButton from '../../../elements/BackButton';
import { getHomeWorks } from "../../../redux/Parent/class/action";
import { Link } from 'react-router-dom'
import noHomework from '../../../assets/Images/noHomework.png'

class StudentHomework extends Component {
    state = {
        loading: true,
        homeworks: []
    }
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getHomeWorks(user.data.auth_token, student_id ? student_id.value : user?.data?.students[0]?.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, homeworks: response.payload.homeworks });
            }
        })
    }
    render() {
        const { homeworks } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader viewBox="0 0 280 539" backgroundColor={'#F3F3F3'}
                            foregroundColor={'#FFFFFF'}>
                            <rect x="13" y="40" rx="10" ry="10" width="255" height="100" />
                            <rect x="13" y="170" rx="10" ry="10" width="255" height="100" />
                            <rect x="13" y="300" rx="10" ry="10" width="255" height="100" />
                            <rect x="13" y="430" rx="10" ry="10" width="255" height="100" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={'Home works'} />
                        <div style={{ paddingBottom: '50px' }}>
                            {homeworks?.length > 0 ? homeworks?.map((data) => (
                                <Link to={'/single-homework/' + data.id}>
                                    <div className="d-flex flex-row justify-content-start p-2 mx-3 align" style={{ backgroundColor: "#F6F5F6" }}>
                                        <img src={homework2} alt="classs-report" />
                                        <div className="d-flex flex-column  ms-3 ">
                                            <span className="mt-1 text-dark" style={{ fontWeight: "600", fontSize: '1.2em' }} >Questions</span>
                                            <span className="text-muted" style={{ fontSize: '.8em' }} >Subject : {data?.subject?.name}</span>
                                            <span className="text-muted" style={{ fontSize: '.8em' }}>Class {data?.section?.student_class?.name} Section {data?.section?.name}</span>
                                        </div>
                                    </div>
                                </Link>
                            )) : (
                                <React.Fragment>
                                    <img src={noHomework} style={{ width: '100%' }} />
                                    <div className="text-center fw-bolder mt-2">No Homework Found</div>
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                )}

            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.user.parent,
});

export default connect(mapStateToProps, { getHomeWorks })(StudentHomework);
