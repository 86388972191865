import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../../elements/BackButton'
import noSection from '../../../../assets/Images/noSection.png'
import moment from 'moment'
import { getAttendenceData, addStudentAttendence } from "../../../../redux/Teacher/student/action"

export class SelectClass extends Component {
    state = {
        loading: false,
        type: this.props.match.params.type,
        date: '',
        classTeacher: [],
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { teacher } = this.props;
        this.props.getAttendenceData(teacher?.data?.auth_token, teacher?.data?.staff?.user_id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, classTeacher: response.payload.classTeacher })
            }
        })
    }

    addAttendence = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const { teacher } = this.props;
        const { date } = this.state;
        this.props.addStudentAttendence(teacher?.data?.auth_token, teacher?.data?.staff?.user_id, date ? date : moment().format("Y-M-D")).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.props.history.replace('/show-all-attendence')
            }
        })
    }
    render() {
        const { loading, type, date, classTeacher } = this.state
        return (
            <React.Fragment>
                {loading ? (
                    <React.Fragment>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={type + " " + "Attendence"} />
                        {classTeacher ? (
                            <React.Fragment>
                                <div className='px-3 mt-5'>
                                    <div className="position-relative mt-5">
                                        <div className='d-flex justify-content-between mt-3 homework-input'>
                                            <div className='text-muted'> Class  </div>
                                            <div style={{ fontWeight: '600' }}>  {classTeacher?.section?.student_class?.name}  </div>
                                        </div>
                                        <div className='d-flex justify-content-between mt-3 homework-input'>
                                            <div className='text-muted'> Section  </div>
                                            <div style={{ fontWeight: '600' }}>  {classTeacher?.section?.name}  </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className='px-2 text-muted' style={{ fontSize: '.9em' }}>
                                            Select Date
                                        </div>
                                        <input type="date" className="text-muted attendence-input px-3" style={{ fontSize: '13px' }} placeholder='Date'
                                            value={date ? date : moment().format("Y-M-D")}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                console.log(value)
                                                this.setState({ date: value })
                                            }} required />
                                    </div>
                                </div>

                                {type == 'Edit' ?
                                    <div className='position-fixed bottom-0 py-2 w-100 px-3' onClick={(e) => this.addAttendence(e)}>
                                        {/* edit submit */}
                                        <button className='btn attendence-dwld-btn py-3 w-100' >
                                            Get
                                        </button>
                                    </div>
                                    :
                                    <div className='position-fixed bottom-0 py-2 w-100 px-3' onClick={(e) => this.addAttendence(e)}>
                                        {/* add submit */}
                                        <button className='btn attendence-dwld-btn py-3 w-100' >
                                            Get
                                        </button>
                                    </div>
                                }
                            </React.Fragment>
                        ) : (
                            <div className='mt-5 pt-5 w-100 d-flex flex-column justify-content-center align-items-center fw-bolder'>
                                <img src={noSection} alt="no reviews" style={{ width: '65vw' }} className='' />
                                <span> You are Not A Class Teacher...</span>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher
})

export default connect(mapStateToProps, {
    getAttendenceData,
    addStudentAttendence
})(SelectClass)
const options = [
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
]

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        border: 'none', // tried border: 'none'
        boxShadow: 'none', // tried border: 'none'
        outline: 'none',

    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "88vw",
        border: "none",
        boarderRadius: "10px",
        fontSize: '13px',
        borderStyle: 'none',
        background: 'rgba(190, 175, 179, 0.05)',
        outline: 'none',
        fontWeight: '400',
        lineHeight: '14px',
        letterSpacing: '1px',
        color: '#86878E',
    })

}
