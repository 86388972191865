import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../elements/BackButton";
import { AiFillBell } from "react-icons/ai";
import { BsChevronRight, BsFillBellFill, BsFillQuestionCircleFill, BsToggle2Off } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { GoPerson } from "react-icons/go";
import { Link } from "react-router-dom";
export class Settings extends Component {

    render() {
        return (
            <>
                <div>
                    <div
                        className="d-flex flex-column gsettings-bg"
                        style={{ height: "100vh" }}
                    >
                        <div style={{ marginTop: "5%" }}>
                            <BackButton
                                title="Settings"
                                color={
                                    this.props.color
                                        ? this.props.color
                                        : "white"
                                }
                            />
                        </div>
                        <div className="settings-curve px-3 pt-3 d-flex flex-column align-items-center ">
                            <div className="settings-panel d-flex flex-column px-4 py-2 w-100">
                                {/* <div className="d-flex flex-row justify-content-between align-items-center py-3">
                                    <div className="d-flex flex-row align-items-center">
                                        <div
                                            className="icon-symbol"
                                            style={{
                                                backgroundColor: "#5ccbf1",
                                            }}
                                        >
                                            <AiFillBell size={18} />
                                        </div>
                                        <div className="d-flex flex-column justify-content-around ps-3">
                                            <div
                                                className="fw-bolder"
                                                style={{ fontSize: ".9em" }}
                                            >
                                                Notifications
                                            </div>
                                            <div
                                                className="text-muted"
                                                style={{ fontSize: ".7em" }}
                                            >
                                                App notifications
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-muted">
                                        <BsToggle2Off size={28} />
                                    </div>
                                </div>
                                <hr
                                    className="text-muted"
                                    style={{ margin: "0em" }}
                                /> */}
                                <Link to="/change-password">
                                    <div className="d-flex flex-row justify-content-between align-items-center py-3">
                                        <div className="d-flex flex-row align-items-center">
                                            <div className="icon-symbol"
                                                style={{ backgroundColor: "#F18A5C", }}>
                                                <FaLock size={17} />
                                            </div>
                                            <div className="d-flex flex-column justify-content-around ps-3">
                                                <div className="fw-bolder text-muted" style={{ fontSize: ".9em" }}>
                                                    Password
                                                </div>
                                                <div className="text-muted" style={{ fontSize: ".7em" }}>
                                                    Change password
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-muted fw-bolder">
                                            <BsChevronRight size={15} />
                                        </div>
                                    </div>
                                </Link>

                                <hr className="text-muted" style={{ margin: "0em" }} />

                                <Link to="/help">
                                    <div className="d-flex flex-row justify-content-between align-items-center py-3">
                                        <div className="d-flex flex-row align-items-center">
                                            <div className="icon-symbol" style={{ backgroundColor: "#5CF198", }}>
                                                <BsFillQuestionCircleFill size={18} />
                                            </div>
                                            <div className="d-flex flex-column justify-content-around ps-3">
                                                <div className="fw-bolder text-muted" style={{ fontSize: ".9em" }}>
                                                    Help
                                                </div>
                                                <div className="text-muted" style={{ fontSize: ".7em" }}>
                                                    Report a problem
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-muted fw-bolder">
                                            <BsChevronRight size={15} />
                                        </div>
                                    </div>
                                </Link>


                                <hr className="text-muted" style={{ margin: "0em" }} />

                                <Link to="/about-us">
                                    <div className="d-flex flex-row justify-content-between align-items-center py-3">
                                        <div className="d-flex flex-row align-items-center">
                                            <div className="icon-symbol" style={{ backgroundColor: "#C24A6B", }}>
                                                <GoPerson size={18} />
                                            </div>
                                            <div className="d-flex flex-column justify-content-around ps-3">
                                                <div className="fw-bolder text-muted" style={{ fontSize: ".9em" }}>
                                                    About us
                                                </div>
                                                <div className="text-muted" style={{ fontSize: ".7em" }}>
                                                    Report a problem
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-muted fw-bolder">
                                            <BsChevronRight size={15} />
                                        </div>
                                    </div>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(Settings);
