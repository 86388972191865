import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProgressiveImage from "react-progressive-image";
import moment from 'moment';
import noImage from '../../../../assets/Images/noImage.png'
import BackButton from "../../../../elements/BackButton";
import img from '../../../../assets/Images/1234.jpg'
import { getSingleStudentInfo } from '../../../../redux/Teacher/student/action';
import { IMAGE_BASE_URL } from '../../../../api';
import ContentLoader from 'react-content-loader';
export class SingleStudent extends Component {
    state = {
        id: this.props.match.params.id,
        loading: false,
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { user } = this.props;
        this.props.getSingleStudentInfo(user.data.auth_token, this.state.id);
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1500)
    }

    render() {
        const { student } = this.props;
        return (

            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 236 470"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="10" y="30" rx="5" ry="5" width="25" height="25" />
                            <rect x="110" y="90" rx="4" ry="4" width="100" height="10" />
                            <rect x="111" y="110" rx="3" ry="3" width="90" height="7" />

                            <circle cx="60" cy="110" r="35" />

                            <rect x="13" y="158" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="206" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="252" rx="10" ry="10" width="210" height="40" />
                            <rect x="13" y="298" rx="10" ry="10" width="210" height="40" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className='teacher-image position-relative' >


                            <BackButton color='white' />

                            <div className="d-flex flex-row position-absolute pt-2" style={{ left: '8px', bottom: '5px' }}>
                                <div className="py-2 px-3">
                                    <ProgressiveImage
                                        delay={20}
                                        src={img}
                                        placeholder={noImage}
                                    >
                                        {(src, loading) => (
                                            <img
                                                src={IMAGE_BASE_URL + student?.data?.user?.image}
                                                style={{
                                                    width: "25vw",
                                                    height: "25vw",
                                                    borderRadius: "50px",
                                                    objectFit: "cover",
                                                    backgroundRepeat: "round",
                                                    border: '2px solid #fff',
                                                    outline: '1px soild #fff'
                                                }}
                                                className=""
                                                alt="grosav"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>

                                <div className="d-flex flex-column ms-2 mt-2 text-white">
                                    <span className="text-white fw-bold" style={{ fontSize: '1.8em' }}>{student?.data?.user?.name}</span>
                                    <span className="text-white fw-bold">{student?.data?.student_class?.name} - {student?.data?.section?.name}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="px-3 flex-column p-1 pt-4">
                                <div className="student-info-div mt-2 pt-3">
                                    <span className="student-roles w-50">
                                        Phone
                                    </span>
                                    <span className="student-details-row w-50 text-end">
                                        {student?.data?.father_phone}
                                    </span>
                                </div>
                            </div>
                            <div className="px-3 flex-column p-1">
                                <div className="student-info-div mt-2 pt-3">
                                    <span className="student-roles w-50">
                                        Date of birth
                                    </span>
                                    <span className="student-details-row w-50 text-end">

                                        {moment(student?.data?.user?.date_of_birth).format('DD-MM-YYYY')}
                                    </span>
                                </div>
                            </div>
                            <div className="px-3 flex-column p-1">
                                <div className="student-info-div mt-2 pt-3">
                                    <span className="student-roles w-50">
                                        Address
                                    </span>
                                    <span className="student-details-row w-50 text-end">
                                        {student?.data?.current_address}
                                    </span>
                                </div>
                            </div>
                            <div className="px-3 flex-column p-1">
                                <div className="student-info-div mt-2 pt-3">
                                    <span className="student-roles w-50">
                                        Bus route
                                    </span>
                                    <span className="student-details-row w-50 text-end">
                                        Not Updated
                                        {student?.data?.user?.routes?.name}
                                    </span>
                                </div>
                            </div>
                            <div className="px-3 flex-column p-1">
                                <div className="student-info-div mt-2 pt-3">
                                    <span className="student-roles w-50">
                                        Lives In
                                    </span>
                                    {student?.data?.is_hostler ? (
                                        <span className="student-details-row w-50 text-end">
                                            Hostel
                                        </span>

                                    ) : "Not A Hostler"}
                                </div>
                            </div>
                            <div className="px-3 flex-column p-1">
                                <div className="student-info-div mt-2 pt-3">
                                    <span className="student-roles w-50">
                                        Admission number
                                    </span>
                                    <span className="student-details-row w-50 text-end">
                                        286543
                                        {student?.data?.admission_number}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    student: state.student.student,
})

export default connect(mapStateToProps, { getSingleStudentInfo })(SingleStudent)
