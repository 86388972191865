import { GET_MARK, VIEW_MARK } from './actionType';

const initialState = {
    mark_info: [],
    marks: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case VIEW_MARK:
            return { ...state, mark_info: action.payload };
        case GET_MARK:
            return { ...state, marks: action.payload };
        default:
            return state;
        
    }
}