import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import img from '../../../assets/Images/nophoto.png'
import ProgressiveImage from "react-progressive-image";
import { getGalleryData } from '../../../redux/Teacher/student/action'
import { IMAGE_BASE_URL } from '../../../api';
export class Gallery extends Component {
    state = {
        category: '',
    }
    componentDidMount() {
        const { teacher } = this.props
        this.props.getGalleryData(teacher.data.auth_token)
            .then((response) => {
                if (response && response.payload.success) {
                    let first = response.payload?.data[0]
                    this.setState({ category: first })
                }
            })
    }
    changeCategory = (data) => {
        this.setState({ category: data })
    }
    render() {
        const { gallery } = this.props
        const { category } = this.state
        return (
            <React.Fragment>
                {gallery?.success ?
                    <>
                        <BackButton title={"Gallery"} />
                        {gallery?.data?.length > 0 ?
                            <div className='px-3 mt-4'>
                                {/* scroll */}
                                <div className="px-2 d-grid gap-3 hidden-scroll-bar" style={gallery?.data?.length > 0 ? { gridTemplateColumns: `repeat(4,1fr)`, overflowX: "scroll", } : { gridTemplateColumns: `repeat(4,1fr)`, overflowX: "scroll", }} >
                                    {gallery?.data?.length > 0 && gallery?.data?.map((data) => (
                                        <div onClick={() => this.changeCategory(data)}>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + data.image}
                                                placeholder={
                                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                                }   >
                                                {(src, loading) => (
                                                    <img
                                                        className=""
                                                        style={{
                                                            width: "37vw",
                                                            objectFit: "cover",
                                                            height: "22vw",
                                                            borderRadius: "10px",
                                                            backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                            backgroundRepeat: "round",
                                                        }}
                                                        src={src}
                                                        alt="main-item"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            <div className={data.id == category.id ? 'text-bold fs-6 p-1' : 'text-muted p-1'}><span>{data.name}</span></div>
                                        </div>
                                    ))}
                                </div>
                                {/* banner image */}

                                <div className="mt-3">
                                    <ProgressiveImage
                                        delay={20}
                                        src={IMAGE_BASE_URL + category?.image}
                                        placeholder={
                                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                        }   >
                                        {(src, loading) => (
                                            <img
                                                className=""
                                                style={{ width: '90vw', borderRadius: '25px' }} src={src}
                                                alt="main-item"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>
                                {/* block images */}
                                <div className="mt-1">
                                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2 }}>
                                        <Masonry>
                                            {category?.images?.map((img) => (
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={IMAGE_BASE_URL + img.path}
                                                    placeholder={
                                                        "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                                    }   >
                                                    {(src, loading) => (
                                                        <img
                                                            className=""
                                                            style={{ borderRadius: '20px', padding: '7px' }}
                                                            src={src}
                                                            alt="main-item"
                                                            onClick={this.openViewer} />
                                                    )}
                                                </ProgressiveImage>
                                            ))}
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </div>
                            </div>
                            :
                            <div className='mt-5 pt-5 w-100 d-flex flex-column justify-content-center align-items-center'>
                                <img src={img} alt="no photo" style={{ width: '65vw' }} className='' />
                                <span>No Images Found...</span>
                            </div>
                        }
                    </>
                    : null}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    gallery: state.student.gallery
})

export default connect(mapStateToProps, { getGalleryData })(Gallery)
