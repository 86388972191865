import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import "../../../assets/css/custom.css";
import splash1 from "../../../assets/Images/teacherSplash1.png";
import splash2 from "../../../assets/Images/teacherSplash2.png";
import splash3 from "../../../assets/Images/teacherSplash3.png";

class TeacherSplash extends Component {
    state = {
        splash1: true,
        splash2: false,
        splash3: false,
    };

    toggleSplash2 = () => {
        this.setState({ splash1: false, splash2: true });
    };
    toggleSplash3 = () => {
        this.setState({ splash1: false, splash2: false, splash3: true });
    };
    render() {
        const { user } = this.props;
        if (user.success) {
            return <Redirect to={"/teacher-home"} />;
        }
        return (
            <React.Fragment>
                <div className="splash-section">
                    {this.state.splash1 && (
                        <React.Fragment>
                            <div className="mt-12vw d-flex justify-content-center align-items-center">
                                <img className="h-100" src={splash1} alt="splash" />
                            </div>
                            <div className="text-center mt-3 text-white ">
                                <span>Manage your students</span>
                            </div>
                            <div
                                className=""
                                onClick={() => this.toggleSplash2()}
                            >
                                <button className="next-btn">Next</button>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="slider"></div>
                                <div className="slider1"></div>
                                <div className="slider1"></div>
                            </div>
                        </React.Fragment>
                    )}
                    {this.state.splash2 && (
                        <React.Fragment>
                            <div className="mt-12vw d-flex justify-content-center align-items-center">
                                <img
                                    className="h-100"
                                    src={splash2}
                                    alt="splash"
                                />
                            </div>
                            <div className="text-center mt-3 text-white">
                                <span>Interact with the teacher</span>
                            </div>
                            <div
                                className="button"
                                onClick={() => this.toggleSplash3()}
                            >
                                <button className="next-btn">Next</button>
                            </div>
                            <div className="scroll">
                                <div className="slider1"></div>
                                <div className="slider"></div>
                                <div className="slider1"></div>
                            </div>
                        </React.Fragment>
                    )}
                    {this.state.splash3 && (
                        <React.Fragment>
                            <div className="mt-12vw d-flex justify-content-center align-items-center">
                                <img
                                    className="h-100"
                                    src={splash3}
                                    alt="splash"
                                />
                            </div>
                            <div className="text-center mt-3 text-white">
                                <span>Live attendence</span>
                            </div>
                            <Link to="/teacher-login">
                                <div className="button">
                                    <button className="get-started-btn">
                                        Get Started
                                    </button>
                                </div>
                            </Link>
                            <div className="scroll">
                                <div className="slider1"></div>
                                <div className="slider1"></div>
                                <div className="slider"></div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher
});

export default connect(mapStateToProps, {})(TeacherSplash);
