import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMediaWiseGallery } from "../../../../redux/Teacher/media/action";
import BackButton from '../../../../elements/BackButton'
import img from '../../../../assets/Images/nophoto.png'
import SingleImage from './SingleImage';
export class MediaGallery extends Component {
    state = {
        image: '',
        open: false,
        id: this.props.match.params,
    }
    componentDidMount() {
        this.setState({ loading: true });
        const { teacher } = this.props;
        this.props.getMediaWiseGallery(teacher?.data?.auth_token, this.state.id);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    openViewer = () => {
        this.setState({ open: !this.state.open })
    }
    render() {
        const { media_gallery } = this.props;
        return (
            <React.Fragment>
                <BackButton title={"Gallery"} />
                {media_gallery?.data?.length > 0 ?
                    <div className="d-flex justify-content-between flex-wrap mx-3 mt-4" >
                        {media_gallery?.data?.length > 0 && media_gallery?.data?.map((data) => (
                            <SingleImage media={data} />
                        ))}
                    </div>
                    :
                    <div className='mt-5 pt-5 w-100 d-flex flex-column justify-content-center align-items-center'>
                        <img src={img} alt="no photo" style={{ width: '65vw' }} className='' />
                        <span>No Images Found...</span>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    media_gallery: state.media.media_gallery,
})

export default connect(mapStateToProps, { getMediaWiseGallery })(MediaGallery)
