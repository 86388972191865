import {
    GET_TEACHER_SCHOOL_MEDIA,
    GET_MEDIA_WISE_GALLERY,
} from './actionType'
const initialState = {
    teacher_media: {},
    media_gallery: {},
};
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TEACHER_SCHOOL_MEDIA:
            return { ...state, teacher_media: action.payload }
        case GET_MEDIA_WISE_GALLERY:
            return { ...state, media_gallery: action.payload }
        default:
            return state;
    }
}
