import React, { Component } from "react";
import { connect } from "react-redux";
import stdimg from "../../../assets/Images/allStudentDetail1.png";
import stdimg2 from "../../../assets/Images/allStudentDetail2.png";
import stdimg3 from "../../../assets/Images/allStudentDetail3.png";
import stdimg4 from "../../../assets/Images/allStudentDetail4.png";
import noImage from '../../../assets/Images/noImage.png'
import ContentLoader from "react-content-loader"
import { Link } from "react-router-dom";
import { getStudent } from '../../../redux/Parent/user/action'
import ProgressiveImage from "react-progressive-image";
import { IMAGE_BASE_URL } from "../../../api";
import { HiOutlineChevronLeft } from 'react-icons/hi';
class AllStudentDetails extends Component {
    state = {
        loading: false,
        student: []
    }
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getStudent(user.data.auth_token, student_id.value).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, student: response.payload.student });
            }
        })
    }
    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        const { student } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                viewBox="0 0 236 470"
                                backgroundColor="#F3F3F3"
                                foregroundColor="#ecebeb"  >
                                <rect x="15" y="134" rx="10" ry="10" width="205" height="66" />
                                <rect x="15" y="215" rx="10" ry="10" width="205" height="66" />
                                <rect x="15" y="295" rx="10" ry="10" width="205" height="66" />
                                <rect x="15" y="378" rx="10" ry="10" width="205" height="66" />
                            </ContentLoader>
                        </React.Fragment>
                    ) :
                    (
                        <React.Fragment>
                            <div className='teacher-image position-relative' >
                                <div className='p-2 pt-3 fw-bold' style={{ borderRadius: '100px' }} onClick={() => this.onHandleBack()}>
                                    <span className='fw-bold'>
                                        <HiOutlineChevronLeft size="1.5em" color={"#fff"} />
                                    </span>
                                </div>
                                <div className="d-flex flex-row position-absolute pt-2" style={{ left: '8px', bottom: '5px' }}>
                                    <div className="py-2 px-3">
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + student?.user?.image}
                                            placeholder={noImage}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    style={{
                                                        width: "25vw",
                                                        height: "25vw",
                                                        borderRadius: "50px",
                                                        objectFit: "cover",
                                                        backgroundRepeat: "round",
                                                        border: '2px solid #fff',
                                                        outline: '1px soild #fff'
                                                    }}
                                                    className=""
                                                    alt="grosav"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>

                                    <div className="d-flex flex-column ms-2 mt-2 text-white">
                                        <span className="text-white fw-bold" style={{ fontSize: '1.8em' }}>{student?.user?.name}</span>
                                        <span className="text-white fw-bold">{student?.student_class?.name} - {student?.section?.name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="px-3" style={{ paddingBottom: '25vw' }}>
                                <Link to={"/student-info"}>
                                    <div className="student-details1 px-3">
                                        <div>
                                            <span className=" mt-2 d-flex flex-column align-items-start justify-content:center fs-4" style={{ color: "#B96678" }}>
                                                Student Info
                                            </span>
                                            <span style={{ color: "#B96678" }}>
                                                Personal details
                                            </span>
                                        </div>
                                        <div className="student-details-image1">
                                            <img src={stdimg} alt="student image1" />
                                        </div>
                                    </div>
                                </Link>

                                <Link to={"/view-teachers"}>
                                    <div className="student-details2 d-flex px-3">
                                        <div>
                                            <span className="mt-2 d-flex flex-column align-items-start justify-content:center fs-4" style={{ color: "#5778AA" }}>
                                                Teachers
                                            </span>
                                            <span style={{ color: "#5778AA" }}>
                                                Teachers details
                                            </span>
                                        </div>
                                        <div>
                                            <img src={stdimg2} alt="student image2" />
                                        </div>
                                    </div>
                                </Link>

                                <Link to={"/attendance"}>
                                    <div className="student-details3 px-3">
                                        <div>
                                            <span className="mt-2 d-flex flex-column align-items-start justify-content:center fs-4" style={{ color: "#70A576" }}>
                                                Attendence</span>
                                            <span style={{ color: "#70A576" }}>
                                                Attendence details
                                            </span>
                                        </div>
                                        <div className="student-details-image2">
                                            <img src={stdimg3} alt="student image3" />
                                        </div>
                                    </div>
                                </Link>

                                <Link to={"/view-mark"}>
                                    <div className="student-details4 px-3">
                                        <div>
                                            <span className="mt-2 d-flex flex-column align-items-start justify-content:center fs-4" style={{ color: "#A57C60" }}>
                                                Marks</span>
                                            <span style={{ color: "#A57C60" }}>
                                                Grade card
                                            </span>
                                        </div>
                                        <div className="student-details-image3">
                                            <img src={stdimg4} alt="student image4" />
                                        </div>
                                    </div>
                                </Link>

                            </div>

                        </React.Fragment>
                    )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent
});

export default connect(mapStateToProps, {
    getStudent
})(AllStudentDetails);
