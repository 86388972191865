import Axios from "axios";
import {
    TEACHER_SIGNUP_URL,
    TEACHER_COMMON_API_URL,
    UPLOAD_HOMEWORK_URL,
    UPLOAD_ACTIVITY_URL,
    GET_ACTIVITY_URL,
    UPLOAD_TODAY_CLASS_URL,
    GET_TEACHER_PROFILE_URL,
    GET_HOMEWORK_URL,
    GET_TEACHER_FEEDBACK_URL
}
    from "../../../api";



import { TEACHER_SIGNUP, TEACHER_COMMON_API, GET_ACTIVITY, LOGOUT_USER, GET_HOMEWORK, GET_TEACHER_FEEDBACK } from "./actionType"



export const teacherSignUp = (user_id, password) => (dispatch) => {
    return Axios.post(TEACHER_SIGNUP_URL, {
        user_id,
        password,
    })
        .then((response) => {
            const teacher = response.data;
            return dispatch({ type: TEACHER_SIGNUP, payload: teacher });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const addHomeWork = (formData) => (dispatch) => {
    return Axios.post(UPLOAD_HOMEWORK_URL, formData)

        .catch(function (error) {
            console.log(error);
        });
};

export const addActivity = (formData) => (dispatch) => {
    return Axios.post(UPLOAD_ACTIVITY_URL, formData)

        .catch(function (error) {
            console.log(error);
        });
};
export const logoutUser = () => (dispatch) => {

    return [
        dispatch({
            type: LOGOUT_USER,
            payload: [],
        }),
    ];
};

//upload todayInClass
export const addTodayInClass = (formData) => {
    return Axios.post(UPLOAD_TODAY_CLASS_URL, formData)
        .catch(function (error) {
            console.log(error);
        });
}

//profile
export const editTeacherProfile = (formData) => (dispatch) =>{
    return Axios.post(GET_TEACHER_PROFILE_URL, formData)
    .then((response) => {
        let teacher = response.data;
        return dispatch({
            type: TEACHER_SIGNUP,
            payload: teacher
        })
    })
}

//common school data
export const getTeacherSchoolData = (token) => (dispatch) => {
    return Axios.post(TEACHER_COMMON_API_URL, { token })
        .then((response) => {
            let schoolData = response.data;
            return dispatch({
                type: TEACHER_COMMON_API,
                payload: schoolData
            })
        })
}

export const getActivity = (token, ) => (dispatch) => {
    return Axios.post(GET_ACTIVITY_URL, { token })
        .then((response) => {
            let student = response.data;
            return dispatch({
                type: GET_ACTIVITY,
                payload: student
            })
        })
}

export const getHomeWork = (token, ) => (dispatch) => {
    return Axios.post(GET_HOMEWORK_URL, { token })
        .then((response) => {
            let homework = response.data;
            return dispatch({
                type: GET_HOMEWORK,
                payload: homework
            })
        })
}

export const getFeedback = (token) => (dispatch) => {
    return Axios.post(GET_TEACHER_FEEDBACK_URL, { token })
        .then((response) => {
            let student = response.data;
            return dispatch({
                type: GET_TEACHER_FEEDBACK,
                payload: student
            })
        })
}
