import React, { Component } from "react";
import { connect } from "react-redux";
// import { GrNext } from "react-icons/gr";
import Footer from "../Footer";
import Accordion from "react-bootstrap/Accordion";
import { BsCircleFill } from "react-icons/bs";
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { getStudentTimeTable } from "../../../redux/Parent/class/action";
import BackButton from "../../../elements/BackButton";
import ContentLoader from "react-content-loader";

export class index extends Component {
    state = {
        loading: false,
        time_table_data: {}
    }
    componentDidMount() {
        this.setState({ loading: true })
        const token = this.props.user.data?.auth_token
        const { value: student_id } = JSON.parse(localStorage.getItem('student_id'))
        if (token) {
            this.props.getStudentTimeTable(token, student_id)
                .then((response) => {
                    if (response.payload) {
                        this.setState({ time_table_data: response.payload, loading: false })
                    }
                })
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 236 470"
                            backgroundColor="#F3F3F3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="34" y="83" rx="8" ry="15" width="52" height="16" />
                            <rect x="193" y="83" rx="8" ry="10" width="20" height="20" />
                            <rect x="34" y="155" rx="8" ry="15" width="52" height="16" />
                            <rect x="193" y="155" rx="8" ry="10" width="20" height="20" />
                            <rect x="34" y="227" rx="8" ry="15" width="64" height="16" />
                            <rect x="193" y="227" rx="8" ry="10" width="20" height="20" />
                            <rect x="34" y="300" rx="8" ry="15" width="59" height="16" />
                            <rect x="193" y="300" rx="8" ry="10" width="20" height="20" />
                            <rect x="34" y="370" rx="8" ry="15" width="52" height="16" />
                            <rect x="193" y="370" rx="8" ry="10" width="20" height="20" />
                            <rect x="34" y="440" rx="8" ry="15" width="64" height="16" />
                            <rect x="193" y="440" rx="8" ry="10" width="20" height="20" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"My TimeTable"} />
                        <div className="timetable-days mt-3">
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0" style={{ color: "#4D56A2", border: "none" }} >
                                    <Accordion.Header className="accordion-header-color" style={{ color: "#4D56A2", border: "none", borderRadius: "10px" }}>
                                        <div className="timetable-days d-flex justify-content-between ">
                                            <div className="">
                                                <span
                                                    style={{
                                                        color: "#524A4D",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Monday
                                                </span>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="">
                                        {this.state.time_table_data && this.state.time_table_data.monday && this.state.time_table_data.monday.map((period, index) =>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="flex-shrink-1 self-align-end">
                                                    <span className="" style={{ color: '#908F8C', fontWeight: '700' }}>{period.period?.name}</span>
                                                </div>
                                                <div className="flex-grow-1 d-flex justify-content-between align-items-center px-2 rounded  py-1 " style={index % 2 == 0 ? { backgroundColor: '#E9EAF4', width: '80%' } : { backgroundColor: '#FFEEEA', width: '80%' }}>
                                                    <div className="d-flex align-items-center ">
                                                        <BsCircleFill color={index % 2 == 0 ? "BABDDB" : '#ED947F'} className="fs-2" />
                                                        <div className="d-flex flex-column ms-2">
                                                            <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.subject?.name}</span>
                                                            <span style={index % 2 == 0 ? { color: "#737BC1" } : { color: '#ED947F' }}>{period.period?.duration}m</span>
                                                        </div>
                                                    </div>
                                                    <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.teacher?.user?.name}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="timetable-days">
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0" style={{ color: "#4D56A2", border: "none" }} >
                                    <Accordion.Header>
                                        <div className="timetable-days d-flex justify-content-between ">
                                            <div className="">
                                                <span
                                                    style={{
                                                        color: "#524A4D",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Tuesday
                                                </span>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="">
                                        {this.state.time_table_data && this.state.time_table_data.tuesday && this.state.time_table_data.tuesday.map((period, index) =>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="flex-shrink-1 self-align-end">
                                                    <span className="" style={{ color: '#908F8C', fontWeight: '700' }}>{period.period?.name}</span>
                                                </div>
                                                <div className="flex-grow-1 d-flex justify-content-between align-items-center px-2 rounded  py-1 " style={index % 2 == 0 ? { backgroundColor: '#E9EAF4', width: '80%' } : { backgroundColor: '#FFEEEA', width: '80%' }}>
                                                    <div className="d-flex align-items-center ">
                                                        <BsCircleFill color={index % 2 == 0 ? "BABDDB" : '#ED947F'} className="fs-2" />
                                                        <div className="d-flex flex-column ms-2">
                                                            <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.subject?.name}</span>
                                                            <span style={index % 2 == 0 ? { color: "#737BC1" } : { color: '#ED947F' }}>{period.period?.duration}m</span>
                                                        </div>
                                                    </div>
                                                    <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.teacher?.user?.name}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="timetable-days">
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0" style={{ color: "#4D56A2", border: "none" }} >
                                    <Accordion.Header>
                                        <div className="timetable-days d-flex justify-content-between ">
                                            <div className="">
                                                <span
                                                    style={{
                                                        color: "#524A4D",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Wednesday
                                                </span>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="">
                                        {this.state.time_table_data && this.state.time_table_data.wenesday && this.state.time_table_data.wenesday.map((period, index) =>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="flex-shrink-1 self-align-end">
                                                    <span className="" style={{ color: '#908F8C', fontWeight: '700' }}>{period.period?.name}</span>
                                                </div>
                                                <div className="flex-grow-1 d-flex justify-content-between align-items-center px-2 rounded  py-1 " style={index % 2 == 0 ? { backgroundColor: '#E9EAF4', width: '80%' } : { backgroundColor: '#FFEEEA', width: '80%' }}>
                                                    <div className="d-flex align-items-center ">
                                                        <BsCircleFill color={index % 2 == 0 ? "BABDDB" : '#ED947F'} className="fs-2" />
                                                        <div className="d-flex flex-column ms-2">
                                                            <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.subject?.name}</span>
                                                            <span style={index % 2 == 0 ? { color: "#737BC1" } : { color: '#ED947F' }}>{period.period?.duration}m</span>
                                                        </div>
                                                    </div>
                                                    <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.teacher?.user?.name}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="timetable-days">
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0" style={{ color: "#4D56A2", border: "none" }} >
                                    <Accordion.Header>
                                        <div className="timetable-days d-flex justify-content-between ">
                                            <div className="">
                                                <span
                                                    style={{
                                                        color: "#524A4D",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Thursday
                                                </span>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="">
                                        {this.state.time_table_data && this.state.time_table_data.thursday && this.state.time_table_data.thursday.map((period, index) =>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="flex-shrink-1 self-align-end">
                                                    <span className="" style={{ color: '#908F8C', fontWeight: '700' }}>{period.period?.name}</span>
                                                </div>
                                                <div className="flex-grow-1 d-flex justify-content-between align-items-center px-2 rounded  py-1 " style={index % 2 == 0 ? { backgroundColor: '#E9EAF4', width: '80%' } : { backgroundColor: '#FFEEEA', width: '80%' }}>
                                                    <div className="d-flex align-items-center ">
                                                        <BsCircleFill color={index % 2 == 0 ? "BABDDB" : '#ED947F'} className="fs-2" />
                                                        <div className="d-flex flex-column ms-2">
                                                            <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.subject?.name}</span>
                                                            <span style={index % 2 == 0 ? { color: "#737BC1" } : { color: '#ED947F' }}>{period.period?.duration}m</span>
                                                        </div>
                                                    </div>
                                                    <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.teacher?.user?.name}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="timetable-days">
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0" style={{ color: "#4D56A2", border: "none" }} >
                                    <Accordion.Header>
                                        <div className="timetable-days d-flex justify-content-between ">
                                            <div className="">
                                                <span
                                                    style={{
                                                        color: "#524A4D",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    friday
                                                </span>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="">
                                        {this.state.time_table_data && this.state.time_table_data.friday && this.state.time_table_data.friday.map((period, index) =>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="flex-shrink-1 self-align-end">
                                                    <span className="" style={{ color: '#908F8C', fontWeight: '700' }}>{period.period?.name}</span>
                                                </div>
                                                <div className="flex-grow-1 d-flex justify-content-between align-items-center px-2 rounded  py-1 " style={index % 2 == 0 ? { backgroundColor: '#E9EAF4', width: '80%' } : { backgroundColor: '#FFEEEA', width: '80%' }}>
                                                    <div className="d-flex align-items-center ">
                                                        <BsCircleFill color={index % 2 == 0 ? "BABDDB" : '#ED947F'} className="fs-2" />
                                                        <div className="d-flex flex-column ms-2">
                                                            <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.subject?.name}</span>
                                                            <span style={index % 2 == 0 ? { color: "#737BC1" } : { color: '#ED947F' }}>{period.period?.duration}m</span>
                                                        </div>
                                                    </div>
                                                    <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.teacher?.user?.name}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="timetable-days">
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0" style={{ color: "#4D56A2", border: "none" }} >
                                    <Accordion.Header>
                                        <div className="timetable-days d-flex justify-content-between ">
                                            <div className="">
                                                <span
                                                    style={{
                                                        color: "#524A4D",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Saturday
                                                </span>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="">
                                        {this.state.time_table_data && this.state.time_table_data.saturday && this.state.time_table_data.saturday.map((period, index) =>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="flex-shrink-1 self-align-end">
                                                    <span className="" style={{ color: '#908F8C', fontWeight: '700' }}>{period.period?.name}</span>
                                                </div>
                                                <div className="flex-grow-1 d-flex justify-content-between align-items-center px-2 rounded  py-1 " style={index % 2 == 0 ? { backgroundColor: '#E9EAF4', width: '80%' } : { backgroundColor: '#FFEEEA', width: '80%' }}>
                                                    <div className="d-flex align-items-center ">
                                                        <BsCircleFill color={index % 2 == 0 ? "BABDDB" : '#ED947F'} className="fs-2" />
                                                        <div className="d-flex flex-column ms-2">
                                                            <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.subject?.name}</span>
                                                            <span style={index % 2 == 0 ? { color: "#737BC1" } : { color: '#ED947F' }}>{period.period?.duration}m</span>
                                                        </div>
                                                    </div>
                                                    <span className="fw-bold" style={index % 2 == 0 ? { color: "#4D56A2" } : { color: '#FD6540' }}>{period.teacher?.user?.name}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        {/* <div>
                            <Footer current={"profile"} />
                        </div> */}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, { getStudentTimeTable })(index);
