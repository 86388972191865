import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactPlayer from "react-player";
import ContentLoader from "react-content-loader";
import BackButton from "../../../elements/BackButton";
import { getClassTutorials } from "../../../redux/Parent/class/action";
import youtube from "../../../assets/Images/YouTube.png";
import image from '../../../assets/Images/tutorials.png'

export class Tutorials extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        let student_id = JSON.parse(localStorage.getItem("student_id"))
        this.props.getClassTutorials(user.data.auth_token, student_id ? student_id.value : user?.data?.students[0]?.id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false });
                }
            })
    }
    render() {
        const { tutorials } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 539"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="13" y="80" rx="10" ry="10" width="255" height="90" />
                            <rect x="13" y="180" rx="10" ry="10" width="255" height="90" />
                            <rect x="13" y="280" rx="10" ry="10" width="255" height="90" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="">
                            <div className="mt-2">
                                <BackButton title={"Tutorials"} />
                            </div>
                            {/* Tutorials */}
                            {tutorials?.success ?
                                <>
                                    <div className="px-3 mt-3 pt-1" style={{ paddingBottom: '15vw' }}>
                                        {tutorials?.data?.length > 0 ?
                                            <div>
                                                {tutorials?.data?.map((data, i) => (
                                                    <div className="tutorial-library bg-white px-2 w-100vw">
                                                        <div className=" tutorial-card p-2 mt-2 position-relative">
                                                            <div className="d-flex">
                                                                <div className="">
                                                                    <ReactPlayer
                                                                        width="80px"
                                                                        height="80px"
                                                                        url={data.url}
                                                                    />
                                                                </div>
                                                                <div className="ms-2 d-flex flex-column">
                                                                    <h2 className="tutorial-heading">
                                                                        {data.title}
                                                                    </h2>
                                                                    <span className="tutorial-details">
                                                                        Subject :
                                                                        {data?.subject?.name}
                                                                    </span>
                                                                    <span className="tutorial-details">
                                                                        Class
                                                                        {data?.class?.name}
                                                                        | Section
                                                                        {data?.section?.name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center position-absolute bottom-0 end-0 m-2">
                                                                <span className="tutorial-details me-2">
                                                                    YouTube
                                                                </span>
                                                                <img
                                                                    src={youtube}
                                                                    alt="youtube"
                                                                    width="15"
                                                                    height="15"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            : <div className="d-flex flex-column align-items-center pt-5 mt-5 ">
                                                <img src={image} style={{ width: '85%', objectFit: 'cover', }} />
                                                <span style={{ fontSize: '11px' }}>Sorry..No Tutorials Found...</span>
                                            </div>
                                        }
                                    </div>
                                </>
                                : null}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    tutorials: state.class.tutorials,
});
export default connect(mapStateToProps, { getClassTutorials })(Tutorials)
