import {
    GET_TEACHER_CHAT_LIST,
    GET_TEACHER_SINGLE_CHAT_VIEW,
    SEND_TEACHER_CHAT,
} from "./actionType";

import {
    GET_TEACHER_CHAT_LIST_URL,
    GET_TEACHER_SINGLE_CHAT_VIEW_URL,
    SEND_TEACHER_CHAT_URL
} from "../../../api";
import Axios from "axios";

export const getTeacherChatList = (token, teacher_id) => (dispatch) => {
    return Axios.post(GET_TEACHER_CHAT_LIST_URL, { token, teacher_id }).then(
        (response) => {
            let chat_list = response.data;
            return dispatch({
                type: GET_TEACHER_CHAT_LIST,
                payload: chat_list,
            });
        }
    );
};

export const getTeacherSingleChatView =
    (token, parent_id, teacher_id) => (dispatch) => {
        return Axios.post(GET_TEACHER_SINGLE_CHAT_VIEW_URL, {
            token,
            parent_id,
            teacher_id,
        }).then((response) => {
            let chats = response.data;
            return dispatch({
                type: GET_TEACHER_SINGLE_CHAT_VIEW,
                payload: chats,
            });
        });
    };

export const sendTeacherChat =
    (token, parent_id, teacher_id,convo_id,message) => (dispatch) => {
        return Axios.post(SEND_TEACHER_CHAT_URL, {
            token,
            parent_id,
            teacher_id,
            convo_id,
            message
        }).then((response) => {
            let send_teacher_chat = response.data;

            return dispatch({
                type: SEND_TEACHER_CHAT,
                payload: send_teacher_chat,
            });
        });
    };
