import { GET_ALL_APPOINTMENTS, GET_STUDENT_DETAILS } from "./actionType";

import { GET_ALL_APPOINTMENTS_URL, GET_STUDENT_APPOINTMENT_DETAILS, GET_STUDENT_APPOINTMENT_DETAILS_URL, SUBMIT_STUDENT_APPOINTMENT, SUBMIT_STUDENT_APPOINTMENT_URL } from "../../../api";

import Axios from "axios";


export const getStudentAppointmentDetails = (token,student_id) => (dispatch) => {
    return Axios.post(GET_STUDENT_APPOINTMENT_DETAILS_URL, {token,student_id })
        .then((response) => {
            let appoinment = response.data;
            return dispatch({
                type: GET_STUDENT_DETAILS,
                payload: appoinment
            })
        })
}

export const submitAppointment = (formData) => (dispatch) => {
    return Axios.post(SUBMIT_STUDENT_APPOINTMENT_URL,formData)
}


export const getAllAppointments = (token,student_id) => (dispatch) => {
    return Axios.post(GET_ALL_APPOINTMENTS_URL, {token,student_id })
        .then((response) => {
            let appoinment = response.data;
            return dispatch({
                type: GET_ALL_APPOINTMENTS,
                payload: appoinment
            })
        })
}
